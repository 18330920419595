// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-drawer-link {
  margin: auto;
  margin-bottom: 23px;
  width: -moz-fit-content;
  width: fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
}

.lr-drawer-body-desc {
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/incident-type/incident-type.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,4BAAuB;EAAvB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".lr-drawer-link {\n  margin: auto;\n  margin-bottom: 23px;\n  width: fit-content;\n  block-size: fit-content;\n}\n\n.lr-drawer-body-desc {\n  padding-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
