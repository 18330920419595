// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-modal {
  display: none;
  align-content: center;
}
.olr-modal-header {
  color: #403C3C;
  font-weight: 600;
}

.olr-center-text {
  text-align:center;
}
.olr-charcoal02 {
  color: #403C3C
}
.olr-merna03-red {
  color: #D62311;
}

.olr-modal-footer-margin {
  margin-top:24px;
  margin-bottom:24px;
}
.olr-center-button {
  margin: 0 auto;
}
.olr-modal-body {
  padding-top:0px;
  padding-bottom:24px;
}

@media screen and (max-width: 576px) {
  .olr-modal-header{
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 24px;
  }
  .olr-policy-msg-spacing {
    margin-bottom: 24px;
  }

  .olr-header-text {
    padding: 10px 0px 0px 0px;
  }
}

@media (min-width: 768px) {
  .olr-modal-header {
    font-size: 33px;
    line-height: 33px;
    margin-bottom: 24px;
  }
  .olr-policy-msg-spacing {
    margin-bottom: 24px;
  }

  .olr-header-text {
    padding: 5px 0px 0px 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/storage-fees-modal/storage-fees-modal.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE;AACF;AACA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;;EAEA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".olr-modal {\n  display: none;\n  align-content: center;\n}\n.olr-modal-header {\n  color: #403C3C;\n  font-weight: 600;\n}\n\n.olr-center-text {\n  text-align:center;\n}\n.olr-charcoal02 {\n  color: #403C3C\n}\n.olr-merna03-red {\n  color: #D62311;\n}\n\n.olr-modal-footer-margin {\n  margin-top:24px;\n  margin-bottom:24px;\n}\n.olr-center-button {\n  margin: 0 auto;\n}\n.olr-modal-body {\n  padding-top:0px;\n  padding-bottom:24px;\n}\n\n@media screen and (max-width: 576px) {\n  .olr-modal-header{\n    font-size: 25px;\n    line-height: 25px;\n    margin-bottom: 24px;\n  }\n  .olr-policy-msg-spacing {\n    margin-bottom: 24px;\n  }\n\n  .olr-header-text {\n    padding: 10px 0px 0px 0px;\n  }\n}\n\n@media (min-width: 768px) {\n  .olr-modal-header {\n    font-size: 33px;\n    line-height: 33px;\n    margin-bottom: 24px;\n  }\n  .olr-policy-msg-spacing {\n    margin-bottom: 24px;\n  }\n\n  .olr-header-text {\n    padding: 5px 0px 0px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
