import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'olr-icon-business',
  templateUrl: './icon-business.component.html',
  styleUrls: ['./icon-business.component.css']
})
export class IconBusinessComponent {

  constructor() { }

}
