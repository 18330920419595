// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-card-container {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.lr-card-details {
    font-weight: normal;
    margin-bottom: 0;
    flex-grow: 1;
}
.lr-heading,
.lr-textbox,
.lr-save-btn {
    width: 100%;
    flex-basis: 100%;
}
.lr-textbox {
    position: relative;
    margin-bottom: 20px;
}
.lr-textbox input {
    padding-right: 56px;
}
.lr-word-break {
    width: 50%;
    word-break: break-all;
    padding-right: 50px;
}
.lr-edit-btn,
.lr-save-btn {

    background: none;
    border: none;
}
.lr-text-clear {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    z-index: 5;
}

.olr-icon-close-filled {
    background-image: url('icon-close-filled.svg');
    width: 24px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/card-standard-editable/card-standard-editable.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;AACA;;;IAGI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,qBAAqB;IACrB,mBAAmB;AACvB;AACA;;;IAGI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,8CAAgE;IAChE,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,wBAAwB;IACxB,cAAc;AAClB","sourcesContent":[".lr-card-container {\n    border-bottom: solid 1px #e6e6e6;\n    padding-bottom: 15px;\n    margin-bottom: 15px;\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n}\n.lr-card-details {\n    font-weight: normal;\n    margin-bottom: 0;\n    flex-grow: 1;\n}\n.lr-heading,\n.lr-textbox,\n.lr-save-btn {\n    width: 100%;\n    flex-basis: 100%;\n}\n.lr-textbox {\n    position: relative;\n    margin-bottom: 20px;\n}\n.lr-textbox input {\n    padding-right: 56px;\n}\n.lr-word-break {\n    width: 50%;\n    word-break: break-all;\n    padding-right: 50px;\n}\n.lr-edit-btn,\n.lr-save-btn {\n\n    background: none;\n    border: none;\n}\n.lr-text-clear {\n    background: none;\n    border: none;\n    margin: 0;\n    padding: 0;\n    position: absolute;\n    top: 0;\n    right: 0;\n    width: 56px;\n    height: 56px;\n    z-index: 5;\n}\n\n.olr-icon-close-filled {\n    background-image: url(../../../assets/img/icon-close-filled.svg);\n    width: 24px;\n    height: 20px;\n    background-repeat: no-repeat;\n    background-size: contain;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
