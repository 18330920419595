import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtherVehiclesRoutingModule } from './other-vehicles-routing.module';
import { VehicleMakeComponent } from './vehicle-make/vehicle-make.component';
import { OtherNameComponent } from './other-name/other-name.component';
import { OtherInvolvedTypeComponent } from './other-involved-type/other-involved-type.component';
import { SharedModule } from '../shared/shared.module';
import { OtherContactComponent } from './other-contact/other-contact.component';
import { VehicleKnownComponent } from './vehicle-known/vehicle-known.component';
import { OtherInformationComponent } from './other-information/other-information.component';
import { OtherPersonComponent } from './other-person/other-person.component';
import { ClaimantParticipantsComponent } from './claimant-participants/claimant-participants.component';


@NgModule({
  declarations: [
    VehicleMakeComponent,
    OtherNameComponent,
    OtherInvolvedTypeComponent,
    OtherContactComponent,
    VehicleKnownComponent,
    OtherInformationComponent,
    OtherPersonComponent,
    ClaimantParticipantsComponent
  ],
  imports: [
    CommonModule,
    OtherVehiclesRoutingModule,
    SharedModule,
  ],
  exports: [
    SharedModule
  ]
})
export class OtherVehiclesModule { }
