export class Customer {
    identifier: string;
    externalClientId: string;
    customerType: string;
    languages: any[];
    name: any;
    email: string;
    residentialAddress: any;
    mailingAddress: any;
    protectedPerson: boolean;
    mobilePhone: string;
    workPhone: string;
    homePhone: string;
    workExtension: string;

    constructor() {}
}
