import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-preferred-phone',
  templateUrl: './preferred-phone.component.html',
  styleUrls: ['./preferred-phone.component.css', '../../shared/styles/question-common-styling.scss']
})
export class PreferredPhoneComponent extends BaseComponent implements OnInit {
  numbers: string[] = [];
  selectedPhone = '';
  selectedPhoneIndex: number;
  answeredState = false;
  hideEdit = true;

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    if (this.isFireLossReport) {
      this.analytics.sendData('insured-phonenumpref-question');
    } else {
      this.analytics.sendData('insured-phonetype-question');
    }
    this.numbers = [...this.step.question.allowedValues];
    if (this.step.completed) {
      this.answeredState = true;
      this.preselectPhoneNumber();
    } else {
      const index = this.numbers.indexOf('anotherNumber');
      if (index > -1) {
        this.numbers.splice(index, 1);
      }
    }
  }

  preselectPhoneNumber() {
    this.selectedPhone = this.step.question.answers[0];
    for (let i = 0; i < this.numbers.length; i++) {
      if (this.selectedPhone === this.numbers[i]) {
        this.selectedPhoneIndex = i;
      }
    }
    this.hideEdit = this.selectedPhoneIndex < this.numbers.length - 1;
  }

  editNewNumber() {
    const route = this.isFireLossReport ? '/homeandproperty/contact-information/add-new-phone' : '/contact-information/add-new-phone';
    this.router.navigate([route]);
  }

  answerQuestion(answer: string): void {
    this.updateAnswer(answer); // no log
  }

  selectPhone(answer: string) {
    this.selectedPhone = answer;
  }
}
