import { Injectable } from '@angular/core';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { LossReportService } from './loss-report.service';
import { Router } from '@angular/router';
import { LogService } from './log.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { HagertyRequest } from '../models/hagerty-request.model';
import { HagertySupportingData } from '../models/hagerty-supporting-data.model';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { parseJwt } from 'customer-oauth2-token-management-utility';

@Injectable({
  providedIn: 'root'
})
export class HagertyService {

  constructor(
    private hostnameBuilderService: HostnameBuilderService,
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private lossReportService: LossReportService,
    private router: Router,
    private logService: LogService,
    private cookieService: CookieHandlerService,
  ) { }

  execute(policyNumber: string): void {
    const logDetails = this.logService.getLogDetails();
    const hagertyrequest = new HagertyRequest();
    const supportingdata = new HagertySupportingData();
    const chosenDate = this.lossReportService.getIncidentDate()
    const formattedDate = chosenDate + "T00:00:00Z"
    const formatPolicyNumber = policyNumber.replace(/-/g, "");
    const formattedPolicyNumer = formatPolicyNumber.slice(-2) + formatPolicyNumber.charAt(formatPolicyNumber.length - 3) + formatPolicyNumber.slice(0, 7);

    supportingdata.lossTimestamp = formattedDate;
    supportingdata.vehicleId = '1'; // re-work later for PL Mod

    hagertyrequest.agreementNumber = formattedPolicyNumer;
    hagertyrequest.applicationName = 'Auto';
    hagertyrequest.intent = 'customerCreateLoss';
    hagertyrequest.supportingData = supportingdata;
    hagertyrequest.sourceSystemCode = 28;

    const ssoToken = this.cookieService.getSSOToken();
    let parsedJwt = parseJwt();
    const oauthHeader = this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : '';

    const headers = parsedJwt ? new HttpHeaders({
      'Authorization': oauthHeader,
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId()
    }) : new HttpHeaders({
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId()
    })

    const hagertyURL = this.hostnameBuilderService.getHagertyURL();
    this.loadingService.startRequest();
    this.httpClient.post(hagertyURL, hagertyrequest, { headers }).toPromise().then((response: any) => {
      this.logService.log(CliffLogCodes.OLR_HAGERTY_SUCCESS, logDetails);
      this.redirectToHagerty(response);
    }).catch((error: any) => {
      this.logService.log(CliffLogCodes.OLR_HAGERTY_FAILED, logDetails);
      this.loadingService.completeRequest();
      this.router.navigate(['/error/technical-error']);

    });

  }

  /* istanbul ignore next */
   redirectToHagerty(redirect: any): void { // ****This is a PRIVATE function only. Only use for Hagerty Service.*****
    const f = document.createElement('form');
    f.setAttribute('id', 'hagertyForm');
    f.setAttribute('method', 'post');
    f.setAttribute('action', redirect.RedirectURL);

    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = 'SAMLResponse';
    i.value = redirect.identityAssertion;
    f.appendChild(i);

    const j = document.createElement('input');
    j.type = 'hidden';
    j.name = 'RelayState';
    j.value = redirect.relayState;
    f.appendChild(j);

    document.body.appendChild(f);
    f.submit();
  }

}
