// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claimant-dob {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/claimant-dob/claimant-dob.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[".claimant-dob {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
