import { Component, Inject, OnInit } from '@angular/core';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { RoutingService } from '../../services/routing.service';
import { AssuredService } from '../../services/assured.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-redirect-assured',
  standalone: true,
  imports: [],
  templateUrl: './redirect-assured.component.html',
  styleUrl: './redirect-assured.component.css'
})
export class RedirectAssuredComponent implements OnInit {

  stateParam: string = '';
  authCodeParam: string = '';
  errorParam: string = '';
  errorDescriptionParam: string = '';

  constructor(
    @Inject('Window') private window: any,
    private hostnameBuilderService: HostnameBuilderService,
    private sfAnalyticsService: SfAnalyticsService,
    private featureFlagService: FeatureFlagService,
    private routingService: RoutingService,
    private route: ActivatedRoute,
    private assuredService: AssuredService,
    private logService: LogService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const parameters = this.route.snapshot.queryParams;
    this.stateParam = parameters.state || '';
    this.authCodeParam = parameters.code || '';
    this.errorParam = parameters.error || '';
    this.errorDescriptionParam = parameters.error_description || '';

    this.sfAnalyticsService.setScreenChannel('filealoss-auto');

    if(this.authCodeParam !== '' && this.errorParam === '') {
      this.sfAnalyticsService.sendData('assured-redirect-success');
      this.logService.log(CliffLogCodes.ASSURED_REDIRECT_SUCCESS);
      this.goToAssuredExperience(this.authCodeParam, this.stateParam);
    } else {
      this.sfAnalyticsService.sendData('assured-redirect-failure');
      this.logService.log(CliffLogCodes.ASSURED_REDIRECT_FAILURE);
      this.failoverToOLRExperience();
    }
  }

  private goToAssuredExperience(authParameter: string, stateParameter: string) {
    const assuredExperienceURL = this.hostnameBuilderService.getAssuredExperienceURL(authParameter, this.assuredService.policyNumber, this.assuredService.dateOfLoss);
    this.window.location.href = assuredExperienceURL;
  }

  private failoverToOLRExperience() {
    if (this.featureFlagService.isOptInEnabled()) {
      this.router.navigate(['/opt-in/preferences']);
    } else {
      this.routingService.navigateNext();
    }
  }

}
