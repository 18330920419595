import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';

@Component({
  selector: 'olr-modal-policy-view',
  templateUrl: './modal-policy-view.component.html',
  styleUrls: ['./modal-policy-view.component.css']
})
export class ModalPolicyViewComponent implements OnInit, OnDestroy {

  @Input() answer: string;
  @Input() claimType: string;
  @Input() dateOfLoss: string;
  @Input() inactivePolicies: any[];
  @Output() setSelection = new EventEmitter<any>();
  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice: boolean;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService
  ) {
    this.isMobileDevice = window.innerWidth <= ModalPolicyViewComponent.oneXSmallBreakpointPx;
  }

  ngOnInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-policy-view'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-policy-view'));
  }

  onResize(event:any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < ModalPolicyViewComponent.oneXSmallBreakpointPx;
  }

  closeModal(): void {
    this.analytics.sendData('policynumber-question', null, 'active');
    this.window['oneX'].Modal.hideModal();
  }

  changeIncidentDate(): void {
    this.analytics.sendData('dateofloss-question');
    this.window['oneX'].Modal.hideModal();
  }

  private getKeyValue(attributes: any[], key: string): string {
    let found: any;
    for(const attribute of attributes) {
      if(attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if(found) {
      return found.value[0];
    }
    return '';
  }

  getButtonHeader(policy: any) {
    if(this.claimType === 'auto') {
      const year = this.getKeyValue(policy.value, 'year');
      const make = this.getKeyValue(policy.value, 'make');
      const model = this.getKeyValue(policy.value, 'model');
      return `${year} ${make} ${model}`.toUpperCase();
    }
    return this.getKeyValue(policy.value, 'policyType').toUpperCase();
  }

  answerPolicyQuestion(policy: any) {
    this.setSelection.emit(policy);
    this.closeModal();
  }

}
