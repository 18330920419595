import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'olr-vehicle-location',
  templateUrl: './vehicle-location.component.html',
  styleUrls: ['./vehicle-location.component.css', '../../shared/styles/question-common-styling.scss']
})
export class VehicleLocationComponent extends BaseComponent implements OnInit {
  incidentAddress: string;
  mailingAddress: string;
  showMyAddress: boolean = false;
  titleCasePipe = new TitleCasePipe();

  constructor(
    private customerProxyService: CustomerProxyService,
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private lossReportAnswerFinderService: LossReportAnswerFinderService,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData('vehicle-location-question');
    this.customerProxyService.getCustomerInfo();
    this.getMailingAddress();
    this.getIncidentAddress();
    this.highlightButtonStandardAnswer();
  }

  findAnswer(question: string){
    return this.lossReportAnswerFinderService.findAnswer(question);
  }

  getIncidentAddress(): void {
    const incidentLocationStep = this.lossReportService.getStepByName('incidentLocation');
    const incidentLocationAns =  incidentLocationStep.question.answers[0];

    if(incidentLocationAns === 'somewhereElse') {
      this.showMyAddress = true;
      const incidentLocationDetailsStep = this.lossReportService.getStepByName('incidentLocationDetails');
      const incidentLocationDetailsSubQuestions = incidentLocationDetailsStep?.question.subQuestions[0];
      this.incidentAddress = this.getAddressData(incidentLocationDetailsSubQuestions);
    } else if(incidentLocationAns === 'mailingAddress') {
      this.showMyAddress = false;
      this.incidentAddress = this.mailingAddress;
    } else {
      this.showMyAddress = true;
      this.incidentAddress = '';
    }
  }

  private getMailingAddress(): void {
    const customerInfo = this.customerProxyService.customerInfo;
    const address = customerInfo ? customerInfo.mailingAddress : null;
    if (address) {
      const street = this.titleCasePipe.transform(address.addressLines[0]);
      const city = this.titleCasePipe.transform(address.city);
      const state = address.stateProvince.replace(/\.+$/, "");
      let postalCode = address.postalCode;
      postalCode = postalCode ? postalCode.substring(0, 5) : '';
      this.mailingAddress = `${street}, ${city}, ${state} ${postalCode}`;
    }
  }

  private getAddressData(subQuestionData: any[]): string {
    const street1 = this.titleCasePipe.transform(this.getStepAnswer(subQuestionData, 'street1'));
    const street2 = this.titleCasePipe.transform(this.getStepAnswer(subQuestionData, 'street2'));
    const city = this.titleCasePipe.transform(this.getStepAnswer(subQuestionData, 'city'));
    const state = this.getStepAnswer(subQuestionData, 'state');
    const postalCode = this.getStepAnswer(subQuestionData, 'postalCode');
    return this.appendLine(street1) + this.appendLine(street2) + this.appendLine(city, ',') + this.appendLine(state) + this.appendLine(postalCode)
  }

  private getStepAnswer(hayStack: any[], needle: string): string {
    const found = hayStack.find((question) => question.name === needle);
    return found ? found.answers[0] : '';
  }

  private appendLine(value: string, separator: string = ''): string {
    let line = '';
    if (value) { line = value; }
    if (line && separator) { line = line + separator; }
    return line ? line + ' ' : '';
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.INCIDENT_LOCATION, logDetails);
    this.updateAnswer(answer);
  }
}
