import { Component, OnInit, ViewChild, ElementRef, Inject,  AfterViewInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../../services/claims-events.service';

@Component({
  selector: 'olr-policy-search',
  templateUrl: './policy-search.component.html',
  styleUrls: ['./policy-search.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class PolicySearchComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild('insuredLastName', {static: true}) insuredLastName: ElementRef;
  @ViewChild('vehicleMake', {static: true}) vehicleMake: ElementRef;
  @ViewChild('phoneNumber', {static: true}) phoneNumber: ElementRef;
  @ViewChild('policyNumber', {static: true}) policyNumber: ElementRef;
  @ViewChild('vinNumber', {static: true}) vinNumber: ElementRef;
  isPolicyNumberError = false;
  isVinNumberError = false;
  isInsuredLastNameError = false;
  isVehicleMakeError = false;
  isPhoneNumberError = false;
  insuredLastNameSubQuestion: any;
  vehicleMakeSubQuestion: any;
  phoneNumberSubQuestion: any;
  policyNumberSubQuestion: any;
  vinNumberSubQuestion: any;
  isValueProvided = false;
  makeOptions = [];
  showSearchCriteria = false;
  makeAnswer = '';

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    @Inject('Window') private window: any
    ) {
      super(lossReportService, router, routingService, logService);
    }

  ngOnInit(): void {
    this.claimsEventsService.buildEvent('Capture Participant Details');
    this.analytics.sendData('insured-policy-details');
    this.getSubQuestions();
    this.getOptions();
    this.displayAnsweredState();
  }
  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#policyFields'));
    this.window.oneX.addElement(document.querySelector('#policyHolderDetails'));
  }

  private displayAnsweredState(){
    this.insuredLastName.nativeElement.value = this.insuredLastNameSubQuestion.answers[0] || '';
    this.phoneNumber.nativeElement.value = this.phoneNumberSubQuestion.answers[0] || '';
    this.makeAnswer = this.vehicleMakeSubQuestion.answers[0] || '';
    this.policyNumber.nativeElement.value = this.policyNumberSubQuestion.answers[0] || '';
    this.vinNumber.nativeElement.value = this.vinNumberSubQuestion.answers[0] || '';
    this.updateContinueButtonVisibility();
  }

  private getSubQuestions() {
    this.insuredLastNameSubQuestion = this.step.question.subQuestions[0][0].subQuestions[0][0];
    this.phoneNumberSubQuestion = this.step.question.subQuestions[0][0].subQuestions[0][1];
    this.vehicleMakeSubQuestion = this.step.question.subQuestions[0][0].subQuestions[0][2];
    this.policyNumberSubQuestion = this.step.question.subQuestions[0][1].subQuestions[0][0];
    this.vinNumberSubQuestion = this.step.question.subQuestions[0][1].subQuestions[0][1];
  }

  private validateStringAnswers(): boolean {
    const isInsuredLastNameValid = this.regexValidate(
      this.insuredLastName.nativeElement.value,
      this.insuredLastNameSubQuestion.allowedValuesRegex,
      this.insuredLastNameSubQuestion.minAnswers
      );
    this.isInsuredLastNameError = !isInsuredLastNameValid;
    const isPhoneNumberValid = this.regexValidate(
      this.formattedPhoneNumber(),
      this.phoneNumberSubQuestion.allowedValuesRegex,
      this.phoneNumberSubQuestion.minAnswers
      );
    this.isPhoneNumberError = !isPhoneNumberValid;
    const isPolicyNumberValid = this.regexValidate(
      this.policyNumber.nativeElement.value,
      this.policyNumberSubQuestion.allowedValuesRegex,
      this.policyNumberSubQuestion.minAnswers
      );
    this.isPolicyNumberError = !isPolicyNumberValid;
    const isVinNumberValid = this.regexValidate(
      this.vinNumber.nativeElement.value,
      this.vinNumberSubQuestion.allowedValuesRegex,
      this.vinNumberSubQuestion.minAnswers
      );
    this.isVinNumberError = !isVinNumberValid;
    return isInsuredLastNameValid && isPhoneNumberValid && isPolicyNumberValid && isVinNumberValid;
  }

  private getOptions() {
    this.makeOptions = this.step.question.subQuestions[0][0].subQuestions[0][2].allowedValues;
  }

  updateContinueButtonVisibility(): void {
    this.showSearchCriteria = this.matchCriteriaAnswered;
    if (!this.showSearchCriteria) {
      this.policyNumber.nativeElement.value = '';
      this.vinNumber.nativeElement.value = '';
    }
    if (this.searchCriteriaAnswered && this.matchCriteriaAnswered) {
      this.isValueProvided = true;
    } else {
      this.isValueProvided = false;
    }
  }

  get matchCriteriaAnswered() {
    return this.insuredLastName.nativeElement.value.length > 0 ||
      this.formattedPhoneNumber().length > 9 ||
      this.vehicleMake.nativeElement.value.length > 0 ||
      this.makeAnswer.length > 0;
  }

  get searchCriteriaAnswered() {
    return this.policyNumber.nativeElement.value.length > 11 || this.vinNumber.nativeElement.value.length > 0;
  }

  resetError(){
    this.isInsuredLastNameError = false;
    this.isPhoneNumberError = false;
    this.isVehicleMakeError = false;
    this.isPolicyNumberError = false;
    this.isVinNumberError = false;
  }

  answerQuestion(): void {
    const logDetails = this.logService.getLogDetails();
    if (this.validateStringAnswers()) {
      const answers = [];
      const policySearchCriteria = [];
      const policyNumber = this.policyNumber.nativeElement.value;
      const vinNumber = this.vinNumber.nativeElement.value;

      const code = CliffLogCodes.CLMT_PLCY_SRCH;
      logDetails.policyNumber = policyNumber.length > 11 ? policyNumber : vinNumber;
      this.logService.log(CliffLogCodes.CLMT_PLCY_SRCH, logDetails);

      policySearchCriteria.push(this.createAnsweredQuestion('1002B', 'matchCriteria', null, this.answerPolicyMatchSubQuestion()));
      policySearchCriteria.push(this.createAnsweredQuestion('1002C', 'searchCriteria', null, this.answerPolicySearchSubQuestion()));
      answers.push(policySearchCriteria);
      this.updateSubQuestions(answers);
    }
  }

  private answerPolicyMatchSubQuestion() {
    const matchValues = [];
    matchValues.push(this.createAnsweredQuestion('1002', 'insuredLastName', this.insuredLastName.nativeElement.value));
    matchValues.push(this.createAnsweredQuestion('1002F', 'phoneNumber', this.formattedPhoneNumber()));
    matchValues.push(this.createAnsweredQuestion('1002D', 'make', this.vehicleMake.nativeElement.value));
    return matchValues;
  }

  private answerPolicySearchSubQuestion() {
    const searchValues = [];
    searchValues.push(this.createAnsweredQuestion('1003', 'policyNumber', this.policyNumber.nativeElement.value));
    searchValues.push(this.createAnsweredQuestion('1002E', 'vinNumber', this.vinNumber.nativeElement.value));
    return searchValues;
  }

  private formattedPhoneNumber(): string {
    const phone: string = this.phoneNumber.nativeElement.value || '';
    return phone.replace(/[^0-9\.]+/g, '');
  }

  get hasSpace(): boolean {
    return /\s/.test(this.insuredLastName.nativeElement.value);
  }
}
