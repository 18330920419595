// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-dc-container {
    width: 32px;
    height: 32px;
    position: relative;
}
.lr-damage-counter {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    font-size: 16px;
    color: #E81E1E;
    border: 1px solid #E81E1E;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    transition: all 80ms ease-in-out; /* this should match JS timeout */
}
.lr-damage-counter.lr-active {
    color: #ffffff;
    background-color: #E81E1E;
}
.lr-damage-counter.lr-animating {
    width: 40px;
    height: 40px;
    top: -4px;
    left: -4px;
    font-size: 20px;
}
.olr-icon-plus-gray{
    background-image: url('icon-plus-gray.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    display: -webkit-inline-box;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/damage-details/exterior-damage/exterior-damage-counter/exterior-damage-counter.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,gCAAgC,EAAE,iCAAiC;AACvE;AACA;IACI,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,eAAe;AACnB;AACA;IACI,2CAAgE;IAChE,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,wBAAwB;IACxB,2BAA2B;AAC/B","sourcesContent":[".lr-dc-container {\n    width: 32px;\n    height: 32px;\n    position: relative;\n}\n.lr-damage-counter {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 32px;\n    width: 32px;\n    font-size: 16px;\n    color: #E81E1E;\n    border: 1px solid #E81E1E;\n    background-color: #FFFFFF;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    float: left;\n    transition: all 80ms ease-in-out; /* this should match JS timeout */\n}\n.lr-damage-counter.lr-active {\n    color: #ffffff;\n    background-color: #E81E1E;\n}\n.lr-damage-counter.lr-animating {\n    width: 40px;\n    height: 40px;\n    top: -4px;\n    left: -4px;\n    font-size: 20px;\n}\n.olr-icon-plus-gray{\n    background-image: url(../../../../assets/img/icon-plus-gray.svg);\n    width: 20px;\n    height: 20px;\n    background-repeat: no-repeat;\n    background-size: contain;\n    display: -webkit-inline-box;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
