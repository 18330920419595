import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { SfAnalyticsService } from 'projects/lossreporting-library/src/lib/services/sf-analytics.service';
import { HostnameBuilderService } from 'projects/lossreporting-library/src/lib/services/helpers/hostname-builder.service';

@Component({
  selector: 'olr-modal-missing-vehicle',
  templateUrl: './modal-missing-vehicle.component.html',
  styleUrls: ['./modal-missing-vehicle.component.css']
})
export class ModalMissingVehicleComponent implements AfterViewInit, OnDestroy {

  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice: boolean;

  constructor(
    private analytics: SfAnalyticsService,
    private urlService: HostnameBuilderService,
    @Inject('Window') private window: any,
  ) {
    this.isMobileDevice = window.innerWidth <= ModalMissingVehicleComponent.oneXSmallBreakpointPx;
  }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-missing-vehicle'));
  }

  onResize(event:any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < ModalMissingVehicleComponent.oneXSmallBreakpointPx;
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-missing-vehicle'));
  }

  closeModal(): void {
    this.analytics.sendData('vehicle-list');
    this.window['oneX'].Modal.hideModal();
  }

  checkDetails(): void {
    this.closeModal();
    this.window.location.href = this.urlService.getAppRedirectionURL('/claimant/incident-details/policy-search');
  }

}
