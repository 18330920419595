import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseConvert'
})
export class CamelCaseConvertPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value ? value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()) : '';
  }

}
