export class PolicyCoverages {
  public collision: string;
  public comprehensive: string;
  public rental: string;
  public ers: string;
  public medicalPayments: string;
  public pip: string;
  public bodilyInjuryLiability: string;
  public propertyDamageLiability: string;
  public uninsured: string;
  public lossOfEarnings: string;
  public deathDismembermentSight: string;
  public showVehicleCoverage: boolean;
  public showInjuryCoverage: boolean;
  public showLiabilityCoverage: boolean;
  public showOtherCoverage: boolean;
  public hasCoverages: boolean;

  constructor() {
    this.collision = '';
    this.comprehensive = '';
    this.rental = '';
    this.ers = '';
    this.medicalPayments = '';
    this.pip = '';
    this.bodilyInjuryLiability = '';
    this.propertyDamageLiability = '';
    this.uninsured = '';
    this.deathDismembermentSight = '';
    this.lossOfEarnings = '';
  }
}
