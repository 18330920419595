import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-disputed-liability',
  templateUrl: './disputed-liability.component.html',
  styleUrls: ['./disputed-liability.component.css', '../../shared/styles/question-common-styling.scss']
})
export class DisputedLiabilityComponent extends BaseComponent implements OnInit {
  answers: [];
  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('liability-questions');
    if (this.step.completed){
      this.answers = this.step.question.answers[0];
    }
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.liabilityAgreement = answer;
    this.logService.log(CliffLogCodes.DISPUTED_LIABILITY, logDetails);
    this.updateAnswer(answer.toLowerCase());
  }

}
