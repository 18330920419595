import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'olr-button-standard',
  templateUrl: './button-standard.component.html',
  styleUrls: ['./button-standard.component.css']
})
export class ButtonStandardComponent {
  @Input() dataValue: string;
  @Output() buttonClicked = new EventEmitter<string>();

  constructor() { }

  onButtonClick() {
    this.buttonClicked.emit(this.dataValue);
  }

}
