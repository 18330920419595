// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.lr-question {
  margin-bottom: 35px;
  text-align: center;
}

.lr-question.lr-has-details {
  margin-bottom: 10px;
}

.lr-question-details {
  margin-bottom: 32px;
  text-align: center;
}

.lr-textfield-details {
  display: block;
  padding-left: 20px;
  padding-top: 3px;
}

.lr-mt-25 {
  margin-top: 25px;
}

.lr-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-self: center;
  justify-self: center;
}

.lr-button-center {
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/styles/question-common-styling.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACI,mBAAA;EACA,kBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,mBAAA;EACA,kBAAA;AAGJ;;AADA;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;AAIJ;;AAFA;EACI,gBAAA;AAKJ;;AAHA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;EACA,oBAAA;AAMF;;AAJA;EACE,iBAAA;EACA,kBAAA;AAOF","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n}\n\n.lr-question {\n    margin-bottom: 35px;\n    text-align: center;\n}\n.lr-question.lr-has-details {\n    margin-bottom: 10px;\n}\n.lr-question-details {\n    margin-bottom: 32px;\n    text-align: center;\n}\n.lr-textfield-details {\n    display: block;\n    padding-left: 20px;\n    padding-top: 3px;\n}\n.lr-mt-25 {\n    margin-top: 25px;\n}\n.lr-center {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  justify-items: center;\n  align-self: center;\n  justify-self: center;\n}\n.lr-button-center {\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
