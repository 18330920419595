import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-vehicle-known',
  templateUrl: './vehicle-known.component.html',
  styleUrls: ['./vehicle-known.component.css', '../../shared/styles/question-common-styling.scss']
})
export class VehicleKnownComponent extends BaseComponent implements OnInit {
  numVehicles;
  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService,
    @Inject('Window') private window: any,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('claimant-vehicle-question');
    this.highlightButtonStandardAnswer();
    this.setNumberOfVehicles();
  }

  setNumberOfVehicles() {
    const maxVehiclesCount = this.lossReportService.getStepByName('maxVehiclesCount');
    this.numVehicles = Number(maxVehiclesCount.question.answers[0]);
  }

  triggerModal(): void {
    this.analytics.sendData('claimant-vehicle-question-modal');
    // @ts-ignore
    this.window.oneX.Modal.showModal(this, 'olr-modal-no-additional-vehicles');
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.CLAIMANT_VEHICLE_KNOWN, logDetails);
    this.updateAnswer(answer.toLowerCase());
  }

}
