// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.lr-cancel-btn {
  background: none;
  border: none;
  color: #6e6e6e;
}

.lr-cancel-link {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.lr-save-btn {
  background: none;
  border: none;
  color: #6e6e6e;
}

.lr-save-link {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.custom-footer {
  text-align: right;
  padding-top: 25px;
  padding-bottom: 10px;
  font-family: MecherleLegal-Regular;
  font-size: 12px;
  color: #6E6E6E;
  line-height: 14px;
  background: #F7F0E4;
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  max-width: 730px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  z-index: -1;
}

.lr-mt-25 {
  margin-top: 25px;
}

.lr-nowrap {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/base-page/base-page.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB;EACpB,kCAAkC;EAClC,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n}\n\n.lr-cancel-btn {\n  background: none;\n  border: none;\n  color: #6e6e6e;\n}\n\n.lr-cancel-link {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.lr-save-btn {\n  background: none;\n  border: none;\n  color: #6e6e6e;\n}\n\n.lr-save-link {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.custom-footer {\n  text-align: right;\n  padding-top: 25px;\n  padding-bottom: 10px;\n  font-family: MecherleLegal-Regular;\n  font-size: 12px;\n  color: #6E6E6E;\n  line-height: 14px;\n  background: #F7F0E4;\n}\n\n.footer {\n  bottom: 0;\n  left: 0;\n  right: 0;\n  position: absolute;\n  width: 100%;\n  max-width: 730px;\n  display: flex;\n  margin-right: auto;\n  margin-left: auto;\n  z-index: -1;\n}\n\n.lr-mt-25 {\n  margin-top: 25px;\n}\n\n.lr-nowrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
