import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Inject, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { AddressConstants } from '../../shared/constants/address-constants';
import { Contact } from '../../models/contact.model';

@Component({
  selector: 'olr-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy{
  @Output() setContact = new EventEmitter<any>();
  @Output() inputVerified = new EventEmitter<boolean>();
  @Input() display: boolean;
  @Input() isBusiness: boolean;
  @Input() isClaimant: boolean;
  @Input() isClient: boolean;
  @Input() isEmailOptional: boolean;
  @Input() isPhoneOptional: boolean;
  @Input() isTypeOptional: boolean;
  @Input() isAutopopulated: boolean;
  @ViewChild('firstName', { static: true }) firstName: ElementRef;
  @ViewChild('lastName', { static: true }) lastName: ElementRef;
  @ViewChild('organization', { static: true }) organization: ElementRef;
  @ViewChild('country', { static: true }) country: ElementRef;
  @ViewChild('state', { static: true }) state: ElementRef;
  @ViewChild('city', { static: true }) city: ElementRef;
  @ViewChild('postalCode', { static: true }) postalCode: ElementRef;
  @ViewChild('street1', { static: true }) street1: ElementRef;
  @ViewChild('street2', { static: true }) street2: ElementRef;
  @ViewChild('phoneType', { static: true }) phoneType: ElementRef;
  @ViewChild('phoneNumber', { static: true }) phoneNumber: ElementRef;
  @ViewChild('email', { static: true }) email: ElementRef;
  private isOrganizationValid = false;
  private isCountryValid = true;
  private isStateValid = true;
  private isCityValid = true;
  private isPostalCodeValid = true;
  private isStreet1Valid = true;
  private isStreet2Valid = true;
  private isPhoneTypeValid = true;
  private isPhoneNumberValid = true;
  private isEmailValid = true;
  private isFirstNameValid = false;
  private isLastNameValid = false;
  isFirstNameError = false;
  isLastNameError = false;
  isStreet1Error = false;
  isStreet2Error = false;
  isVerified = true;
  isCityError = false;
  isPostalError = false;
  areFieldsValid = true;
  isPhoneError = false;
  isPhoneLengthError = false;
  isEmailError = false;
  isOrganizationNameError = false;
  initialCountry: string;
  initialState: string;
  countries = AddressConstants.countryStates;
  states: any[];
  selectedStateOption: string;
  selectedPhoneTypeOption: string;
  answer: any;

  get phoneTypes(): string[] {
    return this.isBusiness ? ['work', 'cell', 'fax', 'voice'] : ['cell', 'home', 'work'];
  }

  get phoneDisabled(): boolean {
    return this.phoneType.nativeElement.value === '';
  }

  get labelText(): any {
    const requiredContactText = {
      zip: 'Zip', street1: 'Address 1', city: 'City', state: 'State', phone: this.isPhoneOptional && this.isClient ? 'Phone number (optional)' : 'Phone number', email: this.isEmailOptional ? 'Email (optional)' : (this.isBusiness ? 'Your work email address' : 'Email')
    };
    const optionalContactText = {
      zip: 'Zip (optional)', street1: 'Address 1 (optional)', city: 'City (optional)', state: 'State (optional)', phone: 'Phone number (optional)'
    };
    return this.isBusiness || this.isClaimant ? requiredContactText : optionalContactText;
  }

  get minAnswers(): any {
    return this.isBusiness || this.isClaimant ? {street1: 1, street2: 0, city: 1, postal: 1} : {street1: 0, street2: 0, city: 0, postal: 0};
  }

  get isRequiredComplete(): boolean {
    if (this.isBusiness) {
      return  this.isOrganizationValid && this.isCountryValid && this.isStateValid && this.isCityValid && this.isPostalCodeValid &&
              this.isStreet1Valid && this.isStreet2Valid && this.isPhoneTypeValid && this.isPhoneNumberValid && this.isEmailValid;
    }

    if (this.isClaimant) {
      return  this.isFirstNameValid && this.isLastNameValid && this.isCountryValid && this.isStateValid && this.isCityValid &&
              this.isPostalCodeValid && this.isStreet1Valid && this.isStreet2Valid && this.isPhoneTypeValid && this.isPhoneNumberValid &&
              this.isEmailValid;
    }

    return  this.isCountryValid && this.isStateValid && this.isCityValid && this.isPostalCodeValid &&
            this.isStreet1Valid && this.isStreet2Valid && this.isPhoneTypeValid && this.isPhoneNumberValid;
  }

  get isHidden(): boolean {
    if (this.isBusiness) { return !this.isBusiness; }
    if (this.isClaimant) { return !this.isClaimant; }
    return true;
  }

  private regexPatterns = {
    usa: '([0-9]{5}|[0-9]{9})',
    canada: '(?!.*[DFIOQU])[A-VXY][0-9][A-Z][0-9][A-Z][0-9]',
    mexico: '[0-9]{5}',
    usTerritories: '([0-9]{5}|[0-9]{9})'
  };

  constructor(
    @Inject('Window') private window: any
  ) { }

  ngOnInit(): void {
    this.getAddressInfo();
    this.validateFields();
    this.sendMessage(this.areFieldsValid)
    this.enterParticipantContact();

  }
  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#claimantContactInfo'));
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#claimantContactInfo'));
  }

  getAddressInfo() {
    const country = this.country.nativeElement.value || 'usa';
    this.country.nativeElement.value = country;
    this.initialCountry = country;
    this.setStatesByCountry(country);
    this.window.oneX.$('label[for=country]').addClass('-oneX--floatUp');
  }

  private setStatesByCountry(countryId: string): void {
    const subject = this.countries.find((country) => {
      return country.id === countryId;
    });
    this.states = subject.states;
  }

  selectChangeHandler(event): void {
    this.initialCountry = event.target.value;
    this.setStatesByCountry(event.target.value);
    this.country.nativeElement.value = event.target.value;
    this.enterParticipantContact();
  }

  private formatPostalAnswer() {
    const postalCode = this.postalCode.nativeElement.value || '';
    return this.initialCountry === 'canada' ? postalCode.toUpperCase().replace(/ +/g, '') : postalCode;
  }

  public phoneTypeCheck(){
    this.validateFields();
    if (this.phoneType.nativeElement.value === ''){
      this.phoneNumber.nativeElement.value = '';
      this.isPhoneNumberValid = true;
    } else {
      this.isPhoneError = this.phoneNumber.nativeElement.value === '';
      this.isPhoneNumberValid = false;
    }
    this.validateFields();
    this.sendMessage(this.areFieldsValid);
    this.enterParticipantContact();
  }

  private regexValidate(answer: string, regexString: string, minAnswers: number): boolean {
    if (!answer && minAnswers === 0) {
       return true;
    }
    const regEx = new RegExp(`^${regexString}$`);
    return regEx.test(answer);
  }

  setAnswerState(answer: any){
    this.answer = answer;
    this.window.oneX.addElement(document.querySelector('#claimantContactInfo'));
    if (answer){
      this.initialCountry = answer.country;
      if (this.isBusiness) {
        this.organization.nativeElement.value = answer.organization;
      }else{
        this.firstName.nativeElement.value = answer.firstName;
        this.lastName.nativeElement.value = answer.lastName;
      }
      this.country.nativeElement.value = answer.country;
      this.street1.nativeElement.value = answer.street1;
      this.street2.nativeElement.value = answer.street2 || '';
      this.city.nativeElement.value = answer.city;
      this.state.nativeElement.value = answer.state;
      this.selectedStateOption = answer.state;
      this.postalCode.nativeElement.value = answer.zipCode;
      this.phoneType.nativeElement.value = answer.phoneType || '';
      this.phoneNumber.nativeElement.value = answer.phoneNumber || '';
      this.email.nativeElement.value = answer.email || '';

      setTimeout(() => {
        this.window.oneX.$('.-oneX-textfield--floating-input, .-oneX-dropdown, .-oneX-textfield__input').trigger('change');
      }, 200);

      this.enterParticipantContact();

      this.getAddressInfo();
    }
  }

  private formattedPhoneNumber(): string {
    const phone: string = this.phoneNumber.nativeElement.value || '';
    return phone.replace(/[()\\s-]+/g, '');
  }

  private gatherBusinessResponse(): Contact {
    const contact = this.gatherRegularResponse();
    contact.organization = this.organization.nativeElement.value;
    contact.email = this.email.nativeElement.value;
    return contact;
  }

  private gatherRegularResponse(): Contact {
    const contact = new Contact();
    contact.country = this.country.nativeElement.value || 'usa';
    contact.zipCode = this.formatPostalAnswer();
    contact.street1 = this.street1.nativeElement.value;
    contact.street2 = this.street2.nativeElement.value || '';
    contact.city = this.city.nativeElement.value;
    contact.state = this.state.nativeElement.value;
    contact.phoneType = this.phoneType.nativeElement.value;
    contact.phoneNumber = this.formattedPhoneNumber();
    if (this.isClaimant) {
      contact.firstName = this.firstName.nativeElement.value;
      contact.lastName = this.lastName.nativeElement.value;
      contact.phoneType = this.phoneType.nativeElement.value || '';
      contact.email = this.email.nativeElement.value || '';
    }
    return contact;
  }

  enterParticipantContact(): void {
    if (this.isRequiredComplete) {
      const response = this.isBusiness ? this.gatherBusinessResponse() : this.gatherRegularResponse();
      this.setContact.emit(response);
    } else {
      this.setContact.emit({});
    }
  }
  sendMessage(isFieldValid: boolean): void {
    this.isVerified = isFieldValid;
    this.inputVerified.emit(this.isVerified);
  }


  validateFields(): void {
    if (this.isBusiness) {
      this.isOrganizationValid = this.regexValidate(this.organization.nativeElement.value, '[a-zA-Z\\d\\-.&, \'\\â€™]{1,60}', 1);
    }

    if (this.isClaimant) {
      this.isFirstNameValid = this.regexValidate(this.firstName.nativeElement.value, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-Z\\- \'\\â€™]{{1,45}(?<!-)', 1);
      this.isLastNameValid = this.regexValidate(this.lastName.nativeElement.value, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-Z\\- \'\\â€™]{1,45}(?<!-)', 1);
    }

    if (this.isBusiness || this.isClaimant) {
      this.isEmailValid = this.regexValidate(
        this.email.nativeElement.value, '([\\w-$%]+(\\.[\\w-$%]+)*@([a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*?\\.[a-zA-Z]{2,6}|(\\d{1,3}\\.){3}\\d{1,3})(:\\d{4})?){1,64}',
        (this.isEmailOptional ? 0 : 1)
      );
    }

    if(!this.isBusiness || !this.isClaimant){
      this.isFirstNameValid = this.regexValidate(this.firstName.nativeElement.value, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\'\\’]{1,45}(?<!-)',1);
      this.isLastNameValid = this.regexValidate(this.lastName.nativeElement.value, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\'\\’]{1,45}(?<!-)',1);
    }


    this.isStreet1Valid = this.regexValidate(this.street1.nativeElement.value, '[A-Za-zÀ-ÖØ-öø-ÿ\\d\\s#\'\\-.&,\\’]{1,80}', this.minAnswers.street1);

    this.isStreet2Valid = this.regexValidate(this.street2.nativeElement.value, '[A-Za-zÀ-ÖØ-öø-ÿ\\d\\s#\'\\-.&,\\’]{1,39}', this.minAnswers.street2);
    this.isCityValid = this.regexValidate(this.city.nativeElement.value, '[A-Za-zÀ-ÖØ-öø-ÿ\\s\\-\\.\'\\’]{1,30}', this.minAnswers.city);

    const state = this.state.nativeElement.value || '';
    this.isStateValid = this.isBusiness || this.isClaimant ?  state !== '' : true;
    let postalRegex
    this.initialCountry ==='canada'? postalRegex = this.regexPatterns[this.initialCountry]:postalRegex = "([0-9]{5}|[0-9]{9})|(?!.*[DFIOQU])[A-VXY][0-9][A-Z][0-9][A-Z][0-9]";
    this.isPostalCodeValid = this.regexValidate(this.formatPostalAnswer(), postalRegex, this.minAnswers.postal);

    this.isPhoneTypeValid = this.phoneType.nativeElement.value !== '' || (this.isBusiness || this.isClaimant ? false : true) || (this.isClient && this.isTypeOptional);

    let required = 0;
    if (this.phoneType.nativeElement.value !== ''){
      if (!this.isTypeOptional){
        required = 1
      }
    }
    this.isPhoneNumberValid = this.regexValidate(this.formattedPhoneNumber(), '\\d{10}', required);
    this.areFieldsValid = this.isStreet1Valid && this.isStreet2Valid && this.isCityValid && this.isStateValid && this.isPostalCodeValid && this.isPhoneNumberValid && this.isFirstNameValid && this.isLastNameValid ;

    this.sendMessage(this.areFieldsValid);
  }

  checkName(name: string): void {
    const length = name.length;
    name[length-1] === '-' ? this.areFieldsValid = false : name[0] === '-' ? this.areFieldsValid = false : this.areFieldsValid = true;
  }

  enterFirstName(): void {

    this.validateFields();
    this.checkName(this.firstName.nativeElement.value);
    this.isFirstNameError = !this.isFirstNameValid;
    this.enterParticipantContact();
    this.sendMessage(this.areFieldsValid);
  }

  enterLastName(): void {
    this.validateFields();
    this.isLastNameError = !this.isLastNameValid;
    this.validateFields()
    this.sendMessage(this.areFieldsValid);
    this.enterParticipantContact();
  }

  enterPhoneNumber(): void {
    this.validateFields();
    this.isPhoneError = !this.isPhoneNumberValid;
    this.enterParticipantContact();
    this.sendMessage(this.areFieldsValid);
  }

  enterOrganization(): void {
    this.validateFields();
    this.isOrganizationNameError = !this.isOrganizationValid;
    this.enterParticipantContact();
  }

  enterEmail(): void {
    this.validateFields();
    this.isEmailError = !this.isEmailValid;
    this.enterParticipantContact();
  }

  enterStreet1(): void {
    this.validateFields();
    this.isStreet1Error = !this.isStreet1Valid;
    this.enterParticipantContact();

  }

  enterStreet2(): void {
    this.validateFields();
    this.isStreet2Error = !this.isStreet2Valid;
    this.enterParticipantContact();

  }

  enterCity(): void {
    this.validateFields();
    this.isCityError = !this.isCityValid;
    this.enterParticipantContact();

  }

  enterState(): void {
    this.validateFields();
    this.enterParticipantContact();

  }

  enterPostalCode(): void {
    this.validateFields();
    this.isPostalError = !this.isPostalCodeValid;
    this.enterParticipantContact();
  }

}
