// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-progress-container {
    display: flex;
    align-items: center;
    line-height: normal;
}
.lr-progress-bar {
    height: 3px;
    background-color: #e6e6e6;
    flex-grow: 1;
    margin-right: 10px;
}
.lr-current-progress {
    height: 3px;
    background-color: #E22925;
}
.lr-progress-label {
    color: #6e6e6e;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/progress/progress-bar/progress-bar.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".lr-progress-container {\n    display: flex;\n    align-items: center;\n    line-height: normal;\n}\n.lr-progress-bar {\n    height: 3px;\n    background-color: #e6e6e6;\n    flex-grow: 1;\n    margin-right: 10px;\n}\n.lr-current-progress {\n    height: 3px;\n    background-color: #E22925;\n}\n.lr-progress-label {\n    color: #6e6e6e;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
