import { Component } from '@angular/core';

@Component({
  selector: 'olr-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

  constructor() { }

}
