// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-selector-spacing {
  margin: 0 16px 16px;
}
.olr-time-field-header {
  color: #403C3C
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/entry-point/date-time-selector/date-time-selector.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE;AACF","sourcesContent":[".olr-selector-spacing {\n  margin: 0 16px 16px;\n}\n.olr-time-field-header {\n  color: #403C3C\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
