// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-margin {
    margin-bottom: 70px;
}
.lr-btn-continue {
    margin-top: 35px;
}
.lr-link {
    display: block;
    text-align: center;
    margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/policy-search/policy-search.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".lr-margin {\n    margin-bottom: 70px;\n}\n.lr-btn-continue {\n    margin-top: 35px;\n}\n.lr-link {\n    display: block;\n    text-align: center;\n    margin-top: 35px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
