import { Component, Inject } from '@angular/core';
import { CookieHandlerService } from 'projects/lossreporting-library/src/lib/services/helpers/cookie-handler.service';
import { HostnameBuilderService } from 'projects/lossreporting-library/src/lib/services/helpers/hostname-builder.service';

@Component({
  selector: 'app-ui-subnav',
  templateUrl: './ui-subnav.component.html',
  styleUrls: ['./ui-subnav.component.css']
})
export class UiSubnavComponent {

  static readonly oneXSmallBreakpointPx = 768;
  rsTitle: string = "Roadside Assistance";
  acTitle: string = "Accident Checklist";

  constructor(
    @Inject('Window') private window: Window,
    private cookieService: CookieHandlerService,
    private hostNameService: HostnameBuilderService
  ) {
    const isMobile = window.innerWidth < UiSubnavComponent.oneXSmallBreakpointPx;
    this.rsTitle = isMobile ? 'Roadside Assist' : 'Roadside Assistance';
    this.acTitle = isMobile ? 'Checklist' : 'Accident Checklist';
  }

  onResize(event:any) {
    const width = event.target.innerWidth;
    const isMobile = width < UiSubnavComponent.oneXSmallBreakpointPx;
    this.rsTitle = isMobile ? 'Roadside Assist' : 'Roadside Assistance';
    this.acTitle = isMobile ? 'Checklist' : 'Accident Checklist';
  }

  gotoClaims():void {
    const myClaims = `https://${this.hostNameService.getAWSHostname('hub.claims')}/#/my-claims`;
    const sfClaims = `https://www${this.hostNameService.getHostname()}/claims`;
    const redirectURL = this.cookieService.get('sf-icp-sso') ? myClaims : sfClaims;
    this.window.location.assign(redirectURL);
  }

  openRoadsideAssistance():void {
    this.window.open('https://www.statefarm.com/claims/get-roadside-assistance', "_blank");
  }

}
