import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceGuard } from '../../services/maintenance.guard';
import { IncidentDateComponent } from '../../incident-details/incident-date/incident-date.component';
import { PolicySelectionComponent } from '../../incident-details/policy-selection/policy-selection.component';
import { IncidentLocationComponent } from '../../incident-details/incident-location/incident-location.component';
import { HomeandpropertyIncidentTypeComponent } from './homeandproperty-incident-type/homeandproperty-incident-type.component';
import { IncidentWeatherComponent } from './homeandproperty-incident-type/incident-weather/incident-weather.component';
import { IncidentWaterDamageComponent } from './homeandproperty-incident-type/incident-water-damage/incident-water-damage.component';
import { IncidentFireComponent } from './homeandproperty-incident-type/incident-fire/incident-fire.component';
import { IncidentSumpPumpFailComponent } from './homeandproperty-incident-type/incident-sump-pump-fail/incident-sump-pump-fail.component';
import { IncidentSepticTankSewerComponent } from './homeandproperty-incident-type/incident-septic-tank-sewer/incident-septic-tank-sewer.component';
import { HomeandpropertySeverityComponent } from './homeandproperty-severity/homeandproperty-severity.component';
import { HomeandpropertyLivableComponent } from "./homeandproperty-livable/homeandproperty-livable.component";
import { IncidentOtherComponent } from './homeandproperty-incident-type/incident-other/incident-other.component';
import { AuthGuard } from '../../services/guards/auth.guard';
import { HomeandpropertyNotLivableDetailsComponent } from "./homeandproperty-not-livable-details/homeandproperty-not-livable-details.component";

const routes: Routes = [
  { path: 'incident-date', component: IncidentDateComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'policy-selection', component: PolicySelectionComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'incident-location', component: IncidentLocationComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'property-incident-type', component: HomeandpropertyIncidentTypeComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'property-incident-weather', component: IncidentWeatherComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'incident-waterdamage', component: IncidentWaterDamageComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'incident-fire', component: IncidentFireComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'incident-septictanksewer', component: IncidentSepticTankSewerComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'incident-sumppumpfail', component: IncidentSumpPumpFailComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'property-incident-other', component: IncidentOtherComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'severity', component: HomeandpropertySeverityComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'livable', component: HomeandpropertyLivableComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'not-livable-details', component: HomeandpropertyNotLivableDetailsComponent, canActivate: [MaintenanceGuard, AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeAndPropertyIncidentDetailsRoutingModule { }
