import { Component, OnInit, AfterViewInit, Inject, OnDestroy, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HostnameBuilderService } from '../services/helpers/hostname-builder.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LossReportService } from '../services/loss-report.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { RoutingService } from '../services/routing.service';
import { PolicyVehicleInfoService } from '../services/policy-vehicle-info.service';
import { SfAnalyticsService } from '../services/sf-analytics.service';
import { PoliciesService } from '../services/policies.service';
import { LogService } from '../services/log.service';
import { PolicyCoverages } from '../models/policy-coverages.model';
import { CustomerProxyService } from '../services/customer-proxy.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ClaimsEventsService } from '../services/claims-events.service';
import { AuthenticationService } from '../services/authentication.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AssuredService } from '../services/assured.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { OlrShouldIFileComponent } from '../shared/modal-should-i-file/modal-should-i-file.component';

@Component({
  selector: 'olr-entry-point',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.css']
})
export class EntryPointComponent implements OnInit, OnDestroy, AfterViewInit {
  informationalBannerContent: boolean = true;
  olrShouldIFileComponent: OlrShouldIFileComponent;
  constructor(
    @Inject('Window') private window: Window,
    private hostnameBuilder: HostnameBuilderService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private lossReportService: LossReportService,
    private loadingService: LoadingService,
    private routingService: RoutingService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private analytics: SfAnalyticsService,
    private policiesService: PoliciesService,
    private logService: LogService,
    private customerProxyService: CustomerProxyService,
    private sanitizer: DomSanitizer,
    private claimsEventsService: ClaimsEventsService,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
    private assuredService: AssuredService
  ) {
    this.isMobileDevice = window.innerWidth <= EntryPointComponent.oneXSmallBreakpointPx;
    let ss = sessionStorage.getItem('flowPath')
    //if there is a flowpath and it isn't claims set local flowpath variable to what is in session storage meaning they are coming from another page else do nothing (keep as claims)
    ss != "" && ss !== 'claims' ? this.flowpath = ss : '';
    sessionStorage.clear();

  }

  @ViewChild('claimType', { static: false }) claimTypeInput: ElementRef;
  @ViewChild('accountType', { static: false }) accountTypeInput: ElementRef;
  static readonly oneXSmallBreakpointPx = 768;
  private _SELECTED_CLAIM_TYPE: string;
  selectedDate = '';
  selectedOrgId = '';
  preselectedOrgId = '';
  isLoading = false;
  isSearching = false;
  authorized = false;
  swapLogin = false;
  policiesNotFound = false;
  onlyInactivePolicies = false;
  policiesFound = false;
  showInactivePolicies = false;
  noInactivePoliciesFound = false;
  showDateTimeFields = false;
  showClaimantLinks = false;
  lockLossReportsCall = false;
  isMotorcycle = false;
  activePolicies = [];
  inactivePolicies = [];
  lossReportCreatedSubscription$: Subscription;
  lossReportUpdatedSubscription$: Subscription;
  loadingSubscription$: Subscription;
  authSubscription$: Subscription;
  coverageSubscription$: Subscription;
  dateAnswer: string;
  glassAnswer: string;
  policyAnswer: string;
  isMobileDevice: boolean;
  showHelpfulTips: boolean = false;
  showShouldIFile: boolean = false;
  showCoverages: boolean = false;
  orgFeatureActive: boolean = false;
  infoBannerTitle: string = ' ';
  infoBannerEnabled: boolean = false;
  infoBannerContent: string = ' ';
  orgGlassOnly: boolean = false;
  policyInfo: PolicyCoverages;
  flowTypes = {
    auto: 'auto',
    fire: 'fire',
    motorcycle: 'auto'
  }
  originalScreenId = '';
  originalScreenChannel = '';
  origin: string;
  flowpath: string;
  relationships = [];
  hasClaimTypeChanged: boolean = false;
  showOrgAccounts: boolean = false;
  displaySmallBusinessCallInDetails: boolean = false;
  usersName: string = '';
  mailingAddressState: string = '';
  showResumeFiling: boolean = false;
  resumingSession: boolean = false;
  showFileClaim: boolean = false;
  hasSessionError: boolean = false;
  previousSessions = [];

 



  ngOnInit(): void {
    this.flowpath ? this.logService.setFlowName(this.flowpath) : this.logService.setFlowName('claims')

    this.logService.setEntryPoint('start-claim');
    const parameters = this.route.snapshot.queryParams;
    if (parameters.origin) {
      this.origin = parameters.origin;
      this.logService.setOrigin(this.origin);
    }
    this.orgFeatureActive = this.featureFlagService.isOrgFeatureActive();
    this.infoBannerEnabled = this.featureFlagService.isInfoBannerEnabled();
    this.infoBannerTitle = this.featureFlagService.getInfoBannerTitle();
    this.infoBannerContent = this.featureFlagService.getInfoBannerContent();
    this.showHelpfulTips = this.featureFlagService.isHelpfulTipsEnabled();
    this.showShouldIFile = this.featureFlagService.isShouldIFileEnabled();
    this.showCoverages = this.featureFlagService.isShowCoveragesEnabled();
    this.logService.setEntryPoint('start-claim');
    this.loadingSubscription$ = this.loadingService.loadingRequest.subscribe(response => {
      this.isLoading = response;
    });

    this.authSubscription$ = this.authService.getAuthInfo().subscribe(response => {
      this.authorized = response;

      if (this.authorized && parameters.redirector === 'glass') {
        this.selectedClaimType = 'auto';
        this.setPreviousSessions();
        this.setRelationships();
        this.processIncomingFromGlass();
        this.showShouldIFile = true;
      } else if (this.authorized) {
        this.authenticationService.startTimer();
        this.preselectClaimType(parameters);
        this.setPreviousSessions();
        this.setRelationships();
        this.setAnsweredState();
      } else {
        this.preselectClaimType(parameters)
        this.showFileClaim = true;
        this.showShouldIFile = false;
        setTimeout(()=>{
          this.window['oneX'].addElement(document.querySelector('.selection-container'));
        }, 10);
        this.flowpath = this.logService.getFlowName();
        this.origin = this.logService.getOrigin();
        sessionStorage.clear();
        this.logService.setFlowName(this.flowpath);
        if (this.origin) {
          this.logService.setOrigin(this.origin);
        }
        this.originalScreenId = 'file-a-claim';
        this.originalScreenChannel = 'claims'
        this.analytics.setScreenChannel(this.originalScreenChannel);
        this.analytics.sendData(this.originalScreenId);
      }
    });

    this.window['oneX'].addElement(document.querySelector('#inpage-login'));
  }

  ngAfterViewInit(): void {
    this.claimsEventsService.buildEvent('Report Claim')
  }
  getCustomerData(): void {
    this.customerProxyService.getCustomerInfo();
    const customerInfoSubscription$ = this.customerProxyService.customerInfoUpdated.subscribe( response => {
      const firstName = this.sanitizer.sanitize(SecurityContext.HTML, this.customerProxyService.customerInfo?.name.firstName);
      const lastName = this.sanitizer.sanitize(SecurityContext.HTML, this.customerProxyService.customerInfo?.name.lastName);
      this.usersName = `${firstName} ${lastName}`;
      this.mailingAddressState = this.sanitizer.sanitize(SecurityContext.HTML, this.customerProxyService.customerInfo?.mailingAddress?.stateProvince);
      setTimeout(()=>{
        customerInfoSubscription$.unsubscribe();
      }, 10);
    });
  }

  gotoLogin() {
    const loginURL = this.hostnameBuilder.getProofingLoginUIURL();
    let qParams = '';
    if(['auto', 'fire'].includes(this.selectedClaimType)) {
      qParams = `?flow=${this.selectedClaimType}&origin=${this.origin}`;
    }
    const pathBackURL = encodeURIComponent(`${this.hostnameBuilder.getReturnRedirectionURL()}${qParams}`);
    this.window.location.href = loginURL + '?goto=' + pathBackURL;
  }

  resumePreviousSession(sessionId: string): void {
    this.hasSessionError = false;
    this.lossReportService.getSession(sessionId);
    let sessionRetrievedSubscription$ = this.lossReportService.sessionRetrieved.subscribe(response => {
      if (response.successful) {
        this.analytics.sendData('resume-saved-session');
        this.resumingSession = true;
        this.setAnsweredState();
        this.glassAnswer = 'No';
        const policyStep = this.lossReportService.getStepByName('policyNumber');
        const policyStepAns = policyStep?.question.answers[0];
        if (policyStepAns !== null && policyStepAns !== undefined && this.selectedClaimType === 'auto') {
          const policies = policyStep.question.displayContent;
          let foundPolicy = this.findPolicy(policies, policyStepAns);
          this.recordPolicyVehicle(foundPolicy);
          this.policiesService.setSelectedPolicyNumber(foundPolicy?.key);
        }
        this.nextSteps();
      } else {
        this.hasSessionError = true;
      }
      setTimeout(()=>{
        sessionRetrievedSubscription$.unsubscribe();
      }, 10);
    });
  }

  setPreviousSessions(): void {
    if (!this.featureFlagService.isSflEnabled()) {
      this.previousSessions = [];
      this.showFileClaim = true;
      setTimeout(()=>{
        this.window['oneX'].addElement(document.querySelector('.selection-container'));
      }, 10);
      this.showResumeFiling = false;
      return;
    }
    this.lossReportService.getSessions();
    const sessionsRetrievedSubscription$ = this.lossReportService.sessionsRetrieved.subscribe(response => {
      this.previousSessions = response;
      if(this.previousSessions.length > 0) {
        this.showFileClaim = false;
        this.showResumeFiling = true;
        this.analytics.sendData('resume-filing');
      } else {
        this.showFileClaim = true;
        setTimeout(()=>{
          this.window['oneX'].addElement(document.querySelector('.selection-container'));
        }, 10);
        this.showResumeFiling = false;
      }
      setTimeout(()=>{
        sessionsRetrievedSubscription$.unsubscribe();
      }, 10);
    });
  }

  toggleShowFileClaim(): void {
    this.hasSessionError = false;
    this.showFileClaim = !this.showFileClaim;
    setTimeout(()=>{
      this.window['oneX'].addElement(document.querySelector('.selection-container'));
      if(this.showFileClaim && this.showGlassQuestion) {
        this.analytics.sendData('glass-question');
      }
    }, 10);
  }

  private setRelationships(): void {
    if(!this.orgFeatureActive) { return; }
    this.getCustomerData();
    this.lossReportService.getRelationships();
    const relationshipSubscription$ = this.lossReportService.relationshipsRetrieved.subscribe(response => {
      this.relationships = response;
      this.showOrgAccounts = response && response.length > 0 && this.selectedClaimType === 'auto';
      setTimeout(()=>{
        relationshipSubscription$.unsubscribe();
        this.window['oneX'].addElement(document.querySelector('.selection-container'));
      }, 10);
    });
  }

  selectRelationship(): void {
    this.hasClaimTypeChanged = false;
    this.orgGlassOnly = false;
    this.glassAnswer = '';
    let account = this.accountTypeInput.nativeElement.value;
    this.selectedOrgId = account;
    this.analytics.setOrgType(account === 'personal' ? 'personal' : 'organization');
    this.logService.setOrgIndicator(account === 'personal' ? 'personal' : 'organization');

    if (!this.lockLossReportsCall && this.selectedDate && this.lossReportService.lossReport) {
      this.lockLossReportsCall = true;
      this.isSearching = true && this.selectedDate != '';
      this.resetPolicyStatus();
      this.updateOrgSelectionStepAnswer(this.selectedOrgId ? this.selectedOrgId : 'personal');
      this.lossReportService.modifyReport(true);
      this.lossReportUpdatedSubscription$ = this.lossReportService.lossReportUpdated.subscribe((lossReport) => {
        this.processLossReport(lossReport);
      });
    } else if(!this.lockLossReportsCall && this.selectedDate && !this.lossReportService.lossReport) {
      this.processDateTimeInput({isAnsInit: false, response: this.selectedDate});
    }

  }

  private updateOrgSelectionStepAnswer(orgSelection: string):void {
    const step = this.lossReportService.getStepByName('orgSelection');
    if(step) {
      step.question.answers = [orgSelection];
      step.completed = true;
    }
  }

  get selectedRelationship(): string {
    if (this.lossReportService.lossReport) {
      this.preselectedOrgId = '';
      const step = this.lossReportService.getStepByName('orgSelection');
      return step && step.question.answers.length > 0 ? step.question.answers[0] : 'personal';
    }
    if (Array.isArray(this.relationships) && this.relationships.length > 0) {
      let activeRelationship = this.relationships.find(relationship => relationship.activeClient);
      if (activeRelationship) {
        this.preselectedOrgId = activeRelationship.id;
        this.selectedOrgId = activeRelationship.id;
        this.analytics.setOrgType('organization');
        return activeRelationship.id;
      }
    }
    return 'personal';
  }

  smallBusinessCall():void {
    this.displaySmallBusinessCallInDetails = true;
  }

  smallBusinessRedirect(flow: string): void {
    this.claimTypeInput.nativeElement.value = flow;
    this.selectClaimType();
  }

  private preselectClaimType(parameters: Params) {
    this.isMotorcycle = parameters.flow === 'motorcycle'
    this.selectedClaimType = this.flowTypes[parameters.flow] ? this.flowTypes[parameters.flow] : 'auto'
    setTimeout(()=>{
      this.window['oneX'].$('#claimType').change();
    }, 10);
  }

  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < EntryPointComponent.oneXSmallBreakpointPx;
  }

  get selectedClaimType(): string {
    if (!this._SELECTED_CLAIM_TYPE) {
      this._SELECTED_CLAIM_TYPE = sessionStorage.getItem('selectedClaimType');
    }
    return this._SELECTED_CLAIM_TYPE || 'auto';
  }

  set selectedClaimType(selectedClaimType: string) {
    this._SELECTED_CLAIM_TYPE = selectedClaimType;
    sessionStorage.setItem('selectedClaimType', selectedClaimType);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('selectedClaimType');
    this.unsubscribe(this.lossReportCreatedSubscription$);
    this.unsubscribe(this.lossReportUpdatedSubscription$);
    this.unsubscribe(this.loadingSubscription$);
    this.unsubscribe(this.authSubscription$);
    this.unsubscribe(this.coverageSubscription$);
    this.window['oneX'].removeElement(document.querySelector('#inpage-login'));
    this.window['oneX'].removeElement(document.querySelector('.selection-container'));
  }

  private unsubscribe(subscription: Subscription): void {
    if (subscription) { subscription.unsubscribe(); }
  }

  private processIncomingFromGlass(): void {
    sessionStorage.clear();
    this.analytics.setScreenChannel('filealoss-auto');
    this.selectedClaimType = 'auto';
    this.processGlassAnswer('No');
  }

  private setAnsweredState(): void {
    const lossreport = this.lossReportService.lossReport;
    if (lossreport) {
      this.selectedClaimType = lossreport.type === 'HOME_AND_PROPERTY' ? 'fire' : 'auto';
      this.setScreenChannel(this.selectedClaimType);
      const dateOfLossStep = this.lossReportService.getStepByName('dateOfLoss');
      this.dateAnswer = dateOfLossStep?.question.answers[0];
      this.selectedDate = this.dateAnswer;
      const policyNumberStep = this.lossReportService.getStepByName('policyNumber');
      this.policyAnswer = policyNumberStep?.question.answers[0];

      if(this.selectedClaimType === 'fire'){
        this.claimsEventsService.buildEvent('Claim Started');
      }
      if (this.selectedClaimType === 'auto') {
        this.isMotorcycle = this.getKeyValue(policyNumberStep.question.displayContent, this.policyAnswer, 'categoryCode') === '08';
      } else {
        this.isMotorcycle = false;
      }

      if (this.dateAnswer) {
        this.showDateTimeFields = true;
        this.processLossReport(lossreport);
      }
    } else if (this.selectedClaimType === 'auto') {
      this.origin = this.logService.getOrigin();
      this.flowpath = this.logService.getFlowName();
      sessionStorage.clear();
      this.logService.setFlowName(this.flowpath);
      if (this.origin) {
        this.logService.setOrigin(this.origin);
      }
      this.analytics.setScreenChannel('filealoss-auto');
      if (this.showGlassQuestion) {
        this.analytics.sendData('glass-question');
      }
    } else if (this.selectedClaimType === 'fire') {
      this.flowpath = this.logService.getFlowName();
      sessionStorage.clear();
      this.logService.setFlowName(this.flowpath);
      this.analytics.setScreenChannel('filealoss-fire');
      this.showDateTimeFields = true;
      this.analytics.sendData('dateofloss-question');
    } else {
      this.origin = this.logService.getOrigin();
      this.flowpath = this.logService.getFlowName();
      sessionStorage.clear();
      this.logService.setFlowName(this.flowpath);
      if (this.origin) {
        this.logService.setOrigin(this.origin);
      }
      this.showDateTimeFields = false;
    }
  }

  get showGlassQuestion(): boolean {
    return this.authorized && this.selectedClaimType === 'auto' && !this.isMotorcycle && !this.showDateTimeFields
      && (this.dateAnswer === '' || this.dateAnswer === undefined) && (this.glassAnswer === '' || this.glassAnswer === undefined);
  }

  selectClaimType() {
    this.hasClaimTypeChanged = true;
    this.showDateTimeFields = false;
    this.showOrgAccounts = false;
    this.resetPolicyStatus();
    this.resetDropdowns();
    let claimType = this.claimTypeInput.nativeElement.value;

    if (claimType === 'motorcycle'){
      this.selectedClaimType = 'auto';
      this.flowpath = 'filealoss-auto'
      this.logService.setFlowName(this.flowpath)
      this.claimsEventsService.buildEvent('Claim Started');
      this.isMotorcycle = true;
    }
    else {
      this.selectedClaimType = claimType;
      this.isMotorcycle = false;
    }
    this.setScreenChannel(this.selectedClaimType);
    if (this.authorized) {
      this.handleShowingDateOfLossQuestion(this.selectedClaimType === 'fire' || this.isMotorcycle);
      if(this.selectedClaimType === 'auto') {
        this.setRelationships();
      }
    }
    if(this.selectedClaimType === 'fire'){
      this.claimsEventsService.buildEvent('Claim Started');
    }
    if (this.showGlassQuestion) {
      this.analytics.sendData('glass-question');
    }
  }

  private handleShowingDateOfLossQuestion(showField: boolean): void {
    setTimeout(() => {
      this.showDateTimeFields = showField;
      if (this.showDateTimeFields) {
        this.analytics.sendData('dateofloss-question');
      }
    }, 2);
  }

  private setScreenChannel(claimType: string): void {
    const channels = { auto: 'filealoss-auto', fire: 'filealoss-fire', motorcycle: 'filealoss-motorcycle' };
    this.analytics.setScreenChannel(channels[claimType] || 'claims');
  }

  displayLogin(): boolean {
    if (!this.selectedClaimType || ["health", "life", "recreational", "small_business"].includes(this.selectedClaimType)) {
      return false;
    }
    this.showClaimantLinks = this.selectedClaimType === "auto"  ||  this.selectedClaimType === 'motorcycle';
    this.window['oneX'].addElement(document.querySelector('#inpage-login'));
    return !this.authorized;
  }

  toggleSwapLogin(): void {
    this.swapLogin = !this.swapLogin;
  }

  processGlassAnswer(answer: string) {
    this.resetPolicyStatus();
    this.glassAnswer = answer;
    if (answer === 'Yes' && (this.selectedOrgId  && this.selectedOrgId !== 'personal')) {
      this.orgGlassOnly = true;
      this.analytics.sendData('org-glass-kickout');
    } else if (answer === 'Yes' && (!this.selectedOrgId || this.selectedOrgId  && this.selectedOrgId === 'personal')) {
      this.window.location.assign(`https://${this.hostnameBuilder.getAWSHostname('reportglassloss.claims')}/injured-kickout`);
    } else {
      this.showDateTimeFields = true;
      this.analytics.sendData('dateofloss-question');
      this.claimsEventsService.buildEvent('Claim Started');
    }
  }

  private resetPolicyStatus(): void {
    this.policiesFound = false;
    this.onlyInactivePolicies = false;
    this.policiesNotFound = false;
    this.swapLogin = false;
    this.displaySmallBusinessCallInDetails = false;
    this.orgGlassOnly = false;
  }

  private resetDropdowns() {
    this.selectedDate = '';
    this.dateAnswer = '';
    this.selectedOrgId = '';
    this.glassAnswer = '';
  }

  redirectClaimant(answer: string) {
    if (answer === "insurance") {
      this.analytics.setScreenChannel('filealoss-OIC');
      this.router.navigate(['../claimant'], { queryParams: { reporterType: 'otherInsuranceCompany' }, relativeTo: this.route });
    } else {
      this.analytics.setScreenChannel('filealoss-claimant');
      this.router.navigate(['../claimant'], { queryParams: { reporterType: 'individual' }, relativeTo: this.route });
    }
  }

  triggerModal(): void {
    if (this.inactivePolicies.length > 0) {
      this.analytics.sendData('inactive-policy-modal');
    } else if (this.inactivePolicies.length === 0) {
      this.analytics.sendData('no-additional-policy-modal');
    }
    this.window['oneX'].Modal.showModal(this, 'olr-modal-policy-view');
  }

  triggerShouldIFileModal(): void {
    this.analytics.sendData('should-i-file-modal');
    this.window['oneX'].Modal.showModal(this, 'olr-should-i-file');
  }

  answerPolicyQuestion(policy: any): void {
    this.logService.setEntryPoint('start-claim');
    if (this.selectedClaimType === 'auto' && this.isMotorcycle) {
      this.analytics.setScreenChannel('filealoss-motorcyle');
      this.recordPolicyVehicle(policy);
    } else if (this.selectedClaimType === 'auto') {
      this.analytics.setScreenChannel('filealoss-auto');
      this.recordPolicyVehicle(policy);
    } else {
      this.analytics.setScreenChannel('filealoss-fire');
    }
    this.policiesService.setSelectedPolicyNumber(policy.key);
    const step = this.lossReportService.getStepByName('policyNumber');
    step.question.answers = [policy.key];
    step.completed = true;
    this.lossReportService.modifyReport();
    this.lossReportUpdatedSubscription$ = this.lossReportService.lossReportUpdated.subscribe(() => {
      this.nextSteps();
    });
  }

  private nextSteps(): void {
    if(this.assuredService.isAssuredEligible(this.selectedClaimType, this.glassAnswer, this.mailingAddressState, this.selectedRelationship, this.policiesService.getSelectedPolicyNumber())) {
      this.logService.log(CliffLogCodes.ASSURED_RETRIEVE_AUTH_CODE);
      this.assuredService.getAuthCode(this.policiesService.getSelectedPolicyNumber(), this.selectedDate);
    } else if (this.featureFlagService.isOptInEnabled()  && this.selectedRelationship === 'personal' && this.showFileClaim) {
      this.router.navigate(['/opt-in/preferences']);
    } else {
      this.routingService.navigateNext();
    }
  }

  private recordPolicyVehicle(policy: any): void {
    const formattedPolicy = {
      risks: [{
        categoryCode: this.getKeyValue(policy.value, 'categoryCode'),
        vehicle: {
          bodyStyle: this.getKeyValue(policy.value, 'bodyStyle'),
          year: this.getKeyValue(policy.value, 'year'),
          make: this.getKeyValue(policy.value, 'make'),
          model: this.getKeyValue(policy.value, 'model'),
        }
      }]
    };
    this.policyVehicleInfoService.setPolicyVehicle(formattedPolicy);
  }

  processDateTimeInput(event: any) {
    if (!this.lockLossReportsCall) {
      this.isSearching = false;
    }
    this.selectedDate = event.response;
    if (!event.isAnsInit) {
      this.policiesFound = false;
      this.policiesNotFound = false;
      this.onlyInactivePolicies = false;

      if (this.selectedDate && !this.lockLossReportsCall) {
        this.lockLossReportsCall = true;
        this.isSearching = true;
        const report = this.lossReportService.lossReport;
        const claimTypeSelection = this.claimTypeInput.nativeElement.value;
        const lossReportClaimType = claimTypeSelection === 'motorcycle' ? 'auto' : claimTypeSelection;
        if (report && this.getLossReportType(report) === lossReportClaimType) {
          const step = this.lossReportService.getStepByName('dateOfLoss');
          step.question.answers = [this.selectedDate];
          step.completed = true;
          this.updateOrgSelectionStepAnswer(this.selectedOrgId ? this.selectedOrgId : 'personal');
          this.lossReportService.modifyReport(true);
          this.lossReportUpdatedSubscription$ = this.lossReportService.lossReportUpdated.subscribe((lossreport) => {
            this.processLossReport(lossreport);
          });
        } else {
          this.lossReportService.createReport(lossReportClaimType, this.route.snapshot.queryParams, this.selectedDate, this.selectedOrgId);
          this.lossReportCreatedSubscription$ = this.lossReportService.lossReportCreated.subscribe((lossreport) => {
            if (lossreport) {
              const agrmntStepAns = this.lossReportService.getStepByName('agreementNumber')?.question.answers[0]
              const policyStep = this.lossReportService.getStepByName('policyNumber');
              const policyStepAns = policyStep?.question.answers[0];
              if (agrmntStepAns && policyStepAns) {
                const policies = policyStep.question.displayContent;
                let foundPolicy = this.findPolicy(policies, policyStepAns);
                this.recordPolicyVehicle(foundPolicy);
                this.policiesService.setSelectedPolicyNumber(foundPolicy?.key);
                this.analytics.sendData('policynumber-question-pvc', null, 'active');
                this.nextSteps();
              } else {
                this.processLossReport(lossreport);
              }
            } else {
              this.processLossReport(lossreport);
            }
          });
        }
      }
    }
  }

  private getLossReportType(lossreport: any): string {
    const types = { AUTO: 'auto', HOME_AND_PROPERTY: 'fire' };
    return types[lossreport?.type] || '';
  }

  private processLossReport(lossreport: any): void {
    this.unsubscribe(this.lossReportCreatedSubscription$);
    this.unsubscribe(this.lossReportUpdatedSubscription$);

    if (lossreport) {
      const step = this.lossReportService.getStepByName('policyNumber');
      const displayContent = step.question.displayContent;
      this.activePolicies = this.filterActive(displayContent);
      this.inactivePolicies = this.filterInactive(displayContent);

      if (this.isMotorcycle) {
        this.activePolicies = this.filterMotorcycles(this.activePolicies);
        this.inactivePolicies = this.filterMotorcycles(this.inactivePolicies);
      } else if(this.selectedClaimType === 'auto') {
        this.activePolicies = this.filterNonMotorcycles(this.activePolicies);
        this.inactivePolicies = this.filterNonMotorcycles(this.inactivePolicies);
      }

      this.isSearching = false;
      if (this.activePolicies.length > 0) {
        this.policiesFound = true;
      } else if (this.activePolicies.length === 0 && this.inactivePolicies.length > 0) {
        this.onlyInactivePolicies = true;
      } else {
        this.policiesNotFound = true;
      }
      if(this.showFileClaim && !this.resumingSession) {
        this.setPolicyDisplayAnalytics(this.activePolicies.length, this.inactivePolicies.length);
      }
    } else {
      // default state as polices not found when error occurs
      this.isSearching = false;
      this.policiesNotFound = true;
      this.analytics.sendData('policy-not-found');
      this.claimsEventsService.buildEvent('Policy Not Found');
    }
    this.lockLossReportsCall = false;
  }

  private filterMotorcycles(policies: any[]): any[] {
    return policies.filter((policy) => {
      return this.getKeyValue(policy.value, 'categoryCode') === '08';
    });
  }

  private filterNonMotorcycles(policies: any[]): any[] {
    return policies.filter((policy) => {
      return this.getKeyValue(policy.value, 'categoryCode') !== '08';
    });
  }


  private setPolicyDisplayAnalytics(activePolicyCount: number, inactivePolicyCount: number): void {
    if (activePolicyCount > 0) {
      this.originalScreenChannel = 'filealoss-auto';
      this.originalScreenId = 'policynumber-question';
      this.analytics.sendData('policynumber-question', null, 'active');
    } else if (activePolicyCount === 0 && inactivePolicyCount > 0) {
      this.originalScreenChannel = 'filealoss-auto';
      this.originalScreenId = 'policynumber-question';
      this.analytics.sendData('policynumber-question', null, 'inactive');
    } else {
      this.analytics.sendData('policy-not-found');
      this.claimsEventsService.buildEvent('Policy Not Found');
    }
  }

  private filterActive(policies: any[]): any[] {
    if (policies.length === 0) { return []; }
    return policies.filter((policy) => {
      return (this.getKeyValue(policy.value, 'status') === 'A' || this.getKeyValue(policy.value, 'liabilityOnlyPolicyStatus') === 'Active');
    });
  }

  private filterInactive(policies: any[]): any[] {
    if (policies.length === 0) { return []; }
    return policies.filter((policy) => {
      return (this.getKeyValue(policy.value, 'status') != 'A' && this.getKeyValue(policy.value, 'liabilityOnlyPolicyStatus') != 'Active');
    });
  }

  private findPolicy(policies: any[], policyNumber: string): any {
    return policies.find(policy => {
      return policy.key === policyNumber;
    });
  }

  private getKeyValue(attributes: any[], key: string, subKey: string = ''): string {
    let found: any;

    for (const attribute of attributes) {
      if (attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if (subKey && found) {
      for (const foundValue of found.value) {
        if (foundValue.key === subKey) {
          return foundValue.value[0];
        }
      }
    }
    return found ? found.value[0] : '';
  }

  private setScreen(): void {
    this.analytics.setValues({
      screenChannel : 'filealoss-auto',
      screenId : 'onboarding-modal2',
    });
  }

  showInfoDrawer(agreementId: any, skipPolicyInfo: boolean = false): void {
    if(skipPolicyInfo)  {
      this.policyInfo = null;
      this.setScreen();
      return;
    }
    this.policyInfo = new PolicyCoverages();
    if(agreementId && this.showCoverages) {
      this.lossReportService.getReportCoverages(agreementId, this.selectedDate);
      this.coverageSubscription$ = this.lossReportService.lossReportCoveragesRetrieved.subscribe((coverages) => {
        this.processCoverages(coverages);
        this.unsubscribe(this.coverageSubscription$);
      });
    } else {
      this.processCoverages([]);
    }
    this.setScreen();
  }

  private processCoverages(coverages: any[]): void {
    const collisionCoverage = this.getCoverageValue(coverages, ['G']);
    if(collisionCoverage) {
      this.policyInfo.collision = `$${collisionCoverage.DEDUCTIBLE}`;
    }

    const comprehensiveCoverage = this.getCoverageValue(coverages, ['D']);
    if(comprehensiveCoverage) {
      this.policyInfo.comprehensive = `$${comprehensiveCoverage.DEDUCTIBLE}`;
    }

    const rentalCoverage = this.getCoverageValue(coverages, ['R', 'R1', 'R2']);
    if(rentalCoverage) {
      const rentalMax = rentalCoverage['LIMIT_PER_OCCURRENCE'];
      const rentalDaily = rentalCoverage['LIMIT_PER_DAY'];
      const rentalPercentage = rentalCoverage['LIMIT_PERCENTAGE'];
      if(rentalDaily) {
        this.policyInfo.rental = `$${rentalDaily} day/$${rentalMax} max`;
      } else if(rentalPercentage !== '') {
        this.policyInfo.rental = `${rentalPercentage}% of rental cost/$${rentalMax} max`;
      }
    }

    const roadsideCoverage = this.getCoverageValue(coverages, ['H']);
    if(roadsideCoverage) {
      this.policyInfo.ers = 'present';
    }

    const medicalPaymentCoverage = this.getCoverageValue(coverages, ['C', 'C2']);
    if(medicalPaymentCoverage) {
      this.policyInfo.medicalPayments = 'present';
    }

    const personalInjuryCoverage = this.getCoverageValue(coverages, ['P']);
    if(personalInjuryCoverage) {
      this.policyInfo.pip = 'present';
    }

    const bodilyInjuryCoverage = this.getCoverageValue(coverages, ['A'], 'Bodily Injury');
    if(bodilyInjuryCoverage) {
      this.policyInfo.bodilyInjuryLiability = 'present';
    }

    const propertyDamageCoverage = this.getCoverageValue(coverages, ['A'], 'Property Damage');
    if(propertyDamageCoverage) {
      this.policyInfo.propertyDamageLiability = 'present';
    }

    const uninsuredMotoristCoverage = this.getCoverageValue(coverages, ['U', 'U1']);
    if(uninsuredMotoristCoverage) {
      this.policyInfo.uninsured = 'present';
    }

    const deathDismembermentLossOfSightCoverage = this.getCoverageValue(coverages, ['Y', 'S']);
    if(deathDismembermentLossOfSightCoverage) {
      this.policyInfo.deathDismembermentSight = 'present';
    }

    const lossOfEarningsCoverage = this.getCoverageValue(coverages, ['Z']);
    if(lossOfEarningsCoverage) {
      this.policyInfo.lossOfEarnings = 'present';
    }

    this.policyInfo.showVehicleCoverage = this.policyInfo && (this.policyInfo.collision != '' || this.policyInfo.comprehensive != '' || this.policyInfo.rental != '' || this.policyInfo.ers != '');
    this.policyInfo.showInjuryCoverage = this.policyInfo && (this.policyInfo.medicalPayments != '' || this.policyInfo.pip != '');
    this.policyInfo.showLiabilityCoverage = this.policyInfo && (this.policyInfo.bodilyInjuryLiability != '' || this.policyInfo.propertyDamageLiability != '');
    this.policyInfo.showOtherCoverage = this.policyInfo && (this.policyInfo.uninsured != '' || this.policyInfo.deathDismembermentSight != '' || this.policyInfo.lossOfEarnings != '');
    this.policyInfo.hasCoverages = this.policyInfo.showVehicleCoverage || this.policyInfo.showInjuryCoverage || this.policyInfo.showLiabilityCoverage || this.policyInfo.showOtherCoverage;
  }

  private getCoverageValue(hayStack: any[], needles: string[], description: string = null): any {
    const found = description
                  ? hayStack.find(element => needles.indexOf(element.code) !== -1 && element.title === description)
                  : hayStack.find(element => needles.indexOf(element.code) !== -1);
    return found ? found.coverageValues : null;
  }
}
