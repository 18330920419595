import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-vehicle-make',
  templateUrl: './vehicle-make.component.html',
  styleUrls: ['./vehicle-make.component.css', '../../shared/styles/question-common-styling.scss']
})
export class VehicleMakeComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('year', {static: true}) year: ElementRef;
  @ViewChild('make', {static: true}) make: ElementRef;
  @ViewChild('model', {static: true}) model: ElementRef;
  isValueProvided = false;
  isMakeError = false;
  isModelError = false;
  yearSubQuestion: any;
  makeSubQuestion: any;
  modelSubQuestion: any;

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
    }

  ngOnInit() {
    this.sfAnalyticsService.sendData('first-claimant-vehicledetails-question');
    this.getSubQuestions();
    this.displayAnsweredState();
    this.populateYearOptions(this.yearSubQuestion.answers[0]);
  }
  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#makeField'));
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#makeField'));
  }

  private displayAnsweredState(): void {
    this.make.nativeElement.value = this.makeSubQuestion.answers[0] || '';
    if (this.makeSubQuestion.answers[0]) { this.triggerOneXChangeState('make'); this.isValueProvided = true; }
    this.model.nativeElement.value = this.modelSubQuestion.answers[0] || '';
    if (this.modelSubQuestion.answers[0]) { this.triggerOneXChangeState('model'); }
  }

  private triggerOneXChangeState(elementId: string) {
    // @ts-ignore
    this.window.oneX.$(`#${elementId}`).change();
  }

  private getSubQuestions(): void {
    this.yearSubQuestion = this.step.question.subQuestions[0][0];
    this.makeSubQuestion = this.step.question.subQuestions[0][1];
    this.modelSubQuestion = this.step.question.subQuestions[0][2];
  }

  private populateYearOptions(answer: string) {
    const max = new Date().getFullYear() + 1;
    const min = 1900;
    const select = document.getElementById('year');
    let hasFound = false;

    for (let i = max; i >= min; i--){
        const opt: HTMLOptionElement = document.createElement('option');
        const value = i.toString();
        opt.value = value;
        opt.innerHTML = value;
        if (answer === value) { opt.selected = true; hasFound = true; }
        select.appendChild(opt);
    }

    if (hasFound) { this.triggerOneXChangeState('year'); }
  }

  onMakeInput(e: Event) {
    this.isValueProvided = this.make.nativeElement.value !== '';
  }

  private validateStringAnswers(): boolean {
    const isMakeValid = this.regexValidate(
      this.make.nativeElement.value, this.makeSubQuestion.allowedValuesRegex, this.makeSubQuestion.minAnswers
      );
    this.isMakeError = !isMakeValid;
    const isModelValid = this.regexValidate(
      this.model.nativeElement.value, this.modelSubQuestion.allowedValuesRegex, this.modelSubQuestion.minAnswers
      );
    this.isModelError = !isModelValid;
    return isMakeValid && isModelValid;
  }

  answerQuestion(){
    if (this.validateStringAnswers()) {
      this.storeVehicleDescription();
      this.updateSubQuestionAnswer('year', 0, null, this.year.nativeElement.value || [], false);
      this.updateSubQuestionAnswer('make', 0, null, this.make.nativeElement.value, false);
      this.updateSubQuestionAnswer('model', 0, null, this.model.nativeElement.value || '', false);
      this.updateSubQuestionStep();
    }
  }

  private storeVehicleDescription(): void {
    const vehicleDescription = `${this.year.nativeElement.value || ''} ${this.make.nativeElement.value} ${this.model.nativeElement.value || ''}`;
    sessionStorage.setItem('firstClaimantVehicle', vehicleDescription.trim());
  }

}
