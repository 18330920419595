// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.optional-text {
  color: gray;
  float: right;
}

.-oneX-exploded_State {
  padding-right: 1%;
  width: 50%;
}

.-oneX-exploded_Zip {
  width: 50%;
}

.address1 {
  padding-bottom: 1%;
}

.-oneX-exploded_Address2 {
  padding-bottom: 1%;
}

.-oneX-exploded_City {
  padding-bottom: 1%;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/claimant-ni-driver/claimant-ni-driver.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["span.optional-text {\n  color: gray;\n  float: right;\n}\n\n.-oneX-exploded_State {\n  padding-right: 1%;\n  width: 50%;\n}\n\n.-oneX-exploded_Zip {\n  width: 50%;\n}\n\n.address1 {\n  padding-bottom: 1%;\n}\n\n.-oneX-exploded_Address2 {\n  padding-bottom: 1%;\n}\n\n.-oneX-exploded_City {\n  padding-bottom: 1%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
