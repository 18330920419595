import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'olr-button-policy-option',
  templateUrl: './button-policy-option.component.html',
  styleUrls: ['./button-policy-option.component.css']
})
export class ButtonPolicyOptionComponent {

  @Input() id: string;
  @Input() answer: string;
  @Input() policy: any;
  @Input() claimType: string;
  @Input() showHelpfulTips: boolean;
  @Input() showCoverages: boolean;
  @Output() buttonClicked = new EventEmitter<string>();
  @Output() showInfoDrawer = new EventEmitter<any>();

  constructor() { }

  getButtonHeader(policy: any) {
    if(this.claimType === 'auto' || this.claimType === 'motorcycle')  {
      const year = this.clearNull(this.getKeyValue(policy.value, 'year'));
      const make = this.clearNull(this.getKeyValue(policy.value, 'make'));
      const model = this.clearNull(this.getKeyValue(policy.value, 'model'));
      return `${year} ${make} ${model}`.toUpperCase().trim();
    }
    return this.getKeyValue(policy.value, 'policyType').toUpperCase();
  }

  clearNull(str: string): string {
    return str !== null ? str : '';
  }

  getKeyValue(attributes: any[], key: string): string {
    let found: any;
    for(const attribute of attributes) {
      if(attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if(found) {
      return found.value[0];
    }
    return '';
  }

  formatPolicyDescription(name: string): string {
    return name ? name.replace('POLICY', '').replace('POL - SPECIAL FORM', '').trim() : '';
  }

  onButtonClick() {
    this.buttonClicked.emit(this.policy);
  }

  onShowInfoDrawer() {
    const agreementId = this.getKeyValue(this.policy.value, 'agreementId');
    this.showInfoDrawer.emit(agreementId);
  }
}
