import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomerContactInformationComponent } from './customer-contact-information/customer-contact-information.component';
import { OrgContactInformationComponent } from './org-contact-information/org-contact-information.component';
import { PreferredPhoneComponent } from './preferred-phone/preferred-phone.component';
import { AddNewPhoneComponent } from './add-new-phone/add-new-phone.component';
import { TextNotificationComponent } from './text-notification/text-notification.component';
import { AuthGuard } from '../services/guards/auth.guard';

const routes: Routes = [
    { path: 'customer-contact-information', component: CustomerContactInformationComponent, canActivate: [AuthGuard] },
    { path: 'org-contact-information', component: OrgContactInformationComponent, canActivate: [AuthGuard] },
    { path: 'preferred-phone', component: PreferredPhoneComponent, canActivate: [AuthGuard] },
    { path: 'add-new-phone', component: AddNewPhoneComponent, canActivate: [AuthGuard] },
    { path: 'text-notification', component: TextNotificationComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ContactInformationRoutingModule {}
