import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgZone } from '@angular/core';
import { Address } from '../models/address.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleSearchService {
  subscription: any;
  private searchElement: HTMLInputElement;
  private autocompleteSubject = new Subject();
  private addressSubject = new Subject();
  latitude: number;
  longitude: number;
  address: Address;
  streetNumber = '';
  route = '';

  constructor(private zone: NgZone) { }

  public bindGoogleSearch(elm: HTMLInputElement) {
    this.setInputElement(elm);
    this.setAutocomplete(elm, this.autocompleteSubject);
  }

  setAutocomplete(elm: HTMLInputElement, autocompleteSubject: any) {
    // eslint-disable-next-line max-len
    const autocomplete = new google.maps.places.Autocomplete(elm, { types: ['geocode', 'establishment'] });
    const that = this;
    autocomplete.addListener('place_changed', this.next(that));
    return autocomplete;
  }

  next(that) {
    return () => {
      that.autocompleteSubject.next();
    }
  }

  getAutocompleteSubject(): Observable<any> {
    return this.autocompleteSubject;
  }

  public setInputElement(elm: HTMLInputElement) {
    this.searchElement = elm;
  }

  public getCurrPosition(): Observable<any> {
    return new Observable(observer => {
      navigator.geolocation.getCurrentPosition(resp => {
        observer.next(resp);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  public reverseGeoCode(lat: any, lng: any): Observable<any> {
    return new Observable(observer => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next(results[0]);
          observer.complete();
        } else {
          if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            observer.error('Address not found!');
          } else {
            observer.error('No data found!');
          }
          observer.complete();
        }
      });
    });
  }

  public performSearch(): Observable<any> {
    return new Observable(observer => {
      const geocoder = new google.maps.Geocoder();
      const address = this.searchElement.value;
      console.log('ADDRESS VALUE IN PERFORM SEARCH: ', address);
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next(results[0]);
          observer.complete();
        } else {
          if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            observer.error('Address not found!');
          } else {
            observer.error('No data found!');
          }
          observer.complete();
        }
      });
    });
  }

  getAddressInfo(googleResponse: any): Address {
    this.address = new Address();
    console.log('google address info--------->' + googleResponse);
    // if (googleResponse.length === undefined) {
    if (googleResponse === null) {

      return null;
    } else {
      console.log(googleResponse);
      for (const answer of googleResponse) {
        if (answer.types[0] === 'street_number') {
          this.streetNumber = answer.long_name;
          this.address.streetNumber = answer.long_name;
          console.log('street number -->' + this.streetNumber);
        } else if (answer.types[0] === 'route') {
          this.route = answer.long_name;
          this.address.route = answer.long_name;
        } else if (answer.types[0] === 'locality') {
          this.address.city = answer.long_name;
        } else if (answer.types[0] === 'administrative_area_level_1') {
          this.address.state = answer.short_name;
        } else if (answer.types[0] === 'country') {
          if (answer.short_name === 'US') {
            this.address.country = 'usa';
          } else if (answer.short_name === 'CA') {
            this.address.country = 'canada';
          } else if (answer.short_name === 'MX') {
            this.address.country = 'mexico';
          } else if (answer.short_name === 'PR' || answer.short_name === 'VI' || answer.short_name === 'GU' || answer.short_name === 'MP'
            || answer.short_name === 'AS') {
            this.address.country = 'usTerritories';
          } else {
            this.address.country = 'other';
            this.address.state = '';
            break; // break since country w/ 'other' must have postal code empty, won't reach postal_code below after country set
          }
        } else if (answer.types[0] === 'postal_code') {
          this.address.postalCode = answer.long_name;
        }
      }
    }
    return this.address;
  }
}
