import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { IncidentDetailsRoutingModule } from './incident-details-routing.module';
import { SharedModule } from '../shared/shared.module';
import { InjuryComponent } from './injury/injury.component';
import { AmountVehiclesComponent } from './amount-vehicles/amount-vehicles.component';
import { IncidentDateComponent } from './incident-date/incident-date.component';
import { PolicySelectionComponent } from './policy-selection/policy-selection.component';
import { IncidentLocationComponent } from './incident-location/incident-location.component';
import { IncidentTypeComponent } from './incident-type/incident-type.component';
import { IncidentAccidentComponent } from './incident-type/incident-accident/incident-accident.component';
import { IncidentWeatherComponent } from './incident-type/incident-weather/incident-weather.component';
import { InvolvementComponent } from './involvement/involvement.component';
import { RoadHazardsComponent } from './road-hazards/road-hazards.component';
import { RideShareScenarioComponent } from './ride-share-scenario/ride-share-scenario.component';
import { WorkRelatedComponent } from './work-related/work-related.component';
import { EnterLocationComponent } from './incident-location/enter-location/enter-location.component';
import { VehicleStolenComponent } from './incident-type/vehicle-stolen/vehicle-stolen.component';
import { VehicleRecoveredComponent } from './incident-type/vehicle-recovered/vehicle-recovered.component';
import { FireDamageComponent } from './incident-type/fire-damage/fire-damage.component';
import { ChildSeatComponent } from './child-seat/child-seat.component';
import { ChildSeatStatusComponent } from './child-seat-status/child-seat-status.component';
import { AirbagsComponent } from './airbags/airbags.component';
import { SpecialtyVehicleComponent } from './specialty-vehicle/specialty-vehicle.component';
import { PoliceReportComponent } from './police-report/police-report.component';
import { PoliceDepartmentComponent } from './police-department/police-department.component';
import { GoogleConfigService } from '../services/helpers/google-config.service';
import { InsuredParticipantSelectionComponent } from './insured-participant-selection/insured-participant-selection.component';
import { InsuredParticipantDetailsComponent } from './insured-participant-details/insured-participant-details.component';
import { AdditionalInsuredParticipantsComponent } from './additional-insured-participants/additional-insured-participants.component';
import { WitnessComponent } from './witness/witness.component';
import { ResponsiblePartyComponent } from './responsible-party/responsible-party.component';
import { WitnessInformationComponent } from './witness-information/witness-information.component';
import { PetInjuryComponent } from './pet-injury/pet-injury.component';
import { DisputedLiabilityComponent } from './disputed-liability/disputed-liability.component';
import { VehicleOccupancyComponent } from './vehicle-occupancy/vehicle-occupancy.component';
import { VehicleStatusComponent } from './vehicle-status/vehicle-status.component';
import { VehicleOccupancyInvolvementComponent } from './vehicle-occupancy-involvement/vehicle-occupancy-involvement.component';
import { VehicleOccupancyInsuredComponent } from './vehicle-occupancy-insured/vehicle-occupancy-insured.component';
import { VehicleOccupancyOtherInsuredParticipantsComponent } from './vehicle-occupancy-other-insured-participants/vehicle-occupancy-other-insured-participants.component';
import { RideShareLoginStatusComponent } from './ride-share-login-status/ride-share-login-status.component';
import { PurposeOfTripComponent } from './purpose-of-trip/purpose-of-trip.component';
import { PurposeOfTripWorkRelatedComponent } from './purpose-of-trip-work-related/purpose-of-trip-work-related.component';
import { HitAndRunComponent } from './hit-and-run/hit-and-run.component';

@NgModule({
  declarations: [
    InjuryComponent,
    AmountVehiclesComponent,
    IncidentDateComponent,
    PolicySelectionComponent,
    IncidentLocationComponent,
    IncidentTypeComponent,
    IncidentAccidentComponent,
    IncidentWeatherComponent,
    InvolvementComponent,
    RoadHazardsComponent,
    RideShareScenarioComponent,
    WorkRelatedComponent,
    VehicleStolenComponent,
    VehicleRecoveredComponent,
    FireDamageComponent,
    ChildSeatComponent,
    ChildSeatStatusComponent,
    AirbagsComponent,
    SpecialtyVehicleComponent,
    PoliceReportComponent,
    PoliceDepartmentComponent,
    InsuredParticipantSelectionComponent,
    InsuredParticipantDetailsComponent,
    AdditionalInsuredParticipantsComponent,
    WitnessComponent,
    ResponsiblePartyComponent,
    WitnessInformationComponent,
    PetInjuryComponent,
    EnterLocationComponent,
    DisputedLiabilityComponent,
    VehicleOccupancyComponent,
    VehicleStatusComponent,
    VehicleOccupancyInvolvementComponent,
    VehicleOccupancyInsuredComponent,
    VehicleOccupancyOtherInsuredParticipantsComponent,
    RideShareLoginStatusComponent,
    PurposeOfTripComponent,
    PurposeOfTripWorkRelatedComponent,
    HitAndRunComponent
  ],
  imports: [
    CommonModule,
    IncidentDetailsRoutingModule,
    SharedModule,
    GoogleMapsModule
  ],
  providers: [],
  exports: [
    SharedModule,
  ]
})
export class IncidentDetailsModule { }
