import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'olr-claimant-landing',
  templateUrl: './claimant-landing.component.html',
  styleUrls: ['./claimant-landing.component.css']
})
export class ClaimantLandingComponent implements OnInit {

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private lossReportService: LossReportService,
    public route: ActivatedRoute,
    private routingService: RoutingService,
  ) { }

  ngOnInit(): void {
    sessionStorage.clear();
    this.analytics.setScreenChannel('filealoss-unauthenticated');
    this.analytics.sendData('landing-page');
  }

  startClaimantFlow(){
    if (this.route.snapshot.queryParams) {
      this.updateAnalytics(this.route.snapshot.queryParams);
    }
    this.lossReportService.createReport('claimant', this.route.snapshot.queryParams);
    this.lossReportService.lossReportCreated.subscribe(() => {
      this.routingService.navigateNext();
    });
  }

  private updateAnalytics(queryParams: any) {
    const screenChannel = {
      individual: 'filealoss-claimant',
      otherInsuranceCompany: 'filealoss-OIC'
    };
    for (const property in queryParams) {
      if (property === 'reporterType') {
        this.analytics.sendData('reporting-entity');
        this.analytics.setScreenChannel(screenChannel[queryParams[property]]);
      }
    }
  }

  onNavigateBack() {
    this.window.location.href = 'https://www.statefarm.com/claims/file-a-claim';
  }

  handleKeyDown(event: KeyboardEvent){
    if(event.key==='Enter' || event.key===' '){
      event.preventDefault();
      this.onNavigateBack();
    }
  }

}
