import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { LogDetails } from '../../../models/log-details.model';
import { CliffLogDetails } from '../../../models/cliff-log-details.model';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-liability',
  templateUrl: './liability.component.html',
  styleUrls: ['./liability.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class LiabilityComponent extends BaseComponent implements OnInit {

  isLiable = false;
  isLiabilitySelected = false;
  selectedLiability: string;
  selectedPercentage: string;
  selectedPayment: string;
  yesNoUnkOptions = ['yes', 'no', 'unknown'];
  percentages = [];
  logDetails: LogDetails;

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.logDetails = this.logService.getLogDetails();
    this.analytics.sendData('liability-questions');
    this.populatePercentageOptions();
    this.selectedLiability = this.getQuestionAnswer('liabilityDetermined');
    this.setFlags(this.selectedLiability);
    this.selectedPercentage = this.getQuestionAnswer('percentageAccepted');
    this.selectedPayment = this.getQuestionAnswer('paymentMade');
  }

  private populatePercentageOptions() {
    const percentages = [];
    for (let i = 0; i <= 100; i++){
      percentages.push(i.toString());
    }
    this.percentages = percentages;
  }

  private getSubQuestion(questionName: string): any {
    const subQuestions = this.step?.question.subQuestions[0] ?? [];
    return subQuestions.find(question => question.name === questionName);
  }

  private getQuestionAnswer(questionName: string): any {
    const question = this.getSubQuestion(questionName);
    if (question) {
      return question.answers[0] || '';
    }
    return '';
  }

  private setFlags(answer) {
    this.isLiable = answer === 'yes';
    this.isLiabilitySelected = answer ? true : false;
  }

  updateLiability(answer: any): void {
    this.selectedLiability = answer;
    this.setFlags(this.selectedLiability);
  }

  updatePercentage(answer: any): void {
    this.selectedPercentage = answer;
  }

  updatePayment(answer: any): void {
    this.selectedPayment = answer;
  }

  answerQuestion(): void {
    const answers = [];
    const liability = [];

    liability.push(this.createAnsweredQuestion('7031', 'liabilityDetermined', this.selectedLiability));
    this.logDetails.claimantLiability = this.selectedLiability;
    this.logService.log(CliffLogCodes.LBLTY_DTRM, this.logDetails);

    liability.push(this.createAnsweredQuestion('7033', 'paymentMade', this.selectedPayment));
    if (this.selectedPayment) {
      this.logDetails.claimantPayment = this.selectedPayment;
      this.logService.log(CliffLogCodes.LBLTY_PYMNT, this.logDetails);
    }

    if (this.isLiable) {
      liability.push(this.createAnsweredQuestion('7032', 'percentageAccepted', this.selectedPercentage));
      if (this.selectedPercentage && this.selectedPercentage !== '0') {
        this.logDetails.claimantLiabilityPercentage = this.selectedPercentage;
        this.logService.log(CliffLogCodes.LBLTY_PRCNT, this.logDetails);
      }
    }

    answers.push(liability);
    this.updateSubQuestions(answers);
  }
}
