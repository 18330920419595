import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-review-answer-panel',
  templateUrl: './review-answer-panel.component.html',
  styleUrls: ['./review-answer-panel.component.css']
})
export class ReviewAnswerPanelComponent {
  @Input() title: string;
  @Input() id: string;
  @Input() route: string;

  constructor(
    public router: Router,
  ) { }

  routeToEdit() {
    if (this.route){
      this.router.navigate([this.route]);
    }
  }

}
