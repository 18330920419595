import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {

  constructor() { }

  createEvent(eventName: string, eventConstructorExists: boolean, options: any) {
    if (eventConstructorExists) {
      return new Event(eventName, options);
    } else {
      // fallback since IE browsers don't support the Event constructor
      const evt = document.createEvent('Event');
      evt.initEvent(eventName, options.bubbles, options.cancelable);
      return evt;
    }
  }
}
