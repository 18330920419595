import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { ErrorHandlingService } from './helpers/error-handling.service';
import { LogService } from './log.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LogDetails } from '../models/log-details.model';
import { parseJwt } from 'customer-oauth2-token-management-utility';
import { error } from 'console';


@Injectable({
  providedIn: 'root'
})
export class LossReportService {
  logDetails: LogDetails;
  lossReportCreated = new Subject<any>();
  lossReportUpdated = new Subject<any>(); // .next() this whenever lossReport is updated
  lossReportCoveragesRetrieved = new Subject<any>();
  relationshipsRetrieved = new Subject<any>();
  stepProgress = new Subject<number[]>(); // updates header progress bar
  sessionSaved = new Subject<any>();
  sessionRetrieved = new Subject<any>();
  sessionsRetrieved = new Subject<any>();
  currentQuestion: any; // current lossReport question being asked
  currentStep: any;
  createdIndicator = false;
  coverages: any[];
  lossReportUpdateSubscription$: Subscription;
  step: any;
  private stepRoutes: string[];
  private incidentDate: any = '';
  private birthDate: any = '';
  private _FLOW_NAME: string;
  private _LOSS_REPORT: any; // current lossReport in progress
  private url;

  private getOptions(fullResponse: boolean): any{
    let contentType = '';
    if(this.flowName) {
      const mimeTypes = {
        auto: 'application/vnd.statefarm-lossreport.auto+json',
        claimant: 'application/vnd.statefarm-lossreport.autoclaimant+json',
        fire: 'application/vnd.statefarm-lossreport.homeandproperty+json'
      };
      contentType = mimeTypes[this.flowName] || '';
    } else if (this.lossReport) {
      const flowData = {
        AUTO: { mime: 'application/vnd.statefarm-lossreport.auto+json', flow: 'auto' },
        AUTO_CLAIMANT: { mime: 'application/vnd.statefarm-lossreport.autoclaimant+json', flow: 'claimant' },
        HOME_AND_PROPERTY: { mime: 'application/vnd.statefarm-lossreport.homeandproperty+json', flow: 'fire' }
      };
      let flowInfo = flowData[this.lossReport.type];
      contentType = flowInfo ? flowInfo.mime : '';
      this.flowName = flowInfo ? flowInfo.flow : '';
    }

    var returnOptions;
    const parsedJwt = parseJwt();
    const oauthHeader = this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : undefined;
    const ssoHeader = this.cookieHandlerService.getSSOToken() ? this.cookieHandlerService.getSSOToken() : undefined;
    let headersToSend = new HttpHeaders({
      Accept: contentType,
      'Content-Type': contentType,
      CorrelationId: this.logService.getCorrelationId()
    });
    if (oauthHeader) {
      headersToSend = headersToSend.append('Authorization', oauthHeader);
    }
    if (ssoHeader) {
      headersToSend = headersToSend.append('X-SF_SSO_TOKEN', ssoHeader);
    }

    if (fullResponse && parsedJwt) {
      returnOptions = {
        headers: headersToSend,
        withCredentials: true,
        observe: 'response'
      }
    }
    else if(parsedJwt) {
      returnOptions = {
        headers: headersToSend,
        withCredentials: true
      }
     } else {
      returnOptions = {
        headers: headersToSend,
        withCredentials: true
      }
    }
    return returnOptions;
  }

  constructor(
    private http: HttpClient,
    private logService: LogService,
    private loadingService: LoadingService,
    private cookieHandlerService: CookieHandlerService,
    private hostnameBuilderService: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService,
    private cookieService: CookieHandlerService,
    ) {
      this.url = 'https://claimsvc' + this.hostnameBuilderService.getHostname() + '/claimloss-api-lossreports/v6/api/lossreports';
      // this.url = 'http://localhost:8080/claimloss-api-lossreports/v6/api/lossreports';
      this.logDetails = this.logService.getLogDetails();
     }

  createReport(flowName: string, queryParams: any, dateOfLoss: string = null, orgId: string = null): void {
    this.flowName = flowName;
    // begin log of passed in flow name for research
    const flowLogCode = CliffLogCodes.LR_SELECTED_FLOW;
    flowLogCode.text = this.flowName;
    this.logService.log(flowLogCode);
    // end log of passed in flow name for research
    if(!dateOfLoss) {
      this.loadingService.startRequest();
    }
    let qParams = this.processParameters(queryParams);
    if(dateOfLoss) {
      qParams =  qParams.indexOf('?') > -1 ? `${qParams}&dateOfLoss=${dateOfLoss}` : `${qParams}?dateOfLoss=${dateOfLoss}`;
    }
    if(orgId && orgId !== 'personal') {
      qParams =  qParams.indexOf('?') > -1 ? `${qParams}&orgId=${orgId}` : `${qParams}?orgId=${orgId}`;
    }

    this.http
      .post<any>(this.url + qParams, '', this.getOptions(false) )
      .pipe(takeUntil(this.lossReportCreated))
      .subscribe({
        next: response => {
          // @ts-ignore
          this.lossReport = response.payload;
          this.lossReportCreated.next(this.lossReport);
          this.logDetails.lossReportId = this.lossReport.metaData.reportId;
          this.logService.log(CliffLogCodes.LR_CREATED, this.logDetails);
        },
        error: error => {
          if(dateOfLoss) {
            this.lossReportCreated.next(null);
            this.logDetails.httpResponse = error.status;
            this.logDetails.serviceErrorCode = error.error.errors[0].code;
            this.logDetails.serviceErrorMessage = JSON.stringify(error);
            this.logDetails.serviceName = 'claimloss-api-lossreports';
            const logCode = CliffLogCodes.LR_CREATE_FAIL;
            logCode.text = error.error.errors[0].message;
            this.logService.log(logCode, this.logDetails);
          } else {
            this.lossReportCreated.next(null);
            this.loadingService.completeRequest();
            this.errorHandlingService.handleError(error, CliffLogCodes.LR_CREATE_FAIL);
          }
        },
        complete: () => {
          if(!dateOfLoss) {
            this.loadingService.completeRequest();
          }
        }
      });
  }

  count = 0;

  saveSession(): void {
    const payload = JSON.stringify(this.lossReport);
    this.loadingService.startRequest();
    this.http
      .post<any>(this.url + '/session', payload, this.getOptions(false) )
      .pipe(takeUntil(this.sessionSaved))
      .subscribe({
        next: response => {
          // @ts-ignore
          this.sessionSaved.next({});
          this.logService.log(CliffLogCodes.LR_SESSION_SAVED);
        },
        error: error => {
          const err = error.error.errors[0].message;
          this.sessionSaved.next({error: err});
          this.loadingService.completeRequest();
          this.logService.log(CliffLogCodes.LR_SESSION_SAVED_FAILED);

        },
        complete: () => {
          this.loadingService.completeRequest();
        }
      });
  }

  getSession(sessionId: string): void {
    this.http
      .get<any>(this.url + '/session/' + sessionId, this.getOptions(false) )
      .pipe(takeUntil(this.sessionRetrieved))
      .subscribe({
        next: response => {
          // @ts-ignore
          this.lossReport = response.payload;
          this.sessionRetrieved.next({successful: true});
          this.logService.log(CliffLogCodes.LR_SESSION_RETRIEVED);
        },
        error: error => {
          const err = error?.statusText;
          this.sessionRetrieved.next({successful: false, error: err});
          this.loadingService.completeRequest();
          this.logService.log(CliffLogCodes.LR_SESSION_RETRIEVED_FAILED);
        },
        complete: () => {
          this.loadingService.completeRequest();
        }
      });
  }

  getSessions(): void {
    this.http
    .get<any>(this.url + '/sessions', this.getOptions(false) )
    .pipe(takeUntil(this.sessionsRetrieved))
    .subscribe({
      next: response => {
          // @ts-ignore
          this.sessionsRetrieved.next(response.payload);
          this.logService.log(CliffLogCodes.LR_SESSIONS_RETRIEVED);
      },
      error: error => {
        this.sessionsRetrieved.next([]);
        this.loadingService.completeRequest();
        this.logService.log(CliffLogCodes.LR_SESSIONS_RETRIEVED_FAILED);
      },
      complete: () => {
        this.loadingService.completeRequest();
      }
    });
  }

  modifyReport(skipErrorHandling: boolean = false): void {
    if(!skipErrorHandling) { this.loadingService.startRequest(); }
    const payload = JSON.stringify(this.lossReport);
    this.http
      .put<any>(this.url + '/' + this.lossReport.metaData.reportId, payload, this.getOptions(false) )
      .subscribe({
        next: response => {
          this.count = 0;
          // @ts-ignore
          this.lossReport = response.payload;

          this.logService.log(CliffLogCodes.LR_UPDATED, this.logDetails);
        },
        error: errorResponse => {
          if (errorResponse.status === 404 && this.count < 2) {
            ++this.count;
            this.loadingService.completeRequest();
            this.replaceNotFoundReport(true);
            return;
          }
          if(!skipErrorHandling) {
            this.count = 0;
            this.loadingService.completeRequest();
            this.errorHandlingService.handleError(errorResponse, CliffLogCodes.LR_UPDATE_FAIL);
          } else {
            this.count = 0;
            this.lossReportUpdated.next(null);
            const logCode = CliffLogCodes.LR_UPDATE_FAIL;
            logCode.text = errorResponse.error.errors[0].message;
            this.logService.log(logCode, this.logDetails);
          }
        },
        complete: () => {
          if(!skipErrorHandling) { this.loadingService.completeRequest(); }
        }
  });
  }

  getReport(): void {
    if(!this.lossReport) { return; }
    const reportId = this.lossReport ? this.lossReport.metaData.reportId : '';
    this.http
      .get<any>(this.url + '/' + reportId, this.getOptions(false))
      .subscribe(
        response => {
          this.logService.log(CliffLogCodes.LR_RETRIEVED, this.logDetails);
        },
        error => {
          this.logService.log(CliffLogCodes.LR_RETRIEVE_FAIL, this.logDetails);
        }
      );
  }




  getRelationships():void {
    this.loadingService.startRequest();
    this.http
      .get<any>(this.url + '/relationships', this.getOptions(false))
      .pipe(takeUntil(this.relationshipsRetrieved))
      .subscribe({
        next: response => {
          let relationships = response['payload'];
          this.relationshipsRetrieved.next(relationships);
          this.logService.log(CliffLogCodes.LR_CRACR_SUCCESS, this.logDetails);
        },
        error: error => {
          this.relationshipsRetrieved.next([]);
          this.logService.log(CliffLogCodes.LR_CRACR_FAIL, this.logDetails);
          this.loadingService.completeRequest();
        },
        complete: () => {
          this.loadingService.completeRequest();
        }
      });
  }

  getReportCoverages(agreementId: string, dateOfLoss: string): void {
    this.loadingService.startRequest();
    this.http
      .get<any>(this.url + `/policy/${agreementId}/coverages?dateOfLoss=${dateOfLoss}`, this.getOptions(false))
      .pipe(takeUntil(this.lossReportCoveragesRetrieved))
      .subscribe({
        next: response => {
          this.coverages = response['payload'];
          this.lossReportCoveragesRetrieved.next(this.coverages);
          this.logService.log(CliffLogCodes.LR_COVERAGES_RETRIEVED, this.logDetails);
        },
        error: error => {
          this.lossReportCoveragesRetrieved.next([]);
          this.logService.log(CliffLogCodes.LR_COVERAGES_RETRIEVE_FAIL, this.logDetails);
          this.loadingService.completeRequest();
        },
        complete: () => {
          this.loadingService.completeRequest();
        }
      });
  }

  deleteReport(): void {
    const reportId = this.lossReport ? this.lossReport.metaData.reportId : '';
    this.loadingService.startRequest();
    const httpOptions = this.getOptions(false);
    httpOptions.headers.delete('Accept');
    this.http
        .delete(this.url + '/' + reportId)
        .subscribe(
          response => {
            this.logService.log(CliffLogCodes.LR_DELETED, this.logDetails);
            this.lossReport = {};
          },
          error => {
            this.loadingService.completeRequest();
            this.errorHandlingService.handleError(error, CliffLogCodes.LR_DELETE_FAIL);
          },
          () => {
            this.loadingService.completeRequest();
          }
        );
  }

  submitReport(): void {
    const reportId = this.lossReport.metaData.reportId || '';
    this.loadingService.startRequest();
    this.http
      .post<any>(this.url + '/' + reportId, '', this.getOptions(true))
      .subscribe(
        response => {
          // @ts-ignore
          this.lossReport = response.body.payload;
          // @ts-ignore
          if (response.status === 201) {
            this.createdIndicator = true;
          }
          this.logService.log(CliffLogCodes.LR_SUBMITTED, this.logDetails);
        },
        errorResponse => {
          if (errorResponse.status === 404) {
            this.loadingService.completeRequest();
            this.replaceNotFoundReport(false);
            return;
          }
          this.loadingService.completeRequest();
          this.errorHandlingService.handleError(errorResponse, CliffLogCodes.LR_SUBMIT_FAIL);
        },
        () => {
          this.loadingService.completeRequest();
        }
      );
  }

  private replaceNotFoundReport(isUpdate: boolean): void {
    this.loadingService.startRequest();
    this.http
      .post<any>(this.url, '', this.getOptions(false) )
      .pipe(take(1))
      .subscribe(
        response => {
          // @ts-ignore
          const newLossReport = response.payload;
          this.lossReport.metaData.reportId = newLossReport.metaData.reportId;
          this.logDetails.lossReportId = this.lossReport.metaData.reportId;
          this.logService.log(CliffLogCodes.LR_RECREATED, this.logDetails); // add new CliffLog for traffic
          if (isUpdate) {
            this.modifyReport();
          } else {
            this.modifyReport();
            this.lossReportUpdated.pipe(take(1)).subscribe(() => {
              this.submitReport();
            });
          }
        },
        error => {
          this.loadingService.completeRequest();
          this.logService.log(CliffLogCodes.LR_RECREATE_FAIL, this.logDetails); // add new CliffLog for fail
        },
        () => {
          this.loadingService.completeRequest();
        }
    );
  }

  private fixCompletedFlags(payload: any): void {
    const steps = payload.steps || [];
    this.stepRoutes = [];
    steps.forEach((step: any) => {
      if (!step.question.hidden){
        this.stepRoutes.push(step.title);
      }
      if (step.completed) {
        if (step.question.minAnswers === 0 && step.question.answers !== undefined && step.question.answers.length === 0) {
          step.completed = false;
        }

        if (step.question.subQuestions){
          const subQuestions = step.question.subQuestions[0];
          let optionalCount = 0;
          let hasDependentCount = 0;
          let hasIgnorableQuestions = false;

          subQuestions.forEach((subQuestion: any) => {
            if (subQuestion.hasDependentQuestions){
              hasDependentCount++;
            }
            if (subQuestion.name === 'paymentMade' || subQuestion.name === 'percentageAccepted' ||
                subQuestion.name === 'injuries' || subQuestion.name === 'medicalTreatment' ||
                subQuestion.name === 'airbagDeploy') { hasIgnorableQuestions = true; }

            if (subQuestion.minAnswers === 0 && subQuestion.answers && subQuestion.answers.length === 0){
              optionalCount++;
            }
          });

          if (!hasIgnorableQuestions && optionalCount !== 0 && (subQuestions.length - hasDependentCount) === optionalCount){
            step.completed = false;
          }
        }
      }
    });
  }

  private processParameters(queryParams: any) {
    let processedParams = '';
    if (queryParams.agrmntNum && queryParams.agrmntId) {
      processedParams = '?agreementAccessKey=' + atob(queryParams.agrmntNum) + '&agreementId=' + atob(queryParams.agrmntId);
    } else if (queryParams.agrmntNum) {
      processedParams = '?agreementAccessKey=' + atob(queryParams.agrmntNum);
    } else if (queryParams.agrmntId) {
      processedParams = '?agreementId=' + atob(queryParams.agrmntId);
    } else if (queryParams.reporterType) {
      processedParams = '?lossType=' + queryParams.lossType + '&reporterType=' + queryParams.reporterType;
    }
    return processedParams;
  }

  answerQuestion(answer: any): boolean {
    this.currentQuestion.answers = typeof answer === 'object' ? answer : [answer];
    this.currentStep.completed = true;
    if (this.currentQuestion.hasDependentQuestions) {
      this.modifyReport();
      return true;
    }
    return false;
  }

  /* Loops through loss report steps, and returns 'name' value of next incompleted question.
  (or false if all available questions are completed)*/
  getNextStep(): any {
    for (const step of this.lossReport.steps) {
      if (!step.completed) {
        this.currentQuestion = step.question;
        this.currentStep = step;
        this.stepProgress.next([step.stepNumber, step.stepsInChapter]);
        return step.title;
      }
    }
    return false;
  }

  getStepByQuestionId(questionId: string): any {
    return this.lossReport.steps.filter( (step: any) => step.question.id === questionId )[0];
  }

  getStepByRoute(route: string): any {
    return this.lossReport.steps.filter( (step: any) => step.title === route )[0];
  }



  getStepByName(stepName: string): any {
    return this.lossReport.steps.filter( (step: any) => step.question.name === stepName )[0];
  }

  areAllQuestionsAnswered() {
    return this.lossReport.steps.every(step => step.completed === true);
  }

  // Pass in 1 to move ahead one step and -1 to move back one step
  getStepRouteByIndex(currentRoute: string, jumpBy: number): any {
    const currentIndex = this.stepRoutes ? this.stepRoutes.indexOf(currentRoute) : 0;
    if (this.stepRoutes && this.stepRoutes.length > 1) {
      return this.stepRoutes[currentIndex + jumpBy ];
    }
    return undefined;
  }

  canMoveForward(currentRoute: string): boolean {
    if (currentRoute.includes('/review/')) {
      return false;
    }
    const currentIndex = this.stepRoutes ? this.getStepByRoute(currentRoute)?.stepNumber : 0;
    const nextIncompleteStepRoute = this.stepRoutes ? this.getNextStep() : undefined;
    const nextIncompleteStepIndex = nextIncompleteStepRoute ? this.getStepByRoute(nextIncompleteStepRoute).stepNumber : 0;

    if (this.stepRoutes) {
      if (nextIncompleteStepIndex === 0 || currentIndex < nextIncompleteStepIndex) {
        return true;
      }
      return false;
    }
  }

  private setStepRoutes(steps: any[]){
    this.stepRoutes = [];
    steps.forEach((step: any) => {
      if (!step.question.hidden){
        this.stepRoutes.push(step.title);
      }
    });
  }

  setIncidentDate(date: string){
    this.incidentDate = date;
    sessionStorage.setItem('incidentDate', date);
  }

  getIncidentDate(){
    return this.incidentDate || sessionStorage.getItem('incidentDate');
  }

  setBirthDate(date: string){
    this.birthDate = date;
    sessionStorage.setItem('birthDate', date);
  }

  getBirthDate(){
    return this.birthDate || sessionStorage.getItem('birthtDate');
  }

  getCreatedIndicator(){
    return this.createdIndicator;
  }

  get flowName(): string {
    if (!this._FLOW_NAME) {
      this._FLOW_NAME = sessionStorage.getItem('flowName');
    }
    return this._FLOW_NAME;
  }

  set flowName(flowName: string) {
    this._FLOW_NAME = flowName;
    sessionStorage.setItem('flowName', flowName);
  }

  get lossReport(): any {
    if (!this._LOSS_REPORT){
      this._LOSS_REPORT = JSON.parse(sessionStorage.getItem('lrSession'));
      if(JSON.stringify(this._LOSS_REPORT) === '{}') {
        this._LOSS_REPORT = null;
      }
      if(this._LOSS_REPORT) {
        this.setStepRoutes(this._LOSS_REPORT.steps);
        this.getNextStep();
      }
    }
    return this._LOSS_REPORT;
  }

  set lossReport(lossreport: any) {
    this.fixCompletedFlags(lossreport);
    this._LOSS_REPORT = lossreport;
    sessionStorage.setItem('lrSession', JSON.stringify(lossreport));
    this.lossReportUpdated.next(this._LOSS_REPORT);
  }
}
