import { Component, OnInit, ElementRef, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';

@Component({
  selector: 'olr-claim-information',
  templateUrl: './claim-information.component.html',
  styleUrls: ['./claim-information.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimInformationComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('claimNumber', {static: true}) claimNumber: ElementRef;
  @ViewChild('claimHandlerName', {static: true}) claimHandlerName: ElementRef;
  @ViewChild('claimHandlerContact', {static: true}) claimHandlerContact: ElementRef;
  isClaimNumberError = false;
  isClaimHandlerNameError =  false;
  isClaimHandlerContactError = false;
  claimNumberSubQuestion: any;
  claimHandlerNameSubQuestion: any;
  claimHandlerContactSubQuestion: any;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('oic-claim-details');
    this.getSubQuestions();
    this.displayAnsweredState();
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#labelsStyles'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#labelsStyles'));
  }

  private displayAnsweredState(): void {
    this.claimNumber.nativeElement.value = this.claimNumberSubQuestion.answers[0] || '';
    this.claimHandlerName.nativeElement.value = this.claimHandlerNameSubQuestion.answers[0] || '';
    this.claimHandlerContact.nativeElement.value = this.claimHandlerContactSubQuestion.answers[0] || '';
    setTimeout(() => {
      this.window.oneX.$('.-oneX-textfield--floating-input').trigger('change');
    }, 200);
  }

  private getSubQuestions(): void {
    this.claimNumberSubQuestion = this.step.question.subQuestions[0][0];
    this.claimHandlerNameSubQuestion = this.step.question.subQuestions[0][1];
    this.claimHandlerContactSubQuestion = this.step.question.subQuestions[0][2];
  }

  resetError(): void {
    this.isClaimNumberError = false;
    this.isClaimHandlerNameError =  false;
    this.isClaimHandlerContactError = false;
  }

  private formattedPhoneNumber(): string {
    const phone: string = this.claimHandlerContact.nativeElement.value || '';
    return phone.replace(/[()\\s-]+/g, '');
  }

  private validateStringAnswers(): boolean {
    const isClaimNumberValid = this.regexValidate(
      this.claimNumber.nativeElement.value,
      this.claimNumberSubQuestion.allowedValuesRegex,
      this.claimNumberSubQuestion.minAnswers
      );
    this.isClaimNumberError = !isClaimNumberValid;

    const isClaimHandlerNameValid = this.regexValidate(
      this.claimHandlerName.nativeElement.value,
      this.claimHandlerNameSubQuestion.allowedValuesRegex,
      this.claimHandlerNameSubQuestion.minAnswers
      );
    this.isClaimHandlerNameError = !isClaimHandlerNameValid;

    const isClaimHandlerContactValid = this.regexValidate(
      this.formattedPhoneNumber(), this.claimHandlerContactSubQuestion.allowedValuesRegex, this.claimHandlerContactSubQuestion.minAnswers
      );
    this.isClaimHandlerContactError = !isClaimHandlerContactValid;
    return isClaimNumberValid && isClaimHandlerNameValid && isClaimHandlerContactValid;
  }

  answerQuestion(): void {
    if (this.validateStringAnswers()) {
      this.updateSubQuestionAnswer('claimantClaimNumber', 0, null, this.claimNumber.nativeElement.value || '');
      this.updateSubQuestionAnswer('claimHandlerName', 0, null, this.claimHandlerName.nativeElement.value || '');
      this.updateSubQuestionAnswer('claimHandlerPhoneNumber', 0, null, this.formattedPhoneNumber());
      this.updateSubQuestionStep();
    }
  }

}
