// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-text-center {
  text-align: center;
}
.olr-loader-alignment {
  margin: 0 auto;
  display: inline-block;
}
.olr-loader-display {
  display:block;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/entry-point/card-loading/card-loading.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,aAAa;AACf","sourcesContent":[".olr-text-center {\n  text-align: center;\n}\n.olr-loader-alignment {\n  margin: 0 auto;\n  display: inline-block;\n}\n.olr-loader-display {\n  display:block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
