import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceGuard } from './services/maintenance.guard';
import { AuthGuard } from './services/guards/auth.guard';
import { MaintenanceComponent } from './maintenance-banner/maintenance.component';
import { EntryPointComponent } from './entry-point/entry-point.component';
import { RedirectAssuredComponent } from './entry-point/redirect-assured/redirect-assured.component';

export const appRoutes: Routes = [

  { path: '', component: EntryPointComponent, canActivate: [MaintenanceGuard] },
  { path: 'start-claim', component: EntryPointComponent, canActivate: [MaintenanceGuard] },
  { path: 'redirect-assured', component: RedirectAssuredComponent },
  { path: 'claimant', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./claimant/claimant.module').then(m => m.ClaimantModule)
  },
  {
    path: 'incident-details', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () => import('./incident-details/incident-details.module').then(m => m.IncidentDetailsModule)
  },
  {
    path: 'damage-details', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () => import('./damage-details/damage-details.module').then(m => m.DamageDetailsModule)
  },
  {
    path: 'contact-information', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () => import('./contact-information/contact-information.module').then(m => m.ContactInformationModule)
  },
  {
    path: 'other-vehicles', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () => import('./other-vehicles/other-vehicles.module').then(m => m.OtherVehiclesModule)
  },
  {
    path: 'file-upload', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./file-upload/file-upload.module').then(m => m.FileUploadModule)
  },
  {
    path: 'review', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren: () => import('./review/review.module').then(m => m.ReviewModule)
  },
  {
    path: 'confirmation', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule)
  },
  {
    path: 'error', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule)
  },
  {
    path: 'session', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./session-timeout/session-timeout.module').then(m => m.SessionTimeOutModule)
  },
  {
    path: 'opt-in', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./opt-in/opt-in.module').then(m => m.OptInModule)
  },
  { path: 'maintenance', component: MaintenanceComponent },

  { path: 'homeandproperty', canActivate: [MaintenanceGuard, AuthGuard],
  loadChildren: () => import('./homeandproperty/homeandproperty.module').then(m => m.HomeandpropertyModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [RouterModule]
})
export class LossReportingRoutingModule { }
