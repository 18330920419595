import { Component, OnInit, Inject, OnDestroy, OnChanges, AfterViewInit, Input } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
@Component({
  selector: 'olr-drawer-helpful-tips',
  templateUrl: './drawer-helpful-tips.component.html',
  styleUrls: ['./drawer-helpful-tips.component.css']
})
export class DrawerHelpfulTipsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() isAuthenticated: boolean;
  @Input() selectedDate: string;
  @Input() policyInfo: any;
  @Input() showCoverages: boolean;
  @Input() originalScreenId: string;
  @Input() originalScreenChannel: string;
  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice = false;
  forwardEnabled: boolean;
  backEnabled: boolean;
  startPageNumber : number;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
  ) {
    this.isMobileDevice = window.innerWidth <= DrawerHelpfulTipsComponent.oneXSmallBreakpointPx;
  }

  ngOnInit(): void {
    this.setDefaults();
  }

  public get totalNumberOfPages(): number {
    return this.pages.length;
  }

  public get pages(): any[] {
    return (this.isAuthenticated && this.policyInfo) ||  !this.showCoverages
    ? [{id: 'deductible-overview', pageNumber: 1, screenId: 'onboarding-modal2'}, {id: 'questions-we-may-ask', pageNumber: 2, screenId: 'onboarding-modal1'}, {id: 'top-questions', pageNumber: 3, screenId: 'onboarding-modal3'}, {id: 'claim-process', pageNumber: 4, screenId: 'onboarding-modal4'}]
    : [{id: 'questions-we-may-ask', pageNumber: 1, screenId: 'onboarding-modal1'}, {id: 'top-questions', pageNumber: 2, screenId: 'onboarding-modal3'}, {id: 'claim-process', pageNumber: 3, screenId: 'onboarding-modal4'}];
  }

  get activePage(): any {
    return  this.pages[this.startPageNumber];
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-drawer-helpful-tips'));
  }

  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#olr-drawer-helpful-tips'));
  }

  ngOnChanges(): void {
    this.setDefaults();
  }

  private setDefaults() {
    this.startPageNumber = 0;
    this.forwardEnabled = true;
    this.backEnabled = false;
  }

  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < DrawerHelpfulTipsComponent.oneXSmallBreakpointPx;
  }

  setAnalyticsValues(): void {
    this.analytics.setValues({
      screenChannel: 'filealoss-auto',
      screenId: this.activePage.screenId,
    });
  }

  pageForward(): void {
    if(this.forwardEnabled) {
      this.startPageNumber = (this.startPageNumber < this.pages.length) ? this.startPageNumber + 1 : this.startPageNumber;
      this.setArrowStates();
      this.setAnalyticsValues();
    }
  }

  pageBack(): void {
    if(this.backEnabled) {
      this.startPageNumber = (this.startPageNumber <= this.pages.length) && (this.startPageNumber > 0) ? this.startPageNumber - 1 : this.startPageNumber;
      this.setArrowStates();
      this.setAnalyticsValues();
    }
  }

  closeTagging(): void {
    globalThis.oneX.Drawer.closeButtonClicked('olr-drawer-helpful-tips');
    this.analytics.setValues({
      screenChannel: this.originalScreenChannel,
      screenId: this.originalScreenId,
    });
  }

  private setArrowStates(): void {
    this.forwardEnabled = this.startPageNumber < this.pages.length - 1;
    this.backEnabled = this.startPageNumber > 0;
  }
}
