import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { ClaimDetailsService } from '../../services/claim-details.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { Customer } from '../../models/customer';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { LogService } from '../../services/log.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { LossReportService } from '../../services/loss-report.service';
import { ClaimsEventsService } from '../../services/claims-events.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'olr-received-property-claim',
  templateUrl: './received-property-claim.component.html',
  styleUrls: ['./received-property-claim.component.css']
})
export class ReceivedPropertyClaimComponent implements OnInit {
  claimNumber: string;
  claimId: string;
  customer: Customer;
  email: string;
  customerInfo: any;
  created = false;

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private claimDetailsService: ClaimDetailsService,
    private hostnameBuilderService: HostnameBuilderService,
    private customerProxyService: CustomerProxyService,
    private answerFinderService: LossReportAnswerFinderService,
    private logService: LogService,
    private lossReportService: LossReportService,
    private claimEventsService: ClaimsEventsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.setCustomerData();
    this.setClaimNumber();
    this.setCreated();
    this.setEmail();
    this.authenticationService.stopTimer();
  }

  private setClaimNumber() {
    const claim = this.claimDetailsService.getClaimDetails();
    this.claimNumber = claim.number;
    this.claimId = claim.id;
    this.sfAnalyticsService.sendData('confirmation-page');
    this.claimEventsService.buildEvent('Claim Confirmed');
    sessionStorage.clear();
    this.logService.setFlowName('filealoss-fire');
  }

  private setEmail() {
    if (this.customer) {
      this.email = this.customer.email;
      return  `${this.email}`;
    } else {
      return '';
    }
  }

  findAnswer(question: string){
    return this.answerFinderService.findAnswer(question);
  }

  public showHelpfulTips() {
    const category: string = this.findAnswer('incidentCategory');
    const subCategory: string = this.findAnswer('incidentSubCategory');

    if (category === 'water' && subCategory === 'other') {
      return false;
    }

    const subCategories = ['other', 'brokenPipe', 'septicSewerIssue', 'electricalAppliance', 'smoke'];
    const found = subCategories.find(sub => sub === subCategory);

    return found !== undefined;
  }

  private setCustomerData() {
    this.customer = this.customerProxyService.customerInfo;
  }

  goToClaims() {
    const claimsHubURL = `https://${this.hostnameBuilderService.getAWSHostname('hub.claims')}/#/claim/${this.claimId}/client/${this.customer.externalClientId}/role/NI/fire`;
    this.window.location.href = claimsHubURL;
  }
  private setCreated() {
    this.created = this.lossReportService.getCreatedIndicator();
  }

}
