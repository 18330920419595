import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehicleKnownComponent } from './vehicle-known/vehicle-known.component';
import { VehicleMakeComponent } from './vehicle-make/vehicle-make.component';
import { OtherNameComponent } from './other-name/other-name.component';
import { OtherInvolvedTypeComponent } from './other-involved-type/other-involved-type.component';
import { OtherContactComponent } from './other-contact/other-contact.component';
import { OtherInformationComponent } from './other-information/other-information.component';
import { OtherPersonComponent } from './other-person/other-person.component';
import { ClaimantParticipantsComponent } from './claimant-participants/claimant-participants.component';
import { AuthGuard } from '../services/guards/auth.guard';

const routes: Routes = [
    { path: 'vehicle-known', component: VehicleKnownComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-make', component: VehicleMakeComponent, canActivate: [AuthGuard] },
    { path: 'other-name', component: OtherNameComponent, canActivate: [AuthGuard] },
    { path: 'other-contact', component: OtherContactComponent, canActivate: [AuthGuard] },
    { path: 'other-person', component: OtherPersonComponent, canActivate: [AuthGuard] },
    { path: 'other-involved-type', component: OtherInvolvedTypeComponent, canActivate: [AuthGuard] },
    { path: 'other-information', component: OtherInformationComponent, canActivate: [AuthGuard] },
    { path: 'claimant-participants', component: ClaimantParticipantsComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class OtherVehiclesRoutingModule {}
