import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {

    constructor(
        private router: Router,
        private featureFlagService: FeatureFlagService,
    ) { }

    canActivate(): boolean {
        if (this.featureFlagService.isRedirectingToMaintenancePage()) {
            this.router.navigate(['/maintenance']); // navigates to the maintenance page if maintenance flag is true
        }
      return true;
    }
}
