import { Component, Input } from '@angular/core';

@Component({
  selector: 'olr-icon-motorcycle',
  templateUrl: './icon-motorcycle.component.html',
  styleUrls: ['./icon-motorcycle.component.css']
})
export class IconMotorcycleComponent {
  @Input() showInactive: boolean;

  constructor() { }

}
