// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-oneX-link--block {
    display: block;
    color: #D62311;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin-top: -5%;
  margin-bottom: 5%;
  text-align: right;
  }

  .-oneX-link--block:hover {
    text-decoration: underline;
  }
  
  a[href="https://reportloss.claims.test.statefarm.com/start-claim"] {
    color: #D62311;
    font-weight: bold;
    text-decoration: none;
  }

  a[href="https://reportloss.claims.test.statefarm.com/start-claim"]:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/modal-should-i-file/modal-should-i-file.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,eAAe;IACf,eAAe;EACjB,iBAAiB;EACjB,iBAAiB;EACjB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".-oneX-link--block {\n    display: block;\n    color: #D62311;\n    font-weight: bold;\n    text-decoration: none;\n    cursor: pointer;\n    margin-top: -5%;\n  margin-bottom: 5%;\n  text-align: right;\n  }\n\n  .-oneX-link--block:hover {\n    text-decoration: underline;\n  }\n  \n  a[href=\"https://reportloss.claims.test.statefarm.com/start-claim\"] {\n    color: #D62311;\n    font-weight: bold;\n    text-decoration: none;\n  }\n\n  a[href=\"https://reportloss.claims.test.statefarm.com/start-claim\"]:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
