import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from 'projects/lossreporting-library/src/lib/services/log.service';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { RoutingService } from 'projects/lossreporting-library/src/lib/services/routing.service';
import { SfAnalyticsService } from 'projects/lossreporting-library/src/lib/services/sf-analytics.service';
import { BaseComponent } from 'projects/lossreporting-library/src/lib/shared/base/base.component';

@Component({
  selector: 'olr-homeandproperty-severity',
  templateUrl: './homeandproperty-severity.component.html',
  styleUrls: ['./homeandproperty-severity.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class HomeandpropertySeverityComponent extends BaseComponent implements OnInit {

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('severity');
    this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    this.updateAnswer(answer);
  }
}
