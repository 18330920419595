// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-modal {
  display: none;
  align-content: center;
}
.olr-policy-margins {
  margin-bottom: 16px;
}
.olr-center-text {
  text-align:center;
}
.olr-charcoal02 {
  color: #403C3C
}
.olr-merna03-red {
  color: #D62311;
}
.olr-modal-footer-margin {
  margin-bottom:24px;
}
a {
  cursor: pointer;
}

.olr-inactive-policies-header {
  color: #403C3C;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .olr-inactive-policies-header {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 24px;
  }
  .olr-policy-msg-spacing {
    margin-bottom: 24px;
  }
}

@media (min-width: 768px) {
  .olr-inactive-policies-header {
    font-size: 33px;
    line-height: 33px;
    margin-bottom: 24px;
  }
  .olr-policy-msg-spacing {
    margin-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .olr-inactive-policies-header {
    font-size: 39px;
    line-height: 39px;
    margin-bottom: 24px;
  }
  .olr-policy-msg-spacing {
    margin-bottom: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/entry-point/modal-policy-view/modal-policy-view.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE;AACF;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".olr-modal {\n  display: none;\n  align-content: center;\n}\n.olr-policy-margins {\n  margin-bottom: 16px;\n}\n.olr-center-text {\n  text-align:center;\n}\n.olr-charcoal02 {\n  color: #403C3C\n}\n.olr-merna03-red {\n  color: #D62311;\n}\n.olr-modal-footer-margin {\n  margin-bottom:24px;\n}\na {\n  cursor: pointer;\n}\n\n.olr-inactive-policies-header {\n  color: #403C3C;\n  font-weight: 600;\n}\n\n@media screen and (max-width: 576px) {\n  .olr-inactive-policies-header {\n    font-size: 25px;\n    line-height: 25px;\n    margin-bottom: 24px;\n  }\n  .olr-policy-msg-spacing {\n    margin-bottom: 24px;\n  }\n}\n\n@media (min-width: 768px) {\n  .olr-inactive-policies-header {\n    font-size: 33px;\n    line-height: 33px;\n    margin-bottom: 24px;\n  }\n  .olr-policy-msg-spacing {\n    margin-bottom: 24px;\n  }\n}\n\n@media (min-width: 992px) {\n  .olr-inactive-policies-header {\n    font-size: 39px;\n    line-height: 39px;\n    margin-bottom: 24px;\n  }\n  .olr-policy-msg-spacing {\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
