import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { AddressConstants } from '../constants/address-constants';
import { Address } from '../../models/address.model';

@Component({
  selector: 'olr-card-address-editable',
  templateUrl: './card-address-editable.component.html',
  styleUrls: ['./card-address-editable.component.css']
})
export class CardAddressEditableComponent implements OnDestroy, AfterViewInit{
  @Input() heading: string;
  @Input() name: string;
  @Input() editBtnText = 'Edit'; // optional
  @Output() cancelEdit = new EventEmitter<string>();
  @Output() saveEdit = new EventEmitter<Address>();
  @Output() startEdit = new EventEmitter<string>();
  @ViewChild('cardContent', { static: false }) cardContent: ElementRef;
  @ViewChild('state', { static: false }) state: ElementRef;
  @ViewChild('city', { static: false }) city: ElementRef;
  @ViewChild('postalCode', { static: false }) postalCode: ElementRef;
  @ViewChild('street1', { static: false }) street1: ElementRef;
  @ViewChild('street2', { static: false }) street2: ElementRef;

  editing = false;
  states: any[];
  isStreet1Error = false;
  isStreet2Error = false;
  isCityError = false;
  isPostalError = false;
  invalidEmail = false;
  isRequiredError = false;
  address: Address;
  countries = AddressConstants.countryStates;
  private street1Regex = '([A-Za-z\\d\\s#\'\\-.&,\\\u2019]{1,80})';
  private street2Regex = '([A-Za-z\\d\\s#\'\\-.&,\\\u2019]{1,39})';
  private usaPostalRegex = '([0-9]{5}|[0-9]{9})';
  private cityRegex = '([A-Za-z\\s\\-\\.\\\'\\\u2019]{1,30})';

  constructor( @Inject('Window') private window: any )
    {
    this.setStates();
    }

    ngOnDestroy(): void {
      this.window.oneX.removeElement(document.querySelector('#cardElements'));
    }
    ngAfterViewInit(): void {
      this.window.oneX.addElement(document.querySelector('#cardElements'));
    }

  startEditing(): void {
    this.startEdit.emit('');
    this.editing = true;
    this.street1.nativeElement.focus();
  }

  saveEditing(): void {
    if (this.street1.nativeElement.value &&
      this.city.nativeElement.value &&
      this.state.nativeElement.value &&
      this.postalCode.nativeElement.value) {
        if (this.validateAddressAnswers()) {
          this.address = new Address();
          this.address.street1 = this.street1.nativeElement.value;
          this.address.street2 = this.street2.nativeElement.value;
          this.address.city = this.city.nativeElement.value;
          this.address.state = this.state.nativeElement.value;
          this.address.postalCode = this.postalCode.nativeElement.value;
          this.saveEdit.emit(this.address);
          this.editing = false;
        }
      } else {
        this.isRequiredError = true;
      }
  }

  onCancelEdit(): void {
    this.cancelEdit.emit('');
    this.editing = false;
    setTimeout(() => {
      this.cardContent.nativeElement.focus();
    }, 0);
  }

  private setStates(): void {
    const subject = this.countries.find((country) => {
      return country.id === 'usa';
    });
    this.states = subject.states;
  }

  private validateAddressAnswers(): boolean {
    const isStreet1Valid = this.regexValidate(this.street1.nativeElement.value, this.street1Regex);
    this.isStreet1Error = !isStreet1Valid;

    const isStreet2Valid = this.regexValidate(this.street2.nativeElement.value ? this.street2.nativeElement.value : ' ', this.street2Regex);
    this.isStreet2Error = !isStreet2Valid;

    const isCityValid = this.regexValidate(this.city.nativeElement.value, this.cityRegex);
    this.isCityError = !isCityValid;

    const isPostalValid = this.regexValidate(this.postalCode.nativeElement.value, this.usaPostalRegex);
    this.isPostalError = !isPostalValid;

    return isStreet2Valid && isStreet1Valid && isCityValid && isPostalValid;
  }

  regexValidate(answer: string, regexString: string): boolean {
    const regEx = new RegExp(`^${regexString}$`);
    return regEx.test(answer);
  }

}
