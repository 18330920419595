import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { LoadingService } from './loading.service';
import { LogService } from './log.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { ErrorHandlingService } from './helpers/error-handling.service';
import { Customer } from '../models/customer';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { SfAnalyticsService } from './sf-analytics.service';
import { parseJwt } from 'customer-oauth2-token-management-utility';
import { CustomerBuilderService } from './helpers/customer-builder.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerProxyService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private customerBuilder: CustomerBuilderService,
    private cookieHandlerService: CookieHandlerService,
    private hostnameBuilder: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService,
    private analyticsService: SfAnalyticsService,
    private logService: LogService,
    private cookieService: CookieHandlerService,
    ) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept:  'application/json',
          'jwt': parseJwt(),
          'X-SF_SSO_TOKEN': this.cookieHandlerService.getSSOToken(),
          'Content-Type': 'application/json',
          CorrelationId: this.logService.getCorrelationId()
        }),
        withCredentials: true
      };
    }

  customerInfoUpdated = new Subject<any>();

  private _CUSTOMER_INFO: any;

  private httpOptions;

  getCustomerInfo(): void {
    const url = `${this.hostnameBuilder.getLRECustomerURL()}`;
    const logDetails = this.logService.getLogDetails();
    this.loadingService.startRequest();
    let parsedJwt = parseJwt();
    const oauthHeader = this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : '';

    const headers = parsedJwt ? new HttpHeaders({
      'Authorization': oauthHeader,
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId()
    }) : new HttpHeaders({
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId()
    })

    this.http
      .get<any>(url, { headers })
      .subscribe(
        response => {
          const transformedData = this.customerBuilder.buildCustomer(response);
          this.customerInfo = transformedData;
          this.customerInfoUpdated.next(this.customerInfo);
          this.analyticsService.setExternalClientId(this.customerInfo.externalClientId);
          this.logService.setExternalClientId(this.customerInfo.externalClientId);
          this.logService.log(CliffLogCodes.CP_RTRV_SUC, logDetails);
         },
        error => {
          this.loadingService.completeRequest();
          this.errorHandlingService.handleError(error, CliffLogCodes.CP_RTRV_FAIL);
        },
        () => {
          this.loadingService.completeRequest();
        }
      );
  }

  get customerInfo(): Customer {
    if (!this._CUSTOMER_INFO){
      this._CUSTOMER_INFO = JSON.parse(sessionStorage.getItem('customerInfo'));
    }
    return this._CUSTOMER_INFO;
  }

  set customerInfo(customerInfo: Customer) {
    sessionStorage.setItem('customerInfo', JSON.stringify(customerInfo));
    this._CUSTOMER_INFO = customerInfo;
  }
}
