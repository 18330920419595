// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-text-container {
  background-color: #EDF8FC;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.lr-help-icon {
  border: none;
  background: none;
}
.lr-help-icon .-oneX-icon {
  width: 24px;
  height: 24px;
}


.olr-address-modal {
  display: none;
  align-content: center;
}

.olr-email-padding {
  width: 50%;
  word-break: break-all;
  padding-right: 50px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/contact-information/org-contact-information/org-contact-information.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".lr-text-container {\n  background-color: #EDF8FC;\n  display: flex;\n  justify-content: space-between;\n  padding: 16px;\n}\n.lr-help-icon {\n  border: none;\n  background: none;\n}\n.lr-help-icon .-oneX-icon {\n  width: 24px;\n  height: 24px;\n}\n\n\n.olr-address-modal {\n  display: none;\n  align-content: center;\n}\n\n.olr-email-padding {\n  width: 50%;\n  word-break: break-all;\n  padding-right: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
