import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { AddressConstants } from '../../shared/constants/address-constants';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-other-contact',
  templateUrl: './other-contact.component.html',
  styleUrls: ['./other-contact.component.css', '../../shared/styles/question-common-styling.scss']
})
export class OtherContactComponent extends BaseComponent implements OnInit, AfterViewInit {
  name: string;
  makeModel: string;
  isValueProvided = true;
  states: any[];
  selectedStateOption = '';
  initialCountry;
  isStreet1Error = false;
  isStreet2Error = false;
  isCityError = false;
  isPostalError = false;
  isPhoneError = false;
  isPhoneLengthError = false;
  phoneDisabled = true;
  initialState;
  addressSubQuestion: any;
  citySubQuestion: any;
  @ViewChild('country', { static: true }) country: ElementRef;
  @ViewChild('state', { static: true }) state: ElementRef;
  @ViewChild('city', { static: true }) city: ElementRef;
  @ViewChild('postalCode', { static: true }) postalCode: ElementRef;
  @ViewChild('street1', { static: true }) street1: ElementRef;
  @ViewChild('street2', { static: true }) street2: ElementRef;
  @ViewChild('phoneType', { static: true }) phoneType: ElementRef;
  @ViewChild('phoneNumber', { static: true }) phoneNumber: ElementRef;


  countries = AddressConstants.countryStates;

  private usaPostalRegex = '([0-9]{5}|[0-9]{9})';
  private mexicanPostalRegex = '[0-9]{5}';
  private canadianPostalRegex = '(?!.*[DFIOQU])[A-VXY][0-9][A-Z][0-9][A-Z][0-9]';


  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    private cdr: ChangeDetectorRef,
    public logService: LogService,
    @Inject('Window') private window: any,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData('first-claimant-address-question');

    if (this.step.completed) {
      this.resetAddressInfo();
    } else {
      this.getAddressInfo();
      this.country.nativeElement.focus();
    }
    this.setVehicleMakeModel();
    this.setClaimantName();
    this.phoneTypeCheck();
  }

  ngAfterViewInit(){
    if (!this.step.completed){
      this.country.nativeElement.value = 'usa';
      this.selectChangeHandler();
    }
    this.cdr.detectChanges();
    // @ts-ignore
    this.window.oneX.FloatingLabel.init();
    // @ts-ignore
    this.window.oneX.AutoFormat.init();
    this.state.nativeElement.focus();
    this.country.nativeElement.focus();
  }

  setClaimantName() {
    const claimantNameStep = this.lossReportService.getStepByName('firstClaimantName');
    const claimantNameSubQuestions = claimantNameStep.question.subQuestions[0];
    this.name = `${claimantNameSubQuestions[0].answers[0]} ${claimantNameSubQuestions[1].answers[0] || ''}`;
  }

  setVehicleMakeModel() {
    const vehicleStep = this.lossReportService.getStepByName('firstClaimantVehicle');
    if (vehicleStep){
      const vehicleSubQuestions = vehicleStep.question.subQuestions[0];
      this.makeModel = `${vehicleSubQuestions[1].answers[0]} ${vehicleSubQuestions[2].answers[0] || ''}`.trim();
    }
    else {
        this.makeModel = '';
    }
  }

  toggleContinue() {
    if (this.country.nativeElement.value.length > 0) {
      this.isValueProvided = true;
    } else {
      this.isValueProvided = false;
    }
  }

  selectChangeHandler() {
    this.toggleContinue();
    if (!this.step.completed)
      {

        this.updateSubQuestionAnswer('country', 0, null, this.country.nativeElement.value);
        this.lossReportService.lossReportUpdated.subscribe(() => {
          this.getAddressInfo();
        });
      }else{
        this.updateSubQuestionAnswer('country', 0, null, this.country.nativeElement.value, false);
        this.getAddressInfo();
      }

  }

  private validatePhone(): boolean {
    if (this.phoneType.nativeElement.value !== ''){
      const phone: string = this.phoneNumber.nativeElement.value;
      const unFormattedPhone = phone.replace(/[()\\s-]+/g, '');
      const phoneLengthValid = unFormattedPhone.length === 10;
      const isPhoneValid = this.regexValidate(
        unFormattedPhone, this.step.question.subQuestions[0][1].allowedValuesRegex,
        1
      );
      this.isPhoneError = !isPhoneValid;
      this.isPhoneLengthError = !phoneLengthValid;
      return isPhoneValid && phoneLengthValid;
    }
    return true;
  }

  public phoneTypeCheck(){
    if (this.phoneType.nativeElement.value === ''){
      this.phoneDisabled = true;
      this.phoneNumber.nativeElement.value = '';
    }
      else {
      this.phoneDisabled = false;
    }
  }

  private validateAddressAnswers(): boolean {
    const isStreet1Valid = this.regexValidate(
      this.street1.nativeElement.value, this.step.question.subQuestions[0][1].allowedValuesRegex,
      this.step.question.subQuestions[0][1].minAnswers
      );
    this.isStreet1Error = !isStreet1Valid;

    const isStreet2Valid = this.regexValidate(
      this.street2.nativeElement.value, this.step.question.subQuestions[0][2].allowedValuesRegex,
      this.step.question.subQuestions[0][2].minAnswers
      );
    this.isStreet2Error = !isStreet2Valid;

    const isCityValid = this.regexValidate(
      this.city.nativeElement.value, this.step.question.subQuestions[0][3].allowedValuesRegex,
      this.step.question.subQuestions[0][3].minAnswers
      );
    this.isCityError = !isCityValid;

    const postalRegex = this.initialCountry === 'canada' ? this.canadianPostalRegex : this.usaPostalRegex;
    const isPostalValid = this.regexValidate(
      this.formatPostalAnswer(), postalRegex, this.step.question.subQuestions[0][5].minAnswers
    );
    this.isPostalError = !isPostalValid;

    return isStreet1Valid && isStreet2Valid && isCityValid && isPostalValid;
  }

  private formatPostalAnswer() {
    const postalCode = this.postalCode.nativeElement.value || '';
    return this.initialCountry === 'canada' ? postalCode.replace(/ +/g, '') : postalCode;
  }

  getAddressInfo() {
    this.initialCountry = this.step.question.subQuestions[0][0].answers[0] || 'usa';
    this.setStatesByCountry(this.initialCountry);
  }

  private setStatesByCountry(countryId: string): void {
    const subject = this.countries.find((country) => {
      return country.id === countryId;
    });
    this.states = subject ? subject.states : [];
  }

  private getStateNameByAbbreviation(stateAbbrv: string): string {
    const subject = this.states.find((state) => {
      return state.state === stateAbbrv;
    });
    const selectedState = subject ? subject.name : '';
    return selectedState;
  }

  resetAddressInfo() {
    this.getAddressInfo();
    const country = this.step.question.subQuestions[0][0].answers[0];
    this.selectedStateOption = this.getStateNameByAbbreviation(this.step.question.subQuestions[0][4].answers[0]);
    this.setStatesByCountry(country);
    this.country.nativeElement.value = this.step.question.subQuestions[0][0].answers[0];
    this.street1.nativeElement.value = this.step.question.subQuestions[0][1].answers[0];
    this.street2.nativeElement.value = this.step.question.subQuestions[0][2].answers[0];
    this.city.nativeElement.value = this.step.question.subQuestions[0][3].answers[0];
    this.state.nativeElement.value = this.step.question.subQuestions[0][4].answers[0];
    this.postalCode.nativeElement.value = this.step.question.subQuestions[0][5].answers[0];
    this.phoneType.nativeElement.value = this.step.question.subQuestions[0][6].answers[0];
    this.phoneNumber.nativeElement.value = this.step.question.subQuestions[0][7].answers[0];
    this.isValueProvided = true;
  }

  resetErrorToFalse() {
    this.isPhoneError = false;
  }

  isSelected(state: string): boolean {
    return state === this.selectedStateOption;
  }

  answerQuestion() {
    if (this.isValueProvided && this.validateAddressAnswers() && this.validatePhone()) {
      const phone: string = this.phoneNumber.nativeElement.value;
      this.updateSubQuestionAnswer('state', 0, null, this.state.nativeElement.value || '', false);
      this.updateSubQuestionAnswer('city', 0, null, this.city.nativeElement.value || '', false);
      this.updateSubQuestionAnswer('postalCode', 0, null, this.formatPostalAnswer() || '', false);
      this.updateSubQuestionAnswer('street1', 0, null, this.street1.nativeElement.value || '', false);
      this.updateSubQuestionAnswer('street2', 0, null, this.street2.nativeElement.value || '', false);
      this.updateSubQuestionAnswer('phoneType', 0, null, this.phoneType.nativeElement.value || '', false);
      this.updateSubQuestionAnswer('phoneNumber', 0, null, phone.replace(/[()\\s-]+/g, '') || '', false);
      this.updateSubQuestionStep();
    }
  }

}
