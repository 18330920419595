// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* sticky footer */
.olr-drawer-content {
  min-height: calc(100vh - 160px);
}

#dialog-title {
  padding-top: 38px;
  padding-bottom: 0;
  padding-right: 32px;
  padding-left: 32px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/styles/drawer-common-styling.scss"],"names":[],"mappings":"AACC,kBAAA;AACA;EACG,+BAAA;AAAJ;;AAGE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n /* sticky footer */\n .olr-drawer-content {\n    min-height: calc(100vh - 160px);\n  }\n\n  #dialog-title {\n    padding-top: 38px;\n    padding-bottom: 0;\n    padding-right: 32px;\n    padding-left: 32px;\n  }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
