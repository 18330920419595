import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { TermConditionsModalComponent } from './term-conditions-modal/term-conditions-modal.component';
import { LogService } from '../../services/log.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { CliffLogDetails } from '../../models/cliff-log-details.model';

@Component({
  selector: 'olr-text-notification',
  templateUrl: './text-notification.component.html',
  styleUrls: ['./text-notification.component.css', '../../shared/styles/question-common-styling.scss']
})
export class TextNotificationComponent extends BaseComponent implements OnInit {
  @ViewChild('myModal') modal: TermConditionsModalComponent;
  numbers: string[] = [];
  hasSelectedNumber = false;
  selectedPhone = '';
  selectedPhoneIndex: number;
  hasAgreed = false;
  agreementOptions = {
    agree: false
  };

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService
  ) {
    super(lossReportService, router, routingService, logService);
    this.numbers = [...this.step.question.subQuestions[0][0].allowedValues];

    const declinetextIndex = this.numbers.indexOf('declinetext');
    if (declinetextIndex > -1) {
      this.numbers.splice(declinetextIndex, 1);
    }

    const notSeenIndex = this.numbers.indexOf('notSeen'); // possibly will be removed from lossreports after we can add a new number
    if (notSeenIndex > -1) {
      this.numbers.splice(notSeenIndex, 1);
    }
  }

  ngOnInit(): void {
    this.hasSelectedNumber = this.step.question.subQuestions[0][0].answers.length > 0;
    this.analytics.sendData('text-preference');
    if (this.step.completed) {
      this.preselectPhoneNumber();

      if (this.step.question.subQuestions[0][0].answers[0] === 'declinetext') {
        const element = document.querySelector(`#declineLink`);
        element.classList.add('lr-btn-answer');

      } else if (this.step.question.subQuestions[0][0].answers[0] === 'notSeen') {
        const element = document.querySelector(`#noSeeNumberLink`);
        element.classList.add('lr-btn-answer');

      }

    }
  }

  preselectPhoneNumber() {
    this.selectedPhone = this.step.question.subQuestions[0][0].answers[0];
    this.hasSelectedNumber = false;
    if (this.selectedPhone !== 'notSeen' && this.selectedPhone !== 'declinetext') {
      for (let i = 0; i < this.numbers.length; i++) {
        if (this.selectedPhone === this.numbers[i]) {
          this.selectedPhoneIndex = i;
        }
      }
      this.hasAgreed = false;
      this.updateSubQuestionAnswer('acknowledgeAgreement', 0, null, [], false);
    }
  }

  declineTextNotification() {
    this.updateSubQuestionAnswer('preferredTextPhone', 0, null, 'declinetext', true);
  }

  noSeeNumber() {
    this.updateSubQuestionAnswer('preferredTextPhone', 0, null, 'notSeen', true);
  }

  selectNumber(preferredNumber: string) {
    this.selectedPhone = preferredNumber;
    this.hasSelectedNumber = true;
    this.updateSubQuestionAnswer('preferredTextPhone', 0, null, preferredNumber, true);
  }

  onAcknowledge(e: Event) {
    const logDetails = this.logService.getLogDetails();
    const defaultValue = 'defaultValue';
    const inputValue = e.target[defaultValue];
    const checked = 'checked';
    const isChecked = e.target[checked];
    this.agreementOptions[inputValue] = isChecked;
    this.hasAgreed = isChecked;
    logDetails.routePath = '/contact-information/text-notification';
    logDetails.serviceName = 'TextNotificationComponent';
    if (isChecked) {
      this.logService.log(CliffLogCodes.OPTIN_AGMT_ACCEPT, logDetails)
    } else {
      this.logService.log(CliffLogCodes.OPTIN_AGMT_REJECTED, logDetails)
    }
    this.updateSubQuestionAnswer('acknowledgeAgreement', 0, null, isChecked ? 'agree' : [], false);
  }

  displayTermsConditions() {
    // @ts-ignore
    this.window.oneX.Modal.showModal(this, 'oneX-modal-1');
  }

  answerQuestion(): void {
    this.analytics.sendData(this.hasAgreed ? 'text-opted-in' : 'text-opted-in-unchecked');
    this.updateSubQuestionStep();
  }

}
