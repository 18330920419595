// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-duplicate-header {
    margin-top: 125px;
    margin-left: 53px;
    margin-right: 46px;
}

.lr-duplicate-primary {
    margin-top: 57px;
    margin-left: 40px;
    margin-right: 40px;
}

.lr-duplicate-secondary {
    margin-top: 141px;
    margin-left: 88px;
    margin-right: 90px;
}

.lr-duplicate-button {
    margin-top: 51px;
    margin-left: 1px;
    margin-right: 3px;
}

.lr-search {
    margin-top: 35px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/error-pages/duplicate-claim/duplicate-claim.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".lr-duplicate-header {\n    margin-top: 125px;\n    margin-left: 53px;\n    margin-right: 46px;\n}\n\n.lr-duplicate-primary {\n    margin-top: 57px;\n    margin-left: 40px;\n    margin-right: 40px;\n}\n\n.lr-duplicate-secondary {\n    margin-top: 141px;\n    margin-left: 88px;\n    margin-right: 90px;\n}\n\n.lr-duplicate-button {\n    margin-top: 51px;\n    margin-left: 1px;\n    margin-right: 3px;\n}\n\n.lr-search {\n    margin-top: 35px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
