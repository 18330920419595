import { Component, Inject, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { BaseComponent } from '../base/base.component';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-modal-remove-participant',
  templateUrl: './modal-remove-participant.component.html',
  styleUrls: ['./modal-remove-participant.component.css']
})
export class ModalRemoveParticipantComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() selectedCustomerName = '';
  @Output() modalChoice: EventEmitter<string> = new EventEmitter();
  constructor(
    @Inject('Window') private window: any,
    lossReportService: LossReportService,
    routingService: RoutingService,
    logService: LogService,
    router: Router
    ) {
        super(lossReportService, router, routingService, logService);
    }

  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#olr-modal-remove-participant'));
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#olr-modal-remove-participant'));
  }

  onCancelRemoval(answer: string){
    this.modalChoice.emit(answer);
    this.window.oneX.Modal.hideModal();
  }

  onConfirmRemoval(answer: string) {
    this.modalChoice.emit(answer);
    this.window.oneX.Modal.hideModal();
  }

}
