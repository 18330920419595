import { Injectable } from '@angular/core';
import { PoliciesService } from '../policies.service';
import { Participant } from '../../models/participant.model';
import { Name } from '../../models/name.model';
import { Contact } from '../../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantBuilderService {

  constructor(
    private policiesService: PoliciesService
  ) { }

  createParticipant(clientId: string, role: string, nameObj: any = null, addressObj: any = null): Participant {
    const participant = new Participant();

    if (clientId) {
      const policyCustomers = this.policiesService.getPolicyCustomers();
      const person = policyCustomers.find(customer => customer.clientId === clientId);
      nameObj = person ? person.name : null;
      participant.clientId = clientId;
    }

    participant.fullName = nameObj ? `${nameObj.firstName} ${nameObj.lastName}` : undefined;
    participant.id = `${Date.now()}`;
    participant.role = role;

    const name = new Name();
    participant.name = name;

    if (nameObj) {
      participant.name.first = nameObj.firstName;
      participant.name.last = nameObj.lastName;
    } else {
      participant.name.unknown = true;
      participant.isHouseholdParticipant = "no"
    }

    if (addressObj) {
      const contact = new Contact();
      contact.country = addressObj.country;
      contact.zipCode = addressObj.zipCode;
      contact.street1 = addressObj.street1;
      contact.street2 = addressObj.street2;
      contact.city = addressObj.city;
      contact.state = addressObj.state;
      contact.phoneType = addressObj.phoneType;
      contact.phoneNumber = addressObj.phoneNumber;
      participant.contact = contact;
    }

    return participant;
  }

  updateParticipant(participant: Participant, nameObj: any = null, addressObj: any = null ) {
    participant.fullName = nameObj ? `${nameObj.firstName} ${nameObj.lastName}` : undefined;

    const name = new Name();
    participant.name = name;

    if (nameObj) {
      participant.name.first = nameObj.firstName;
      participant.name.last = nameObj.lastName;
    } else {
      participant.name.unknown = true;
    }

    if (addressObj) {
      const contact = new Contact();
      contact.country = addressObj.country;
      contact.zipCode = addressObj.zipCode;
      contact.street1 = addressObj.street1;
      contact.street2 = addressObj.street2;
      contact.city = addressObj.city;
      contact.state = addressObj.state;
      contact.phoneType = addressObj.phoneType;
      contact.phoneNumber = addressObj.phoneNumber;
      participant.contact = contact;
    } else {
      participant.contact = undefined;
    }

    return participant;
  }
}
