import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';

@Component({
  selector: 'olr-add-new-phone',
  templateUrl: './add-new-phone.component.html',
  styleUrls: ['./add-new-phone.component.css', '../../shared/styles/question-common-styling.scss']
})
export class AddNewPhoneComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('phoneType', {static: true}) phoneType: ElementRef;
  @ViewChild('phoneNumber', {static: true}) phoneNumber: ElementRef;
  phoneTypeValue: string;
  phoneNumberValue = '';
  numCharacters = 0;
  saveDisabled = true;

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService
    ) {
      super(lossReportService, router, routingService, logService);
    }

  ngOnInit(): void {
    if (this.isFireLossReport) {
          this.analytics.sendData('add-phone-number');
    } else {
      this.analytics.sendData('insured-phonenumberpref-question');
    }
    if (this.step.completed){
      this.populateData();
    }
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#phoneLabels'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#phoneLabels'));
  }

  populateData() {
    this.phoneTypeValue = this.step.question.subQuestions[0][0].answers[0];
    this.phoneNumberValue = this.step.question.subQuestions[0][1].answers[0];
  }

  updateNumCharacters(value: string): void {
    value = value ? value : "";
    var phoneNumber = value.replace(/[^0-9\.]+/g, '');
    if (phoneNumber.length === 10) {
      this.phoneNumberValue = phoneNumber;
      this.saveDisabled = false;
    } else {
      this.saveDisabled = true;
    }
  }

  answerQuestion() {
    const phone: string = this.phoneNumber.nativeElement.value;
    this.updateSubQuestionAnswer('phoneType', 0, null, this.phoneType.nativeElement.value);
    this.updateSubQuestionAnswer('phoneNumber', 0, null, phone.replace(/[()\\s-]+/g, ''));
    this.updateSubQuestionStep();
  }
}
