import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LogService } from '../../../services/log.service';

@Component({
  selector: 'olr-claimant-vehicle',
  templateUrl: './claimant-vehicle.component.html',
  styleUrls: ['./claimant-vehicle.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantVehicleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('policy', {static: true}) policy: ElementRef;
  @ViewChild('year', {static: true}) year: ElementRef;
  @ViewChild('make', {static: true}) make: ElementRef;
  @ViewChild('model', {static: true}) model: ElementRef;
  private isPolicyValid = false;
  private isYearValid = false;
  private isMakeValid = false;
  private isModelValid = false;
  isBusiness = false;
  isValueProvided = false;
  isPolicyError = false;
  isMakeError = false;
  isModelError = false;
  policySubQuestion: any;
  yearSubQuestion: any;
  makeSubQuestion: any;
  modelSubQuestion: any;
  selectedYear = '';
  years = [];

  get labelText(): any {
    const oicText = {
      title: 'Now tell us your client\'s policy information.', policy: 'Policy Number', year: 'Vehicle Year', make: 'Vehicle Make', model: 'Vehicle Model'
    };
    const indText = {
      title: 'Now tell us about your vehicle.', year: 'Year', make: 'Make', model: 'Model'
    };
    return this.isBusiness ? oicText : indText;
  }

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
      this.populateYearOptions();
      this.determineBusiness();
    }

  ngOnInit(): void {
    this.sfAnalyticsService.sendData('vehicle-information');
    this.getSubQuestions();
    this.displayAnsweredState();
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#clientPolicyInfo'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#clientPolicyInfo'));
  }

  private determineBusiness(): void {
    const url = this.router.url;
    if (url && url.includes('client-vehicle')) {
      this.isBusiness = true;
    }
  }

  private getSubQuestions(): void {
    if (this.isBusiness) {
      this.policySubQuestion = this.step.question.subQuestions[0][0];
      this.yearSubQuestion = this.step.question.subQuestions[0][1];
      this.makeSubQuestion = this.step.question.subQuestions[0][2];
      this.modelSubQuestion = this.step.question.subQuestions[0][3];
    } else {
      this.yearSubQuestion = this.step.question.subQuestions[0][0];
      this.makeSubQuestion = this.step.question.subQuestions[0][1];
      this.modelSubQuestion = this.step.question.subQuestions[0][2];
    }
  }

  private displayAnsweredState(): void {
    if (this.isBusiness) {
      this.policy.nativeElement.value = this.policySubQuestion.answers[0] || '';
    }
    this.selectedYear = this.yearSubQuestion.answers[0] || '';
    this.year.nativeElement.value = this.selectedYear;
    this.window.oneX.$('#year').change();
    this.make.nativeElement.value = this.makeSubQuestion.answers[0] || '';
    this.model.nativeElement.value = this.modelSubQuestion.answers[0] || '';
    this.triggerOneXChangeState();
    this.validateFields();
  }

  private populateYearOptions() {
    const max = new Date().getFullYear() + 1;
    for (let i = max; i >= 1900; i--){
      this.years.push(i.toString());
    }
  }

  private triggerOneXChangeState() {
    setTimeout(() => {
      this.window.oneX.$('.-oneX-textfield--floating-input, .-oneX-dropdown, .-oneX-textfield__input').trigger('change');
    }, 100);
  }

  enterPolicyNumber() {
    this.validateFields();
    this.isPolicyError = !this.isPolicyValid;
  }

  enterYear() {
    this.validateFields();
  }

  enterMake() {
    this.validateFields();
    this.isMakeError = !this.isMakeValid;
  }

  enterModel() {
    this.validateFields();
    this.isModelError = !this.isModelValid;
  }

  private validateFields(): void {
    if (this.isBusiness) {
      this.isPolicyValid = this.regexValidate(
        this.policy.nativeElement.value, this.policySubQuestion.allowedValuesRegex, this.policySubQuestion.minAnswers);
    }
    this.isMakeValid = this.regexValidate(
      this.make.nativeElement.value, this.makeSubQuestion.allowedValuesRegex, this.makeSubQuestion.minAnswers);
    this.isModelValid = this.regexValidate(
      this.model.nativeElement.value, this.modelSubQuestion.allowedValuesRegex, this.modelSubQuestion.minAnswers);
    this.isYearValid = this.year.nativeElement.value !== '' || this.selectedYear !== '';

    this.isValueProvided = this.isBusiness ? this.isPolicyValid && this.isMakeValid && this.isModelValid && this.isYearValid
                                           : this.isMakeValid && this.isModelValid && this.isYearValid;
  }

  answerQuestion(){
    if (this.isBusiness) {
      this.updateSubQuestionAnswer('claimantPolicyNumber', 0, null, this.policy.nativeElement.value);
    }
    this.updateSubQuestionAnswer('year', 0, null, this.year.nativeElement.value);
    this.updateSubQuestionAnswer('make', 0, null, this.make.nativeElement.value);
    this.updateSubQuestionAnswer('model', 0, null, this.model.nativeElement.value);
    this.updateSubQuestionStep();
  }

}
