import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueTranslator'
})
export class ValueTranslatorPipe implements PipeTransform {
  private translations = {
    incidentCategory: {
      accident: 'Vehicle accident',
      other: 'Other vehicle incident',
      weather: 'Weather/Natural disaster',
      waterDamage: 'Water damage',
      septicTankSewer: 'Septic tank/Sewer',
      fire: 'Fire',
      sumpPumpFail: 'Sump pump fail',
      vandalism: 'Vandalism',
      theft: 'Theft',
      explosion: 'Explosion',
      animal: 'Accident with an animal',
    },
    incidentSubCategory: {
      anotherVehicle: 'Accident with another vehicle',
      hitMyParkedVehicle: 'My car was hit by another vehicle while parked',
      stationaryObject: 'Accident with a non-vehicle stationary object',
      potholeDebris: 'Pothole/Debris in road',
      bicyclist: 'Vehicle driven by me hit bicyclist',
      pedestrian: 'Vehicle driven by me hit pedestrian',
      accidentOther: 'Other',
      other: 'Other',
      hail: 'Hail',
      windstorm: 'Windstorm',
      flood: 'Flood',
      wind: 'Wind',
      earthquake: 'Earthquake',
      wildfire: 'Wildfire',
      tornado: 'Tornado',
      weatherOther: 'Other',
      frozenPipe: 'Frozen pipe',
      heavyRainStorm: 'Heavy rain storm',
      brokenPipe: 'Broken pipe',
      sleetSnowIce: 'Sleet/Snow/Ice',
      waterDamage: 'Water damage',
      septicTankSewer: 'Septic tank/Sewer',
      septicSewerIssue: 'Septic/Sewer issue',
      naturalDisaster: 'Wildfire/Natural disaster',
      electricalAppliance: 'Electrical appliance',
      damageSomeoneElse: 'Damage (Someone else)',
      smokeDamage: 'Smoke damage',
      pipesDamage: 'Pipes/Sewer',
      pipesSewer: 'Pipes/Sewer',
      lostItem: 'Lost item',
      lightning: 'Lightning',
      smoke: 'Smoke',
      fire: 'Fire',
      groundWater: 'Ground water'
    },
    claimantIncidents: {
      hitMyVehicleWhileDriving: 'Hit a vehicle while driving',
      hitMyParkedVehicle: 'Hit a parked vehicle',
      hitMyProperty: 'Hit property (ex: fence, mailbox, house)',
      hitMeWhileIWasWalkingOrBiking: 'Hit someone while they were walking or biking',
      other: 'Other'
    },
    vehicleCondition: {
      damagedSafe: 'Damaged and drivable',
      damagedNotSafe: 'Damaged and not drivable',
      notDamaged: 'Not damaged',
    },
    totalVehicleFire: {
      yes: 'Total vehicle fire',
      no: 'Partial vehicle fire'
    },
    vehicleRecovered: {
      yes: 'and it was recovered',
      no: 'and it was not recovered'
    },
    country: {
      usa: 'USA',
      canada: 'Canada',
      mexico: 'Mexico',
      usTerritories: 'U.S. Territories'
    },
    insuredRole: {
      driver: 'Driver',
      passenger: 'Passenger',
      pedestrian: 'Pedestrian',
      other: 'Not involved in the incident'
    },
    claimantRole: {
      owner: 'Owner',
      driverOwner: 'Driver and Owner',
      driver: 'Driver',
      passengerOwner: 'Passenger and Owner',
      passenger: 'Passenger',
      notSure: 'I\'m not sure',
      other: 'Other'
    },
    mexicoStates: {
      'Ags.': 'AG',
      'B.C.': 'BC',
      'B.C.S.': 'BS',
      'Camp.': 'CM',
      'Chis.': 'CS',
      'Chih.': 'CH',
      'Coah.': 'CO',
      'Col.': 'CL',
      CDMX: 'DF',
      'Dgo.': 'DG',
      'Gto.': 'GT',
      'Gro.': 'GR',
      'Hgo.': 'HG',
      'Jal.': 'JA',
      'Edomex.': 'EM',
      'Méx.': 'EM',
      'Mich.': 'MI',
      'Mor.': 'MO',
      'Nay.': 'NA',
      'N.L.': 'NL',
      'Oax.': 'OA',
      'Pue.': 'PU',
      'Qro.': 'QT',
      'Q. Roo.': 'QR',
      'Q.R.': 'QR',
      'S.L.P.': 'SL',
      'Sin.': 'SI',
      'Son.': 'SO',
      'Tab.': 'TB',
      'Tamps.': 'TM',
      'Tlax.': 'TL',
      'Ver.': 'VE',
      'Yuc.': 'YU',
      'Zac.': 'ZA'
    },
    dropdown: {
      yes: 'Yes',
      no: 'No',
      unknown: 'I don\'t know',
      na: 'N/A'
    },
    yesNoUnknown: {
      yes: 'Yes',
      no: 'No',
      unknown: 'Unknown',
    },
    phoneType: {
      work: 'Work',
      cell: 'Cell',
      fax: 'Fax',
      home: 'Home',
      voice: 'Voice'
    },
  };

  transform(value: string, ...args: string[]): string {
    const options = this.translations[args[0]];
    return options ? options[value] || value : value;
  }

}
