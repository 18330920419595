import { Component, OnInit,  Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { PoliciesService } from '../../services/policies.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'olr-policy-selection',
  templateUrl: './policy-selection.component.html',
  styleUrls: ['./policy-selection.component.css', '../../shared/styles/question-common-styling.scss']
})
export class PolicySelectionComponent extends BaseComponent implements OnInit {
  isLoading = false;
  selectablePolicies = [];
  answer = '';

  constructor(
    @Inject('Window') private window: any,
    private loadingService: LoadingService,
    private policiesService: PoliciesService,
    private analytics: SfAnalyticsService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('policynumber-question');
    this.loadingService.loadingRequest.subscribe( response => {
      this.isLoading = response;
    });
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      this.answer = answers[0];
    }
    let incidentDate = this.lossReportService.getIncidentDate();
    if (incidentDate.length === 10) {
      incidentDate = incidentDate + 'T04:00:00.000Z';
    }
    const allowedPolicies = this.step.question.allowedValues;
    this.getPolicies(this.isFireLossReport ? 'fire' : 'auto', incidentDate, allowedPolicies);
  }

  private getPolicies(policyType: string, incidentDate: string, allowedPolicies: any){
    this.policiesService.getPolicies(incidentDate, policyType).subscribe( (policies: any) => {
      for (const allowedPolicy of allowedPolicies) {
        const match = policies.filter( (policy: any) => policy.policyNumber === allowedPolicy);
        if ( match.length > 0 ) {
          this.selectablePolicies.push(match[0]);
        }
      }
    });
  }

  buildButtonHeading(i: number): string {
    if (this.isFireLossReport) {
      return this.selectablePolicies[i].risks[0].description;
    }
    const year = this.selectablePolicies[i].risks[0].vehicle.year;
    const make = this.selectablePolicies[i].risks[0].vehicle.make;
    const model = this.selectablePolicies[i].risks[0].vehicle.model || this.selectablePolicies[i].risks[0].vehicle.bodyStyle;
    return `${year} ${make} ${model}`;
  }

  getPropertyType(i: number): string {
    const property = this.selectablePolicies.length > 0 ? this.selectablePolicies[i].policyDescription : '';
    return property ? property.replace('POLICY', '').trim() : '';
  }

  getVin(i: number): string {
    return this.selectablePolicies[i].risks[0].vehicle.vin;
  }

  isSelected(policyNumber: string): boolean {
    return policyNumber === this.answer;
  }

  answerQuestion(answer: string): void {
    let selectedPolicy = this.selectablePolicies.find((policy: any) => policy.policyNumber === answer);
    if (selectedPolicy.policyDescription) {
      selectedPolicy.policyDescription=selectedPolicy.policyDescription.toUpperCase();
    }
    const logDetails = this.logService.getLogDetails();
    logDetails.policyType = selectedPolicy.policyDescription;
    if (!this.isFireLossReport) {
      this.policyVehicleInfoService.setPolicyVehicle(selectedPolicy);
      if (this.isPLMPolicy(selectedPolicy.policyNumber)) {
        this.logService.log(CliffLogCodes.SELECTED_PLM_POLICY, logDetails);
      } else {
        this.logService.log(CliffLogCodes.SELECTED_NON_PLM_POLICY, logDetails);
      }
    } else {
      this.logService.log(CliffLogCodes.SELECTED_FIRE_POLICY, logDetails);
    }
    this.policiesService.setSelectedPolicy(selectedPolicy);
    this.updateAnswer(answer);
  }

  triggerPolicyNotModal(): void {
    this.analytics.sendData('vehicle-not-listed');
    this.window.oneX.Modal.showModal(this, 'olr-modal-policy-not-found');
  }

  isPLMPolicy(policyNumber: string): boolean {
    return policyNumber ? policyNumber.indexOf('SF') > -1 : false;
  }

}
