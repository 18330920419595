// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    margin-bottom: 10px;
}
.lr-mt {
    margin-top: 25px;
}
.lr-eyebrow {
    color: #6e6e6e;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/name/name.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":["input {\n    margin-bottom: 10px;\n}\n.lr-mt {\n    margin-top: 25px;\n}\n.lr-eyebrow {\n    color: #6e6e6e;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
