import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'olr-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.css']
})
export class ButtonCardComponent {
  @Input() id: string;
  @Input() heading: string;
  @Input() text: string;
  @Input() selected = false;
  @Input() dataValue: string;
  @Output() buttonClicked = new EventEmitter<string>();

  constructor() { }

  onButtonClick() {
    this.buttonClicked.emit(this.dataValue);
  }

}
