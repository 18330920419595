import { Component, Inject, ViewChild, ElementRef, AfterContentInit, OnInit} from '@angular/core';
import { loadRemoteModule } from '@angular-architects/module-federation';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { RoutingService } from '../../services/routing.service';
import { ClaimsEventsService } from '../../services/claims-events.service';
@Component({
  selector: 'olr-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css'],
})
export class PreferencesComponent implements AfterContentInit {

  @ViewChild('mfe', {read: ElementRef, static: true}) mfe: ElementRef;

  /* istanbul ignore next */
  constructor(
    @Inject('Window') private window: any,
    private hostnameBuilderService: HostnameBuilderService,
    private routingService: RoutingService,
    private claimsEventsService: ClaimsEventsService,
  ) { }


  ngAfterContentInit(): void {
    this.claimsEventsService.buildEvent('Contact Preference');
    loadRemoteModule({
      type: 'module',
      remoteEntry: this.hostnameBuilderService.getPreferencesRemoteEntryUrl(),
      exposedModule: './web-components'
    }).then(_ => {
      setTimeout(() => {
        this.window.document.querySelector('ui-subnav')?.remove();
      }, 100);
    }).catch(err => console.error(err));
    const element = document.createElement('mfe-preferences-root');
    this.mfe.nativeElement.appendChild(element);
  }

  goToNextRoute() {
    this.routingService.navigateNext();
  }
}
