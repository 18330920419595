// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-disclaimer {
    margin-top: 35px;
}
.lr-submit {
    margin-top: 35px;
    margin-bottom: 50px;
}
.lr-review-answer {
    padding: 0px;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-review/claimant-review-claim/claimant-review-claim.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".lr-disclaimer {\n    margin-top: 35px;\n}\n.lr-submit {\n    margin-top: 35px;\n    margin-bottom: 50px;\n}\n.lr-review-answer {\n    padding: 0px;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
