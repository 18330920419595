import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionExpiredConfirmationComponent } from './session-expired-confirmation/session-expired-confirmation.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';


const routes: Routes = [
    { path: 'session-expired', component: SessionExpiredComponent },
    { path: 'session-expired-confirmation', component: SessionExpiredConfirmationComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class SessionTimeOutRoutingModule {}
