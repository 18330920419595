import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-incident-accident',
  templateUrl: './incident-accident.component.html',
  styleUrls: ['./incident-accident.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class IncidentAccidentComponent extends BaseComponent implements OnInit {
  numVehicles;
  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('whathappened-accident-details-question');
    this.highlightButtonStandardAnswer();
    this.setNumberOfVehicles();
  }

  setNumberOfVehicles() {
    const maxVehiclesCount = this.lossReportService.getStepByName('maxVehiclesCount');
    this.numVehicles = Number(maxVehiclesCount.question.answers[0]);
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.incidentType = answer;
    this.logService.log(CliffLogCodes.ACCIDENT_TYPE, logDetails);
    this.updateAnswer(answer);
  }

}
