import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';

@Component({
  selector: 'olr-card-loading',
  templateUrl: './card-loading.component.html',
  styleUrls: ['./card-loading.component.css']
})
export class CardLoadingComponent implements OnInit, OnDestroy {

  @Input() loadingMessage: string;

  constructor(
    @Inject('Window') private window: Window
  ) { }

  ngOnInit(): void {
    this.window['oneX'].addElement(document.querySelector('.-oneX-page-loader'));
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('.-oneX-page-loader'));
  }
}
