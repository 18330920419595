import { Component, Inject, OnDestroy, OnInit, OnChanges, AfterViewInit, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PolicyCoverages } from '../../models/policy-coverages.model';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'olr-drawer-deductible-overview',
  templateUrl: './drawer-deductible-overview.component.html',
  styleUrls: ['./drawer-deductible-overview.component.css', '../styles/drawer-common-styling.scss']
})
export class DrawerDeductibleOverviewComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() selectedDate: string;
  @Input() policyInfo: PolicyCoverages = new PolicyCoverages();
  @Input() showCoverages: boolean;
  loadingServiceSubscription$: Subscription;
  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice = false;

  constructor(
    @Inject('Window') private window: any,
    public loadingService: LoadingService
  ) {
    this.isMobileDevice = window.innerWidth <= DrawerDeductibleOverviewComponent.oneXSmallBreakpointPx;
  }

  ngOnInit(): void {
    this.loadingServiceSubscription$ = this.loadingService.loadingRequest.subscribe(()=> {
      this.addDrawerElement();
    });
  }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-deductible-expansion-panel'));
  }

  addDrawerElement(): void {
    var that = this;
    var refreshExpansionPanels = function(){
        that.window['oneX'].addElement(document.querySelector('#olr-deductible-expansion-panel'));
    }
    setTimeout(refreshExpansionPanels, 100);
  }

  ngOnChanges(): void {
    this.closeOtherOpenedPanel();
  }

  closeOtherOpenedPanel(): void {
    var expansionPanels = document.querySelectorAll('div > button[aria-expanded=true]');
    for (var i = 0; i < expansionPanels.length; i++) {
      // @ts-ignore
      expansionPanels[i].click();
    }
  }

  ngOnDestroy() {
    this.loadingServiceSubscription$.unsubscribe();
    this.window['oneX'].removeElement(document.querySelector('#olr-deductible-expansion-panel'));
    this.window['oneX'].removeElement(document.querySelector('#olr-drawer-deductible-overview'));
  }
}

