import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DamageDetailsRoutingModule } from './damage-details-routing.module';
import { SharedModule } from '../shared/shared.module';

import { DamageComponent } from './damage/damage.component';
import { DamageLocationComponent } from './damage-location/damage-location.component';
import { ExteriorDamageComponent } from './exterior-damage/exterior-damage.component';
import { ExteriorDamageCounterComponent } from './exterior-damage/exterior-damage-counter/exterior-damage-counter.component';
import { DamageConfirmationComponent } from './damage-confirmation/damage-confirmation.component';
import { RolloverComponent } from './rollover/rollover.component';
import { VehicleLocationComponent } from './vehicle-location/vehicle-location.component';
import { VehicleStorageFeeComponent } from './vehicle-storage-fee/vehicle-storage-fee.component';

@NgModule({
  declarations: [
    DamageComponent,
    DamageLocationComponent,
    ExteriorDamageComponent,
    ExteriorDamageCounterComponent,
    DamageConfirmationComponent,
    RolloverComponent,
    VehicleLocationComponent,
    VehicleStorageFeeComponent
  ],
  imports: [
    CommonModule,
    DamageDetailsRoutingModule,
    SharedModule,
  ],
  exports: [
    SharedModule,
  ]
})
export class DamageDetailsModule { }
