import { Injectable } from '@angular/core';
import { CreateEventService } from './helpers/create-event.service';
import { LogService } from './log.service';
import { MedalliaService } from './medallia.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';

@Injectable({
  providedIn: 'root',
})

export class SfAnalyticsService {
  readonly _EVENT_NAME = 'screenChange'; // Do NOT modify this value!
  private claimNumber = '';
  private externalClaimId = '';
  private orgType = '';

  _WINDOW = window;

  constructor(
    private createEventService: CreateEventService,
    public logService: LogService,
    private medallia: MedalliaService
    ) { }

  sendData(id: string, callBack: () => void = null, policyStatus: string = '', errorMsg: string = ''): void{
    const logDetails = this.logService.getLogDetails();
    logDetails.currentPage = id;
    this.logService.log(CliffLogCodes.PAGE_LOG, logDetails);
    const _W = this.getWindow();
    if (typeof _W.dataLayer === 'undefined'){
      _W.dataLayer = {
        title: '',
        pageName: '',
        pageChannel: '',
        screenId: '',
        screenChannel: '',
        product: '',
        visitor: '',
        language: '',
        state: '',
        clientID: '',
        urlPath: {},
        queryParams: {},
        deploymentIds: [],
        policeReport: '',
        liabilityDetermined: '',
        policyStatus: '',
        errorMsg: ''
      };
    }
    if ('confirmation-page' === id) {
      _W.dataLayer.claimNumber = this.claimNumber;
      _W.dataLayer.externalClaimId = this.externalClaimId;
    }
    _W.dataLayer.screenChannel = this.getScreenChannel();
    _W.dataLayer.externalClientId = this.getExternalClientId();
    _W.dataLayer.screenId = id.replace(/\//gi, '');
    _W.dataLayer.policyStatus = policyStatus.replace(/\//gi, '');
    const orgType = this.getOrgType();
    _W.dataLayer.clientType  = orgType === 'personal' || orgType === '' ? 'individual' : orgType;
    _W.dataLayer.errorMsg = errorMsg;
    this.createEvent(_W, callBack);
    this.medallia.updatePageView();
  }

  setValues(_OBJ: any): void {
    const _W = this.getWindow();
    if (typeof _W.dataLayer === 'undefined'){
      _W.dataLayer = {
        title: '',
        pageName: '',
        pageChannel: '',
        screenId: '',
        screenChannel: '',
        product: '',
        visitor: '',
        language: '',
        state: '',
        clientID: '',
        urlPath: {},
        queryParams: {},
        deploymentIds: [],
        policeReport: '',
        liabilityDetermined: '',
        policyStatus: '',
        errorMsg: ''
      };
    }
    _W.dataLayer.screenChannel = _OBJ.screenChannel ? _OBJ.screenChannel : this.getScreenChannel();
    // eslint-disable-next-line
    for (const _name in _OBJ) {
      _W.dataLayer[_name] = _OBJ[_name];
    }
    _W.dataLayer.externalClientId = this.getExternalClientId();
  }

  setInitialState(): void{
    const _W = this.getWindow();
    _W.screenChange = true;
  }

  setExternalClientId(externalClientId: string): void {
    const clientId = sessionStorage.getItem('externalClientId');
    if (externalClientId && !clientId) {
      sessionStorage.setItem('externalClientId', externalClientId);
    }
  }

  getExternalClientId(): string {
    const externalClientId = sessionStorage.getItem('externalClientId');
    return externalClientId ? externalClientId : '';
  }

  setScreenChannel(channel: string): void {
    this.logService.setFlowName(channel);
    sessionStorage.setItem('screenChannel', channel);
  }

  getScreenChannel(){
    return sessionStorage.getItem('screenChannel') || 'filealoss';
  }

  setClaimInformation(claimNumber: string, externalClaimId: string) {
    this.claimNumber = claimNumber;
    this.externalClaimId = externalClaimId;
  }

  setOrgType(type: string): void {
    this.orgType = type;
    sessionStorage.setItem('orgType', type);
  }

  getOrgType(): string {
    return sessionStorage.getItem('orgType') || '';
  }

  private getWindow(): any{
    return this._WINDOW;
  }

  private createEvent(_WIN: Window, callBack: () => void){
    const _W = _WIN;

    const _EV = this.createEventService.createEvent(this._EVENT_NAME,
    /* this "typeof(Event)" argument is needed so the createEvent function knows how
       to create the event. The Event constructor doesn't exist in IE browsers */
    typeof(Event) === 'function', {
      bubbles: true,
      cancelable: false
    });
    _WIN.setTimeout(() => {
      _W.dispatchEvent(_EV);
      if (callBack){
        callBack();
      }
    }, 500);
  }

}
