import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InjuryErrorComponent } from './injury-error/injury-error.component';
import { SharedModule } from '../shared/shared.module';
import { ErrorPagesRoutingModule } from './error-pages-routing.module';
import { TechnicalErrorComponent } from './technical-error/technical-error.component';
import { DuplicateClaimComponent } from './duplicate-claim/duplicate-claim.component';
import { ClaimAlreadySubmittedComponent } from './claim-already-submitted/claim-already-submitted.component';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { UnsupportedAssociateComponent } from './unsupported-associate/unsupported-associate.component';



@NgModule({
  declarations: [
    InjuryErrorComponent,
    TechnicalErrorComponent,
    DuplicateClaimComponent,
    ClaimAlreadySubmittedComponent,
    UnauthorizedAccessComponent,
    UnsupportedAssociateComponent,
  ],
  imports: [
    CommonModule,
    ErrorPagesRoutingModule,
    SharedModule
  ],
  exports: [
    SharedModule,
  ]
})
export class ErrorPagesModule { }
