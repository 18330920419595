import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonCardComponent } from './button-card/button-card.component';
import { ButtonBinaryComponent } from './button-binary/button-binary.component';
import { ButtonCounterComponent } from './button-counter/button-counter.component';
import { ButtonStandardComponent } from './button-standard/button-standard.component';
import { CardStandardComponent } from './card-standard/card-standard.component';
import { CardStandardEditableComponent } from './card-standard-editable/card-standard-editable.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { BirthDateComponent } from './birth-date/birth-date.component';
import { ProgressComponent } from './progress/progress.component';
import { CancelComponent } from './progress/cancel/cancel.component';
import { SaveComponent } from './progress/save/save.component';
import { ProgressBarComponent } from './progress/progress-bar/progress-bar.component';
import { ProgressIndicatorComponent } from './progress/progress-indicator/progress-indicator.component';
import { BasePageComponent } from './base-page/base-page.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { BaseComponent } from './base/base.component';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { ModalPolicyNotFoundComponent } from './modal-policy-not-found/modal-policy-not-found.component';
import { ModalOtherVehicleDetailsComponent } from './modal-other-vehicle-details/modal-other-vehicle-details.component';
import { ModalNoAdditionalVehiclesComponent } from './modal-no-additional-vehicles/modal-no-additional-vehicles.component';
import { ClaimNumberFormatterPipe } from './pipes/claim-number-formatter.pipe';
import { ValueTranslatorPipe } from './pipes/value-translator.pipe';
import { FraudLanguageSelectorPipe } from './pipes/fraud-language-selector.pipe';
import { CardAddressEditableComponent } from './card-address-editable/card-address-editable.component';
import { ModalRemoveParticipantComponent } from './modal-remove-participant/modal-remove-participant.component';
import { NameComponent } from './name/name.component';
import { ContactComponent } from './contact/contact.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CamelCaseConvertPipe } from './pipes/camel-case-convert.pipe';
import { InjurySelectionComponent } from './injury-selection/injury-selection.component';
import { ReviewAnswerPanelComponent } from './review-answer-panel/review-answer-panel.component';
import { ModalParticipantMatchErrorComponent } from './modal-participant-match-error/modal-participant-match-error.component';

import { ModalHagertyComponent } from './modal-hagerty/modal-hagerty.component';
import { IconAutoInsuranceComponent } from './icons/icon-auto-insurance/icon-auto-insurance.component';
import { IconInsuranceComponent } from './icons/icon-insurance/icon-insurance.component';
import { ModalPolicyStillNotFoundComponent } from './modal-policy-still-not-found/modal-policy-still-not-found.component';
import { IconMotorcycleComponent } from './icons/icon-motorcycle/icon-motorcycle.component';
import { CoveragePanelComponent } from './drawer-deductible-overview/coverage-panel/coverage-panel.component';
import { IconBusinessComponent } from './icons/icon-business/icon-business.component';
import { IconPhoneComponent } from './icons/icon-phone/icon-phone.component';
import { StorageFeesModalComponent } from './storage-fees-modal/storage-fees-modal.component';
import { TrimOnFocusOutDirective } from 'projects/lossreporting-library/src/directives/trimOnFocusOut.directive';
import { OlrShouldIFileComponent } from '../shared/modal-should-i-file/modal-should-i-file.component';


@NgModule({
  declarations: [
    ButtonCardComponent,
    ButtonBinaryComponent,
    ButtonCounterComponent,
    ButtonStandardComponent,
    CardStandardComponent,
    CardStandardEditableComponent,
    ModalErrorComponent,
    DatetimePickerComponent,
    BirthDateComponent,
    ProgressComponent,
    CancelComponent,
    SaveComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    BasePageComponent,
    LoadingIndicatorComponent,
    BaseComponent,
    PhoneFormatPipe,
    ModalPolicyNotFoundComponent,
    ModalOtherVehicleDetailsComponent,
    ModalNoAdditionalVehiclesComponent,
    ClaimNumberFormatterPipe,
    ValueTranslatorPipe,
    FraudLanguageSelectorPipe,
    CardAddressEditableComponent,
    ModalRemoveParticipantComponent,
    NameComponent,
    ContactComponent,
    DropdownComponent,
    CamelCaseConvertPipe,
    InjurySelectionComponent,
    ReviewAnswerPanelComponent,
    ModalParticipantMatchErrorComponent,
    ModalHagertyComponent,
    IconAutoInsuranceComponent,
    IconInsuranceComponent,
    ModalPolicyStillNotFoundComponent,
    IconMotorcycleComponent,
    CoveragePanelComponent,
    IconBusinessComponent,
    IconPhoneComponent,
    StorageFeesModalComponent,
    TrimOnFocusOutDirective,
    OlrShouldIFileComponent,
],
  imports: [
    CommonModule,
  ],
  exports: [
    CommonModule,
    ButtonCardComponent,
    ButtonBinaryComponent,
    ButtonCounterComponent,
    ButtonStandardComponent,
    CardStandardComponent,
    CardStandardEditableComponent,
    ModalErrorComponent,
    DatetimePickerComponent,
    BirthDateComponent,
    ProgressComponent,
    CancelComponent,
    SaveComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    BasePageComponent,
    LoadingIndicatorComponent,
    PhoneFormatPipe,
    ClaimNumberFormatterPipe,
    ValueTranslatorPipe,
    FraudLanguageSelectorPipe,
    ModalOtherVehicleDetailsComponent,
    ModalNoAdditionalVehiclesComponent,
    CardAddressEditableComponent,
    ModalRemoveParticipantComponent,
    NameComponent,
    ContactComponent,
    DropdownComponent,
    CamelCaseConvertPipe,
    InjurySelectionComponent,
    ReviewAnswerPanelComponent,
    ModalHagertyComponent,
    IconAutoInsuranceComponent,
    IconInsuranceComponent,
    IconMotorcycleComponent,
    IconBusinessComponent,
    IconPhoneComponent,
    CoveragePanelComponent,
    OlrShouldIFileComponent,
  ]
})
export class SharedModule { }
