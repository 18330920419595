// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .err-page {
  height: 781px;
  width: 1038px;
} */
.lr-session-expired{
    margin-top: 125px;
    margin-left: 53px;
    margin-right: 46px;

  }

  .lr-btn {
    margin-bottom: 32px;
    margin-left: 20%;
    margin-right: 20%;
}

  .lr-technical-primary {
    margin-top: 57px;
    margin-left: 40px;
    margin-right: 40px;
}

.lr-technical-secondary {
    margin-top: 141px;
    margin-left: 88px;
    margin-right: 90px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/session-timeout/session-expired/session-expired.component.css"],"names":[],"mappings":"AAAA;;;GAGG;AACH;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;;EAEpB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;EAEE;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["/* .err-page {\n  height: 781px;\n  width: 1038px;\n} */\n.lr-session-expired{\n    margin-top: 125px;\n    margin-left: 53px;\n    margin-right: 46px;\n\n  }\n\n  .lr-btn {\n    margin-bottom: 32px;\n    margin-left: 20%;\n    margin-right: 20%;\n}\n\n  .lr-technical-primary {\n    margin-top: 57px;\n    margin-left: 40px;\n    margin-right: 40px;\n}\n\n.lr-technical-secondary {\n    margin-top: 141px;\n    margin-left: 88px;\n    margin-right: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
