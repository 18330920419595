import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';


@Component({
  selector: 'olr-ride-share-login-status',
  templateUrl: './ride-share-login-status.component.html',
  styleUrls: ['./ride-share-login-status.component.css','../../shared/styles/question-common-styling.scss']
})
export class RideShareLoginStatusComponent extends BaseComponent implements OnInit {
  answers:[];
  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit(): void {
    this.analytics.sendData('transportation-app-login');
      this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.rideShareLoginStatus = answer;
    this.logService.log(CliffLogCodes.TRANSPORTATION_NETWORK_COMPANY, logDetails);
    this.updateAnswer(answer);
  }

}
