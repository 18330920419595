import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InjuryComponent } from './injury/injury.component';
import { AmountVehiclesComponent } from './amount-vehicles/amount-vehicles.component';
import { IncidentDateComponent } from './incident-date/incident-date.component';
import { PolicySelectionComponent } from './policy-selection/policy-selection.component';
import { IncidentLocationComponent } from './incident-location/incident-location.component';
import { IncidentTypeComponent } from './incident-type/incident-type.component';
import { IncidentAccidentComponent } from './incident-type/incident-accident/incident-accident.component';
import { IncidentWeatherComponent } from './incident-type/incident-weather/incident-weather.component';
import { InvolvementComponent } from './involvement/involvement.component';
import { RoadHazardsComponent } from './road-hazards/road-hazards.component';
import { RideShareScenarioComponent } from './ride-share-scenario/ride-share-scenario.component';
import { WorkRelatedComponent } from './work-related/work-related.component';
import { EnterLocationComponent } from './incident-location/enter-location/enter-location.component';
import { VehicleStolenComponent } from './incident-type/vehicle-stolen/vehicle-stolen.component';
import { VehicleRecoveredComponent } from './incident-type/vehicle-recovered/vehicle-recovered.component';
import { FireDamageComponent } from './incident-type/fire-damage/fire-damage.component';
import { ChildSeatComponent } from './child-seat/child-seat.component';
import { ChildSeatStatusComponent } from './child-seat-status/child-seat-status.component';
import { AirbagsComponent } from './airbags/airbags.component';
import { SpecialtyVehicleComponent } from './specialty-vehicle/specialty-vehicle.component';
import { PoliceReportComponent } from './police-report/police-report.component';
import { PoliceDepartmentComponent } from './police-department/police-department.component';
import { InsuredParticipantSelectionComponent } from './insured-participant-selection/insured-participant-selection.component';
import { InsuredParticipantDetailsComponent } from './insured-participant-details/insured-participant-details.component';
import { AdditionalInsuredParticipantsComponent } from './additional-insured-participants/additional-insured-participants.component';
import { WitnessComponent } from './witness/witness.component';
import { ResponsiblePartyComponent } from './responsible-party/responsible-party.component';
import { WitnessInformationComponent } from './witness-information/witness-information.component';
import { PetInjuryComponent } from './pet-injury/pet-injury.component';
import { DisputedLiabilityComponent } from './disputed-liability/disputed-liability.component';
import { AuthGuard } from '../services/guards/auth.guard';
import { VehicleOccupancyComponent } from './vehicle-occupancy/vehicle-occupancy.component';
import { VehicleStatusComponent } from './vehicle-status/vehicle-status.component';
import { VehicleOccupancyInvolvementComponent } from './vehicle-occupancy-involvement/vehicle-occupancy-involvement.component';
import { VehicleOccupancyInsuredComponent } from './vehicle-occupancy-insured/vehicle-occupancy-insured.component';
import { VehicleOccupancyOtherInsuredParticipantsComponent } from './vehicle-occupancy-other-insured-participants/vehicle-occupancy-other-insured-participants.component';
import { RideShareLoginStatusComponent } from './ride-share-login-status/ride-share-login-status.component';
import { PurposeOfTripComponent } from './purpose-of-trip/purpose-of-trip.component';
import { PurposeOfTripWorkRelatedComponent } from './purpose-of-trip-work-related/purpose-of-trip-work-related.component';
import { HitAndRunComponent } from './hit-and-run/hit-and-run.component';




const routes: Routes = [
    { path: 'injury', component: InjuryComponent, canActivate: [AuthGuard] },
    { path: 'amount-vehicles', component: AmountVehiclesComponent, canActivate: [AuthGuard] },
    { path: 'incident-date', component: IncidentDateComponent, canActivate: [AuthGuard] },
    { path: 'policy-selection', component: PolicySelectionComponent, canActivate: [AuthGuard] },
    { path: 'incident-location', component: IncidentLocationComponent, canActivate: [AuthGuard] },
    { path: 'incident-location-enter', component: EnterLocationComponent, canActivate: [AuthGuard] },
    { path: 'incident-type', component: IncidentTypeComponent, canActivate: [AuthGuard] },
    { path: 'incident-accident', component: IncidentAccidentComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-stolen', component: VehicleStolenComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-recovered', component: VehicleRecoveredComponent, canActivate: [AuthGuard] },
    { path: 'fire-damage', component: FireDamageComponent, canActivate: [AuthGuard] },
    { path: 'incident-weather', component: IncidentWeatherComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-occupancy', component: VehicleOccupancyComponent, canActivate: [AuthGuard] },
    { path: 'ride-share-scenario', component: RideShareScenarioComponent, canActivate: [AuthGuard] },
    { path: 'involvement', component: InvolvementComponent, canActivate: [AuthGuard] },
    { path: 'road-hazards', component: RoadHazardsComponent, canActivate: [AuthGuard] },
    { path: 'work-related', component: WorkRelatedComponent, canActivate: [AuthGuard] },
    { path: 'child-seat', component: ChildSeatComponent, canActivate: [AuthGuard] },
    { path: 'child-seat-status', component: ChildSeatStatusComponent, canActivate: [AuthGuard] },
    { path: 'airbags', component: AirbagsComponent, canActivate: [AuthGuard] },
    { path: 'specialty-vehicle', component: SpecialtyVehicleComponent, canActivate: [AuthGuard] },
    { path: 'police-report', component: PoliceReportComponent, canActivate: [AuthGuard] },
    { path: 'police-department', component: PoliceDepartmentComponent, canActivate: [AuthGuard] },
    { path: 'insured-participant-selection', component: InsuredParticipantSelectionComponent, canActivate: [AuthGuard] },
    { path: 'insured-participant-details', component: InsuredParticipantDetailsComponent, canActivate: [AuthGuard] },
    { path: 'additional-insured-participants', component: AdditionalInsuredParticipantsComponent, canActivate: [AuthGuard] },
    { path: 'witness', component: WitnessComponent, canActivate: [AuthGuard] },
    { path: 'responsible-party', component: ResponsiblePartyComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-status', component: VehicleStatusComponent, canActivate: [AuthGuard]},
    { path: 'witness-information', component: WitnessInformationComponent, canActivate: [AuthGuard] },
    { path: 'pet-injury', component: PetInjuryComponent, canActivate: [AuthGuard]},
    { path: 'disputed-liability', component: DisputedLiabilityComponent, canActivate: [AuthGuard]},
    { path: 'vehicle-occupancy-involvement', component: VehicleOccupancyInvolvementComponent, canActivate: [AuthGuard]},
    { path: 'vehicle-occupancy-insured', component: VehicleOccupancyInsuredComponent, canActivate: [AuthGuard]},
    { path: 'vehicle-occupancy-additional-participant', component: VehicleOccupancyOtherInsuredParticipantsComponent, canActivate: [AuthGuard]},
    {path:'ride-share-login-status', component:RideShareLoginStatusComponent,canActivate:[AuthGuard]},
    {path:'purpose-of-trip', component:PurposeOfTripComponent ,canActivate:[AuthGuard]},
    {path:'purpose-of-trip-work-related', component:PurposeOfTripWorkRelatedComponent , canActivate:[AuthGuard]},
     {path:'hit-and-run',component:HitAndRunComponent, canActivate:[AuthGuard]}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class IncidentDetailsRoutingModule {}
