import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { LoadingService } from './loading.service';
import { LogService } from './log.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { ErrorHandlingService } from './helpers/error-handling.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LogDetails } from '../models/log-details.model';
import { parseJwt } from 'customer-oauth2-token-management-utility';

const separateMultiCarPolicyRisks = (policies: any[]): any[] => {
  const newPolicies = [];
  // const modernizedPolicies = ['24', '25', '15'];

  policies.forEach((policy: any) => {
    const length = policy.risks.length;
    if (length > 1) {
      for (let i = 0; i < length; i++) {
        const newPolicy = Object.assign({}, policy);
        newPolicy.risks = policy.risks.slice(i, i + 1);
        if (newPolicy.risks[0].vehicle.carNumber) {
          newPolicy.policyNumber += '::' + newPolicy.risks[0].vehicle.carNumber;
        }
        newPolicies.push(newPolicy);
      }
    } else {
      const newPolicy = Object.assign({}, policy);
      if (newPolicy.risks[0].vehicle.carNumber) {
        if (newPolicy.risks[0].vehicle.carNumber) {
          newPolicy.policyNumber += '::' + newPolicy.risks[0].vehicle.carNumber;
        }
      }
      newPolicies.push(newPolicy);
    }
  });
  return newPolicies;
};

const policyNumberFilter = (policies: any[], policyNumber: string): any => {
  return policies.find((policy) => {
    return policy.policyNumber === policyNumber;
  });
};

const lineOfBusinessFilter = {
  auto: (policy: any): boolean => {
    return policy.lineOfBusiness === 'A';
  },
  glass: (policy: any): boolean => {
    return policy.lineOfBusiness === 'A';
  },
  fire: (policy: any): boolean => {
    return policy.lineOfBusiness === 'F';
  }
};

const newBrunswickFilter = (policy: any) => {
  if (!policy.desjardins && policy.regionCode === '03') {
    return !(policy.stateCode === '64' || policy.stateCode === '94');
  }
  return true; // policy is not NB, returning true to include in result
};

const filterPoliciesByLOB = (policies: any[], lob: string) => {
  const filteredPolicies = policies.filter(newBrunswickFilter).filter(lineOfBusinessFilter[lob]);
  if (lob === 'auto') {
    return separateMultiCarPolicyRisks(filteredPolicies);
  }
  return filteredPolicies;
};

@Injectable({
  providedIn: 'root'
})
export class PoliciesService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private cookieHandlerService: CookieHandlerService,
    private hostnameBuilderService: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService,
    private logService: LogService,
    private cookieService: CookieHandlerService,
  ) {
    this.url = 'https://claimsvc' + this.hostnameBuilderService.getHostname() + '/claimpolicyapipolicies/v7/api/policies';
    this.logDetails = this.logService.getLogDetails();
   }

  private _POLICIES = [];
  private _POLICY_CUSTOMERS: any[];
  private url;
  policiesUpdated = new Subject<any>();
  selectedPolicy: any;
  selectedPolicyNumber: string;
  logDetails: LogDetails;


  findPolicy(policyNumber: string): any {
    const autoPolicies = this.policies.filter( (policy: any): boolean => {
      return policy.lineOfBusiness === 'A';
    });
    const requestedPolicy = policyNumberFilter(autoPolicies, policyNumber);
    return requestedPolicy;
  }

  getPolicies(date: string, lob: string): Subject<any> {
    var httpOptions;
    let parsedJwt = parseJwt();
    const oauthHeader = this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : '';
    if(parsedJwt){
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': oauthHeader,
          'X-SF_SSO_TOKEN': this.cookieHandlerService.getSSOToken(),
          CorrelationId: this.logService.getCorrelationId()
        }),
        withCredentials: true
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'X-SF_SSO_TOKEN': this.cookieHandlerService.getSSOToken(),
          CorrelationId: this.logService.getCorrelationId()
        }),
        withCredentials: true
      };
    }


    this.loadingService.startRequest();
    const policyEndpoint = date ? this.url + '?effectiveDate=' + `${date}` : this.url;
    this.http
      .get<any>(policyEndpoint, httpOptions)
      .subscribe(
        response => {
          const myResponse = response as any;
          this.policies = filterPoliciesByLOB(myResponse.payload, lob);
          this.policiesUpdated.next(this.policies);
          this.logService.log(CliffLogCodes.POL_RTRV_SUC, this.logDetails);
  },
        error => {
          this.loadingService.completeRequest();
          this.errorHandlingService.handleError(error, CliffLogCodes.POL_RTRV_FAIL);
        },
        () => {
          this.loadingService.completeRequest();
        }
      );
    return this.policiesUpdated;
  }

  getPolicyCustomers(): any[]{
    if(this.policyCustomers) {
      return this.policyCustomers;
    } else if (!this.selectedPolicy){
      this.selectedPolicy = JSON.parse(sessionStorage.getItem('selectedPolicy'));
    }
    return this.selectedPolicy ? this.selectedPolicy.customers : [];
  }

  setPolicyCustomers(policyCustomers: any[]) {
    this.policyCustomers = policyCustomers;
  }

  setSelectedPolicy(policy: any){
    sessionStorage.setItem('selectedPolicy', JSON.stringify(policy));
    this.selectedPolicy = policy;
  }

  setSelectedPolicyNumber(policyNumber: string): void{
    sessionStorage.setItem('selectedPolicyNumber', JSON.stringify(policyNumber));
    this.selectedPolicyNumber = policyNumber;
  }

  getSelectedPolicyNumber(): string{
    if (!this.selectedPolicyNumber){
      this.selectedPolicyNumber = sessionStorage.getItem('selectedPolicyNumber');
    }
    return this.selectedPolicyNumber;
  }

  get policies() {
    return this._POLICIES;
  }

  set policies(policies: any) {
    this._POLICIES = policies;
  }

  get policyCustomers(): any[] {
    if (!this._POLICY_CUSTOMERS) {
      this._POLICY_CUSTOMERS = JSON.parse(sessionStorage.getItem('policyCustomers'));
    }
    return this._POLICY_CUSTOMERS;
  }

  set policyCustomers(customers: any[]) {
    this._POLICY_CUSTOMERS = customers;
    sessionStorage.setItem('policyCustomers', JSON.stringify(customers));
  }

}
