import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';

@Component({
  selector: 'olr-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  percentComplete = 2;

  constructor(
    private lossReportService: LossReportService
  ) { }

  ngOnInit() {
    const stepNumber = this.lossReportService.lossReport && this.lossReportService.currentStep ?
                        this.lossReportService.currentStep.stepNumber : 1;
    let stepsInChapter = this.lossReportService.lossReport && this.lossReportService.currentStep ?
                        this.lossReportService.currentStep.stepsInChapter : 1;

    if(stepNumber >= stepsInChapter) {
      stepsInChapter = stepNumber + 1;
    }

    this.percentComplete = Math.ceil((stepNumber / stepsInChapter) * 100);
  }

}
