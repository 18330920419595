import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';


@Component({
  selector: 'olr-received-auto-claim',
  templateUrl: './save-confirmation.component.html',
  styleUrls: ['./save-confirmation.component.css', '../../shared/styles/question-common-styling.scss']
})
export class SaveConfirmationComponent implements OnInit {
  static readonly oneXSmallBreakpointPx = 768;
  created = false;
  loadingSubscription$: Subscription;
  isLoading = true;
  isMobileDevice: boolean;
  screenChannel = 'filealoss-auto';
  role:string;

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private authenticationService: AuthenticationService,
    private hostnameBuilderService: HostnameBuilderService,
  ) {
    this.isMobileDevice = window.innerWidth <= SaveConfirmationComponent.oneXSmallBreakpointPx;
   }

  ngOnInit(): void {
    this.sfAnalyticsService.setScreenChannel(this.screenChannel);
    this.sfAnalyticsService.sendData('save-progress-success');
    sessionStorage.clear();
    this.authenticationService.stopTimer();
  }


  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width <= SaveConfirmationComponent.oneXSmallBreakpointPx;
  }

  redirectToEntryPage():void {
    this.window.location.replace(`https://${this.hostnameBuilderService.getSFLHostname()}/start-claim`);
  }
}
