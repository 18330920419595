import { Component, OnInit,  Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { LogService } from '../../services/log.service';
import { LossReportService } from '../../services/loss-report.service';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-duplicate-claim',
  templateUrl: './duplicate-claim.component.html',
  styleUrls: ['./duplicate-claim.component.css']
})
export class DuplicateClaimComponent implements OnInit {
  private incidentDateRoutes = {
    AUTO: '/start-claim',
    AUTO_CLAIMANT: '/claimant/incident-details/incident-date',
    HOME_AND_PROPERTY: '/start-claim'
  };
  reporter: string;

  get isOtherInsuranceCompany(): boolean {
    return this.reporter === 'otherInsuranceCompany';
  }

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private logService: LogService,
    private answerFinderService: LossReportAnswerFinderService,
    public router: Router,
    private lossReportService: LossReportService,
  ) {
      this.reporter = this.answerFinderService.findAnswer('claimantType');
  }

  ngOnInit(): void {
    this.analytics.sendData('duplicate-claim');
  }

  searchClaim(){
    sessionStorage.clear();
    this.window.location.href = `https://b2b.statefarm.com/GuestPortalWeb/find-claim`;
  }

  navigate() {
    this.router.navigate([this.incidentDateRoutes[this.lossReportService.lossReport.type]]);
  }
}
