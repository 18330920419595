import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { SessionExpiredConfirmationComponent } from './session-expired-confirmation/session-expired-confirmation.component';
import { SessionTimeOutRoutingModule } from './session-routing.module';



@NgModule({
  declarations: [
    SessionExpiredComponent,
    SessionExpiredConfirmationComponent
  ],
  imports: [
    CommonModule,
    SessionTimeOutRoutingModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ]
})
export class SessionTimeOutModule { }
