import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { InjuryTrackingService } from '../../services/injury-tracking.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';

@Component({
  selector: 'olr-insured-participant-details',
  templateUrl: './vehicle-occupancy-insured.component.html',
  styleUrls: ['./vehicle-occupancy-insured.component.css', '../../shared/styles/question-common-styling.scss'],
})
export class VehicleOccupancyInsuredComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  policyVehicle: string;
  yesNoOptions = ['yes', 'no'];
  yesNoUnkOptions = ['yes', 'no', 'unknown'];
  yesNoUnkNaOptions = ['yes', 'no', 'unknown', 'na'];
  roleOptions = ['driver', 'passenger'];
  selectedRole = '';
  selectedInjuryStatus: string = '';
  selectedMedCare: string = '';
  selectedAirbagDeployed: string = '';
  selectedInjuries: string[] = [];
  answerRoleNumber = '1056';
  answerRoleName = 'insuredRole';
  partyInjury = 'reportingPartyInjury';
  analyticsTag = 'sf:us:filealoss-auto:policy-participant1-details page';

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private insuredParticipantTrackingService: InsuredParticipantTrackingService,
    public cdr: ChangeDetectorRef,
    private injuryTrackingService: InjuryTrackingService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    const vehicle = this.policyVehicleInfoService.getSelectedPolicyVehicle();
    this.policyVehicle = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model;
    this.analytics.sendData(this.analyticsTag);
    let role = this.getQuestionAnswer(this.answerRoleName);
    let isRoleAnswered = this.roleOptions.includes(role)
    if (isRoleAnswered) {
      this.selectedRole = role;
    }
    let injuryStatus = this.getQuestionAnswer('injuryStatus');
    let isInjuryStatusAnswered = this.yesNoOptions.includes(injuryStatus)
    if (isInjuryStatusAnswered) {
      this.selectedInjuryStatus = injuryStatus;
    }
    if (injuryStatus === 'yes') {
      this.selectedInjuries = this.getQuestionAnswer('injuries', true);
      this.selectedMedCare = this.getQuestionAnswer('medicalTreatment');
    }
    if (isRoleAnswered && isInjuryStatusAnswered) this.selectedAirbagDeployed = this.getQuestionAnswer('airbagDeploy');

  }

  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#insuredDropdowns'));
    this.cdr.detectChanges();

  }

  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#insuredDropdowns'));
  }

  private getSubQuestion(questionName: string): any {
    const subQuestions = this.step?.question.subQuestions[0] ?? [];
    return subQuestions.find(question => question.name === questionName);
  }

  private getQuestionAnswer(questionName: string, hasMultipleAnswers = false): any {
    const question = this.getSubQuestion(questionName);
    if (question) {
      return hasMultipleAnswers ? question.answers : question.answers[0] || '';
    }
    return '';
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement(document.querySelector('#insuredDropdowns'));
    }, 100);
  }

  get isAnswerSelected() {
    return this.selectedRole?.length && this.selectedInjuryStatus?.length;
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.activateFloatingLabel();
  }

  updateInjuryStatus(answer: any) {
    this.selectedInjuryStatus = answer;
    this.activateFloatingLabel();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedCare = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  answerQuestion(): void {
    const answers = [];
    const participant = [];
    let injuryCount = 0;
    if (!this.isAnswerSelected) return;
    participant.push(this.createAnsweredQuestion(this.answerRoleNumber, this.answerRoleName, this.selectedRole));
    participant.push(this.createAnsweredQuestion('1051', 'injuryStatus', this.selectedInjuryStatus));
    if (this.selectedInjuryStatus === 'yes') {
      injuryCount++;
      participant.push(this.createAnsweredQuestion('1053A', 'injuries', this.selectedInjuries));
      if (this.selectedMedCare?.length) participant.push(this.createAnsweredQuestion('1054A', 'medicalTreatment', this.selectedMedCare));
      if (this.selectedAirbagDeployed?.length) participant.push(this.createAnsweredQuestion('1055A', 'airbagDeploy', this.selectedAirbagDeployed));
    }
    answers.push(participant);
    this.injuryTrackingService.setStatus(this.partyInjury, injuryCount > 0);
    this.updateSubQuestions(answers);
  }
}
