import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-vehicle-stolen',
  templateUrl: './vehicle-stolen.component.html',
  styleUrls: ['./vehicle-stolen.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class VehicleStolenComponent extends BaseComponent implements OnInit {
  answers: [];
  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('vehicle-stolen-question');
    if (this.step.completed){
      this.answers = this.step.question.answers[0];
    }
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.vehicleStolen = answer;
    this.logService.log(CliffLogCodes.VEHICLE_STOLEN, logDetails);
    this.updateAnswer(answer.toLowerCase());
  }

}
