import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { OptInRoutingModule } from './opt-in-routing.module';
import { PreferencesComponent } from '../opt-in/preferences/preferences.component';

@NgModule({
  declarations: [
    PreferencesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OptInRoutingModule
  ],
  exports: [
    SharedModule
  ]
})
export class OptInModule { }
