import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { InjuryTrackingService } from './injury-tracking.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LossReportService } from './loss-report.service';
import { SfAnalyticsService } from './sf-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimDetailsService {
  private CLAIM_DETAILS: any;

  constructor(
    private logService: LogService,
    private injuryTrackingService: InjuryTrackingService,
    private lossReportService: LossReportService,
    private analyticsService: SfAnalyticsService,
  ) { }

  getClaimDetails(): any {
    const lossReport = this.lossReportService.lossReport;
    if (lossReport) {
      this.claimDetails = lossReport.claimDetail;
      this.logService.setClaimId(this.claimDetails.id);
      this.logService.setClaimNumber(this.claimDetails.number);
      this.logInjury();
      this.logFatality();
      this.logService.log(CliffLogCodes.CLM_DTL_SUC);
      this.analyticsService.setClaimInformation(this.claimDetails.number, this.claimDetails.id);
      return this.claimDetails;
    }
  }

  get claimDetails() {
    return this.CLAIM_DETAILS;
  }

  set claimDetails(claimDetails: any) {
    this.CLAIM_DETAILS = claimDetails;
  }

  private logInjury(): void {
    if (this.injuryTrackingService.hasInjury) {
      this.logService.log(CliffLogCodes.INJURY);
    }
  }

  private logFatality(): void {
    if (this.injuryTrackingService.hasFatality) {
      this.logService.log(CliffLogCodes.FATALITY);
    }
  }

}
