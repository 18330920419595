import { Component, Input } from '@angular/core';

@Component({
  selector: 'olr-icon-insurance',
  templateUrl: './icon-insurance.component.html',
  styleUrls: ['./icon-insurance.component.css']
})
export class IconInsuranceComponent {

  @Input() showInactive: boolean;

  constructor() { }

}
