import { Injectable } from '@angular/core';

// this model allows for mocking of the broswer window in service/component testing.
// use in place of direct reference to window object.
// see url-resolver.service.ts for example
function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
  })
export class WindowReference {
    get nativeWindow(): any {
        return _window();
    }
}
