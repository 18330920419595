import { Component, Input } from '@angular/core';
import { ExteriorDamageCounter } from '../exterior-damage-counter.model';
import { ExteriorDamageService } from '../exterior-damage.service';

@Component({
  selector: 'olr-exterior-damage-counter',
  templateUrl: './exterior-damage-counter.component.html',
  styleUrls: ['./exterior-damage-counter.component.css']
})
export class ExteriorDamageCounterComponent {
  @Input() id: string;
  @Input() damageCounter: ExteriorDamageCounter;

  constructor(private exteriorDamageService: ExteriorDamageService) { }

  onButtonClick() {
    if (!this.damageCounter.isActive) {
      this.exteriorDamageService.setIsActive(this.damageCounter.id);
    }
  }

}
