import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LossReportService } from './loss-report.service';

@Injectable({
    providedIn: 'root'
})
  export class RoutingService {
    private reviewRoutes = {
        AUTO: '/review/review-claim',
        AUTO_CLAIMANT: '/claimant/review/review-claim',
        HOME_AND_PROPERTY: '/homeandproperty/review/review-claim'
    };

    constructor(
        private router: Router,
        private lossReportService: LossReportService
    ) { }

    navigateNext(): void {
        const nextRoute = this.lossReportService.getNextStep();
        if (this.lossReportService.lossReport.metaData.status === 'INITIATED' && !nextRoute) {
            this.lossReportService.modifyReport();
        } else if (this.lossReportService.lossReport.metaData.status === 'COMPLETE' && this.lossReportService.areAllQuestionsAnswered()) {
            const reviewPage = this.reviewRoutes[this.lossReportService.lossReport.type] || '/review/review-claim';
            this.router.navigate([reviewPage]);
        } else if (this.lossReportService.lossReport.metaData.status === 'SUBMITTED' && this.lossReportService.areAllQuestionsAnswered()) {
          this.router.navigate(['/error/claim-already-submitted']);
        } else {
            this.router.navigate([ nextRoute ]);
        }
    }
}
