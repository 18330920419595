import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-damage-location',
  templateUrl: './damage-location.component.html',
  styleUrls: ['./damage-location.component.css', '../../shared/styles/checkbox-grouping.scss', '../../shared/styles/question-common-styling.scss']
})
export class DamageLocationComponent extends BaseComponent implements OnInit {
  qtySelected = 0;
  damageOptions = {
    exterior: false,
    interior: false,
    attachedTrailer: false,
    waterDamage: false,
    undercarriage: false,
    airbags: false
  };

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('damage-details-question');
    this.displayAnsweredState();
  }

  private displayAnsweredState() {
    const answers = this.step.question.answers;
    answers.forEach((answer: string) => {
      // @ts-ignore
      this.window.oneX.$(`#${answer}`).prop('checked', true);
      this.damageOptions[answer] = true;
      this.qtySelected++;
    });
  }

  onCheckboxChange(e: Event) {
    const defaultValue = 'defaultValue';
    const inputValue = e.target[defaultValue];
    const checked = 'checked';
    const isChecked = e.target[checked];
    this.damageOptions[inputValue] = isChecked;
    if (isChecked === true) {
      this.qtySelected++;
    } else {
      this.qtySelected--;
    }
  }

  answerQuestion(): void {
    const answer = [];

    Object.keys(this.damageOptions).forEach((key) => {
      if (this.damageOptions[key]) {
        answer.push(key);
      }
    });
    const logDetails = this.logService.getLogDetails();
    logDetails.damageLocation = answer;
    this.logService.log(CliffLogCodes.VEHICLE_DAMAGE_LOC, logDetails);
    this.updateAnswer(answer);
  }

}
