import { LogDetails } from './log-details.model';

export class CliffLogDetails {
  applicationId: string; // adding back to check why prod logs not showing
  messageId: string;     // required
  logLevel: string;      // required
  message: string;
  httpResponse?: number;  // THIS IS INTENTIALLY DUPLICATED; NUMERIC
  claimId?: string;
  claimNum?: string;
  clientOrPartyId?: string;
  participantId?: string;
  entryPoint?: string;
  logData: LogDetails;   //  app specific K/V pairs

  constructor(messageId: string) {
    this.applicationId = 'File-A-Loss'; // adding back to check why prod logs not showing
    this.messageId = messageId;
    this.logLevel = 'info';
  }
}
