import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-amount-vehicles',
  templateUrl: './amount-vehicles.component.html',
  styleUrls: ['./amount-vehicles.component.css', '../../shared/styles/question-common-styling.scss']
})
export class AmountVehiclesComponent extends BaseComponent implements OnInit {
  count = 1;
  answer: number;

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.claimsEventsService.buildEvent('Capture Loss Details');
    this.analytics.sendData('number-of-vehicles-involved');
    const currentAnswer = this.step.question.answers;
    if (currentAnswer.length > 0){
      this.answer = currentAnswer[0];
      this.count = this.answer;
    }
  }

  updateCount(count: number): void {
    this.count = count;
  }

  answerQuestion(): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.numberOfVehicles = this.count.toString();
    this.logService.log(CliffLogCodes.NUM_VEHICLES, logDetails);
    this.updateAnswer(this.count.toString());
  }

}
