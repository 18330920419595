import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'olr-button-binary',
  templateUrl: './button-binary.component.html',
  styleUrls: ['./button-binary.component.css']
})
export class ButtonBinaryComponent {
  step: string;
  @Input() dataValue1: string;
  @Input() dataValue2: string;
  @Input() default: string;
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter();


  isDefault(yesno: string){
    if (this.default){
      return this.default.includes(yesno);
   }
    return false;
  }
  onButtonClick(dataVal: string) {
    this.buttonClicked.emit(dataVal);
  }

}
