import { Component, ViewChild, ElementRef, Output, EventEmitter, Inject, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'olr-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.css']
})
export class NameComponent implements AfterViewInit, OnDestroy {
  @Output() setName = new EventEmitter<any>();
  @Output() nameInputVerified = new EventEmitter<boolean>();
  @ViewChild('firstName', {static: false}) firstName: ElementRef;
  @ViewChild('lastName', {static: false}) lastName: ElementRef;
  isFirstNameError = false;
  isLastNameError = false;
  isFirstNameValid = false;
  isLastNameValid = false;

  constructor(
    @Inject('Window') private window: any
  ) { }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#firstLastOlrName'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#firstLastOlrName'));
  }

  setAnswerState(answer: any){
    this.window['oneX'].addElement(document.querySelector('#firstLastOlrName'));
    if (answer){
      this.firstName.nativeElement.value = this.removeAccents(answer.first);
      this.lastName.nativeElement.value = this.removeAccents(answer.last);
      this.enterParticipantName();
    }
  }

  enterParticipantName(): void {
    this.validateStringAnswers()
    this.setName.emit({firstName: this.firstName.nativeElement.value, lastName: this.lastName.nativeElement.value});
  }


  removeAccents(originalText: string): string {
    return originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }

  checkFirstName(name: string): void {
    const length = name.length;
    name[length-1] === '-' ? this.isFirstNameValid = false : name[0] === '-' ? this.isFirstNameValid = false : this.isFirstNameValid = this.isFirstNameValid;
    this.isFirstNameError = !this.isFirstNameValid;
  }

  checkLastName(name: string): void {
    const length = name.length;
    name[length-1] === '-' ? this.isLastNameValid = false : name[0] === '-' ? this.isLastNameValid = false : this.isLastNameValid = this.isLastNameValid;
    this.isLastNameError = !this.isLastNameValid;
  }

  checkNames(firstName: string, lastName: string):void {
    this.checkFirstName(firstName);
    this.checkLastName(lastName)
  }
  checkNameEmitter(firstName: string, lastName: string): void{
    this.checkFirstName(firstName);
    this.checkLastName(lastName)
    this.nameInputVerified.emit(this.isFirstNameValid && this.isLastNameValid)
 }

  private validateStringAnswers(): void {
    let firstName = this.firstName.nativeElement.value;
    let lastName = this.lastName.nativeElement.value
    this.isFirstNameValid = this.regexValidate(firstName, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\\ \'\\’]{1,45}(?<!-)',1) || firstName == '';
    this.isLastNameValid = this.regexValidate(lastName, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\\ \'\\’]{1,45}(?<!-)',1) || lastName == '';
    this.checkNames(firstName,lastName)
    this.isFirstNameError = !this.isFirstNameValid;
    this.isLastNameError = !this.isLastNameValid;
    this.nameInputVerified.emit(this.isFirstNameValid && this.isLastNameValid)

  }

  private regexValidate(answer: string, regexString: string, minAnswers: number): boolean {
    if (!answer && minAnswers === 0) {
      return true;
   }
    const regEx = new RegExp(`^${regexString}$`);
    return regEx.test(answer);
  }

}
