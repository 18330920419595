import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CookieHandlerService {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  getSSOToken(): any {
    return this.get('sf-icp-sso=');
  }

  getOauthToken(): string {
    return this.get('sf-cauth1=');
  }

  getOauthRefreshToken(): string {
    return this.get('sf-cauth2=');
  }

  get(needle: string): any {
    const docCookie = this.sanitizer.sanitize(SecurityContext.HTML, document.cookie);
    const cookies = docCookie.split(';');
    for (let cookie of cookies) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(needle) === 0) {
        const something = cookie.substring(needle.length, cookie.length);
        return something;
      }
    }
    return '';
  }
}
