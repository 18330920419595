// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-modal-content-center {
  text-align: center;
  line-height: 1em;
}

.olr-modal-edit-spacing {
  margin-top: 40px;
}

.olr-modal-content-padding {
  padding: 60px 30px;
}

.olr-modal {
  display: none;
  align-content: center;
}

.olr-pnf-modal-header {
  margin-left: 24px;
  margin-right: 24px;
}

.olr-pnf-modal-line-spacing {
  margin-top: 49px;
  margin-bottom: 78px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/modal-participant-match-error/modal-participant-match-error.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".olr-modal-content-center {\n  text-align: center;\n  line-height: 1em;\n}\n\n.olr-modal-edit-spacing {\n  margin-top: 40px;\n}\n\n.olr-modal-content-padding {\n  padding: 60px 30px;\n}\n\n.olr-modal {\n  display: none;\n  align-content: center;\n}\n\n.olr-pnf-modal-header {\n  margin-left: 24px;\n  margin-right: 24px;\n}\n\n.olr-pnf-modal-line-spacing {\n  margin-top: 49px;\n  margin-bottom: 78px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
