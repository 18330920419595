import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { ClaimDetailsService } from '../../services/claim-details.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { Router } from '@angular/router';
import { Customer } from '../../models/customer';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { LossReportService } from '../../services/loss-report.service';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-received-claimant-claim',
  templateUrl: './received-claimant-claim.component.html',
  styleUrls: ['./received-claimant-claim.component.css', '../../shared/styles/question-common-styling.scss']
})
export class ReceivedClaimantClaimComponent implements OnInit {
  claimNumber: string;
  claimId: string;
  customer: Customer;
  email: string;
  emailStatus: string;
  reporter: string;
  created = false;

  get isOtherInsuranceCompany(): boolean {
    return this.reporter === 'otherInsuranceCompany';
  }

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private claimDetailsService: ClaimDetailsService,
    private router: Router,
    private customerProxyService: CustomerProxyService,
    private answerFinderService: LossReportAnswerFinderService,
    private lossReportService: LossReportService,
    private claimsEventsService: ClaimsEventsService,
  ) {
      this.reporter = this.answerFinderService.findAnswer('claimantType');
    }

  ngOnInit(): void {
    this.setCustomerData();
    this.setClaimNumber();
    this.setCreated();
    this.setEmail();
  }


  private setClaimNumber() {
    const claim = this.claimDetailsService.getClaimDetails();
    this.claimNumber = claim.number;
    this.claimId = claim.id;
    this.sfAnalyticsService.sendData('confirmation-page');
    this.claimsEventsService.buildEvent('Claim Confirmed');

  }

  private setEmail() {
    this.email = this.answerFinderService.findAnswer('claimant.claimantContact.email');
  }

  private setCustomerData() {
    this.customer = this.customerProxyService.customerInfo;
  }
  // TODO: uncomment when claimsHub accepts clientID for claimant
  // goToClaims() {
  // eslint-disable-next-line max-len
  // const claimsHubURL = `https://${this.hostnameBuilderService.getAWSHostname('hub.claims')}/#/claim/${this.claimId}/client/${this.customer.externalClientId}/role/NI`;
  // this.window.location.href = claimsHubURL;
  // }

  searchClaim(){
    const oicSearch = `https://claims.statefarm.com/GuestPortalWeb/find-claim`;
    this.window.location.href = oicSearch;
  }
  newClaim(){
    sessionStorage.clear();
    this.router.navigate(['/claimant']);
  }

  private setCreated() {
    this.created = this.lossReportService.getCreatedIndicator();
  }

}
