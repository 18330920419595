// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-btn-center{
    margin-right: auto;
    margin-left: auto;
}

.lr-button-top-position {
    margin-top: 72px;
}

.lr-btn {
  margin-bottom: 32px;
  margin-left: 25%;
  margin-right: 25%;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/error-pages/unauthorized-access/unauthorized-access.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".lr-btn-center{\n    margin-right: auto;\n    margin-left: auto;\n}\n\n.lr-button-top-position {\n    margin-top: 72px;\n}\n\n.lr-btn {\n  margin-bottom: 32px;\n  margin-left: 25%;\n  margin-right: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
