import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { EntryPointComponent } from '../../entry-point/entry-point.component';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-should-i-file',
  templateUrl: './modal-should-i-file.component.html',
  styleUrls: ['./modal-should-i-file.component.css']
})
export class OlrShouldIFileComponent{
  
  @Input() isAuthenticated: boolean;


  onResize(event: { target: { innerWidth: number } }): void {
    // Handle the resize logic here
    console.log('Window resized to:', event.target.innerWidth);
  }


    showShouldIFile: boolean = true; // Add this property
    
}