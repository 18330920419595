import { Component, Inject, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-drawer-top-questions',
  templateUrl: './drawer-top-questions.component.html',
  styleUrls: ['./drawer-top-questions.component.css', '../styles/drawer-common-styling.scss']
})
export class DrawerTopQuestionsComponent implements OnInit, OnDestroy {
  isOpen11 = false;
  isOpen12 = false;
  isOpen13 = false;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-drawer-top-questions'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-drawer-top-questions'));
  }
}
