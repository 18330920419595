// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-continue {
    margin-top: 35px;
}
.lr-selectallspacing {
    margin-bottom: 26px;
}
.lr-additionalspacing {
    margin-top: 26px;
    margin-bottom: 26px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/insured-participant-selection/insured-participant-selection.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".lr-continue {\n    margin-top: 35px;\n}\n.lr-selectallspacing {\n    margin-bottom: 26px;\n}\n.lr-additionalspacing {\n    margin-top: 26px;\n    margin-bottom: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
