// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-field-height-margin {
    margin-top: 63px;
    margin-bottom: 60px;
}

.olr-left-field-padding {
    padding-right: 4px;
}

.olr-right-field-padding {
    padding-left: 4px;
}

.lr-link {
    display: block;
    margin-top: 25px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/contact-information/add-new-phone/add-new-phone.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".olr-field-height-margin {\n    margin-top: 63px;\n    margin-bottom: 60px;\n}\n\n.olr-left-field-padding {\n    padding-right: 4px;\n}\n\n.olr-right-field-padding {\n    padding-left: 4px;\n}\n\n.lr-link {\n    display: block;\n    margin-top: 25px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
