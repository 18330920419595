import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'olr-card-standard-editable',
  templateUrl: './card-standard-editable.component.html',
  styleUrls: ['./card-standard-editable.component.css']
})
export class CardStandardEditableComponent {
  @Input() heading: string;
  @Input() name: string;
  @Input() editBtnText = 'Edit'; // optional
  @Output() cancelEdit = new EventEmitter<string>();
  @Output() saveEdit = new EventEmitter<string>();
  @Output() startEdit = new EventEmitter<string>();
  @Output() changeEvent = new EventEmitter<string>();
  @ViewChild('cardContent', { static: false }) cardContent: ElementRef;
  @ViewChild('cardTextbox', { static: false }) cardTextfield: ElementRef;
  @Output() cardTextfieldValue = new EventEmitter<string>();

  editing = false;
  invalid = false;
  errorMsg = 'Invalid Email';

  constructor() { }

  startEditing(): void {
    this.invalid = false;
    this.startEdit.emit('');
    this.editing = true;
    setTimeout(() => {
      this.cardTextfield.nativeElement.focus();
    }, 0);
  }

  startChanging(): void {
    this.cardTextfieldValue.emit(this.cardTextfield.nativeElement);
  }

  saveEditing(): void {
    if (this.name === 'email') {
      if (!this.isEmail(this.cardTextfield.nativeElement.value)) {
        this.invalid = true;
        this.editing = true;
      } else {
        this.saveEdit.emit(this.cardTextfield.nativeElement.value);
        this.editing = false;
      }
    }
    else {
      this.saveEdit.emit(this.cardTextfield.nativeElement.value);
      this.editing = false;
    }
  }

  isEmail(search: string): boolean {
    if (this.cardTextfield.nativeElement.value.length > 64) {
      this.errorMsg = 'Max email length is 64 characters';
      return false;
    }
    const regexp = new RegExp(/^([\w$%-]+(\.[\w$%-]+)*@([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*?\.[a-zA-Z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?){0,1}/);
    const convertedString = decodeURI(search);
    const regexMatchArray = convertedString.match(regexp);
    const valid = regexMatchArray != null && regexMatchArray.length && regexMatchArray[0] === convertedString;
    if (!valid) {
      this.errorMsg = 'Invalid Email';
    }
    return valid;
  }

  onCancelEdit(): void {
    this.cancelEdit.emit('');
    this.editing = false;
    this.invalid = false;
    setTimeout(() => {
      this.cardContent.nativeElement.focus();
    }, 0);
  }
}
