import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-other-information',
  templateUrl: './other-information.component.html',
  styleUrls: ['./other-information.component.css', '../../shared/styles/question-common-styling.scss']
})
export class OtherInformationComponent extends BaseComponent implements OnInit {
  name: string;
  makeModel: string;
  isValueProvided = false;
  answers: string;

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('claimant-contact-information');
    this.setVehicleMakeModel();
    this.setClaimantName();
    if (this.step.completed){
      this.answers = this.step.question.answers[0];
    }
  }

  setVehicleMakeModel() {
    const vehicleStep = this.lossReportService.getStepByName('firstClaimantVehicle');
    if (vehicleStep){
        const vehicleSubQuestions = vehicleStep.question.subQuestions[0];
        this.makeModel = `${vehicleSubQuestions[1].answers[0]} ${vehicleSubQuestions[2].answers[0] || ''}`.trim();
    }
    else {
        this.makeModel = '';
    }
  }

  setClaimantName() {
    const claimantNameStep = this.lossReportService.getStepByName('firstClaimantName');
    const claimantNameSubQuestions = claimantNameStep.question.subQuestions[0];
    this.name = `${claimantNameSubQuestions[0].answers[0]} ${claimantNameSubQuestions[1].answers[0]}`;
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.haveClaimantContact = answer;
    this.logService.log(CliffLogCodes.HAVE_CLAIMANT_CONTACT, logDetails);
    this.updateAnswer(answer.toLowerCase());
  }


}
