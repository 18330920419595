export class Contact {
    public organization: string;
    public firstName: string;
    public lastName: string;
    public country: string;
    public street1: string;
    public street2: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public phoneType: string;
    public phoneNumber: string;
    public email: string;
}
