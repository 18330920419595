import { Injectable } from '@angular/core';
import { LossReportService } from './loss-report.service';
import { ParticipantBuilderService } from './helpers/participant-builder.service';
import { Participant } from '../models/participant.model';

@Injectable({
  providedIn: 'root'
})
export class InsuredParticipantTrackingService {
  participants: Participant[] = [];
  additionalParticipants: Participant[] = [];
  private passedPolicyParticipant: Participant;
  private passedAdditionalParticipant: Participant;
  private newParticipantTrigger: boolean;

  setPassedPolicyParticipant(participant: Participant): void {
    this.passedPolicyParticipant = participant;
  }

  setPassedAdditionalParticipant(participant: Participant): void {
    this.passedAdditionalParticipant = participant;
  }

  setAddNewParticipantTrigger(triggered: boolean): void {
    this.newParticipantTrigger = triggered;
  }

  getPassedPolicyParticipant(): Participant {
    return this.passedPolicyParticipant;
  }

  getPassedAdditionalParticipant(): Participant {
    return this.passedAdditionalParticipant;
  }

  getAddNewParticipantTrigger(): boolean {
    return this.newParticipantTrigger;
  }

  constructor(
    private lossReportService: LossReportService,
    private participantBuilder: ParticipantBuilderService
  ) { }

  getParticipants(){
     return JSON.parse(sessionStorage.getItem('participants')) || this.participants;
  }

  getAdditionalParticipants(): Participant[] {
    return JSON.parse(sessionStorage.getItem('additionalParticipants')) || this.additionalParticipants;
  }

  getSelectedInsuredParticipants(): Participant[] {
    this.participants = this.getParticipants();
    if (this.participants && this.participants.length > 0){
      return this.participants;
    }
    const participants = [];
    const involvedPolicyParticipantsQuestion = this.lossReportService.getStepByName('involvedPolicyParticipants');
    const selected = involvedPolicyParticipantsQuestion.question.answers;
    const selectedParticipants = selected.filter(id => id !== 'someOneElse');
    selectedParticipants.forEach(id => {
      participants.push(this.createPolicyParticipant(id));
    });
    sessionStorage.setItem('participants', JSON.stringify(participants));
    this.participants = participants;
    return this.participants;
  }

  private createPolicyParticipant(clientId: string): Participant {
    const isDriverFound = this.isDriverSelected;
    const participant = this.participantBuilder.createParticipant(clientId, (isDriverFound ? 'passenger' : undefined), null, null);
    participant.options = isDriverFound ? ['passenger'] : ['driver', 'passenger'];
    return participant;
  }

  /* Updates participants when insured-participant-details saveParticipants() is called.
     UPDATED: to refresh both selected poicy participants and entered additional participants  */
  refreshParticipantsList(participants: Participant[], listName: string): void {
    if (this.isDriverSelected || participants.some(participant => participant.role === 'driver')){
        participants.forEach(participant => {
          participant.options = participant.role === 'driver' ? ['driver', 'passenger'] : ['passenger'];
          participant.role = participant.role === 'driver' ? 'driver' : 'passenger';
        });
    } else {
        participants.forEach(participant => {
          participant.options = ['driver', 'passenger'];
        });
    }
    if (listName === 'participants') {
      this.participants = participants;
    } else {
      this.additionalParticipants = participants;
    }
    sessionStorage.setItem(listName, JSON.stringify(participants));
  }

  /* When user updates or changes the logged in user role in the involved-type-component this method is triggered
     if logged in user changes role then all other must change*/
  refreshSelectedDriver(loggedInUserRole: string): string {
    this.updatePolicyParticipantsRole(loggedInUserRole);
    this.updateAdditionalParticipantsRoles(loggedInUserRole);
    return loggedInUserRole;
  }

  updatePolicyParticipantsRole(role: string){
    const lossReportQuestion = this.lossReportService.getStepByName('insuredVehicleParticipants');
    this.refreshParticipantRoleAndOptions(role, lossReportQuestion, this.participants, 'participants');
  }

  updateAdditionalParticipantsRoles(role: string){
    const lossReportQuestion = this.lossReportService.getStepByName('additionalInsuredParticipant');
    this.refreshParticipantRoleAndOptions(role, lossReportQuestion, this.additionalParticipants, 'additionalParticipants');
  }

  private refreshParticipantRoleAndOptions(role: string, lossReportQuestion: any, participants: Participant[], sessionKey: string) {
    if (lossReportQuestion && participants.length !== 0) {
      const driverFound = participants.find(participant => participant.role === 'driver');
      participants.forEach(participant => {
        if (role === 'driver'){
          participant.role = 'passenger';
          participant.options = ['passenger'];
        } else {
          participant.role = participant.role === 'driver' ? 'driver' : 'passenger';
          participant.options = participant.role === 'driver' || !driverFound ? ['driver', 'passenger'] : ['passenger'];
        }
      });
      sessionStorage.setItem(sessionKey, JSON.stringify(participants));
      lossReportQuestion.completed = !driverFound;
    }
  }

  /* is called from insured-participant-selection component on answerQuestion and modalAnswer.
     purpose is to update participants list that is used on details page*/
  refreshSelectedParticipants(answers: string[]): string[] {
    if (this.participants.length !== 0) {
      const updatedParticipants = [];
      const filteredAnswers = answers.filter(id => id !== 'someOneElse');
      filteredAnswers.forEach(answer => {
        const foundParticipant = this.participants.find(participant => participant.clientId === answer);
        if (foundParticipant) {
          updatedParticipants.push(foundParticipant);
        } else {
          updatedParticipants.push(this.createPolicyParticipant(answer));
        }
      });
      this.participants = updatedParticipants;
      sessionStorage.setItem('participants', JSON.stringify(this.participants));
      const driverSelected = this.isDriverSelected;
      this.updateRoleOptions(this.getParticipants(), 'participants', driverSelected);
      this.updateRoleOptions(this.getAdditionalParticipants(), 'additionalParticipants', driverSelected);
      this.refreshInsuredVehicleParticipantsQuestion(answers);
    }
    return answers;
  }

  updatePolicyParticipants(): void {
    const driverSelected = this.isDriverSelected;
    const policyParticipants = this.getParticipants();
    this.updateRoleOptions(policyParticipants, 'participants', driverSelected);

    const lossReportQuestion = this.lossReportService.getStepByName('insuredVehicleParticipants');
    if (lossReportQuestion) {
      const driverFound = policyParticipants.find(participant => participant.role === 'driver');
      lossReportQuestion.completed = !driverFound;
    }
  }

  updateAdditionalParticipants(): void {
    const driverSelected = this.isDriverSelected;
    const additionalParticipants = this.getAdditionalParticipants();
    this.updateRoleOptions(additionalParticipants, 'additionalParticipants', driverSelected);

    const lossReportQuestion = this.lossReportService.getStepByName('additionalInsuredParticipant');
    if (lossReportQuestion && additionalParticipants.length !== 0) {
      const driverFound = additionalParticipants.find(participant => participant.role === 'driver');
      lossReportQuestion.completed = !driverFound;
    }
  }

  private updateRoleOptions(participants: Participant[], listName: string, driverSelected: boolean) {
    participants.forEach(participant => {
      participant.options = driverSelected && participant.role !== 'driver' ? ['passenger'] : ['driver', 'passenger'];
    });

    if (listName === 'participants') {
      this.participants = participants;
    } else {
      this.additionalParticipants = participants;
    }

    sessionStorage.setItem(listName, JSON.stringify(participants));
  }

  private refreshInsuredVehicleParticipantsQuestion(clientIds: string[]) {
    const insuredVehicleParticipantsQuestion = this.lossReportService.getStepByName('insuredVehicleParticipants');
    if (insuredVehicleParticipantsQuestion) {
      const updatedSubQuestions = [];
      const subQuestions = insuredVehicleParticipantsQuestion.question.subQuestions;
      subQuestions.forEach(subQuestion => {
        if (subQuestion.some(question => clientIds.includes(question.answers[0]))) {
          updatedSubQuestions.push(subQuestion);
        }
      });
      insuredVehicleParticipantsQuestion.question.subQuestions = updatedSubQuestions;
    }
  }

  removeAllSelectedParticipants(answer: string): string {
    this.participants = [];
    this.additionalParticipants = [];
    sessionStorage.removeItem('participants');
    sessionStorage.removeItem('additionalParticipants');
    return answer;
  }

  private isLoggedInUserDriver(): boolean {
    const insuredRoleQuestion = this.lossReportService.getStepByName('insuredInvolvement');
    const insuredRoleAnswer = insuredRoleQuestion ? insuredRoleQuestion.question.subQuestions[0][0].answers[0] : '';
    return insuredRoleAnswer === 'driver';
  }

  private isSelectedParticipantDriver(): boolean {
    const participants = this.getParticipants();
    return participants.some(participant => participant.role === 'driver');
  }

  private isAdditionalParticipantDriver(): boolean {
    const additionalParticipants = this.getAdditionalParticipants();
    return additionalParticipants.some(participant => participant.role === 'driver');
  }

  get isDriverSelected(): boolean {
    return this.isLoggedInUserDriver() || this.isSelectedParticipantDriver() || this.isAdditionalParticipantDriver();
  }

}
