// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-continue {
    margin-top: 35px;
}
.lr-note {
    margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/damage-details/damage-location/damage-location.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".lr-continue {\n    margin-top: 35px;\n}\n.lr-note {\n    margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
