// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-btn-standard {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #6e6e6e;
    background-color: #fff;
    color: #6e6e6e;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 5%;
    border-radius: 8px;
}
.lr-btn-standard button:hover {
    border: solid 2px #6e6e6e;
}
.lr-btn-standard button:active {
    border: solid 2px #003a65;
}
.lr-btn-standard button:focus {
    border: solid 2px #0a70bc;
}
.lr-btn-standard button:selected {
    border: none;
    background-color: #0a70bc;
    color: #fff;
}
.lr-btn-standard button:disabled {
    border: solid 1px #6e6e6e;
    background-color: #f3f3f3;
    color: #cbcbcb;
}
.lr-btn-answer {
    border: 1px solid #403C3C;
    background-color: #F3F2F2;
  }
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/button-standard/button-standard.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;IACd,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,yBAAyB;EAC3B","sourcesContent":[".lr-btn-standard {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: solid 1px #6e6e6e;\n    background-color: #fff;\n    color: #6e6e6e;\n    width: 100%;\n    margin-bottom: 10px;\n    padding: 15px 5%;\n    border-radius: 8px;\n}\n.lr-btn-standard button:hover {\n    border: solid 2px #6e6e6e;\n}\n.lr-btn-standard button:active {\n    border: solid 2px #003a65;\n}\n.lr-btn-standard button:focus {\n    border: solid 2px #0a70bc;\n}\n.lr-btn-standard button:selected {\n    border: none;\n    background-color: #0a70bc;\n    color: #fff;\n}\n.lr-btn-standard button:disabled {\n    border: solid 1px #6e6e6e;\n    background-color: #f3f3f3;\n    color: #cbcbcb;\n}\n.lr-btn-answer {\n    border: 1px solid #403C3C;\n    background-color: #F3F2F2;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
