import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-police-department',
  templateUrl: './police-department.component.html',
  styleUrls: ['./police-department.component.css', '../../shared/styles/question-common-styling.scss']
})
export class PoliceDepartmentComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('governmentBodyName', {static: true}) governmentBodyName: ElementRef;
  @ViewChild('governmentReportNum', {static: true}) governmentReportNum: ElementRef;
  isValueProvided = false;
  isGovernmentBodyNameError = false;
  isGovernmentReportNumError = false;
  governmentBodyNameSubQuestion: any;
  governmentReportNumSubQuestion: any;

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
    }

  ngOnInit() {
    this.analytics.sendData('police-report-details');

    this.getSubQuestions();
    this.displayAnsweredState();
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#floatingLabels'));
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#floatingLabels'));
  }
  private displayAnsweredState(){
    this.governmentBodyName.nativeElement.value = this.governmentBodyNameSubQuestion.answers[0] || '';
    if (this.governmentBodyNameSubQuestion.answers[0]) { this.triggerOneXChangeState('governmentBodyName'); }
    this.governmentReportNum.nativeElement.value = this.governmentReportNumSubQuestion.answers[0] || '';
    if (this.governmentReportNumSubQuestion.answers[0]) { this.triggerOneXChangeState('governmentReportNum'); }
    this.setProvidedStatus();
  }

  private getSubQuestions() {
    this.governmentBodyNameSubQuestion = this.step.question.subQuestions[0][0];
    this.governmentReportNumSubQuestion = this.step.question.subQuestions[0][1];
  }

  private triggerOneXChangeState(elementId: string) {
    // @ts-ignore
    this.window.oneX.$(`#${elementId}`).change();
  }

  private validateStringAnswers(): boolean {
    const isGovernmentBodyNameValid = this.regexValidate(
      this.governmentBodyName.nativeElement.value,
      this.governmentBodyNameSubQuestion.allowedValuesRegex,
      this.governmentBodyNameSubQuestion.minAnswers
      );
    this.isGovernmentBodyNameError = !isGovernmentBodyNameValid;
    const isGovernmentReportNumValid = this.regexValidate(
      this.governmentReportNum.nativeElement.value,
      this.governmentReportNumSubQuestion.allowedValuesRegex,
      this.governmentReportNumSubQuestion.minAnswers
      );
    this.isGovernmentReportNumError = !isGovernmentReportNumValid;
    return isGovernmentBodyNameValid && isGovernmentReportNumValid;
  }

  setProvidedStatus(): void {
    if (this.governmentBodyName.nativeElement.value.length > 0 || this.governmentReportNum.nativeElement.value.length > 0) {
      this.isValueProvided = true;
    } else {
      this.isValueProvided = false;
    }
  }

  resetError(){
    this.isGovernmentBodyNameError = false;
    this.isGovernmentReportNumError = false;
  }

  answerQuestion(): void {
    if (this.validateStringAnswers()) {
      const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.POLICE_REPORT_DETAILS, logDetails);
      this.updateSubQuestionAnswer('governmentBodyName', 0, null, this.governmentBodyName.nativeElement.value || '');
      this.updateSubQuestionAnswer('governmentReportNum', 0, null, this.governmentReportNum.nativeElement.value || '');
      this.updateSubQuestionStep();
    }
  }

}
