import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LossReportingModule } from '../../../lossreporting-library/src/lib/lossreporting.module';
import { AppComponent } from './app.component';
import { OneXHeaderComponent } from './header/onex-header.component';
import { UserIdleModule } from './user-idle/user-idle.module';
import { HttpClientModule } from '@angular/common/http';
import { UiSubnavComponent } from './header/ui-subnav/ui-subnav.component';
import { DrawerAccidentChecklistComponent } from './header/drawer-accident-checklist/drawer-accident-checklist.component';
import { FeatureFlagService } from 'projects/lossreporting-library/src/lib/services/feature-flag.service';

export function initConfig(appConfig: FeatureFlagService) {
  return () => appConfig.loadFeatureFlags();
}
@NgModule({
  declarations: [
    AppComponent,
    OneXHeaderComponent,
    UiSubnavComponent,
    DrawerAccidentChecklistComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    LossReportingModule,
    UserIdleModule.forRoot({ idle: 750, timeout: 120, ping: 60 }),
    HttpClientModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [FeatureFlagService],
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
