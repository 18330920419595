import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';

@Component({
  selector: 'olr-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css']
})
export class ModalErrorComponent implements OnInit, AfterViewInit {
  @Input() errorType: string;
  @ViewChild('errorDialog', {static: false}) errorDialog: ElementRef;
  errorDetails = {
    technical:  {
      heading: 'Technical Error',
      message: 'This is a technical error message.',
      button1: 'OK',
      button2: false,
      message2: false
    },
    maintenance: {
      heading: 'Maintenance',
      message: 'This is a maintenance message.',
      button1: 'OK',
      button2: false,
      message2: false
    },
    injury: {
      heading: 'We can better assist your injury with a phone call.',
      message: 'Please call 800-SF-CLAIM (800-732-5246). We\'re available 24/7.',
      button1: 'OK',
      button2: false,
      message2: 'For those who are deaf, hard of hearing, or do not use voice channels, please contact us via 711 or other relay services.'
    }
  };

  constructor(@Inject('Window') private window: any) { }

  ngOnInit() {
    // @ts-ignore
    this.window.oneX.Modal.init();
  }

  ngAfterViewInit(): void {
    this.errorDialog.nativeElement.focus();
  }

  onButtonClick(){
    console.log('TODO: Implement');
  }
}
