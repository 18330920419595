import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InjuryTrackingService {

  private status = {
    reportingPartyInjury: false,
    insuredParticipantInjury: false,
    additionalParticipantInjury: false,
    claimantParticipantInjury: false,
    insuredParticipantFatality: false,
    additionalParticipantFatality: false,
    claimantParticipantFatality: false,
    claimantPartyInjury: false,
    claimantPartyFatality: false
  };

  constructor() { }

  getStatus() {
    return JSON.parse(sessionStorage.getItem('trackedStatus')) || this.status;
  }

  setStatus(statusName: string, value: boolean) {
    const status = this.getStatus();
    status[statusName] = value;
    this.status = status;
    sessionStorage.setItem('trackedStatus', JSON.stringify(this.status));
  }

  get hasInjury(): boolean {
    const status = this.getStatus();
    return  status.reportingPartyInjury ||
            status.insuredParticipantInjury ||
            status.additionalParticipantInjury ||
            status.claimantPartyInjury ||
            status.claimantParticipantInjury;
  }

  get hasFatality(): boolean {
    const status = this.getStatus();
    return  status.insuredParticipantFatality ||
            status.additionalParticipantFatality ||
            status.claimantPartyFatality ||
            status.claimantParticipantFatality;
  }

}
