import { Component, OnInit, Inject, Input } from '@angular/core';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';

@Component({
  selector: 'olr-term-conditions-modal',
  templateUrl: './term-conditions-modal.component.html',
  styleUrls: ['./term-conditions-modal.component.css']
})
export class TermConditionsModalComponent implements OnInit {
  @Input() selectedPhone: string;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService
    ) {}

  ngOnInit(): void {
    // @ts-ignore
    this.window.oneX.Modal.init();
    this.analytics.sendData('text-terms-and-conditions');
  }

  hideModal() {
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
  }

}
