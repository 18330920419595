// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.accident-checklist {
  list-style: none;
  padding: 0;
}

.grid {
  display: grid;
  gap: 0.2em 0;
  grid-template-areas:
    "index title"
    "index copy";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  padding-bottom: 32px;
}

.index {
  color: #e81e1e;
  font-size: 25px;
  font-weight: 600;
  grid-area: index;
  letter-spacing: 0;
  line-height: 25px;
  width: 1.5em;
}

.title {
  color: #403c3c;
  font-size: 25px;
  font-weight: 600;
  grid-area: title;
  letter-spacing: 0;
  line-height: 25px;
}

.copy {
  grid-area: copy;
}

.heading {
  font-size: 18px;
  margin-bottom: 0.25em;
  margin-top: 0;
}

.subheading {
  font-size: 18px;
  margin-bottom: 3em;
  margin-top: 0;
}

.red {
  color: #e81e1e;
}

.clickable {
  cursor: pointer;
}

.olr-hide-drawer {
  display: none;
}
.olr-drawer-padding {
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-app/src/app/header/drawer-accident-checklist/drawer-accident-checklist.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,YAAY;EACZ;;gBAEc;EACd,+BAA+B;EAC/B,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB","sourcesContent":["ul.accident-checklist {\n  list-style: none;\n  padding: 0;\n}\n\n.grid {\n  display: grid;\n  gap: 0.2em 0;\n  grid-template-areas:\n    \"index title\"\n    \"index copy\";\n  grid-template-columns: auto 1fr;\n  grid-template-rows: auto auto;\n  padding-bottom: 32px;\n}\n\n.index {\n  color: #e81e1e;\n  font-size: 25px;\n  font-weight: 600;\n  grid-area: index;\n  letter-spacing: 0;\n  line-height: 25px;\n  width: 1.5em;\n}\n\n.title {\n  color: #403c3c;\n  font-size: 25px;\n  font-weight: 600;\n  grid-area: title;\n  letter-spacing: 0;\n  line-height: 25px;\n}\n\n.copy {\n  grid-area: copy;\n}\n\n.heading {\n  font-size: 18px;\n  margin-bottom: 0.25em;\n  margin-top: 0;\n}\n\n.subheading {\n  font-size: 18px;\n  margin-bottom: 3em;\n  margin-top: 0;\n}\n\n.red {\n  color: #e81e1e;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.olr-hide-drawer {\n  display: none;\n}\n.olr-drawer-padding {\n  padding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
