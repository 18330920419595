import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-drawer-questions-we-may-ask',
  templateUrl: './drawer-questions-we-may-ask.component.html',
  styleUrls: ['./drawer-questions-we-may-ask.component.css', '../styles/drawer-common-styling.scss']
})
export class DrawerQuestionsWeMayAskComponent implements AfterViewInit, OnDestroy{
  isOpen1 = false;
  isOpen2 = false;
  isOpen3 = false;
  isOpen4 = false;
  isOpen5 = false;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
  ) { }

  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#expansionPanel'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#expansionPanel'));
  }
}
