import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InjuryErrorComponent } from './injury-error/injury-error.component';
import { TechnicalErrorComponent } from './technical-error/technical-error.component';
import { DuplicateClaimComponent } from './duplicate-claim/duplicate-claim.component';
import { ClaimAlreadySubmittedComponent } from './claim-already-submitted/claim-already-submitted.component';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { UnsupportedAssociateComponent } from './unsupported-associate/unsupported-associate.component';

const routes: Routes = [
    { path: 'injury-error', component: InjuryErrorComponent },
    { path: 'technical-error', component: TechnicalErrorComponent },
    { path: 'duplicate-claim', component: DuplicateClaimComponent },
    { path: 'claim-already-submitted', component: ClaimAlreadySubmittedComponent },
    { path: 'unauthorized-access', component: UnauthorizedAccessComponent },
    { path: 'unsupported-associate', component: UnsupportedAssociateComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ErrorPagesRoutingModule {}
