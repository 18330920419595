// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-center {
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/opt-in/preferences/preferences.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".olr-center {\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
