// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.err-page {
    height: 781px;
    width: 1038px;
  }
  
  .lr-injury-header{
    margin-top: 125px;
    margin-left: 53px;
    margin-right: 46px;

  }

  .lr-injury-primary {
    margin-top: 57px;
    margin-left: 40px;
    margin-right: 40px;
}

.lr-injury-secondary {
    margin-top: 141px;
    margin-left: 88px;
    margin-right: 90px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/error-pages/injury-error/injury-error.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;;EAEpB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".err-page {\n    height: 781px;\n    width: 1038px;\n  }\n  \n  .lr-injury-header{\n    margin-top: 125px;\n    margin-left: 53px;\n    margin-right: 46px;\n\n  }\n\n  .lr-injury-primary {\n    margin-top: 57px;\n    margin-left: 40px;\n    margin-right: 40px;\n}\n\n.lr-injury-secondary {\n    margin-top: 141px;\n    margin-left: 88px;\n    margin-right: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
