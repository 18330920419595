// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-link {
    display: block;
    margin-top: 25px;
    text-align: center;
}
.lr-btn-continue {
    margin-top: 35px;
    width: 200px;
    height: 48px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.olr-selection-spacing {
    margin-top: 40px;
    margin-bottom: 63px;
}
.lr-btn-answer {
    border: 0.5px solid #403C3C;
    background-color: #F3F2F2;
    font-weight: 600;
    border-radius: 25px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/contact-information/text-notification/text-notification.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".lr-link {\n    display: block;\n    margin-top: 25px;\n    text-align: center;\n}\n.lr-btn-continue {\n    margin-top: 35px;\n    width: 200px;\n    height: 48px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n.olr-selection-spacing {\n    margin-top: 40px;\n    margin-bottom: 63px;\n}\n.lr-btn-answer {\n    border: 0.5px solid #403C3C;\n    background-color: #F3F2F2;\n    font-weight: 600;\n    border-radius: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
