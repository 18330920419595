import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../../../shared/base/base.component';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { LogService } from '../../../services/log.service';


@Component({
  selector: 'olr-claimant-ni-driver',
  templateUrl: './claimant-ni-driver.component.html',
  styleUrls: ['./claimant-ni-driver.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantNiDriverComponent extends BaseComponent implements OnInit {
  @ViewChild('firstName', { static: true }) firstName: ElementRef;
  @ViewChild('lastName', { static: true }) lastName: ElementRef;
  @ViewChild('middleInitial', { static: true }) middleInitial: ElementRef;
  @ViewChild('suffix', { static: true }) suffix: ElementRef;
  @ViewChild('fullName', { static: true }) fullName: ElementRef;
  @ViewChild('address1', { static: true }) address1: ElementRef;
  @ViewChild('address2', { static: true }) address2: ElementRef;
  @ViewChild('city', { static: true }) city: ElementRef;
  @ViewChild('state', { static: true }) state: ElementRef;
  @ViewChild('postalCode', { static: true }) postalCode: ElementRef;
  isFirstNameError = false;
  isLastNameError = false;
  isMiddleInitialError = false;
  isSuffixError = false;
  isAddress1Error = false;
  isAddress2Error = false;
  isCityError = false;
  isStateError = false;
  isPostalCodeError = false;
  isMobileDevice: boolean;
  static readonly oneXSmallBreakpointPx = 768;
  errorDisplay: boolean = false;
  errorMessage: string = '';
  questionsObj = {};
  isValid = true;

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
      this.isMobileDevice = window.innerWidth <= ClaimantNiDriverComponent.oneXSmallBreakpointPx;
  }

  ngOnInit(): void {
    this.sfAnalyticsService.sendData('claimant-ni-driver');
    this.window['oneX'].addElement(document.querySelector('#claimant-ni-driver-exploding'));
    this.window['oneX'].addElement(document.querySelector('#claimantNiDriverAddress'));
    this.address1.nativeElement.classList.remove('pac-target-input');
    this.window.document.querySelector('.-oneX-exploded_Zip>span').remove();
    this.getQuestions();
    this.getAnswers();
  }

  private getQuestions() {
    let questions: object[] = this.step.question.subQuestions[0];
    for (let i = 0; i < questions.length; ++i) {
      this.questionsObj[questions[i]['name']] = {};
      if (Array.isArray(questions[i]['subQuestions'])) {
        for (let j = 0; j < questions[i]['subQuestions'][0].length; ++j) {
          let subQuestion = questions[i]['subQuestions'][0][j];
          this.questionsObj[questions[i]['name']][subQuestion.name] = subQuestion;
        }
      } else {
        this.questionsObj[questions[i]['name']] = questions[i];
      }
    }
  }

  private getAnswers() {
      if (this.step.completed) {
          if (this.questionsObj['claimantNamedInsuredDriverUnknown']['answers'][0] === 'true') return;
          this.firstName.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverContact']['firstName']['answers'][0] || ''
          this.lastName.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverContact']['lastName']['answers'][0] || ''
          this.middleInitial.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverContact']['middleInitial']['answers'][0] || ''
          this.suffix.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverContact']['suffix']['answers'][0] || ''
          this.window.oneX.Explode.updateFullName('#claimantNiDriver-first-name-id');

          if (this.questionsObj['claimantNamedInsuredDriverAddress']['street1']['answers'][0]) {
            this.address1.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverAddress']['street1']['answers'][0];
            this.window.oneX.$('#full1-address_line1-label-id')[0].classList.add('-oneX--floatUp');
          }
          if (this.questionsObj['claimantNamedInsuredDriverAddress']['street2']['answers'][0]) {
            this.address2.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverAddress']['street2']['answers'][0];
            this.window.oneX.$('#full1-address_line2-label-id')[0].classList.add('-oneX--floatUp');
          }
          if (this.questionsObj['claimantNamedInsuredDriverAddress']['city']['answers'][0]) {
            this.city.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverAddress']['city']['answers'][0];
            this.window.oneX.$('#full1-city-label-id')[0].classList.add('-oneX--floatUp');
          }
          if (this.questionsObj['claimantNamedInsuredDriverAddress']['state']['answers'][0]) {
            this.state.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverAddress']['state']['answers'][0];
            this.window.oneX.$('#full1-state-label-id')[0].classList.add('-oneX--floatUp');
          }
          if (this.questionsObj['claimantNamedInsuredDriverAddress']['postalCode']['answers'][0]) {
            this.postalCode.nativeElement.value = this.questionsObj['claimantNamedInsuredDriverAddress']['postalCode']['answers'][0];
            this.window.oneX.$('#full1-zip-label-id')[0].classList.add('-oneX--floatUp');
          }
    }
  }

  get displayAddress() {
    return !!this.firstName.nativeElement.value ||
      !!this.lastName.nativeElement.value ||
      !!this.middleInitial.nativeElement.value ||
      !!this.suffix.nativeElement.value;
  }

  get displayContinueButton(): boolean {
    return !!this.firstName.nativeElement.value &&
    !!this.lastName.nativeElement.value
  }

  get displayOptional(): boolean {
    return this.fullName.nativeElement.style.display === 'none';
  }

  createUnknownParticipant() {
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverUnknown', 0, 'claimantNamedInsuredDriver', 'true');
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'firstName', ' ');
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'lastName', 'unknown');

    this.updateSubQuestionStep();
  }

  answerQuestion(){
    if (!this.displayContinueButton) return;

    this.resetValidation();

    if (this.errorsFound()) return;

    // name answers
    const firstName = this.firstName.nativeElement.value || '';
    const lastName = this.lastName.nativeElement.value || '';
    const middleInitial = this.middleInitial.nativeElement.value || '';
    const suffix = this.suffix.nativeElement.value || '';

    // address answers
    const addressLine1 = this.address1.nativeElement.value || '';
    const addressLine2 = this.address2.nativeElement.value || '';
    const city = this.city.nativeElement.value || '';
    const state = this.state.nativeElement.value || '';
    const postalCode = this.postalCode.nativeElement.value || '';

    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'firstName', firstName);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'lastName', lastName);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'middleInitial', middleInitial);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverContact', 0, 'suffix', suffix);

    this.updateSubQuestionAnswer('claimantNamedInsuredDriverAddress', 0, 'street1', addressLine1);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverAddress', 0, 'street2', addressLine2);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverAddress', 0, 'city', city);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverAddress', 0, 'state', state);
    this.updateSubQuestionAnswer('claimantNamedInsuredDriverAddress', 0, 'postalCode', postalCode);

    this.updateSubQuestionAnswer('claimantNamedInsuredDriverUnknown', 0, 'claimantNamedInsuredDriver', 'false');

    this.updateSubQuestionStep();
  }

  checkFirstName(name: string): void {
    const length = name.length;
    name[length-1] === '-' ? this.isFirstNameError = true : name[0] === '-' ? this.isFirstNameError = true : this.isFirstNameError = this.isFirstNameError;
  }

  checkLastName(name: string): void {
    const length = name.length;
    name[length-1] === '-' ? this.isLastNameError = true : name[0] === '-' ? this.isLastNameError = true : this.isLastNameError = this.isLastNameError;
  }

  enterFirstName(): void {
    this.isValid = true
    const first = this.firstName.nativeElement.value
    this.isFirstNameError = !(this.regexValidate(first, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\'\\’]{1,45}(?<!-)',1) || first == '');
    this.checkFirstName(first);

  }

  enterLastName(): void {
    const last = this.lastName.nativeElement.value
    this.isLastNameError = !(this.regexValidate(last, '(?!-)(?!.*--)(?!.*-$)(?!.*-.*-)[a-zA-ZÀ-ÖØ-öø-ÿ-\'\\’]{1,45}(?<!-)',1) || last == '');
    this.checkLastName(last);
  }

  enterMiddleInitial(): void {
    const mi = this.middleInitial.nativeElement.value
    this.isMiddleInitialError = !(this.regexValidate(this.middleInitial.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverContact']['middleInitial']['allowedValuesRegex'], this.middleInitial.nativeElement.value ? 1 : 0) || mi == '');
  }

  enterAddress1(): void {
    this.isAddress1Error = !(this.regexValidate(this.address1.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['street1']['allowedValuesRegex'], this.address1.nativeElement.value ? 1 : 0) || this.address1.nativeElement.value == '');
  }

  enterAddress2(): void {
    this.isAddress2Error = !(this.regexValidate(this.address2.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['street2']['allowedValuesRegex'], this.address2.nativeElement.value ? 1 : 0) || this.address2.nativeElement.value == '');
  }

  enterCity(): void {
    this.isCityError = !(this.regexValidate(this.city.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['city']['allowedValuesRegex'], this.city.nativeElement.value ? 1 : 0) || this.city.nativeElement.value == '');
  }

  enterPostal(): void {
    this.isPostalCodeError = !(this.regexValidate(this.postalCode.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['postalCode']['allowedValuesRegex'], this.postalCode.nativeElement.value ? 1 : 0) || this.postalCode.nativeElement.value == '');
  }

  private errorsFound() {
    this.isFirstNameError = !this.regexValidate(this.firstName.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverContact']['firstName']['allowedValuesRegex'], 1);

    if (this.isFirstNameError) this.firstName.nativeElement.classList.add('-oneX-widget--err')
    this.isLastNameError = !this.regexValidate(this.lastName.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverContact']['lastName']['allowedValuesRegex'], 1);
    if (this.isLastNameError) this.lastName.nativeElement.classList.add('-oneX-widget--err')
    this.isMiddleInitialError = !this.regexValidate(this.middleInitial.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverContact']['middleInitial']['allowedValuesRegex'], this.middleInitial.nativeElement.value ? 1 : 0);
    if (this.isMiddleInitialError) this.middleInitial.nativeElement.classList.add('-oneX-widget--err')
    this.isSuffixError = !this.regexValidate(this.suffix.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverContact']['suffix']['allowedValuesRegex'], this.suffix.nativeElement.value ? 1 : 0);
    if (this.isSuffixError) this.suffix.nativeElement.classList.add('-oneX-widget--err')

    this.isAddress1Error = !this.regexValidate(this.address1.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['street1']['allowedValuesRegex'], this.address1.nativeElement.value ? 1 : 0);
    if (this.isAddress1Error) this.address1.nativeElement.classList.add('-oneX-widget--err')
    this.isAddress2Error = !this.regexValidate(this.address2.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['street2']['allowedValuesRegex'], this.address2.nativeElement.value ? 1 : 0);
    if (this.isAddress2Error) this.address2.nativeElement.classList.add('-oneX-widget--err')
    this.isCityError = !this.regexValidate(this.city.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['city']['allowedValuesRegex'], this.city.nativeElement.value ? 1 : 0);
    if (this.isCityError) this.city.nativeElement.classList.add('-oneX-widget--err')
    this.isStateError = !this.regexValidate(this.state.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['state']['allowedValuesRegex'], this.state.nativeElement.value ? 1 : 0);
    if (this.isStateError) this.state.nativeElement.classList.add('-oneX-widget--err')
    this.isPostalCodeError = !this.regexValidate(this.postalCode.nativeElement.value, this.questionsObj['claimantNamedInsuredDriverAddress']['postalCode']['allowedValuesRegex'], this.postalCode.nativeElement.value ? 1 : 0);
    if (this.isPostalCodeError) this.postalCode.nativeElement.classList.add('-oneX-widget--err')

    return this.isFirstNameError
    || this.isLastNameError
    || this.isMiddleInitialError
    || this.isSuffixError
    || this.isAddress1Error
    || this.isAddress2Error
    || this.isCityError
    || this.isStateError
    || this.isPostalCodeError;
  }

  private resetValidation() {
    this.firstName.nativeElement.classList.remove('-oneX-widget--err');
    this.lastName.nativeElement.classList.remove('-oneX-widget--err');
    this.middleInitial.nativeElement.classList.remove('-oneX-widget--err');
    this.suffix.nativeElement.classList.remove('-oneX-widget--err');
    this.address1.nativeElement.classList.remove('-oneX-widget--err');
    this.address2.nativeElement.classList.remove('-oneX-widget--err');
    this.city.nativeElement.classList.remove('-oneX-widget--err');
    this.state.nativeElement.classList.remove('-oneX-widget--err');
    this.postalCode.nativeElement.classList.remove('-oneX-widget--err');
  }
}
