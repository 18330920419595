import { Component, OnInit } from '@angular/core';
import { ExteriorDamageService } from '../exterior-damage/exterior-damage.service';
import { ExteriorDamageCounter } from '../exterior-damage/exterior-damage-counter.model';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-damage-confirmation',
  templateUrl: './damage-confirmation.component.html',
  styleUrls: ['./damage-confirmation.component.css', '../../shared/styles/question-common-styling.scss']
})
export class DamageConfirmationComponent extends BaseComponent implements OnInit {
  damagedAreas: ExteriorDamageCounter[] = [];
  otherDamageAreas: string[] = [];

  constructor(
    private exteriorDamageService: ExteriorDamageService,
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
      this.step = this.lossReportService.getStepByRoute('/damage-details/exterior-damage');
     }

  ngOnInit() {
    this.sfAnalyticsService.sendData('damage-confirmation-summary');
    this.damagedAreas = this.exteriorDamageService.getDamageCounters().filter(dc => dc.counter > 0);
    const damageLocationStep = this.lossReportService.getStepByName('damages');
    const damageLocations = {
      interior: 'Interior',
      attachedTrailer: 'Attached Trailer',
      waterDamage: 'Water Damage',
      undercarriage: 'Underside',
      airbags: 'Air Bags',
    };
    damageLocationStep.question.answers.forEach((answer) => {
      if (answer !== 'exterior') {
        this.otherDamageAreas.push(damageLocations[answer]);
      }
    });
  }

  answerQuestion(): void {
    const answer = [];

    this.damagedAreas.forEach((area) => {
      area.optionsSelected.forEach((option) => {
        answer.push(option.value);
      });
    });
    const logDetails = this.logService.getLogDetails();
    logDetails.damagedComponents = answer;
    this.logService.log(CliffLogCodes.DAMAGED_COMPONENTS, logDetails);
    this.updateAnswer(answer);
  }

}
