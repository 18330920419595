// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-selected {
  background-color: #f4f3f3;
  color: #212121;
  border: 1px solid #403c3c;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/vehicle-selection/vehicle-selection.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".olr-selected {\n  background-color: #f4f3f3;\n  color: #212121;\n  border: 1px solid #403c3c;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
