import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LossReportService } from '../../../services/loss-report.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LossReportAnswerFinderService } from '../../../services/helpers/loss-report-answer-finder.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { CustomerProxyService } from '../../../services/customer-proxy.service';
import { Customer } from '../../../models/customer';
import { ClaimsEventsService } from '../../../services/claims-events.service';

@Component({
  selector: 'olr-homeandproperty-review-claim',
  templateUrl: './homeandproperty-review-claim.component.html',
  styleUrls: ['./homeandproperty-review-claim.component.css']
})
export class HomeandpropertyReviewClaimComponent implements OnInit {
  hasSubmitted = false;
  reporter: string;
  customerInfo: any;
  subIncidentEditRouting: string;
  incidentLocationEditRouting: string;
  email: string;
  dateOfLossRoute: string;

  constructor(
    public lossReportService: LossReportService,
    public analyticsService: SfAnalyticsService,
    public answerFinderService: LossReportAnswerFinderService,
    public router: Router,
    public logService: LogService,
    private customerProxyService: CustomerProxyService,
    private claimsEventsService: ClaimsEventsService,
  ) {
    this.reporter = this.findAnswer('claimantType');
  }

  ngOnInit(): void {
    this.setDynamicRouteInfo();
    this.analyticsService.sendData('review-submit-page');
    this.customerInfo = this.customerProxyService.customerInfo;
    this.subIncidentEditRouting = this.getSubIncidentRouting();
    this.incidentLocationEditRouting = this.getIncidentLocationRouting();
    this.claimsEventsService.buildEvent('Review Claim');
  }

  private setDynamicRouteInfo(): void {
    const dateOfLossStep = this.lossReportService.getStepByName('dateOfLoss');
    this.dateOfLossRoute = dateOfLossStep?.title;
  }

  findAnswer(question: string) {
    return this.answerFinderService.findAnswer(question);
  }

  getCustomerEmail() {
    return this.customerInfo ? this.customerInfo.email : '';
  }

  getFormattedDate(): string {
    const dateOfLoss = this.findAnswer('dateOfLoss');
    const formattedDate = moment.utc(dateOfLoss).format('MM/DD/YYYY');
    return formattedDate;
  }

  getCustomerName(): string {
    if (this.customerInfo) {
      const name = this.customerInfo.name;
      return `${name.firstName} ${name.lastName}`;
    } else {
      return '';
    }
  }

  getAddress() {
    this.router.navigate(['/homeandproperty/contact-information/customer-contact-information']);
  }

  getPhone() {
    this.findAnswer('namedInsuredPhonePreference') === 'anotherNumber' ?
      this.router.navigate(['/homeandproperty/contact-information/add-new-phone']) :
      this.router.navigate(['/homeandproperty/contact-information/preferred-phone']);
  }

  getCustomerAddress(): any {
    if (this.customerInfo) {
      const address = this.customerInfo.mailingAddress;
      return {
        line1: `${address.addressLines[0]}`,
        line2: `${address.addressLines[1]}`,
        line3: `${address.city},${address.stateProvince} ${address.postalCode}`,
      };
    } else {
      return { line1: '', line2: '', line3: '' };
    }
  }

  getSubIncidentRouting(): string {
    const subType = this.findAnswer('incidentCategory');
    let routingURL = '';
    if ((subType === 'weather') || (subType === 'other')) {
      routingURL = '/homeandproperty/incident-details/property-incident-'.concat(subType.toLowerCase());
    } else {
      routingURL = '/homeandproperty/incident-details/incident-'.concat(subType.toLowerCase());
    }
    return routingURL;
  }

  getIncidentLocationRouting(): string {
    const incidentLocationAnswer = this.findAnswer('incidentLocation');
    let routingUrl = '';
    if (incidentLocationAnswer !== 'mailingAddress') {
      routingUrl = '/homeandproperty/incident-details/incident-location-enter';
    } else {
      routingUrl = '/homeandproperty/incident-details/incident-location';
    }
    return routingUrl;
  }

  submitClaim() {
    const logDetails = this.logService.getLogDetails();
    if (!this.hasSubmitted) {
      this.hasSubmitted = true;
      this.lossReportService.submitReport();
      this.lossReportService.lossReportUpdated.subscribe(() => {
        this.logService.log(CliffLogCodes.FIRE_SBMT_SUC, logDetails);
        if (this.lossReportService.isASavedSession()) {
          this.logService.log(CliffLogCodes.LR_SAVED_SESSION_SUBMITTED);
        }
        this.router.navigate(['/confirmation/received-property-claim']);
      });
    }
  }

}
