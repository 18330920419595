import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'olr-card-not-found',
  templateUrl: './card-not-found.component.html',
  styleUrls: ['./card-not-found.component.css']
})
export class CardNotFoundComponent {

  @Input() claimType: string;
  @Input() message: string;
  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice: boolean;

  constructor() {
    this.isMobileDevice = window.innerWidth <= CardNotFoundComponent.oneXSmallBreakpointPx;
  }

  onResize(event:any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < CardNotFoundComponent.oneXSmallBreakpointPx;
  }

}
