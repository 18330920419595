import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerContactInformationComponent } from '../../contact-information/customer-contact-information/customer-contact-information.component';
import { MaintenanceGuard } from '../../services/maintenance.guard';
import { AuthGuard } from '../../services/guards/auth.guard';


const routes: Routes = [
  { path: 'customer-contact-information', component: CustomerContactInformationComponent, canActivate: [MaintenanceGuard, AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeandpropertyContactInformationRoutingModule { }
