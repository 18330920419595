// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-oneX--scrim__modal {
    background-color: rgba(0,0,0,0.75)
}
.lr-heading {
    text-align: center;
    margin-bottom: 32px;
    padding-left: 36px;
}
.lr-center {
    text-align: center;
    padding-top: 8px;
}
.lr-btn-prime {
    margin-bottom: 16px;
}

@media(min-width: 768px) {
    .lr-heading {
        font-size: 23px;
    }
}

.olr-modal {
  display: none;
  align-content: center;
}

.olr-modal-content-padding {
    padding: 72px 40px;
}

.olr-auto-left {
    left: auto;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/progress/save/save.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".-oneX--scrim__modal {\n    background-color: rgba(0,0,0,0.75)\n}\n.lr-heading {\n    text-align: center;\n    margin-bottom: 32px;\n    padding-left: 36px;\n}\n.lr-center {\n    text-align: center;\n    padding-top: 8px;\n}\n.lr-btn-prime {\n    margin-bottom: 16px;\n}\n\n@media(min-width: 768px) {\n    .lr-heading {\n        font-size: 23px;\n    }\n}\n\n.olr-modal {\n  display: none;\n  align-content: center;\n}\n\n.olr-modal-content-padding {\n    padding: 72px 40px;\n}\n\n.olr-auto-left {\n    left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
