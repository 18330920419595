// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-center-text {
    text-align: center;
}

.olr-auto-left {
  left: auto;
}

.olr-remove-padding {
    padding-right: 0px;
}

.olr-modal-content-padding {
  padding: 72px 40px;
}

button.-oneX-btn-primary -oneX-btn-fit-content {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.lr-btn-prime {
  margin-top: 52px;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/contact-information/text-notification/term-conditions-modal/term-conditions-modal.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB;AACJ;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".olr-center-text {\n    text-align: center;\n}\n\n.olr-auto-left {\n  left: auto;\n}\n\n.olr-remove-padding {\n    padding-right: 0px;\n}\n\n.olr-modal-content-padding {\n  padding: 72px 40px;\n}\n\nbutton.-oneX-btn-primary -oneX-btn-fit-content {\n    display: block;\n    margin-left: auto;\n    margin-right: auto\n}\n\n.lr-btn-prime {\n  margin-top: 52px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
