import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

export interface FeatureFlagConfig {
  "feature-flags":{
    "redirect-to-maint": boolean,
    "optin-enabled": boolean,
    "helpful-tips": boolean,
    "show-coverages": boolean,
    "org-feature-active": boolean,
    "claims-eventing-enabled": boolean,
    "assured-enabled": boolean,
    "assured-traffic-percentage": number,
    "sfl-enabled": boolean,
  }
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private config: FeatureFlagConfig;
  loaded = false;
  private URL = '/assets/data/feature-flags.json';

  // setting with initialized values so that feature flags are never undefined
  private redirectToMaintenance = false;
  private optinEnabled = true;
  private helpfulTips = true;
  private showCoverages = false;
  private orgFeatureActive = true;
  private claimsEventingEnabled = false;
  private abFeature = false;
  private assuredEnabled = false;
  private assuredTrafficPercentage = 0;
  private sflEnabled = false;

  constructor(private http: HttpClient) { }

  loadFeatureFlags(): Promise<void> {
    return firstValueFrom(this.http
      .get<FeatureFlagConfig>(this.URL))
      .then(featureJson => {
        this.redirectToMaintenance = featureJson['feature-flags']['redirect-to-maint'];
        this.optinEnabled = featureJson['feature-flags']['optin-enabled'];
        this.helpfulTips = featureJson['feature-flags']['helpful-tips'];
        this.showCoverages = featureJson['feature-flags']['show-coverages'];
        this.orgFeatureActive = featureJson['feature-flags']['org-feature-active'];
        this.claimsEventingEnabled = featureJson['feature-flags']['claims-eventing-enabled'];
        this.abFeature = featureJson['feature-flags']['ab-feature'];
        this.assuredEnabled = featureJson['feature-flags']['assured-enabled'];
        this.assuredTrafficPercentage = featureJson['feature-flags']['assured-traffic-percentage'];
        this.sflEnabled = featureJson['feature-flags']['sfl-enabled'];
        this.config = featureJson;
        this.loaded = true;
      });
  }

  isLoaded(): boolean {
    return this.loaded;
  }

  isRedirectingToMaintenancePage(): boolean {
    return this.redirectToMaintenance;
  }

  isOptInEnabled(): boolean {
    return this.optinEnabled;
  }

  isHelpfulTipsEnabled(): boolean {
    return this.helpfulTips;
  }

  isShowCoveragesEnabled(): boolean {
    return this.showCoverages;
  }

  isOrgFeatureActive(): boolean {
    return this.orgFeatureActive;
  }

  isClaimsEventingEnabled(): boolean {
    return this.claimsEventingEnabled;
  }

  isAssuredEnabled(): boolean {
    return this.assuredEnabled;
  }

  getAssuredTrafficPercentage(): number {
    return this.assuredTrafficPercentage;
  }

  isSflEnabled(): boolean {
    return this.sflEnabled;
  }

//can use this for future test cases
  isABFeature(): boolean {
    return this.abFeature;
  }
  // This setter function is only used to assist with testing,
  // so the URL to test feature flag json files can be set in test cases
  setUrl(url: string) {
    this.URL = url;
  }

}
