// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-modal-content-center {
    text-align: center;
    line-height: 1em;
}

.olr-modal-content-padding {
    padding: 60px 30px;
}

.olr-modal {
    display: none; 
    align-content: center;
}

@media(min-width: 475px) {
    .olr-noaddit-modal-header {
        margin-left: 40px;
        margin-right: auto;
    }
}

.lr-btn-prime {
    margin-top: 52px;
    margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/modal-remove-participant/modal-remove-participant.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;AACJ;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".olr-modal-content-center {\n    text-align: center;\n    line-height: 1em;\n}\n\n.olr-modal-content-padding {\n    padding: 60px 30px;\n}\n\n.olr-modal {\n    display: none; \n    align-content: center;\n}\n\n@media(min-width: 475px) {\n    .olr-noaddit-modal-header {\n        margin-left: 40px;\n        margin-right: auto;\n    }\n}\n\n.lr-btn-prime {\n    margin-top: 52px;\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
