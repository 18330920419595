import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdditionalCommentsComponent } from './additional-comments/additional-comments.component';
import { ReviewClaimComponent } from './review-claim/review-claim.component';

import { ReviewRoutingModule } from './review-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AdditionalCommentsComponent,
    ReviewClaimComponent,
  ],
  imports: [
    CommonModule,
    ReviewRoutingModule,
    SharedModule,
  ],
  exports: [
    SharedModule
  ]
})
export class ReviewModule { }
