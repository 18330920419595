// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-page-note {
    margin-top: 22px;
}
.lr-nowrap {
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/policy-selection/policy-selection.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".lr-page-note {\n    margin-top: 22px;\n}\n.lr-nowrap {\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
