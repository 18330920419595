import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { LoadingService } from '../../services/loading.service';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'olr-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.css']
})
export class BasePageComponent implements OnInit {
  isLoading = false;
  isSflFlag = false;
  showCancelButton = true;
  showSaveButton = true;
  isClaimantLossReport = false;

  constructor(
    private loadingService: LoadingService,
    private analytics: SfAnalyticsService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    @Inject('Window') private window: any
    ) {
      this.analytics.setInitialState(); // Sets the screenChange boolean !
      this.isSflFlag = this.featureFlagService.isSflEnabled();
      if (this.router.url) {
        this.isClaimantLossReport = this.router.url.includes('/claimant/');
      }
      this.router.events.pipe(
        filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)).subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            this.displayCancelButton(event);
            this.displaySaveButton(event);
          }
        });
    }

  ngOnInit(): void {
    this.loadingService.loadingRequest.subscribe( response => {
      this.isLoading = response;
    });
  }

  onCancelModalShow(): void {
    // @ts-ignore
    this.window.oneX.Modal.showModal(this, 'olr-modal-cancel');
    this.analytics.sendData('cancel-modal');
  }

  onSaveModalShow(): void {
    // @ts-ignore
    this.window.oneX.Modal.showModal(this, 'olr-modal-save');
    this.analytics.sendData('save-progress');
  }

  displayCancelButton(event: NavigationEnd) {
    const noShowConditions = [
      n => n === '/',
      n => n === '/home',
      n => n === '/claimant',
      n => n === '/homeandproperty',
      n => n === '/claim-type',
      n => n === '/opt-in/preferences',
      n => n === '/homeandproperty/opt-in/preferences',
      n => n === '/session/session-expired',
      n => n === '/session/session-expired-confirmation',
      n => n === '/maintenance',
      n => n === '/redirect-assured',
      n => n === n && n.includes('?'),
      n => n === n && n.includes('/error/'),
      n => n === n && n.includes('/confirmation/'),
    ];
    const url = event.url;
    this.showCancelButton = !noShowConditions.find(fn => fn(url));
  }

  displaySaveButton(event: NavigationEnd) {
    const noShowConditions = [
      n => n === '/',
      n => n === '/home',
      n => n === '/claimant',
      n => n === '/homeandproperty',
      n => n === '/claim-type',
      n => n === '/opt-in/preferences',
      n => n === '/homeandproperty/opt-in/preferences',
      n => n === '/session/session-expired',
      n => n === '/session/session-expired-confirmation',
      n => n === '/maintenance',
      n => n === n && n.includes('?'),
      n => n === n && n.includes('/error/'),
      n => n === n && n.includes('/confirmation/'),
    ];
    const url = event.url;
    this.showSaveButton = !noShowConditions.find(fn => fn(url));
  }
}
