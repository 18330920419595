// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-btn-binary {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 56px;
    margin-bottom: 10px;
}
.lr-btn-binary button {
    border-radius: 8px;
    border: solid 1px #6e6e6e;
    color: #6e6e6e;
    background-color: #fff;
    text-align: center;
    width: 50%;
}
.lr-btn-binary button:hover {
    border: solid 2px #6e6e6e;
}
.lr-btn-binary button:active {
    border: solid 2px #003a65;
}
.lr-btn-binary button:focus {
    border: solid 2px #0a70bc;
}
.lr-btn-binary button:selected {
    border: 1px solid #403C3C;
    border-radius: 8px;
    background-color: #F3F2F2;
    color: #403c3c;
}
.lr-btn-binary button.selected {
    border: 1px solid #403C3C;
    border-radius: 8px;
    background-color: #F3F2F2;
    color: #403c3c;
}
.lr-btn-binary button:disabled {
    border: solid 1px #6e6e6e;
    background-color: #f3f3f3;
    color: #cbcbcb;
}
.lr-btn-binary button:first-child  {
    margin-right: 6px;
}
.lr-btn-binary button:last-child  {
    margin-left: 6px;
}
.lr-mt-25 {
    margin-top: 25px;
}
.lr-center{
    display: flex;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/button-binary/button-binary.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".lr-btn-binary {\n    display: flex;\n    justify-content: center;\n    align-items: stretch;\n    width: 100%;\n    height: 56px;\n    margin-bottom: 10px;\n}\n.lr-btn-binary button {\n    border-radius: 8px;\n    border: solid 1px #6e6e6e;\n    color: #6e6e6e;\n    background-color: #fff;\n    text-align: center;\n    width: 50%;\n}\n.lr-btn-binary button:hover {\n    border: solid 2px #6e6e6e;\n}\n.lr-btn-binary button:active {\n    border: solid 2px #003a65;\n}\n.lr-btn-binary button:focus {\n    border: solid 2px #0a70bc;\n}\n.lr-btn-binary button:selected {\n    border: 1px solid #403C3C;\n    border-radius: 8px;\n    background-color: #F3F2F2;\n    color: #403c3c;\n}\n.lr-btn-binary button.selected {\n    border: 1px solid #403C3C;\n    border-radius: 8px;\n    background-color: #F3F2F2;\n    color: #403c3c;\n}\n.lr-btn-binary button:disabled {\n    border: solid 1px #6e6e6e;\n    background-color: #f3f3f3;\n    color: #cbcbcb;\n}\n.lr-btn-binary button:first-child  {\n    margin-right: 6px;\n}\n.lr-btn-binary button:last-child  {\n    margin-left: 6px;\n}\n.lr-mt-25 {\n    margin-top: 25px;\n}\n.lr-center{\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
