import { Component, AfterViewInit, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'olr-modal-other-vehicle-details',
  templateUrl: './modal-other-vehicle-details.component.html',
  styleUrls: ['./modal-other-vehicle-details.component.css']
})
export class ModalOtherVehicleDetailsComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject('Window') private window: any
  ) { }

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-other-vehicle-details'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-other-vehicle-details'));
  }
  closeModal() {
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
  }

}
