import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerContactInformationComponent } from './customer-contact-information/customer-contact-information.component';

import { ContactInformationRoutingModule } from './contact-information-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PreferredPhoneComponent } from './preferred-phone/preferred-phone.component';
import { AddNewPhoneComponent } from './add-new-phone/add-new-phone.component';
import { TextNotificationComponent } from './text-notification/text-notification.component';
import { TermConditionsModalComponent } from './text-notification/term-conditions-modal/term-conditions-modal.component';
import { OrgContactInformationComponent } from './org-contact-information/org-contact-information.component';


@NgModule({
  declarations: [
    CustomerContactInformationComponent,
    PreferredPhoneComponent,
    AddNewPhoneComponent,
    TextNotificationComponent,
    TermConditionsModalComponent,
    OrgContactInformationComponent
  ],
  imports: [
    CommonModule,
    ContactInformationRoutingModule,
    SharedModule
  ],
  exports: [
    SharedModule,
  ]
})
export class ContactInformationModule { }
