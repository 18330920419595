import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DamageComponent } from './damage/damage.component';
import { DamageLocationComponent } from './damage-location/damage-location.component';
import { ExteriorDamageComponent } from './exterior-damage/exterior-damage.component';
import { DamageConfirmationComponent } from './damage-confirmation/damage-confirmation.component';
import { RolloverComponent } from './rollover/rollover.component';
import { VehicleLocationComponent } from './vehicle-location/vehicle-location.component';
import { VehicleStorageFeeComponent } from './vehicle-storage-fee/vehicle-storage-fee.component';
import { AuthGuard } from '../services/guards/auth.guard';
import { EnterLocationComponent } from '../incident-details/incident-location/enter-location/enter-location.component';

const routes: Routes = [
    { path: 'damage', component: DamageComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-location', component: VehicleLocationComponent, canActivate: [AuthGuard]},
    { path: 'enter-location', component: EnterLocationComponent, canActivate: [AuthGuard] },
    { path: 'vehicle-storage', component: VehicleStorageFeeComponent, canActivate: [AuthGuard]},
    { path: 'damage-location', component: DamageLocationComponent, canActivate: [AuthGuard] },
    { path: 'exterior-damage', component: ExteriorDamageComponent, canActivate: [AuthGuard] },
    { path: 'damage-confirmation', component: DamageConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'rollover', component: RolloverComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class DamageDetailsRoutingModule {}
