import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'olr-button-session-option',
  templateUrl: './button-session-option.component.html',
  styleUrl: './button-session-option.component.css'
})
export class ButtonSessionOptionComponent implements OnInit {

  @Input() buttonId: string;
  @Input() sessionId: any;
  @Input() claimType: string;
  @Input() description: string;
  @Output() buttonClicked = new EventEmitter<string>();
  title1: string;
  title2: string;

  ngOnInit(): void {
    this.setDescription();
  }

  onButtonClick() {
    this.buttonClicked.emit(this.sessionId);
  }

  setDescription():void {
    if (this.claimType === 'HOME_AND_PROPERTY') {
      const str = this.description ? this.description : '';
      const parts = str.split('<br/>');
      this.title1 = parts[0] || '';
      if (parts.length > 1) {
      this.title2 = parts[1];
      }
      return;
    }
    this.title1 = this.description ? this.description : '';
  }

}
