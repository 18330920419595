import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { ClaimantParticipantTrackingService } from '../../services/claimant-participant-tracking.service';
import { ParticipantBuilderService } from '../../services/helpers/participant-builder.service';
import { InjuryTrackingService } from '../../services/injury-tracking.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { NameComponent } from '../../shared/name/name.component';
import { ContactComponent } from '../../shared/contact/contact.component';
import { Participant } from '../../models/participant.model';
import { Name } from '../../models/name.model';
import { Contact } from '../../models/contact.model';
import { LogDetails } from '../../models/log-details.model';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-claimant-participants',
  templateUrl: './claimant-participants.component.html',
  styleUrls: ['./claimant-participants.component.css', '../../shared/styles/question-common-styling.scss']
})
export class ClaimantParticipantsComponent extends BaseComponent implements OnInit {

  @ViewChild(NameComponent) nameComponent: NameComponent;
  @ViewChild(ContactComponent) contactComponent: ContactComponent;
  vehicle: string;
  participants: Participant[] = [];
  currentParticipant: Participant;
  isValueProvided = false;
  isParticipantInfoComplete = false;
  isAnswerSelected = false;
  editMode = false;
  addMode = false;
  updating = false;
  nameObject: any;
  contactObject: any;
  injuryStatusOptions = ['yes', 'no', 'unknown'];
  medicalOptions = ['yes', 'no', 'unknown'];
  lossOfLifeOptions = ['yes', 'no'];
  airbagDeployOptions = ['yes', 'no', 'unknown', 'na'];
  selectedCustomerName = '';
  selectedRole = '';
  selectedInjuryStatus = '';
  selectedLossOfLife = '';
  selectedInjuries = [];
  selectedMedicalTreatment = '';
  selectedAirbagDeployed = '';
  logDetails: LogDetails;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private participantBuilder: ParticipantBuilderService,
    private trackingService: ClaimantParticipantTrackingService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private injuryTrackingService: InjuryTrackingService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.logDetails = this.logService.getLogDetails();
    this.window.oneX.addElement(document.querySelector('#claimantDropdowns'));
    this.vehicle = this.getVehicleInfo('firstClaimantVehicle');
    this.participants = this.trackingService.getClaimantParticipants('firstClaimantVehicleParticipants');
    this.setAnalytics();
  }

  getVehicleInfo(key: string): string {
    return sessionStorage.getItem(key) || '';
  }

  setAnalytics(): void {
    if (this.participants.length > 0 && !this.editMode && !this.currentParticipant && !this.addMode) {
      this.analytics.sendData('claimant-vehicle1-participant-list');
    }

    if (!this.currentParticipant && this.participants.length === 0 || this.editMode && this.currentParticipant || this.addMode) {
      const index = this.currentParticipant ? this.participants.lastIndexOf(this.currentParticipant) : this.participants.length;
      this.analytics.sendData(`clamant-vehicle1-add-participant${index + 1}`);
    }
  }

  updateName(answer: any): void {
    this.isValueProvided = answer && answer.firstName && answer.lastName;
    this.isParticipantInfoComplete = this.isValueProvided;
    if (this.isValueProvided) {
      this.nameObject = answer;
      this.contactComponent.ngOnInit();
    }
  }

  updateContact(answer: any): void {
    this.isParticipantInfoComplete = this.isValueProvided && answer && answer.country;
    if (this.isParticipantInfoComplete) {
      this.contactObject = answer;
    }
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement(document.querySelector('#claimantDropdowns'));
    }, 100);
  }

  private toggleContinue() {
    const initialQuestionsAnswered = this.selectedRole.length > 0 && this.selectedInjuryStatus.length > 0;
    const lossOfLifeAnswered = this.selectedInjuryStatus === 'yes' ? this.selectedLossOfLife.length > 0 : true;
    this.isAnswerSelected = initialQuestionsAnswered && lossOfLifeAnswered;
    this.activateFloatingLabel();
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.toggleContinue();
  }

  updateInjuryStatus(answer: any) {
    this.selectedInjuryStatus = answer;
    if (answer === 'no') {
      this.selectedLossOfLife = ''; // resets in the event of a change of mind.
    }
    this.toggleContinue();
  }

  updateLossOfLife(answer: any) {
    this.selectedLossOfLife = answer;
    this.toggleContinue();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedicalTreatment = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  createUnknownParticipant(): void {
    this.buildParticipant(null, null);
  }

  createParticipant(): void {
    this.buildParticipant(this.nameObject, this.contactObject);
  }

  private buildParticipant(nameObject: any, contactObject: any) {
    const role = this.editMode ? this.currentParticipant.role : undefined;
    this.isAnswerSelected = this.editMode;
    const options = this.editMode && this.currentParticipant.role === 'driver' ? this.currentParticipant.options : this.determineOptions();
    if (!this.updating) {
      this.currentParticipant = this.participantBuilder.createParticipant(null, role, nameObject, contactObject);
      this.currentParticipant.options = options;
    } else {
      this.currentParticipant = this.participantBuilder.updateParticipant(this.currentParticipant, nameObject, contactObject);
    }
    this.resetModes(role);
  }

  private determineOptions(): string[] {
    return this.trackingService.getSelectableOptions('firstClaimantVehicleParticipants');
  }

  private resetModes(role: string): void {
    this.editMode = false;
    this.addMode = false;
    setTimeout(() => {
      this.window.oneX.addElement('#edit-person');
      this.activateFloatingLabel();
    }, 100);
  }

  saveParticipant(): void {
    this.currentParticipant.role = this.selectedRole;
    this.currentParticipant.injuryStatus = this.selectedInjuryStatus;
    this.currentParticipant.lossOfLife = this.selectedLossOfLife;
    if (this.displayRemainingQuestions) {
      this.currentParticipant.injuries = this.selectedInjuries;
      this.currentParticipant.medicalTreatment = this.selectedMedicalTreatment;
      this.currentParticipant.airbagDeploy = this.selectedAirbagDeployed;
      this.selectedInjuries = [];
      this.selectedAirbagDeployed = '';
      this.selectedMedicalTreatment = '';
    } else {
      this.currentParticipant.injuries = [];
      this.currentParticipant.medicalTreatment = '';
      this.currentParticipant.airbagDeploy = '';
    }

    if (!this.updating) {
      if (this.currentParticipant.name) {
        this.currentParticipant.name.first = this.removeAccents(this.currentParticipant.name.first)
        this.currentParticipant.name.last = this.removeAccents(this.currentParticipant.name.last)
      }
      if (this.currentParticipant.contact) {
        this.currentParticipant.contact.street1 = this.removeAccents(this.currentParticipant.contact.street1)
        this.currentParticipant.contact.street2 = this.removeAccents(this.currentParticipant.contact.street2)
        this.currentParticipant.contact.city = this.removeAccents(this.currentParticipant.contact.city)
        this.currentParticipant.contact.state = this.removeAccents(this.currentParticipant.contact.state)
      }

      this.participants.push(this.currentParticipant);
    } else {
      this.updating = false;
    }
    this.trackingService.refreshParticipantsList(this.participants, 'firstClaimantVehicleParticipants');
    this.currentParticipant = undefined;
    this.isValueProvided = false;
    this.selectedRole = '';
    this.selectedLossOfLife = '';
    this.selectedInjuryStatus = '';
    this.setAnalytics();
  }

  editParticipant(participant: Participant): void {
    this.currentParticipant = participant;
    this.editMode = true;
    setTimeout(() => {
      if (!this.currentParticipant.name.unknown) {
        this.nameComponent.setAnswerState(this.currentParticipant.name);
        this.contactComponent.setAnswerState(this.currentParticipant.contact);
        this.setAnalytics();
      }
      this.window.oneX.addElement(document.querySelector('#claimantDropdowns'));
    }, 100);
  }

  editParticipantInvolvement(participant: Participant): void{
    this.currentParticipant = participant;
    this.updating = true;
    this.selectedRole = this.currentParticipant.role;
    this.selectedInjuryStatus = this.currentParticipant.injuryStatus;
    this.selectedLossOfLife  = this.currentParticipant.lossOfLife;
    this.selectedInjuries = this.currentParticipant.injuries || [];
    this.selectedMedicalTreatment = this.currentParticipant.medicalTreatment;
    this.selectedAirbagDeployed = this.currentParticipant.airbagDeploy;
    setTimeout(() => {
      this.toggleContinue();
      this.window.oneX.addElement(document.querySelector('#claimantDropdowns'));
    }, 10);
    this.logService.log(CliffLogCodes.CLM_PRT_EDT, this.logDetails);
  }

  addAnotherParticipant(): void {
    this.addMode = true;
    this.isParticipantInfoComplete = false;
    setTimeout(() => {
      this.window.oneX.addElement(document.querySelector('#claimantDropdowns'));
      this.setAnalytics();
    }, 100);
  }

  removeParticipant() {
    this.selectedCustomerName = this.currentParticipant.fullName || 'the person';
    this.analytics.sendData('remove-participant-modal');
    this.window.oneX.Modal.showModal(this, 'olr-modal-remove-participant');
  }

  modalAnswer(answer: string) {
    if (answer === 'yes') {
      this.participants = this.participants.filter(participant => participant.id !== this.currentParticipant.id);
      this.currentParticipant = null;
      this.updating = false;
      this.isValueProvided = false;
      this.selectedRole = '';
      this.selectedInjuryStatus = '';
      this.selectedLossOfLife  = '';
      this.selectedInjuries = [];
      this.selectedMedicalTreatment = '';
      this.selectedAirbagDeployed = '';
      this.trackingService.refreshParticipantsList(this.participants, 'firstClaimantVehicleParticipants');
      this.logService.log(CliffLogCodes.CLM_PRT_RMV, this.logDetails);
    } else {
      this.isAnswerSelected = this.selectedRole.length > 0
      && this.selectedInjuryStatus.length > 0
      && this.selectedLossOfLife.length > 0;
    }
  }

  get hideAddAdditionalLink(): boolean {
    return this.participants.length === 10;
  }

  get displayRemainingQuestions(): boolean {
    return this.selectedInjuryStatus === 'yes' && this.selectedLossOfLife === 'no';
  }

  removeAccents(originalText: string): string {
    var returnText = ''
    if ((originalText != null) && (originalText != undefined)) {
      returnText = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    }
    return returnText
  }

  answerQuestion(){
    const answers = [];
    let fatalityCount = 0;
    let injuryCount = 0;
    this.logDetails = { numberOfClaimantParticipants: `${this.participants.length}` };
    this.logService.log(CliffLogCodes.CLM_ADD_PRT, this.logDetails);
    this.participants.forEach(participant => {
      const injuryStatus = participant.injuryStatus;
      const lossOfLife = participant.lossOfLife;
      if (injuryStatus === 'yes') { injuryCount++; }
      if (lossOfLife === 'yes') { fatalityCount++; }
      const additionalParticipants = [];
      additionalParticipants.push(
        this.createAnsweredQuestion('1010C', 'claimantName', null, this.answerNameSubQuestions(participant.name)));
      additionalParticipants.push(
        this.createAnsweredQuestion('7019', 'claimantContact', null, this.answerContactSubQuestions(participant.contact)));
      additionalParticipants.push(this.createAnsweredQuestion('7020', 'claimantRole', participant.role));
      additionalParticipants.push(this.createAnsweredQuestion('1051', 'injuryStatus', injuryStatus));
      if (injuryStatus === 'yes') {
        additionalParticipants.push(this.createAnsweredQuestion('1052', 'lossOfLife', lossOfLife));
      }
      if (injuryStatus === 'yes' && lossOfLife === 'no') {
        additionalParticipants.push(this.createAnsweredQuestion('1053', 'injuries', participant.injuries));
        additionalParticipants.push(this.createAnsweredQuestion('1054', 'medicalTreatment', participant.medicalTreatment));
        additionalParticipants.push(this.createAnsweredQuestion('1055', 'airbagDeploy', participant.airbagDeploy));
      }
      answers.push(additionalParticipants);
    });
    this.injuryTrackingService.setStatus('claimantParticipantInjury', injuryCount > 0);
    this.injuryTrackingService.setStatus('claimantParticipantFatality', fatalityCount > 0);
    this.updateSubQuestions(answers);
  }

  private answerNameSubQuestions(nameAnswers: Name){
    const nameSteps = [];
    nameSteps.push(this.createAnsweredQuestion('1011NR', 'firstName', nameAnswers.unknown ? [] : nameAnswers.first));
    nameSteps.push(this.createAnsweredQuestion('1012NR', 'lastName', nameAnswers.unknown ? [] : nameAnswers.last));
    return nameSteps;
  }

  private answerContactSubQuestions(contactAnswers: Contact) {
    const contactSteps = [];
    contactSteps.push(this.createAnsweredQuestion('3037', 'country', contactAnswers ? contactAnswers.country : null || 'usa'));
    contactSteps.push(this.createAnsweredQuestion('1030NR', 'street1', contactAnswers ? contactAnswers.street1 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1031', 'street2', contactAnswers ? contactAnswers.street2 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1032NR', 'city', contactAnswers ? contactAnswers.city : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1033NC', 'state', contactAnswers ? contactAnswers.state : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1037NC', 'postalCode', contactAnswers ? contactAnswers.zipCode : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1040NR', 'phoneType', contactAnswers ? contactAnswers.phoneType : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1041NR', 'phoneNumber', contactAnswers ? contactAnswers.phoneNumber : null || [] ));
    return contactSteps;
  }

}
