import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-claimant-vehicle-location',
  templateUrl: './claimant-vehicle-location.component.html',
  styleUrls: ['./claimant-vehicle-location.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantVehicleLocationComponent extends BaseComponent implements OnInit {

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData('vehicle-location-question');
    this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.INCIDENT_LOCATION, logDetails);
    this.updateAnswer(answer);
  }
}
