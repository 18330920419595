import { Component, OnInit, Inject, SecurityContext, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '../../shared/base/base.component';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { LossreportAnsweredStepBuilderService } from '../../services/helpers/lossreport-answered-step-builder.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { CliffLogDetails } from '../../models/cliff-log-details.model';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { Participant } from '../../models/participant.model';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-insured-participant-details',
  templateUrl: './insured-participant-details.component.html',
  styleUrls: ['./insured-participant-details.component.css', '../../shared/styles/question-common-styling.scss']
})
export class InsuredParticipantDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  vehicle: string;
  currentParticipant: Participant;
  isAnswerSelected = false;
  customers = [];
  additionalParticipants: Participant[] = [];
  isEditing = false;
  loggedInCustomer = '';
  loggedInCustomerRole = '';
  options = ['driver', 'passenger'];
  injuryStatusOptions = ['yes', 'no', 'unknown'];
  medicalOptions = ['yes', 'no', 'unknown'];
  lossOfLifeOptions = ['yes', 'no'];
  airbagDeployOptions = ['yes', 'no', 'unknown', 'na'];
  selectedCustomerName = '';
  selectedRole = '';
  selectedInjuryStatus = '';
  selectedLossOfLife = '';
  selectedInjuries = [];
  selectedMedicalTreatment = '';
  selectedAirbagDeployed = '';
  addMode = false;
  isParticipantInfoComplete = true;



  constructor(
    @Inject('Window') private window: any,
    private claimsEventsService: ClaimsEventsService,
    private analytics: SfAnalyticsService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private customerProxyService: CustomerProxyService,
    private trackingService: InsuredParticipantTrackingService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private sanitizer: DomSanitizer,
    private stepBuilderService: LossreportAnsweredStepBuilderService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.customers = this.trackingService.getSelectedInsuredParticipants();
    this.additionalParticipants = this.trackingService.getAdditionalParticipants();
    this.currentParticipant = this.customers.find(customer => !customer.role || !customer.injuryStatus);
    this.selectedRole = this.currentParticipant ? this.currentParticipant.role : '';
    this.options = this.currentParticipant ? this.currentParticipant.options : [];
    this.setAnalyticsTag(this.currentParticipant);
    this.getLoggedInCustomerInfo();
    this.vehicle = this.policyVehicleInfoService.getVehicleDescription();
    this.setupEditForPassedPolicyParticipant();
    this.claimsEventsService.buildEvent('Capture Participant Details');
  }

  get hideAddAdditionalLink(): boolean {
    return this.additionalParticipants.length === 10 || this.additionalParticipants.length < 1;
  }


  getLoggedInCustomerInfo() {
    this.customerProxyService.getCustomerInfo();
    this.customerProxyService.customerInfoUpdated.subscribe( response => {
      this.loggedInCustomer = `${response.name.firstName} ${response.name.lastName}`;
    });
    const insuredInvolvementQuestion = this.lossReportService.getStepByName('insuredInvolvement');
    this.loggedInCustomerRole =
      this.sanitizer.sanitize(SecurityContext.HTML, insuredInvolvementQuestion.question.subQuestions[0][0].answers.toString());
  }

  private setupEditForPassedPolicyParticipant(): void {
    const participant = this.trackingService.getPassedPolicyParticipant();
    if (participant && participant.clientId) {
      this.trackingService.setPassedPolicyParticipant(null);
      this.editParticipant(participant.clientId);
    }
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      this.window.oneX.addElement(document.querySelector('#involvementPage'));
    }, 100);
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#involvementPage'));
  }
  private setAnalyticsTag(currentParticipant: Participant) {
    if (currentParticipant) {
      const index = this.customers.lastIndexOf(currentParticipant) + 1;
      this.analytics.sendData(`policy-participant${index}-details`);
    } else {
      this.analytics.sendData('insured-vehicle-participant-list');
    }
  }

  private log(messageId: string, message: string){
    const cliffLog = new CliffLogDetails(messageId);
    cliffLog.message = message;
    cliffLog.logData = this.logService.getLogDetails();
    this.logService.sendSparklLog(cliffLog);
  }

  private toggleContinue() {
    const initialQuestionsAnswered = this.selectedRole.length > 0 && this.selectedInjuryStatus.length > 0;
    const lossOfLifeAnswered = this.selectedInjuryStatus === 'yes' ? this.selectedLossOfLife.length > 0 : true;
    this.isAnswerSelected = initialQuestionsAnswered && lossOfLifeAnswered;
    this.activateFloatingLabel();
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.toggleContinue();
  }

  updateInjuryStatus(answer: any) {
    this.selectedInjuryStatus = answer;
    if (answer === 'no') {
      this.selectedLossOfLife = ''; // resets in the event of a change of mind.
    }
    this.toggleContinue();
  }

  updateLossOfLife(answer: any) {
    this.selectedLossOfLife = answer;
    this.toggleContinue();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedicalTreatment = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  get displayRemainingQuestions(): boolean {
    return this.selectedInjuryStatus === 'yes' && this.selectedLossOfLife === 'no';
  }

  editParticipant(clientId: string){
    this.log(CliffLogCodes.INJ_DTL_EDT.id, CliffLogCodes.INJ_DTL_EDT.text);
    this.currentParticipant = this.customers.find(customer => customer.clientId === clientId);
    this.selectedCustomerName = this.currentParticipant.fullName;
    this.isEditing = true;
    this.options = this.currentParticipant.options;
    this.setAnalyticsTag(this.currentParticipant);
    this.isAnswerSelected = true;
    this.selectedRole = this.currentParticipant.role;
    this.selectedInjuryStatus = this.currentParticipant.injuryStatus;
    this.selectedLossOfLife  = this.currentParticipant.lossOfLife;
    this.selectedInjuries = this.currentParticipant.injuries || [];
    this.selectedMedicalTreatment = this.currentParticipant.medicalTreatment;
    this.selectedAirbagDeployed = this.currentParticipant.airbagDeploy;
    this.activateFloatingLabel();
  }

  editAdditionalParticipant(participant: Participant) {
    this.additionalParticipants = this.trackingService.getAdditionalParticipants();
    const updatedParticipant = this.additionalParticipants.find(p => p.id === participant.id);
    this.trackingService.setPassedAdditionalParticipant(updatedParticipant);
    this.router.navigate(['/incident-details/additional-insured-participants']);
  }

  addAnotherParticipant(): void {
    this.trackingService.setAddNewParticipantTrigger(true);
    this.router.navigate(['incident-details/additional-insured-participants']);
  }

  returnToSelection() {
    this.router.navigate(['/incident-details/insured-participant-selection']);
  }

  editLoggedInUserInvolvement(){
    this.router.navigate(['/incident-details/involvement']);
  }

  saveParticipant(){
    this.currentParticipant.role = this.selectedRole;
    this.currentParticipant.injuryStatus = this.selectedInjuryStatus;
    this.currentParticipant.lossOfLife = this.selectedLossOfLife;
    if (this.displayRemainingQuestions) {
      this.currentParticipant.injuries = this.selectedInjuries;
      this.currentParticipant.medicalTreatment = this.selectedMedicalTreatment;
      this.currentParticipant.airbagDeploy = this.selectedAirbagDeployed;
      this.selectedInjuries = [];
      this.selectedAirbagDeployed = '';
      this.selectedMedicalTreatment = '';
    } else {
      this.currentParticipant.injuries = [];
      this.currentParticipant.medicalTreatment = '';
      this.currentParticipant.airbagDeploy = '';
    }
    this.trackingService.refreshParticipantsList(this.customers, 'participants');
    this.trackingService.updateAdditionalParticipants();
    this.currentParticipant = this.customers.find(customer => !customer.role || !customer.injuryStatus);
    this.setAnalyticsTag(this.currentParticipant);
    this.selectedRole = this.currentParticipant?.role || '';
    this.selectedLossOfLife = '';
    this.selectedInjuryStatus = this.currentParticipant?.injuryStatus || '';
    this.isEditing = !this.currentParticipant;
    this.toggleContinue();
  }

  answerQuestion(){
    this.step.question.minAnswers = this.customers.length;
    this.step.question.maxAnswers = this.customers.length;
    if (this.additionalParticipants.length > 0){
      const additionalParticipantsStep = this.lossReportService.getStepByName('additionalInsuredParticipant');
      additionalParticipantsStep.question.subQuestions =
        this.stepBuilderService.answerAdditionalInsuredParticipants(this.additionalParticipants);
      additionalParticipantsStep.completed = true;
    }
    this.updateSubQuestions(this.stepBuilderService.answerPolicyInsuredParticipants(this.customers));
  }

}
