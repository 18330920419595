import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { Address } from '../../models/address.model';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-org-contact-information',
  templateUrl: './org-contact-information.component.html',
  styleUrls: ['./org-contact-information.component.css', '../../shared/styles/question-common-styling.scss']
})
export class OrgContactInformationComponent extends BaseComponent implements OnInit {

  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  isAddressUpdated = false;
  isEmailUpdated = false;
  edit = false;
  continueDisabled = false;

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData('insured-verifycontact-question');
    this.populateCustomerContactInfo();
  }

  populateCustomerContactInfo(): void {
    // eslint-disable-next-line max-len
    if (this.step.completed && this.step.question.subQuestions[0][0].answers.includes('address') &&
      this.step.question.subQuestions[0][0].answers.includes('email')) {
      this.email = this.step.question.subQuestions[0][2].answers[0] || 'No email found.';
      this.isEmailUpdated = true;
      this.populateAddress();
      return;
    }
    else if (this.step.completed && this.step.question.subQuestions[0][0].answers.includes('address')) {
      this.populateAddress();
    }
    else if (this.step.completed && this.step.question.subQuestions[0][0].answers.includes('email')) {
      this.isEmailUpdated = true;
      this.email = this.step.question.subQuestions[0][1].answers[0];
    }

    if (!this.isEmailUpdated) {
      this.email = this.getKeyValue(this.step.question.displayContent, 'email') || 'No email found.';
    }

    if (!this.isAddressUpdated) {
      this.street1 = this.getKeyValue(this.step.question.displayContent, 'address', 'street1');
      this.street2 = this.getKeyValue(this.step.question.displayContent, 'address', 'street2');
      this.city = this.getKeyValue(this.step.question.displayContent, 'address', 'city');
      this.state = this.getKeyValue(this.step.question.displayContent, 'address', 'state');
      let postalCode = this.getKeyValue(this.step.question.displayContent, 'address', 'postalCode');
      postalCode = postalCode ? postalCode.substring(0, 5) : '';
      this.postalCode = postalCode;
    }
  }

  removeContactAnswer(element: string) {
    let answer: string[];
    if (element === '') {
      answer = [];
    } else {
      answer = [... this.step.question.subQuestions[0][0].answers];
    }
    if (answer.includes(element)) {
      answer.splice(answer.indexOf(element), 1);
    }
    return answer;
  }

  changeAddress(): void {
    this.continueDisabled = true;
    if (!this.step.question.subQuestions[0][0].answers.includes('address')) {
      this.updateSubQuestionAnswer('namedInsuredContactIncorrect', 0, null, this.updateContactAnswers('address'), true);
    }
  }

  changeEmail(): void {
    this.continueDisabled = true;
    if (!this.step.question.subQuestions[0][0].answers.includes('email')) {
      this.updateSubQuestionAnswer('namedInsuredContactIncorrect', 0, null, this.updateContactAnswers('email'), true);
    }
  }

  clearEdit(element: string) {
    this.continueDisabled = false;
    if ((element === 'email' && !this.isEmailUpdated) || (element === 'address' && !this.isAddressUpdated)) {
      this.updateSubQuestionAnswer('namedInsuredContactIncorrect', 0, null, this.removeContactAnswer(element), false);
    }
  }

  updateContactAnswers(element: string) {
    let answer: string[];
    if (element === '') {
      answer = [];
    } else {
      answer = [... this.step.question.subQuestions[0][0].answers];
      answer = answer.filter(value => value !== '');
    }
    if (!answer.includes(element)) {
      answer.push(element);
    } else {
      answer = answer.filter(value => value !== element);
    }
    return answer;
  }

  updateAddressQuestion(address: Address): void {
    const logDetails = this.logService.getLogDetails();
    this.continueDisabled = false;
    this.logService.log(CliffLogCodes.ADDR_UPDATE, logDetails);
    if (address && Object.keys(address).length !== 0) {
      this.isAddressUpdated = true;
      this.street1 = address.street1;
      this.street2 = address.street2;
      this.city = address.city;
      this.state = address.state;
      this.postalCode = address.postalCode;
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'country', 'usa', true);
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'street1', this.street1, false);
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'street2', this.street2 ? this.street2 : [], false);
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'city', this.city, false);
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'state', this.state, false);
      this.updateSubQuestionAnswer('namedInsuredAddress', 0, 'postalCode', this.postalCode, false);
    } else {
      this.logService.log(CliffLogCodes.ADDR_UPDATE_FAIL, logDetails);
    }
  }

  updateEmail(emailValue: string): void {
    this.continueDisabled = false;
    if (emailValue !== '') {
      this.email = emailValue;
      this.updateSubQuestionAnswer('namedInsuredEmail', 0, null, emailValue, false);
      this.isEmailUpdated = true;
    }
  }

  answerQuestion(): void {
    if (this.step.question.subQuestions[0][0].answers.length === 0) {
      this.updateSubQuestionAnswer('namedInsuredContactIncorrect', 0, null, this.updateContactAnswers(''), true);
    } else {
      this.updateSubQuestionStep();
    }
  }

  populateAddress(): void {
    this.isAddressUpdated = true;
    this.country = this.step.question.subQuestions[0][1].subQuestions[0][0].answers[0];
    this.street1 = this.step.question.subQuestions[0][1].subQuestions[0][1].answers[0];
    this.street2 = this.step.question.subQuestions[0][1].subQuestions[0][2].answers[0];
    this.city = this.step.question.subQuestions[0][1].subQuestions[0][3].answers[0];
    this.state = this.step.question.subQuestions[0][1].subQuestions[0][4].answers[0];
    this.postalCode = this.step.question.subQuestions[0][1].subQuestions[0][5].answers[0];
  }

  private getKeyValue(attributes: any[], key: string, subKey: string = ''): string {
    let found: any;

    for (const attribute of attributes) {
      if (attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if (subKey && found) {
      for (const foundValue of found.value) {
        if (foundValue.key === subKey) {
          return foundValue.value[0];
        }
      }
    }
    return found ? found.value[0] : '';
  }

}
