import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentDetailsModule as SharedIncidentResourceModule } from '../../incident-details/incident-details.module';
import { HomeandpropertyIncidentTypeComponent } from './homeandproperty-incident-type/homeandproperty-incident-type.component';
import { HomeAndPropertyIncidentDetailsRoutingModule } from './homeandproperty-incident-details-routing.module';
import { IncidentWeatherComponent } from './homeandproperty-incident-type/incident-weather/incident-weather.component';
import { HomeandpropertySeverityComponent } from './homeandproperty-severity/homeandproperty-severity.component';
import { IncidentWaterDamageComponent } from './homeandproperty-incident-type/incident-water-damage/incident-water-damage.component';
import { IncidentFireComponent } from './homeandproperty-incident-type/incident-fire/incident-fire.component';
import { IncidentSepticTankSewerComponent } from './homeandproperty-incident-type/incident-septic-tank-sewer/incident-septic-tank-sewer.component';
import { IncidentSumpPumpFailComponent } from './homeandproperty-incident-type/incident-sump-pump-fail/incident-sump-pump-fail.component';
import { IncidentOtherComponent } from './homeandproperty-incident-type/incident-other/incident-other.component';
import { HomeandpropertyLivableComponent } from './homeandproperty-livable/homeandproperty-livable.component';
import { HomeandpropertyNotLivableDetailsComponent } from './homeandproperty-not-livable-details/homeandproperty-not-livable-details.component';

@NgModule({
  declarations: [
    HomeandpropertyIncidentTypeComponent,
    IncidentWeatherComponent,
    HomeandpropertySeverityComponent,
    IncidentWaterDamageComponent,
    IncidentFireComponent,
    IncidentSepticTankSewerComponent,
    IncidentSumpPumpFailComponent,
    IncidentOtherComponent,
    HomeandpropertyLivableComponent,
    HomeandpropertyNotLivableDetailsComponent,
  ],
  imports: [
    CommonModule,
    HomeAndPropertyIncidentDetailsRoutingModule,
    SharedIncidentResourceModule
  ]
})
export class HomeAndPropertyIncidentDetailsModule { }
