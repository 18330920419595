import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { ErrorHandlingService } from '../../services/helpers/error-handling.service';
import { UserIdleService } from 'projects/lossreporting-app/src/app/user-idle/user-idle.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { CliffLogDetails } from '../../models/cliff-log-details.model';

@Component({
  selector: 'olr-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.css']
})
export class UnauthorizedAccessComponent implements OnInit {
  @ViewChild('errorDialog', {static: false}) errorDialog: ElementRef;

  private proofingUrl: string;
  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private userIdle: UserIdleService,
    private logService: LogService,
    private hostnameBuilderService: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService
  ) { }

  ngOnInit(): void {
    this.proofingUrl = 'https://proofing' + this.hostnameBuilderService.getHostname() + '/login-ui/logout';
    const logDetails = this.logService.getLogDetails();
    this.userIdle.stopWatching();
    this.sfAnalyticsService.sendData('unauthorized-access');
    logDetails.routePath = '/error-pages/unauthorized-access';
    logDetails.serviceName = 'UnauthorizedAccessComponent';
    this.logService.log(CliffLogCodes.UNAUTH, logDetails);
  }

  goToLoginPage(): void {
    const olrUrl = this.errorHandlingService.currentPage;
    const returnUrl = encodeURIComponent(olrUrl);
    const redirectUrl = this.proofingUrl;
    this.window.location.replace(redirectUrl);
  }

}
