import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeandpropertyContactInformationRoutingModule } from './homeandproperty-contact-information-routing.module';
import { ContactInformationModule as SharedContactInformationModule } from '../../contact-information/contact-information.module';
import { CustomerContactInformationComponent } from '../../contact-information/customer-contact-information/customer-contact-information.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HomeandpropertyContactInformationRoutingModule,
    SharedContactInformationModule,
  ]
})
export class HomeandpropertyContactInformationModule { }
