// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-btn-card {
    border: solid 1px #403C3C;
    background-color: #fff;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    padding: 17px 16px;
    border-radius: 8px;
}
.lr-btn-card:hover {
    border: solid 2px #6e6e6e;
    padding: 16px 15px;
}
.lr-btn-card:active {
    border: solid 2px #003a65;
    padding: 16px 15px;
}
.lr-btn-card:focus {
    border: solid 2px #0a70bc;
    padding: 16px 15px;
}
.lr-btn-card:selected {
    border: solid 2px #0a70bc;
    background-color: #edf8fc;
    padding: 16px 15px;
}
.lr-btn-card:disabled {
    border: solid 1px #cbcbcb;
    background-color: #f3f3f3;
}
.lr-btn-card:disabled .lr-btn-heading,
.lr-btn-card:disabled .lr-btn-body {
    color: #cbcbcb;
}

.lr-btn-card:disabled:hover {
    padding: 17px 16px;
}
.lr-btn-heading {
    height: 21px;
    width: 303px;
    color: #2D2929;
    font-family: "MecherleSans-SemiBold";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
}
.lr-btn-body {
    height: 21px;
    color: #2D2929;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}
.lr-btn-answer {
    border: 1px solid #403C3C;
    background-color: #F3F2F2;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/button-card/button-card.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;;IAEI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,oCAAoC;IACpC,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".lr-btn-card {\n    border: solid 1px #403C3C;\n    background-color: #fff;\n    width: 100%;\n    margin-bottom: 10px;\n    text-align: left;\n    padding: 17px 16px;\n    border-radius: 8px;\n}\n.lr-btn-card:hover {\n    border: solid 2px #6e6e6e;\n    padding: 16px 15px;\n}\n.lr-btn-card:active {\n    border: solid 2px #003a65;\n    padding: 16px 15px;\n}\n.lr-btn-card:focus {\n    border: solid 2px #0a70bc;\n    padding: 16px 15px;\n}\n.lr-btn-card:selected {\n    border: solid 2px #0a70bc;\n    background-color: #edf8fc;\n    padding: 16px 15px;\n}\n.lr-btn-card:disabled {\n    border: solid 1px #cbcbcb;\n    background-color: #f3f3f3;\n}\n.lr-btn-card:disabled .lr-btn-heading,\n.lr-btn-card:disabled .lr-btn-body {\n    color: #cbcbcb;\n}\n\n.lr-btn-card:disabled:hover {\n    padding: 17px 16px;\n}\n.lr-btn-heading {\n    height: 21px;\n    width: 303px;\n    color: #2D2929;\n    font-family: \"MecherleSans-SemiBold\";\n    font-size: 16px;\n    letter-spacing: 0;\n    line-height: 21px;\n}\n.lr-btn-body {\n    height: 21px;\n    color: #2D2929;\n    font-size: 14px;\n    letter-spacing: 0;\n    line-height: 21px;\n}\n.lr-btn-answer {\n    border: 1px solid #403C3C;\n    background-color: #F3F2F2;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
