import { Component, OnInit,  ViewChild, ElementRef, Inject } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-vehicle-make-selection',
  templateUrl: './vehicle-make-selection.component.html',
  styleUrls: ['./vehicle-make-selection.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class VehicleMakeSelectionComponent extends BaseComponent implements OnInit {
  @ViewChild('make', {static: true}) make: ElementRef;
  isValueProvided = false;
  isMakeError = false;
  makeQuestion: any;
  makeOptions = [];
  answer = '';

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit(): void {
    this.getAnsweredState();
    this.analytics.sendData('vehicle-make');
    this.getOptions();
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement(document.querySelector('#vehicleMakeDetails'));
    }, 100);
  }

  private getAnsweredState(): void {
    this.answer = this.step.question.answers[0] || '';
    this.isValueProvided = this.answer !== '';
    this.activateFloatingLabel();
  }

  private getOptions() {
    this.makeOptions = this.step.question.allowedValues;
  }
  public makeCheck(){
    this.isValueProvided = this.make.nativeElement.value === '' ? false : true;
  }

  answerQuestion(){
    const logDetails = this.logService.getLogDetails();
    logDetails.vehicleMake = this.make.nativeElement.value;
    this.logService.log(CliffLogCodes.SELECTED_VEH_MAKE, logDetails);
    this.updateAnswer(this.make.nativeElement.value);
  }
}
