import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ExteriorDamageService } from './exterior-damage.service';
import { ExteriorDamageCounter } from './exterior-damage-counter.model';
import { ExteriorDamageOption } from './exterior-damage-option.model';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';

@Component({
  selector: 'olr-exterior-damage',
  templateUrl: './exterior-damage.component.html',
  styleUrls: ['./exterior-damage.component.css', '../../shared/styles/question-common-styling.scss']
})
export class ExteriorDamageComponent implements OnInit {

  @ViewChild('damageOptions', {static: true}) damageOptions: ElementRef;
  damageCounters: ExteriorDamageCounter[];
  activeDamageCounter: ExteriorDamageCounter;
  totalOptionsSelected: number;
  vehicleType: string;
  exteriorDamageVehicleUrls = {
    "suv": "/assets/img/exterior-damage-suv.svg",
    "van": "/assets/img/exterior-damage-van.svg",
    "car": "/assets/img/exterior-damage-car.svg",
    "truck": "/assets/img/exterior-damage-truck.svg",
    "motorcycle": "/assets/img/exterior-damage-motorcycle.svg"
  }
  exteriorDamageUrl: string;


  constructor(
    @Inject('Window') private window: any,
    private exteriorDamageService: ExteriorDamageService,
    private sfAnalyticsService: SfAnalyticsService,
    private policyVehicleInfoService: PolicyVehicleInfoService
    ) { }

  ngOnInit() {
    /* Logic needed here to check if vehicle is a truck or not, and use correct svg image */
    this.vehicleType = this.policyVehicleInfoService.getVehicleType();

    if (this.vehicleType == undefined || this.vehicleType == null) {
      this.policyVehicleInfoService.getSelectedPolicyVehicle();
      this.vehicleType = this.policyVehicleInfoService.getVehicleType();
    }

    if (this.vehicleType === "motorcycle") {
      this.exteriorDamageService.setIsActive(0);
    }

    this.exteriorDamageUrl = this.exteriorDamageVehicleUrls[this.vehicleType] ? this.exteriorDamageVehicleUrls[this.vehicleType] : this.exteriorDamageVehicleUrls["car"];

    this.damageCounters = this.exteriorDamageService.getDamageCounters();
    this.totalOptionsSelected = this.exteriorDamageService.getTotalOptionsSelected();
    this.activeDamageCounter = this.exteriorDamageService.getActiveDamageCounter();

    this.exteriorDamageService.damageCountersUpdated.subscribe( damageCounters => {
      this.damageCounters = damageCounters;
    });
    this.exteriorDamageService.activeDamageCounterUpdated.subscribe( damageCounter => {
      this.sfAnalyticsService.sendData(damageCounter.analyticsTag);
      this.activeDamageCounter = damageCounter;
      setTimeout( () => {
        // @ts-ignore
        this.window.oneX.$('.lr-options-heading').focus();
      }, 0);
    });
    this.exteriorDamageService.optionSelectionMade.subscribe( totalSelected => {
      this.totalOptionsSelected = totalSelected;
    });
  }

  onDamageOptionClick(option: ExteriorDamageOption): void {
    this.exteriorDamageService.updateDamageOption(this.activeDamageCounter.id, option);
    this.activeDamageCounter.isAnimating = true;
    setTimeout( () => {
      this.activeDamageCounter.isAnimating = false;
    }, 80); // CSS transition should match this
  }

  isOptionSelected(value: string): boolean {
    const isSelected = this.exteriorDamageService.selectedOptions.find(s => s.value === value);
    return isSelected !== undefined;
  }

  get vehicleTypeClass(): string {
    switch (this.vehicleType) {
      case "suv": return "suv";
      case "motorcycle": return "motorcycle";
      case "van": return "van";
      default: return "car";
    }
  }

}
