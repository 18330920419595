import { Component, OnInit } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-injury-error',
  templateUrl: './injury-error.component.html',
  styleUrls: ['./injury-error.component.css']
})
export class InjuryErrorComponent implements OnInit {

  constructor(
    private analytics: SfAnalyticsService,
    private logService: LogService,
    public router: Router,
   ) { }


  ngOnInit(): void {
    this.analytics.sendData('injury-error');
  }



}
