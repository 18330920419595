import { Component, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { BaseComponent } from '../../shared/base/base.component';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-modal-no-additional-vehicles',
  templateUrl: './modal-no-additional-vehicles.component.html',
  styleUrls: ['./modal-no-additional-vehicles.component.css']
})
export class ModalNoAdditionalVehiclesComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject('Window') private window: any,
    lossReportService: LossReportService,
    routingService: RoutingService,
    logService: LogService,
    router: Router
    ) {
        super(lossReportService, router, routingService, logService);
    }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-no-additional-vehicles'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-no-additional-vehicles'));
  }

  closeModal(){
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
  }

  onConfirmNoAdditionalVehicles() {
    this.step = this.lossReportService.getStepByRoute('/incident-details/amount-vehicles');
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
    const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.CONFIRM_NO_ADNTL_VEHICLES, logDetails);
    this.updateAnswer('1');
  }

}
