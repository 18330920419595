import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneformat'
})
export class PhoneFormatPipe implements PipeTransform {

  transform(value: string, args: string[]): any {
    if (value === 'anotherNumber') {
      return 'New number';
    }
    if (args && args.includes('parenthesis')){
      return `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }

}
