import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { UserIdleService } from 'projects/lossreporting-app/src/app/user-idle/user-idle.service';
import { ErrorHandlingService } from '../../services/helpers/error-handling.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'session-expired-confirmation',
  templateUrl: './session-expired-confirmation.component.html',
  styleUrls: ['./session-expired-confirmation.component.css', '../../shared/styles/question-common-styling.scss']
})
export class SessionExpiredConfirmationComponent implements OnInit {

  private proofingUrl;
  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private logService: LogService,
    public router: Router,
    private userIdle: UserIdleService,
    private hostnameBuilderService: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.proofingUrl = 'https://proofing' + this.hostnameBuilderService.getHostname() + '/login-interceptor/login';
  }

  ngOnInit(): void {
    this.userIdle.stopWatching();
    this.analytics.sendData('session-expired-confirmation');
    document.getElementById('modal-idle-timeout').style.display = 'none';
  }

  goToLoginPage(): void {
    const olrUrl = this.errorHandlingService.currentPage;
    // Going to leave this commented out until we get session storage working to persist LossReportID
    // const returnUrl = encodeURIComponent(olrUrl);
    const returnUrl = `https://www${this.hostnameBuilderService.getHostname()}/claims`;
    this.window.location.replace(returnUrl);
  }

}
