import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[olrTrimOnFocusOut]' // The selector used to apply the directive in templates
})
export class TrimOnFocusOutDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('focusout')
  onfocusout() {
    const value = this.el.nativeElement.value;
    this.el.nativeElement.value = value.trim();
  }
}