// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-continue {
    margin-top: 35px;
}
.lr-selectallspacing {
    margin-top: 26px;
}
.lr-additionalspacing {
    margin-top: 26px;
    margin-bottom: 26px;
}


.lr-answer-smaller {
    font-size: 12px;
}

.lr-header-rule {
    color: #E4E3E3; 
    height: 1px;
}
.lr-participant-edit, .lr-vehicle-subheader {
    font-size: 14px;
}
.lr-participant-edit-cell {
    text-align: right;
}
.lr-participant-name-cell {
    width: 85%; 
    float: left;
}
.lr-participant-counter {
    color: #767676;
}
.lr-participant-counter-cell {
    width: 5%; 
    float: left;
}
.lr-participant-card {
    width: 100%; 
    overflow: hidden;
}
.lr-link-spacer {
    margin-top:54px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/vehicle-occupancy-insured/vehicle-occupancy-insured.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;AACA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,cAAc;AAClB;AACA;IACI,SAAS;IACT,WAAW;AACf;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB","sourcesContent":[".lr-continue {\n    margin-top: 35px;\n}\n.lr-selectallspacing {\n    margin-top: 26px;\n}\n.lr-additionalspacing {\n    margin-top: 26px;\n    margin-bottom: 26px;\n}\n\n\n.lr-answer-smaller {\n    font-size: 12px;\n}\n\n.lr-header-rule {\n    color: #E4E3E3; \n    height: 1px;\n}\n.lr-participant-edit, .lr-vehicle-subheader {\n    font-size: 14px;\n}\n.lr-participant-edit-cell {\n    text-align: right;\n}\n.lr-participant-name-cell {\n    width: 85%; \n    float: left;\n}\n.lr-participant-counter {\n    color: #767676;\n}\n.lr-participant-counter-cell {\n    width: 5%; \n    float: left;\n}\n.lr-participant-card {\n    width: 100%; \n    overflow: hidden;\n}\n.lr-link-spacer {\n    margin-top:54px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
