import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LossReportService } from '../../../services/loss-report.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LossReportAnswerFinderService } from '../../../services/helpers/loss-report-answer-finder.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../../services/claims-events.service';
@Component({
  selector: 'olr-claimant-review-claim',
  templateUrl: './claimant-review-claim.component.html',
  styleUrls: ['./claimant-review-claim.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantReviewClaimComponent implements OnInit {
  hasSubmitted = false;
  reporter: string;

  get isOtherInsuranceCompany(): boolean {
    return this.reporter === 'otherInsuranceCompany';
  }

  get displayClaimInformation(): boolean {
    const claimNumber = this.findAnswer('otherClaimDetails.claimantClaimNumber');
    const claimHandler = this.findAnswer('otherClaimDetails.claimHandlerName');
    const handlerPhone = this.findAnswer('otherClaimDetails.claimHandlerPhoneNumber');
    const hasClaimInformation = claimNumber || claimHandler || handlerPhone;
    return this.isOtherInsuranceCompany && hasClaimInformation;
  }

  constructor(
    public lossReportService: LossReportService,
    public analyticsService: SfAnalyticsService,
    public answerFinderService: LossReportAnswerFinderService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
  ) {
    this.reporter = this.findAnswer('claimantType');
  }

  ngOnInit(): void {
    this.claimsEventsService.buildEvent('Review Claim');
    this.analyticsService.sendData('review-submit-page');
  }

  findAnswer(question: string){
    return this.answerFinderService.findAnswer(question);
  }

  findDisplayContent(questionName: string, contentKey: string, valueKey: string): string {
    const step = this.lossReportService.getStepByName(questionName);
    const displayContent = step?.question.displayContent;
    const content = displayContent?.filter( (record: any) => record.key === contentKey );
    if(content && content.length !== 0) {
      const found = content[0].value.filter((value: any) => value.key === valueKey);
      return found.length !== 0 ? found[0].value[0] : '';
    }
    return '';
  }

  showVehicleSelection(): boolean {
    const step = this.lossReportService.getStepByName('vehicleSelection');
    if(step && step.question) {
      const hasAnswers = step.question.answers.length > 0;
      const isNotHidden = step.question.hidden !== true;
      return hasAnswers && isNotHidden;
    }
    return false;
  }

  getFormattedDate(): string {
    const dateOfLoss = this.findAnswer('dateOfLoss');
    const formattedDate = moment.utc(dateOfLoss).format('MM/DD/YYYY');
    return formattedDate;
  }

  submitClaim() {
    const logDetails = this.logService.getLogDetails();
    if (!this.hasSubmitted) {
      this.hasSubmitted = true;
      this.lossReportService.submitReport();
      this.lossReportService.lossReportUpdated.subscribe(() => {
        this.logService.log(CliffLogCodes.CLMNT_SBMT_SUC, logDetails);
        this.router.navigate(['/confirmation/received-claimant-claim']);
      });
    }
  }

}
