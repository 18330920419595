// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-modal-content-left {
    text-align: left;
    line-height: 1em;
    padding-bottom: 40px;
}

.olr-modal-content-center {
    text-align: center;
    line-height: 1em;
    padding-bottom: 40px;
}

.olr-modal-edit-spacing {
    margin-top: 40px;
}

.olr-modal-content-padding {
    padding: 32px 0px;
}

.olr-modal-content-padding-auto-fire {
    padding: 60px 30px;
}

.olr-modal {
    display: none; 
    align-content: center;
}

.olr-pnf-modal-line-spacing {
    margin-top: 49px;
    margin-bottom: 78px;
}

.-oneX-btn-fit-content {
    margin: auto;
}

.modal-size {
    width: 26rem !important;
    height: 26rem !important;
}

.modal-align-text {
    margin-left: 16%;
    margin-right: 16%;
    width: -moz-fit-content;
    width: fit-content;
}

.padding-right-0 {
    padding-right: 0px;
}

b {
    color: #D62311;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/modal-policy-not-found/modal-policy-not-found.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".olr-modal-content-left {\n    text-align: left;\n    line-height: 1em;\n    padding-bottom: 40px;\n}\n\n.olr-modal-content-center {\n    text-align: center;\n    line-height: 1em;\n    padding-bottom: 40px;\n}\n\n.olr-modal-edit-spacing {\n    margin-top: 40px;\n}\n\n.olr-modal-content-padding {\n    padding: 32px 0px;\n}\n\n.olr-modal-content-padding-auto-fire {\n    padding: 60px 30px;\n}\n\n.olr-modal {\n    display: none; \n    align-content: center;\n}\n\n.olr-pnf-modal-line-spacing {\n    margin-top: 49px;\n    margin-bottom: 78px;\n}\n\n.-oneX-btn-fit-content {\n    margin: auto;\n}\n\n.modal-size {\n    width: 26rem !important;\n    height: 26rem !important;\n}\n\n.modal-align-text {\n    margin-left: 16%;\n    margin-right: 16%;\n    width: fit-content;\n}\n\n.padding-right-0 {\n    padding-right: 0px;\n}\n\nb {\n    color: #D62311;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
