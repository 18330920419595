import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { UserIdleService } from 'projects/lossreporting-app/src/app/user-idle/user-idle.service';
import { ErrorHandlingService } from '../../services/helpers/error-handling.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { ClaimsEventsService } from '../../services/claims-events.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'olr-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css', '../../shared/styles/question-common-styling.scss']
})
export class SessionExpiredComponent implements OnInit {

  private proofingUrl;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private logService: LogService,
    public router: Router,
    private userIdle: UserIdleService,
    private hostnameBuilderService: HostnameBuilderService,
    private errorHandlingService: ErrorHandlingService,
    private claimsEventsService: ClaimsEventsService,
    private authenticationService: AuthenticationService
  ) {
    this.proofingUrl = 'https://proofing' + this.hostnameBuilderService.getHostname() + '/login-ui/logout';
  }

  ngOnInit(): void {
    this.claimsEventsService.buildEvent('Session Expired');
    this.userIdle.stopWatching();
    this.authenticationService.stopTimer();
    this.analytics.sendData('session-expired', () =>{
      sessionStorage.clear();
    });
  }

  goToLoginPage(): void {
    const olrUrl = this.errorHandlingService.currentPage;
    // Going to leave this commented out until we get session storage working to persist LossReportID
    // const returnUrl = encodeURIComponent(olrUrl);
    const returnUrl = this.proofingUrl;
    this.window.location.replace(returnUrl);
  }

}
