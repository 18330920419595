import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LossReportService } from '../../services/loss-report.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-modal-participant-match-error',
  templateUrl: './modal-participant-match-error.component.html',
  styleUrls: ['./modal-participant-match-error.component.css']
})
export class ModalParticipantMatchErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobileDevice = false;
  isClaimantLossReport = false;
  isOICFlow = false;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
    private lossReportService: LossReportService,
    private logService: LogService
    ) {
      if (this.router.url) {
      this.isClaimantLossReport = this.router.url.includes('/claimant/');
      this.isOICFlow = this.router.url.includes('/client-contact');
      }
    }

  ngOnInit(): void {
    this.isMobileDevice = this.detectMobile();
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-participant-match-error'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-participant-match-error'));
  }

  private detectMobile() {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];

      // @ts-ignore
      return toMatch.some((toMatchItem) => {
          return this.window.navigator.userAgent.match(toMatchItem);
      });
  }

  closeModal(){
    const logDetails = this.logService.getLogDetails();
    // @ts-ignore
    this.window.oneX.Modal.hideModal();

    if (this.isClaimantLossReport){
      const step = this.lossReportService.getStepByName('claimant');

      step.question.subQuestions[0][0].subQuestions[0][0].answers = [];
      step.question.subQuestions[0][0].subQuestions[0][1].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][0].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][1].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][2].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][3].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][4].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][5].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][6].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][7].answers = [];
      step.question.subQuestions[0][1].subQuestions[0][8].answers = [];

      step.completed = false;
      logDetails.currentPage = 'contact-information';

      setTimeout(() => {
        this.isOICFlow ? this.router.navigate(['/claimant/incident-details/client-contact']) : this.router.navigate(['/claimant/incident-details/claimant-contact']);
      }, 100);
    }
  }
}
