export class CIHEvent {
  area: 'CIH';
  number: number;
  trigger: string;
  publisher: string = 'OLR';
  externalClaimId?: string;
  enterprise_interaction_identifier?: string;
  custom: string;
  customs?: Custom;
  constructor(){
    this.externalClaimId = undefined;
    this.enterprise_interaction_identifier = undefined;
    this.customs = new Custom();
  }
}

export class Custom {
  enterpriseInteractionEventText: string;
  enterpriseInteractionIntentName: string;
     producerInteractionTimestamp: string;
     producerInteractionTimezone: string;
     stateFarmUserIdentifier?: string;
     levelOfAssuranceNumber: string = "4";
     claimsRoleName?: string;
     lineOfBusinessName: string;
     constructor(){
      this.claimsRoleName = undefined;
      this.stateFarmUserIdentifier = undefined;
    }
  }
