export class LogDetails {
  clientId?: string;
  devicePlatform?: string; // desktop or mobile
  serviceName?: string;
  httpResponse?: string;
  currentPage?: string;
  routePath?: string;
  flowName?: string;
  claimNum?: string;
  extClaimId?: string;
  submissionStatus?: string;
  policyNumber?: string;
  policyStatus?: string;
  policyType?: string;
  dateOfLoss?: string;
  lastName?: string;
  vehicleMake?: string;
  vehicleType?: string;
  lossReportId?: string;
  serviceErrorCode?: string;
  serviceErrorMessage?: string;
  incidentEnteredLocation?: string;
  googleLocationResponse?: string;
  lossType?: string;
  incidentType?: string;
  participantType?: string;
  claimantReporterType?: string;
  vehicleCondition?: string;
  damagedComponents?: string[];
  damageLocation?: string [];
  vehicleRollOver?: string;
  airbagDeployment?: string;
  numberOfVehicles?: string;
  carSeatInVehicle?: string;
  carSeatStatus?: string;
  entireVehicleFire?: string;
  vehicleRecovered?: string;
  vehicleStolen?: string;
  anyInjuries?: string;
  hitAndRun?: string;
  petInjured?: string;
  policeReportFiled?: string;
  responsibleParty?: string;
  specialtyVehicle?: string;
  vehicleOccupiedDetails?: string;
  parkedVehicleDetails?: string; 
  incidentWitness?: string;
  roadHazards?: string;
  workRelatedUse?: string;
  rideShareScenario?: string;
  purposeOfTrip?: string;
  purposeOfTripWorkRelated?: string;
  rideShareLoginStatus?: string;
  haveClaimantContact?: string;
  claimantRole?: string;
  lossDescription?: string;
  numberOfInsuredParticipants?: string;
  numberOfAdditionalParticipants?: string;
  numberOfClaimantParticipants?: string;
  claimantInjuryStatus?: string;
  claimantLiabilityPercentage?: string;
  claimantPayment?: string;
  claimantLiability?: string;
  consumer?: string;
  orgIds?: string;
  externalOrgId?: string;
  vehicleStorageFee?: string;
  liabilityAgreement?: string;
  constructor() {}
}
