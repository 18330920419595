import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { LogService } from './log.service';
import { Subject, takeUntil } from 'rxjs';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { RepairEligResponse } from '../models/repair.elig.response';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class RepairEligibilityService {

  decisionReceived = new Subject<any>();
  isRepairEligible = false;
  role: string;
  participantId; string;
  isSimpleRepair: boolean;

  constructor(
    private http: HttpClient,
    private hostnameBuilder: HostnameBuilderService,
    private cookieService: CookieHandlerService,
    private logService: LogService,
    private loadingService: LoadingService,

  ) { }

  getRepairEligibility(extClaimId: string, extClientId: string): Subject<any> {
    this.loadingService.startRequest();
    const url = `${this.hostnameBuilder.getRepairEligURL(extClaimId, extClientId)}`;
    const repairEligResponse = new RepairEligResponse;

    let headers;

    if (this.cookieService.getOauthToken()) {
      headers = new HttpHeaders({
        'x-sf_sso_token': this.cookieService.getSSOToken(),
        'CorrelationId': this.logService.getCorrelationId(),
        'Authorization': this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : ''
        });
    } else {
      headers = new HttpHeaders({
        'x-sf_sso_token': this.cookieService.getSSOToken(),
        'CorrelationId': this.logService.getCorrelationId()
        });
    }

    this.http
    .get(url, { headers: headers
    })
    .pipe(takeUntil(this.decisionReceived))
    .subscribe({
      next: response => {
        this.logService.log(CliffLogCodes.REPAIR_ELIG_SUCCESS);
        this.loadingService.completeRequest();
        this.isRepairEligible = !!response['eligibility_decision'];
        this.role = response['role'];
        this.participantId = response['participant_id'];
        // @ts-ignore
        this.decisionReceived.next(response);
      },
      error: _error => {
        this.logService.log(CliffLogCodes.REPAIR_ELIG_FAIL);
        this.loadingService.completeRequest();
        this.decisionReceived.next(_error);
      }

    });
    return this.decisionReceived;
  }

  setIsRepairEligible(val) {
    this.isRepairEligible = val;
  }

  setRole(val) {
    this.role = val;
  }

  setParticipantId(val) {
    this.participantId = val;
  }

  getIsRepairEligible() {
    return this.isRepairEligible;
  }

  getIsSimpleRepair(): boolean{
    return this.isSimpleRepair;
  }

  getRole() {
    return this.role;
  }

  getParticipantId() {
    return this.participantId;
  }

  setSimpleRepair(value: boolean ):void{
      this.isSimpleRepair = value;
  }

}
