import { Component, OnInit } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-claim-already-submitted',
  templateUrl: './claim-already-submitted.component.html',
  styleUrls: ['./claim-already-submitted.component.css']
})
export class ClaimAlreadySubmittedComponent implements OnInit {
  isClaimantLossReport = false;
  isInsuredAutoLossReport = false;

  constructor(
    private analytics: SfAnalyticsService,
    private logService: LogService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    const flowName = this.logService.getFlowName();
    this.isClaimantLossReport = flowName === 'filealoss-claimant' || flowName === 'filealoss-oic';
    this.isInsuredAutoLossReport = flowName === 'filealoss-auto';
    this.analytics.sendData(this.isInsuredAutoLossReport ? 'confirmation-please-wait' : 'claim-already-submitted');
  }

}
