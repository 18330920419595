import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-other-name',
  templateUrl: './other-name.component.html',
  styleUrls: ['./other-name.component.css', '../../shared/styles/question-common-styling.scss']
})
export class OtherNameComponent extends BaseComponent implements OnInit {
  makeModel: string;
  areValuesProvided = false;
  @ViewChild('firstName', {static: true}) firstName: ElementRef;
  @ViewChild('lastName', {static: true}) lastName: ElementRef;
  isFirstNameError = false;
  isLastNameError = false;

  answers: string;
  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private analytics: SfAnalyticsService,
    @Inject('Window') private window: any,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('first-claimant-name-question');
    if (this.step.completed){
     // this.answers = this.step.question.answers[0];
      this.firstName.nativeElement.value = this.step.question.subQuestions[0][0].answers[0];
      this.lastName.nativeElement.value = this.step.question.subQuestions[0][1].answers[0];
      this.toggleContinue();
    }
    // @ts-ignore
    this.window.oneX.FloatingLabel.init();
    this.setVehicleMakeModel();
  }

  setVehicleMakeModel() {
    const vehicleStep = this.lossReportService.getStepByName('firstClaimantVehicle');
    const vehicleSubQuestions = vehicleStep.question.subQuestions[0];
    this.makeModel = `${vehicleSubQuestions[1].answers[0]} ${vehicleSubQuestions[2].answers[0] || ''}`;
  }

  toggleContinue() {
    if (this.firstName.nativeElement.value.length > 0 && this.lastName.nativeElement.value.length > 0) {
      this.areValuesProvided = true;
    } else {
      this.areValuesProvided = false;
    }
  }

  private validateStringAnswers(): boolean {
    const isFirstNameValid = this.regexValidate(
      this.firstName.nativeElement.value, this.step.question.subQuestions[0][1].allowedValuesRegex,
      this.step.question.subQuestions[0][0].minAnswers
      );
    this.isFirstNameError = !isFirstNameValid;
    const isLastNameValid = this.regexValidate(
      this.lastName.nativeElement.value, this.step.question.subQuestions[0][1].allowedValuesRegex,
      this.step.question.subQuestions[0][1].minAnswers
      );
    this.isLastNameError = !isLastNameValid;
    return isFirstNameValid && isLastNameValid;
  }

  answerQuestion(){
    // answer question
    if (this.areValuesProvided && this.validateStringAnswers()){
      this.updateSubQuestionAnswer('firstName', 0, null, this.firstName.nativeElement.value, false);
      this.updateSubQuestionAnswer('lastName', 0, null, this.lastName.nativeElement.value, false);
      this.updateSubQuestionStep();
    }
  }

}
