import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-incident-location',
  templateUrl: './incident-location.component.html',
  styleUrls: ['./incident-location.component.css', '../../shared/styles/question-common-styling.scss']
})
export class IncidentLocationComponent extends BaseComponent implements OnInit {
  policyAddress: string;
  private fp = sessionStorage.getItem('flowPath');
  constructor(
    private customerProxyService: CustomerProxyService,
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.fp === 'filealoss-fire' ? this.claimsEventsService.buildEvent('Capture Loss Details') : '';
    this.analytics.sendData('locverify-question');
    this.customerProxyService.getCustomerInfo();
    this.getPolicyAddress();
    this.highlightButtonStandardAnswer();
  }

  getPolicyAddress(): void {
    this.customerProxyService.customerInfoUpdated.subscribe( response => {
      const address = response.mailingAddress;
      if (address) {
        const street = address.addressLines[0];
        const city = address.city;
        const state = address.stateProvince.replace(/\.+$/, "");
        let postalCode = address.postalCode;
        postalCode = postalCode ? postalCode.substring(0, 5) : '';
        this.policyAddress = `${street}, ${city}, ${state} ${postalCode}` ;
      }
    });
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    this.logService.log(CliffLogCodes.INCIDENT_LOCATION, logDetails);
    this.updateAnswer(answer);
  }

}
