import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdditionalCommentsComponent } from './additional-comments/additional-comments.component';
import { ReviewClaimComponent } from './review-claim/review-claim.component';
import { AuthGuard } from '../services/guards/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: '/review/additional-comments', pathMatch: 'full' },
    { path: 'additional-comments', component: AdditionalCommentsComponent, canActivate: [AuthGuard] },
    { path: 'review-claim', component: ReviewClaimComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ReviewRoutingModule {}
