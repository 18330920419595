// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  border-bottom: none !important;
}

.modal-content {
  border-radius: 0 !important;
}

.modal-phone-red {
  color: #D62311;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/homeandproperty/homeandproperty-incident-details/homeandproperty-flood-policy/homeandproperty-flood-policy.component.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".modal-header {\n  border-bottom: none !important;\n}\n\n.modal-content {\n  border-radius: 0 !important;\n}\n\n.modal-phone-red {\n  color: #D62311;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
