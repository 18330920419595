import { Injectable, Inject } from '@angular/core';
import { LogService } from './log.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';

@Injectable({
  providedIn: 'root'
})
export class MedalliaService {

  constructor(
    @Inject('Window') private window: any,
    private logService: LogService
  ) { }

  updatePageView() {
    setTimeout(() => {
      // @ts-ignore
      const medallia = this.window.KAMPYLE_ONSITE_SDK;
      if (medallia && typeof medallia.updatePageView === 'function' ){
        medallia.updatePageView();
     }
    }, 4000);
  }
}
