import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { LogService } from '../../services/log.service';
import { Router } from '@angular/router';
import { NameComponent } from '../../shared/name/name.component';
import { ContactComponent } from '../../shared/contact/contact.component';
import { Participant } from '../../models/participant.model';
import { Contact } from '../../models/contact.model';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';

@Component({
  selector: 'olr-witness-information',
  templateUrl: './witness-information.component.html',
  styleUrls: ['./witness-information.component.css', '../../shared/styles/question-common-styling.scss']
})
export class WitnessInformationComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(NameComponent) nameComponent: NameComponent;
  @ViewChild(ContactComponent) contactComponent: ContactComponent;
  participants: Participant[] = [];
  currentParticipant: Participant;
  isValueProvided = false;
  isParticipantInfoComplete = false;
  isAnswerSelected = false;
  editMode = false;
  addMode = false;
  updating = false;
  nameObject: any;
  contactObject: any;
  selectedCustomerName = '';
  displayRemainingQuestions = true;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private lossreportanswerfinderservice: LossReportAnswerFinderService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('witness-info');
    this.editWitnessInformation();
  }
  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#witness'));
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#witness'));
  }
  updateName(answer: any): void {
    this.isValueProvided = answer && answer.firstName && answer.lastName;
    this.isParticipantInfoComplete = this.isValueProvided;
    if (this.isValueProvided) {
      this.nameObject = answer;
      this.contactComponent.ngOnInit();
    }
  }

  updateContact(answer: any): void {
    this.isParticipantInfoComplete = this.isValueProvided && answer && answer.country;
    if (this.isParticipantInfoComplete) {
      this.contactObject = answer;
    }
  }

  createParticipant(): void {
    this.answerQuestion();
  }

  unknownWitness(): void {
    this.nameObject = null;
    this.answerQuestion();
  }

  answerQuestion(){
    const answers = [];
    const additionalParticipants = [];
    additionalParticipants.push(
    this.createAnsweredQuestion('7032C', 'witnessInformationName', null, this.answerNameSubQuestions(this.nameObject)));
    additionalParticipants.push(
    this.createAnsweredQuestion('7032D', 'witnessContact', null, this.answerContactSubQuestions(this.contactObject)));
    answers.push(additionalParticipants);
    this.updateSubQuestions(answers);
  }

  private answerNameSubQuestions(nameAnswers: any){
    const nameSteps = [];
    nameSteps.push(this.createAnsweredQuestion('1011witness', 'firstName', nameAnswers?.firstName || [] ));
    nameSteps.push(this.createAnsweredQuestion('1012witness', 'lastName', nameAnswers?.lastName || 'unknown' ));
    return nameSteps;
  }

  private answerContactSubQuestions(contactAnswers: Contact) {
    const contactSteps = [];
    contactSteps.push(this.createAnsweredQuestion('3037', 'country', contactAnswers ? contactAnswers.country : null || 'usa'));
    contactSteps.push(this.createAnsweredQuestion('1030NR', 'street1', contactAnswers ? contactAnswers.street1 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1031', 'street2', contactAnswers ? contactAnswers.street2 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1032NR', 'city', contactAnswers ? contactAnswers.city : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1033NC', 'state', contactAnswers ? contactAnswers.state : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1037NC', 'postalCode', contactAnswers ? contactAnswers.zipCode : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1040NR', 'phoneType', contactAnswers ? contactAnswers.phoneType : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1041NR', 'phoneNumber', contactAnswers ? contactAnswers.phoneNumber : null || [] ));
    return contactSteps;
  }

  findAnswer(question: string){
    return this.lossreportanswerfinderservice.findAnswer(question);
  }

  editWitnessInformation() {
    const witnessInformation = this.findAnswer('witnessInfo');

    setTimeout(() => {
      const nameUnknown = witnessInformation.witnessInformationName.firstName.concat(witnessInformation.witnessInformationName.lastName) === 'unknown';

      if (!nameUnknown) {
        const name = {
          first: witnessInformation.witnessInformationName.firstName,
          last: witnessInformation.witnessInformationName.lastName
        };
        const contact: Contact = {
          ...witnessInformation.witnessContact,
          zipCode: witnessInformation.witnessContact.postalCode
        };

        this.nameComponent.setAnswerState(name);
        this.contactComponent.setAnswerState(contact);

      }
      this.window.oneX.addElement(document.querySelector('#witness'));
    }, 100);
  }
}
