import { Component, OnInit, ViewChild } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { ContactComponent } from '../../../shared/contact/contact.component';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { Contact } from '../../../models/contact.model';
import { ClaimsEventsService } from '../../../services/claims-events.service';

@Component({
  selector: 'olr-claimant-contact',
  templateUrl: './claimant-contact.component.html',
  styleUrls: ['./claimant-contact.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantContactComponent extends BaseComponent implements OnInit {

  @ViewChild(ContactComponent) contactComponent: ContactComponent;
  isValueProvided = false;
  contactObject: any;
  isBusiness = false;
  isClaimant = false;
  isClient = false;
  isEmailOptional = false;
  isPhoneOptional = false;
  isTypeOptional = false;
  labelText: any;
  stepInfo: any;

  private claimantStepInfo = {
    question2: {id: '7023IND', name: 'claimantContact'},
    country: {id: '3037', name: 'country'},
    phoneType: {id: '1040', name: 'phoneType'},
    phoneNumber: {id: '1041', name: 'phoneNumber'},
    email: {id: '1042', name: 'email'}
  };

  private businessStepInfo = {
    question2: {id: '7004', name: 'organizationContact'},
    country: {id: '1013', name: 'country'},
    phoneType: {id: '7006', name: 'businessPhoneType'},
    phoneNumber: {id: '3049A', name: 'businessPhoneNumber'},
    email: {id: '7007', name: 'otherInsuranceEmail'}
  };

  private clientStepInfo = {
    question2: {id: '7023OIC', name: 'claimantContact'},
    country: {id: '3037', name: 'country'},
    phoneType: {id: '1040NR', name: 'phoneType'},
    phoneNumber: {id: '1041NR', name: 'phoneNumber'},
    email: {id: '1042NR', name: 'email'}
  };

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
  ) {
      super(lossReportService, router, routingService, logService);
      this.setupContactView();
  }

  ngOnInit(): void {
    this.claimsEventsService.buildEvent('Contact Preference');
    this.analytics.sendData(this.isBusiness ? 'oic-contact-information' : 'contact-information');
    this.setAnsweredState();
  }

  private setupContactView(): void {
    const url = this.router.url;
    if (url && url.includes('oic-contact')) {
      this.isBusiness = true;
      this.labelText = { title: 'What is your company\'s information?' };
      this.stepInfo = this.businessStepInfo;
    } else if (url && url.includes('client-contact')) {
      this.isClaimant = true;
      this.isClient = true;
      this.isPhoneOptional = true;
      this.isEmailOptional = true;
      this.isTypeOptional = true;
      this.labelText = { title: 'What is your client\'s contact information?' };
      this.stepInfo = this.clientStepInfo;
    } else {
      this.isClaimant = true;
      this.labelText = { title: 'What is your contact information?' };
      this.stepInfo = this.claimantStepInfo;
    }
  }

  private setAnsweredState(): void {
    if (this.step.completed) {
      setTimeout(() => {
          this.contactComponent.setAnswerState(this.grabAnswers());
      }, 100);
    }
  }

  private grabAnswers(): Contact {
    const subQuestions = this.step.question.subQuestions[0];
    const contact = new Contact();
    if (this.isBusiness) {
      contact.organization = subQuestions[0].answers[0];
    } else {
      const nameInfo = subQuestions[0].subQuestions[0];
      contact.firstName = nameInfo[0].answers[0];
      contact.lastName = nameInfo[1].answers[0];
    }
    const contactInfo = subQuestions[1].subQuestions[0];
    contact.country = contactInfo[0].answers[0];
    contact.street1 = contactInfo[1].answers[0];
    contact.street2 = contactInfo[2].answers[0] || '';
    contact.city = contactInfo[3].answers[0];
    contact.state = contactInfo[4].answers[0];
    contact.zipCode = contactInfo[5].answers[0];
    contact.phoneType = contactInfo[6].answers[0];
    contact.phoneNumber = contactInfo[7].answers[0];
    contact.email = contactInfo[8].answers[0];
    return contact;
  }

  updateContact(answer: any): void {
    this.isValueProvided = answer && answer.country;
    if (this.isValueProvided) {
      this.contactObject = answer;
    }
  }

  answerQuestion() {
    const answers = [];
    const contactInfo = [];
    if (this.isBusiness) {
      contactInfo.push(this.createAnsweredQuestion('7003', 'organizationName', this.contactObject.organization));
    } else {
      contactInfo.push(this.createAnsweredQuestion('1010E', 'claimantName', null, this.answerNameSubQuestions(this.contactObject)));
    }
    contactInfo.push(this.createAnsweredQuestion(
      this.stepInfo.question2.id, this.stepInfo.question2.name, null, this.answerContactSubQuestions(this.contactObject)));
    answers.push(contactInfo);
    this.updateSubQuestions(answers);
  }

  private answerNameSubQuestions(contactAnswers: Contact) {
    const nameSteps = [];
    nameSteps.push(this.createAnsweredQuestion('1011', 'firstName', contactAnswers.firstName ));
    nameSteps.push(this.createAnsweredQuestion('1012', 'lastName', contactAnswers.lastName ));
    return nameSteps;
  }

  private answerContactSubQuestions(contactAnswers: Contact) {
    const contactSteps = [];
    contactSteps.push(this.createAnsweredQuestion(this.stepInfo.country.id, this.stepInfo.country.name, contactAnswers.country ));
    contactSteps.push(this.createAnsweredQuestion('1030NCR', 'street1', contactAnswers.street1 ));
    contactSteps.push(this.createAnsweredQuestion('1031', 'street2', contactAnswers.street2 ));
    contactSteps.push(this.createAnsweredQuestion('1032NCR', 'city', contactAnswers.city ));
    contactSteps.push(this.createAnsweredQuestion('1033NAVR', 'state', contactAnswers.state ));
    contactSteps.push(this.createAnsweredQuestion('1037NCR', 'postalCode', contactAnswers.zipCode ));
    contactSteps.push(this.createAnsweredQuestion(this.stepInfo.phoneType.id, this.stepInfo.phoneType.name, contactAnswers.phoneType ));
    contactSteps.push(this.createAnsweredQuestion(
      this.stepInfo.phoneNumber.id, this.stepInfo.phoneNumber.name, contactAnswers.phoneNumber ));
    contactSteps.push(this.createAnsweredQuestion(this.stepInfo.email.id, this.stepInfo.email.name, contactAnswers.email ));
    return contactSteps;
  }
}
