// @dynamic
export class AddressConstants {
    public static get countryStates(): any[]
    {
        return [
            {id: 'usa', name: 'United States',
              states: [
                {state: 'AL', name: 'Alabama'},
                {state: 'AK', name: 'Alaska'},
                {state: 'AR', name: 'Arkansas'},
                {state: 'AZ', name: 'Arizona'},
                {state: 'CA', name: 'California'},
                {state: 'CO', name: 'Colorado'},
                {state: 'CT', name: 'Connecticut'},
                {state: 'CO', name: 'Colorado'},
                {state: 'DC', name: 'District of Columbia'},
                {state: 'DE', name: 'Delaware'},
                {state: 'FL', name: 'Florida'},
                {state: 'GA', name: 'Georgia'},
                {state: 'HI', name: 'Hawaii'},
                {state: 'IA', name: 'Iowa'},
                {state: 'ID', name: 'Idaho'},
                {state: 'IL', name: 'Illinois'},
                {state: 'IN', name: 'Indiana'},
                {state: 'KS', name: 'Kansas'},
                {state: 'KY', name: 'Kentucky'},
                {state: 'LA', name: 'Louisiana'},
                {state: 'MA', name: 'Massachusetts'},
                {state: 'MD', name: 'Maryland'},
                {state: 'ME', name: 'Maine'},
                {state: 'MI', name: 'Michigan'},
                {state: 'MN', name: 'Minnesota'},
                {state: 'MO', name: 'Missouri'},
                {state: 'MS', name: 'Mississippi'},
                {state: 'MT', name: 'Montana'},
                {state: 'NC', name: 'North Carolina'},
                {state: 'ND', name: 'North Dakota'},
                {state: 'NE', name: 'Nebraska'},
                {state: 'NH', name: 'New Hampshire'},
                {state: 'NJ', name: 'New Jersey'},
                {state: 'NM', name: 'New Mexico'},
                {state: 'NV', name: 'Nevada'},
                {state: 'NY', name: 'New York'},
                {state: 'OH', name: 'Ohio'},
                {state: 'OK', name: 'Oklahoma'},
                {state: 'OR', name: 'Oregon'},
                {state: 'PA', name: 'Pennsylvania'},
                {state: 'RI', name: 'Rhode Island'},
                {state: 'SC', name: 'South Carolina'},
                {state: 'SD', name: 'South Dakota'},
                {state: 'TN', name: 'Tennessee'},
                {state: 'TX', name: 'Texas'},
                {state: 'UT', name: 'Utah'},
                {state: 'VA', name: 'Virginia'},
                {state: 'VT', name: 'Vermont'},
                {state: 'WA', name: 'Washington'},
                {state: 'WI', name: 'Wisconsin'},
                {state: 'WV', name: 'West Virginia'},
                {state: 'WY', name: 'Wyoming'}
              ]
            },
            {id: 'canada', name: 'Canada',
              states: [
                {state: 'AB', name: 'Alberta'},
                {state: 'BC', name: 'British Columbia'},
                {state: 'MB', name: 'Manitoba'},
                {state: 'NB', name: 'New Brunswick'},
                {state: 'NL', name: 'British Columbia'},
                {state: 'NS', name: 'Nova Scotia'},
                {state: 'NT', name: 'Northwest Territory'},
                {state: 'NU', name: 'Nunavut'},
                {state: 'ON', name: 'Ontario'},
                {state: 'PE', name: 'Prince Edward Island'},
                {state: 'QC', name: 'Quebec'},
                {state: 'SK', name: 'Saskatchewan'},
                {state: 'YT', name: 'Yukon Territory'}
              ]
            },
            {id: 'usTerritories', name: 'U.S. Territories',
              states: [
                {state: 'AA', name: 'Military units outside US and Canada'},
                {state: 'AE', name: 'BMilitary units in Europe Mideast Africa and Canada'},
                {state: 'AP', name: 'Military units in Pacific'},
                {state: 'AS', name: 'American Samoa'},
                {state: 'CZ', name: 'Panama Canal Zone'},
                {state: 'FM', name: 'Federated States of Micronesia'},
                {state: 'GU', name: 'Guam'},
                {state: 'MH', name: 'Marshall Islands'},
                {state: 'MP', name: 'Northern Mariana Islands'},
                {state: 'PR', name: 'Puerto Rico'},
                {state: 'PW', name: 'Palau'},
                {state: 'VI', name: 'Virgin Islands'}
              ]
            },
            {id: 'mexico', name: 'Mexico',
              states: [
                {state: 'AG', name: 'Aguascalientes'},
                {state: 'BC', name: 'Baja California Norte'},
                {state: 'BS', name: 'Baja California Sur'},
                {state: 'CH', name: 'Chihuahua'},
                {state: 'CL', name: 'Colima'},
                {state: 'CM', name: 'Campeche'},
                {state: 'CO', name: 'Coahuila'},
                {state: 'CS', name: 'Chiapas'},
                {state: 'DF', name: 'Distrito Federal'},
                {state: 'DG', name: 'Durango'},
                {state: 'EM', name: 'Mexico'},
                {state: 'GR', name: 'Guerrero'},
                {state: 'GT', name: 'Guanajuato'},
                {state: 'HG', name: 'Hidalgo'},
                {state: 'JA', name: 'Jalisco'},
                {state: 'MI', name: 'Michoacan'},
                {state: 'MO', name: 'Morelos'},
                {state: 'NA', name: 'Nayarit'},
                {state: 'NL', name: 'Nuevo Leon'},
                {state: 'OA', name: 'Oaxaca'},
                {state: 'PU', name: 'Puebla'},
                {state: 'QR', name: 'Quintana Roo'},
                {state: 'QT', name: 'Queretaro'},
                {state: 'SI', name: 'Sinaloa'},
                {state: 'SL', name: 'San Luis Potosi'},
                {state: 'SO', name: 'Sonora'},
                {state: 'TB', name: 'Tabasco'},
                {state: 'TL', name: 'Tlaxcala'},
                {state: 'TM', name: 'Tamaulipas'},
                {state: 'VE', name: 'Veracruz'},
                {state: 'YU', name: 'Yucatan'},
                {state: 'ZA', name: 'Zacatecas'}
              ]
            },
              ];
     }
}

