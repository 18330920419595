import { Injectable } from '@angular/core';
import { LossReportService } from '../loss-report.service';

@Injectable({
  providedIn: 'root'
})
export class LossReportAnswerFinderService {
  constructor(
    public lossReportService: LossReportService,
  ) { }

   private getAnswerIndex(questionName) {
    const index = questionName.lastIndexOf('.');
    if (index > 0) {
      const segment = questionName.substring(index + 1);
      if (isNaN(segment)) {
        return undefined;
      }
      return parseInt(segment, 10);
    }
    return undefined;
  }

  findAnswer(questionName) {
    const question = this.lossReportQuestionFinder([this.lossReportService.lossReport, questionName]);
    return this.getAnswers([question, this.getAnswerIndex(questionName)]);
  }

  private findStep(steps, questionName) {
    return steps.find((step) => {
      return step.question.name === questionName;
    });
  }

   private extractFirstSegment(questionName) {
    const index = questionName.indexOf('.');
    if (index > 0) {
      return [questionName.substring(0, index), questionName.substring(index + 1)];
    }
    return [questionName];
  }

   private findSubQuestion(question, questionName) {
    let [firstSegment, remainder] = this.extractFirstSegment(questionName);
    let index = parseInt(firstSegment, 10);
    if (isNaN(index)) {
      index = 0;
    } else {
      if (remainder === undefined) {
        // if the question name passed in was just an index, then return the question that was passed in
        return question;
      }
      // the first segment was an index, so get the next segment which should be a question name
      [firstSegment, remainder] = this.extractFirstSegment(remainder);
    }
    if (index >= question.subQuestions.length) {
      return undefined;
    }
    const foundQuestion = question.subQuestions[index].find((q) => {
      return q.name === firstSegment;
    });
    if (foundQuestion === undefined) {
      return undefined;
    }
    if (remainder !== undefined) {
      return this.findSubQuestion(foundQuestion, remainder);
    }
    return foundQuestion;
  }

  private lossReportQuestionFinder([lrResponse, questionName]) {
    if (!lrResponse) { return undefined; }
    const [stepName, remainder] = this.extractFirstSegment(questionName);
    const step = this.findStep(lrResponse.steps, stepName);
    if (step === undefined) {
      return undefined;
    }
    if (remainder === undefined) {
      return step.question;
    }
    return this.findSubQuestion(step.question, remainder);
  }

  private getAnswers([question, index = null]) {
    if (!question) { return ''; }

    if (question.answers) {
      if (index === undefined || index === null) {
        return question.maxAnswers > 1 ? question.answers : question.answers[0] || '';
      }
      return question.answers[index] || '';
    } else {
      if (index === undefined || index === null) {
        if (question.maxAnswers > 1) {
          const answers = [];
          question.subQuestions.forEach((_, i) => {
            answers.push(this.getAnswers([question, i]));
          });
          return answers;
        }
        index = 0;
      }
      const answer = {};
      question.subQuestions[index].forEach((subQuestion) => {
        answer[subQuestion.name] = this.getAnswers([subQuestion]);
      });
      return answer;
    }
  }


}
