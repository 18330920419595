import { Injectable } from '@angular/core';
import { Participant } from '../models/participant.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimantParticipantTrackingService {
  OPTIONS_WITH_DRIVER = ['owner', 'driverOwner', 'driver', 'passengerOwner', 'passenger', 'notSure', 'other'];
  OPTIONS_WITHOUT_DRIVER = ['owner', 'passengerOwner', 'passenger', 'notSure', 'other'];

  constructor() { }

  getClaimantParticipants(listName: string): Participant[] {
    return JSON.parse(sessionStorage.getItem(listName)) || [];
  }

  getSelectableOptions(listName: string): string[] {
    const participants = this.getClaimantParticipants(listName);
    return participants.some(participant => this.isDriverFound(participant.role))
    ? this.OPTIONS_WITHOUT_DRIVER : this.OPTIONS_WITH_DRIVER;
  }

  refreshParticipantsList(participants: Participant[], listName: string): void {
    if (participants.some(participant => this.isDriverFound(participant.role))){
        participants.forEach(participant => {
          participant.options = this.isDriverFound(participant.role) ? this.OPTIONS_WITH_DRIVER : this.OPTIONS_WITHOUT_DRIVER;
        });
    } else {
        participants.forEach(participant => {
          participant.options = this.OPTIONS_WITH_DRIVER;
        });
    }
    sessionStorage.setItem(listName, JSON.stringify(participants));
  }

  private isDriverFound(role: string): boolean {
    return role === 'driver' || role === 'driverOwner';
  }

}
