import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'olr-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.css']
})
export class DateTimeSelectorComponent implements OnInit, OnDestroy {

  @Input() answer: string;
  @Input() error: boolean;
  @Output() setIncidentDateTime = new EventEmitter<any>();
  @ViewChild('date', { static: false }) date: ElementRef;
  @ViewChild('time', { static: false }) time: ElementRef;

  private dateFormatString = 'MM-DD-YYYYTHH:mm:A';
  selectedDateTime: string = '';
  dateRequiredError = false;
  futureError: boolean;
  timeError: boolean;
  yearError: boolean;
  isDateProvided = false;
  timeOpen = false;
  incidentDate: any = '';
  inputDate: string = '';
  inputTime: string = '';
  inputError: boolean;
  isDateAnsInit: boolean;
  isTimeAnsInit: boolean;

  constructor(
    @Inject('Window') private window: Window,
  ) { }

  ngOnInit(): void {
    this.configureCalendar();
  }

  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#calendar-input-group'));
    this.window['oneX'].removeElement(document.querySelector('#time-input-group'));
  }

  private configureCalendar() {
    this.registerOneXElement('#calendar-input-group');
    setTimeout(() => {
      const calendar = this.window['oneX'].Datepicker.calendars['incident-calendar'];
      calendar.setMax(new Date(), true);
      calendar.refresh();
      if(this.answer) {
        this.isDateAnsInit = true;
        const dateAnswer = moment.utc(this.answer).toISOString();
        const dateTimeParts = dateAnswer.split('T');
        const dateParts = dateTimeParts[0].split('-');
        this.window['oneX'].$('#incident-calendar').val(`${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`).focus();
        const timeAns = this.getAnsweredTime(dateTimeParts[1]);
        if(timeAns) {
          this.isTimeAnsInit = true;
          this.toggleTimeOpen();
          setTimeout(()=>{
            this.window['oneX'].$('#incident-time').val(timeAns);
          },2);
        }
      } else {
        this.window['oneX'].$('#incident-calendar').val('').focus();
      }
    }, 2);
  }

  private getAnsweredTime(timeSlice: string): string {
    if(timeSlice === '00:00:00.000Z') {
      return '';
    }
    const timeParts = timeSlice.split(':');
    return `${this.getConvertedTime(timeParts[0])}:${this.getConvertedTime(timeParts[1], true)} ${this.getMeridiem(timeParts[0])}`;
  }

  private getConvertedTime(timeValue: string, isMinute:boolean = false): string {
    const value = this.parseStringToInt(timeValue);
    if(value === 0) { return isMinute ? '00' : '12'; }
    if(value < 10) { return `0${value}`; }
    if(value > 9 && value < 13) { return `${value}`; }
    if(isMinute) { return `${value}`; }
    const convert24 = {13:'01',14:'02',15:'03',16:'04',17:'05',18:'06',19:'07',20:'08',21:'09',22:'10',23:'11'};
    return `${convert24[value]}`;
  }

  private getMeridiem(timeHour: string): string {
    const hour = this.parseStringToInt(timeHour);
    return hour < 12 ? 'AM' : 'PM';
  }

  private parseStringToInt(value: string): number {
    const parsed = parseInt(value);
    return Number.isNaN(parsed) ? 0 : parsed;
  }

  toggleTimeOpen() {
    this.timeOpen = !this.timeOpen;
    if(this.timeOpen) {
      this.registerOneXElement('#time-input-group');
    }
  }

  private registerOneXElement(selector: string) {
    setTimeout(()=>{
      this.window['oneX'].addElement(document.querySelector(selector));
    }, 1);
  }
  
  setSelectedDate(event: any) {
    this.error = false;
    this.yearError = false;
    this.futureError = false;
    this.dateRequiredError = false;
    const dateInput = event.target.value;
    if (dateInput.length === 10) {
      if(this.validateYear(dateInput.split("-")[2])){
      this.inputDate = dateInput;
      this.setSelectedDateTime(this.isDateAnsInit);
      this.isDateAnsInit = false;
      }
      else{
        this.error = true;
      }
    }

  }

  setSelectedTime(event: any) {
    this.timeError = false;
    const timeInput = event.target.value;
    if (timeInput.length === 8 && this.validateTime(timeInput)) {
      this.inputTime = timeInput.replace(' ', ':');
      this.setSelectedDateTime(this.isTimeAnsInit);
      this.isTimeAnsInit = false;
    }
  }

  private validateTime(time: string): boolean {
    const validTimeRegex = /(0[1-9]|1[0-2]):([0-5][0-9])\s(AM|PM|am|pm){1}/;
    const isValid = validTimeRegex.test(time);
    this.timeError = !isValid;
    return isValid;
  }

  private validateYear(year: string): boolean {
    const validYearRegex = /^(19|20)\d{2}$/;
    const isValid = validYearRegex.test(year);
    this.yearError = !isValid;
    return isValid;
  }

  private setSelectedDateTime(ansInitialized: boolean): void {
    this.selectedDateTime = this.validateDateTimeNotFuture();
    
    if(this.selectedDateTime) {
      this.setIncidentDateTime.emit({ response: this.selectedDateTime, isAnsInit: ansInitialized });
    }
  }

  setInputError(status: boolean): void {
    this.error = status;
    if (status) {
      this.date.nativeElement.focus();
    }
  }

  validateDateTimeNotFuture(): any {
    const dateTime = this.getCombinedDateTime();
    if (dateTime) {
      this.incidentDate = `${dateTime.date}T${dateTime.time.toUpperCase()}`;
      if (this.validateDate(this.incidentDate)) {
        return moment.utc(this.incidentDate, this.dateFormatString).toISOString();
      } else {
        return false;
      }
    }
    return false;
  }

  getCombinedDateTime() {
    if(!this.inputDate) {
      this.dateRequiredError = true;
      return false
    }
    return {
      date: this.inputDate,
      time: this.inputTime || '12:00:AM',
    };
  }

  private validateDate(event: any): boolean {
    if (this.dateIsInvalid(event)) {
      this.isDateProvided = false;
      return false;
    }
    if (this.dateIsFuture(event)) {
      return false;
    }
    this.isDateProvided = true;
    return true;
  }

  private dateIsInvalid(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isValid()) {
      this.error = false;
      return false;
    }
    this.error = true;
    this.futureError = false;
    return true;
  }

  private dateIsFuture(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isBefore(moment.now())) {
      this.futureError = false;
      return false;
    }
    this.futureError = true;
    return true;
  }
}
