import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from 'projects/lossreporting-library/src/lib/services/log.service';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { RoutingService } from 'projects/lossreporting-library/src/lib/services/routing.service';
import { SfAnalyticsService } from 'projects/lossreporting-library/src/lib/services/sf-analytics.service';
import { BaseComponent } from 'projects/lossreporting-library/src/lib/shared/base/base.component';

@Component({
  selector: 'olr-homeandproperty-not-livable-details',
  templateUrl: './homeandproperty-not-livable-details.component.html',
  styleUrls: ['./homeandproperty-not-livable-details.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class HomeandpropertyNotLivableDetailsComponent extends BaseComponent implements OnInit {

  selectedValues: string[] = [];

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('property-not-livable-conditions');
    this.highlightCheckboxStandardAnswer();
  }

  onCheckBoxChange(e: Event, value: string): void {
    const checkbox = e.target as HTMLInputElement;

    if (checkbox.checked) {
      this.selectedValues.push(value);
    }
    else {
      const index = this.selectedValues.indexOf(value);
      if (index !== -1) {
        this.selectedValues.splice(index, 1);
      }
    }
  }

  answerQuestion(): void {
    this.updateAnswer(this.selectedValues);
  }

  highlightCheckboxStandardAnswer(){
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      this.selectedValues = [];
      answers.forEach((answer: any) => {
        const checkboxElement = document.querySelector(`#${answer}`);
        checkboxElement.setAttribute('checked', 'true');
        this.selectedValues.push(answer);
      });
    }
  }

}
