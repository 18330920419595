import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CliffLogDetails } from '../models/cliff-log-details.model';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { v1 as uuidv1 } from 'uuid';
import { LogDetails } from '../models/log-details.model';
import { SFLogger, KEYS } from 'sf-logger-javascript';

let correlationId = sessionStorage.getItem('CORRELATION_ID');
if (!correlationId) {
  correlationId = uuidv1();
  sessionStorage.setItem('CORRELATION_ID', correlationId);
}
@Injectable({
  providedIn: 'root',
})
export class LogService {
  correlationId: string;
  traceLogId: string;
  response: HttpResponse<string>;
  externalClientId: string;
  cliffLog: CliffLogDetails;
  logDetails: LogDetails;

  loggerConfig = {
    sparkl: {
      env: `${this.getEnvironment()}`, // test or prod
      endpointType: 'public',
    },
    httpLoggingEnabled: true,  // Enables log to be sent to splunk
    consoleLoggingEnabled: true, // Used for debugging (keep false in master)
    includeReportingLocation: false, // Was broken in safari(keep false)
  };
  
  logger = SFLogger(
    this.loggerConfig,
    KEYS.APPLICATION_ID,
    'File-A-Loss', //app ID used
    KEYS.CORRELATION_ID,
    correlationId
  );

  getEnvironment():string {
   const sparklObject = this.hostnameBuilderService.getSparklEnv();
   if (sparklObject) {
      return sparklObject.env
   }
   return ""
  }

  constructor(private http: HttpClient, private hostnameBuilderService: HostnameBuilderService) {
    this.logDetails = new LogDetails();
  }

  getLogDetails(): LogDetails {
    return this.logDetails;
  }

  setExternalClientId(extClientId: string): void {
    this.externalClientId = extClientId;
  }

  setClaimNumber(claimNumber: string): void {
    sessionStorage.setItem('claimNumber', claimNumber);
  }

  getClaimNumber(): string {
    return sessionStorage.getItem('claimNumber');
  }

  setClaimId(claimId: string): void {
    sessionStorage.setItem('claimId', claimId);
  }

  getClaimId(): string {
    return sessionStorage.getItem('claimId');
  }

  setFlowName(flowName: string): void {
    sessionStorage.setItem('flowPath', flowName);
  }

  getFlowName(): string {
    return sessionStorage.getItem('flowPath') || 'filealoss';
  }

  setEntryPoint(entryPoint: string): void {
    sessionStorage.setItem('entryPoint', entryPoint);
  }

  getEntryPoint(): string {
    return sessionStorage.getItem('entryPoint') || 'landing';
  }

  setOrigin(origin: string): void {
    sessionStorage.setItem('origin', origin);
  }

  getOrigin(): string {
    return sessionStorage.getItem('origin') || 'browser';
  }

  setOrgIndicator(type: string): void {
    if (type && type !== 'personal') {
      sessionStorage.setItem('orgIndicator', type);
    } else {
      sessionStorage.removeItem('orgIndicator');
    }
  }

  getOrgIndicator(): string {
    return sessionStorage.getItem('orgIndicator') || '';
  }

  getCorrelationId() {
    if (!this.correlationId) {
      this.correlationId = uuidv1();
    }
    return this.correlationId;
  }

  sendSparklLog(sparklLog: CliffLogDetails) {

    this.logger.setAuthToken(`${this.hostnameBuilderService.getSparklEnv().apiKey}`);
    sparklLog.clientOrPartyId = this.externalClientId;
    sparklLog.logData.flowName = this.getFlowName();
    sparklLog.logData.consumer = this.getOrigin();
    sparklLog.logData.orgIds = this.getOrgIndicator();
    sparklLog.claimId = this.getClaimId();
    sparklLog.claimNum = this.getClaimNumber();
    sparklLog.entryPoint = this.getEntryPoint();

    this.logger.info(
      sparklLog.messageId, 
      sparklLog.message, 
      'externalClientId', 
      sparklLog.clientOrPartyId ? sparklLog.clientOrPartyId : undefined, 
      'flowName',
      sparklLog.logData.flowName ? sparklLog.logData.flowName : undefined,
      'consumer',
      sparklLog.logData.consumer ? sparklLog.logData.consumer : undefined,
      'orgIds',
      sparklLog.logData.orgIds ? sparklLog.logData.orgIds : undefined,
      'claimId',
      sparklLog.claimId ? sparklLog.claimId : undefined,
      'claimNum',
      sparklLog.claimNum ? sparklLog.claimNum : undefined,
      'entryPoint',
      sparklLog.entryPoint ? sparklLog.entryPoint : undefined,
      'applicationId',
      sparklLog.logLevel ? sparklLog.applicationId : undefined,
      'logLevel',
      sparklLog.logLevel ? sparklLog.logLevel : undefined,
      'httpResponse',
      sparklLog.httpResponse ? sparklLog.httpResponse : undefined,
      'participantId',
      sparklLog.participantId ? sparklLog.participantId : undefined,
      'logData',
      sparklLog.logData ? sparklLog.logData : undefined,
      'clientId',
      sparklLog.logData.clientId ? sparklLog.logData.clientId : undefined,
      'devicePlatform',
      sparklLog.logData.devicePlatform ? sparklLog.logData.devicePlatform : undefined,
      'serviceName',
      sparklLog.logData.serviceName ? sparklLog.logData.serviceName : undefined,
      'httpResponse',
      sparklLog.logData.httpResponse ? sparklLog.logData.httpResponse : undefined,
      'currentPage',
      sparklLog.logData.currentPage ? sparklLog.logData.currentPage : undefined,
      'routePath',
      sparklLog.logData.routePath ? sparklLog.logData.routePath : undefined,
      'claimNum',
      sparklLog.logData.claimNum ? sparklLog.logData.claimNum : undefined,
      'extClaimId',
      sparklLog.logData.extClaimId ? sparklLog.logData.extClaimId : undefined,
      'submissionStatus',
      sparklLog.logData.submissionStatus ? sparklLog.logData.submissionStatus : undefined,
      'policyNumber',
      sparklLog.logData.policyNumber ? sparklLog.logData.policyNumber : undefined,
      'policyStatus',
      sparklLog.logData.policyStatus ? sparklLog.logData.policyStatus : undefined,
      'policyType',
      sparklLog.logData.policyType ? sparklLog.logData.policyType : undefined,
      'dateOfLoss',
      sparklLog.logData.dateOfLoss ? sparklLog.logData.dateOfLoss : undefined,
      'lastName',
      sparklLog.logData.lastName ? sparklLog.logData.lastName : undefined,
      'vehicleMake',
      sparklLog.logData.vehicleMake ? sparklLog.logData.vehicleMake : undefined,
      'vehicleType',
      sparklLog.logData.vehicleType ? sparklLog.logData.vehicleType : undefined,
      'lossReportId',
      sparklLog.logData.lossReportId ? sparklLog.logData.lossReportId : undefined,
      'serviceErrorCode',
      sparklLog.logData.serviceErrorCode ? sparklLog.logData.serviceErrorCode : undefined,
      'serviceErrorMessage',
      sparklLog.logData.serviceErrorMessage ? sparklLog.logData.serviceErrorMessage : undefined,
      'incidentEnteredLocation',
      sparklLog.logData.incidentEnteredLocation ? sparklLog.logData.incidentEnteredLocation : undefined,
      'googleLocationResponse',
      sparklLog.logData.googleLocationResponse ? sparklLog.logData.googleLocationResponse : undefined,
      'incidentType',
      sparklLog.logData.incidentType ? sparklLog.logData.incidentType : undefined,
      'participantType',
      sparklLog.logData.participantType ? sparklLog.logData.participantType : undefined,
      'claimantReporterType',
      sparklLog.logData.claimantReporterType ? sparklLog.logData.claimantReporterType : undefined,
      'vehicleCondition',
      sparklLog.logData.vehicleCondition ? sparklLog.logData.vehicleCondition : undefined,
      'damagedComponents',
      sparklLog.logData.damagedComponents ? sparklLog.logData.damagedComponents : undefined,
      'damageLocation',
      sparklLog.logData.damageLocation ? sparklLog.logData.damageLocation : undefined,
      'vehicleRollOver',
      sparklLog.logData.vehicleRollOver ? sparklLog.logData.vehicleRollOver : undefined,
      'airbagDeployment',
      sparklLog.logData.airbagDeployment ? sparklLog.logData.airbagDeployment : undefined,
      'numberOfVehicles',
      sparklLog.logData.numberOfVehicles ? sparklLog.logData.numberOfVehicles : undefined,
      'carSeatInVehicle',
      sparklLog.logData.carSeatInVehicle ? sparklLog.logData.carSeatInVehicle : undefined,
      'carSeatStatus',
      sparklLog.logData.carSeatStatus ? sparklLog.logData.carSeatStatus : undefined,
      'entireVehicleFire',
      sparklLog.logData.entireVehicleFire ? sparklLog.logData.entireVehicleFire : undefined,
      'vehicleRecovered',
      sparklLog.logData.vehicleRecovered ? sparklLog.logData.vehicleRecovered : undefined,
      'vehicleStolen',
      sparklLog.logData.vehicleStolen ? sparklLog.logData.vehicleStolen : undefined,
      'anyInjuries',
      sparklLog.logData.anyInjuries ? sparklLog.logData.anyInjuries : undefined,
      'hitAndRun',
      sparklLog.logData.hitAndRun ? sparklLog.logData.hitAndRun : undefined,
      'petInjured',
      sparklLog.logData.petInjured ? sparklLog.logData.petInjured : undefined,
      'policeReportFiled',
      sparklLog.logData.policeReportFiled ? sparklLog.logData.policeReportFiled : undefined,
      'responsibleParty',
      sparklLog.logData.responsibleParty ? sparklLog.logData.responsibleParty : undefined,
      'specialtyVehicle',
      sparklLog.logData.specialtyVehicle ? sparklLog.logData.specialtyVehicle : undefined,
      'vehicleOccupiedDetails',
      sparklLog.logData.vehicleOccupiedDetails ? sparklLog.logData.vehicleOccupiedDetails : undefined,
      'parkedVehicleDetails',
      sparklLog.logData.parkedVehicleDetails ? sparklLog.logData.parkedVehicleDetails : undefined,
      'incidentWitness',
      sparklLog.logData.incidentWitness ? sparklLog.logData.incidentWitness : undefined,
      'roadHazards',
      sparklLog.logData.roadHazards ? sparklLog.logData.roadHazards : undefined,
      'workRelatedUse',
      sparklLog.logData.workRelatedUse ? sparklLog.logData.workRelatedUse : undefined,
      'rideShareScenario',
      sparklLog.logData.rideShareScenario ? sparklLog.logData.rideShareScenario : undefined,
      'purposeOfTrip',
      sparklLog.logData.purposeOfTrip ? sparklLog.logData.purposeOfTrip : undefined,
      'purposeOfTripWorkRelated',
      sparklLog.logData.purposeOfTripWorkRelated ? sparklLog.logData.purposeOfTripWorkRelated : undefined,
      'rideShareLoginStatus',
      sparklLog.logData.rideShareLoginStatus ? sparklLog.logData.rideShareLoginStatus : undefined,
      'haveClaimantContact',
      sparklLog.logData.haveClaimantContact ? sparklLog.logData.haveClaimantContact : undefined,
      'claimantRole',
      sparklLog.logData.claimantRole ? sparklLog.logData.claimantRole : undefined,
      'lossDescription',
      sparklLog.logData.lossDescription ? sparklLog.logData.lossDescription : undefined,
      'numberOfInsuredParticipants',
      sparklLog.logData.numberOfInsuredParticipants ? sparklLog.logData.numberOfInsuredParticipants : undefined,
      'numberOfAdditionalParticipants',
      sparklLog.logData.numberOfAdditionalParticipants ? sparklLog.logData.numberOfAdditionalParticipants : undefined,
      'numberOfClaimantParticipants',
      sparklLog.logData.numberOfClaimantParticipants ? sparklLog.logData.numberOfClaimantParticipants : undefined,
      'claimantInjuryStatus',
      sparklLog.logData.claimantInjuryStatus ? sparklLog.logData.claimantInjuryStatus : undefined,
      'claimantLiabilityPercentage',
      sparklLog.logData.claimantLiabilityPercentage ? sparklLog.logData.claimantLiabilityPercentage : undefined,
      'claimantPayment',
      sparklLog.logData.claimantPayment ? sparklLog.logData.claimantPayment : undefined,
      'claimantLiability',
      sparklLog.logData.claimantLiability ? sparklLog.logData.claimantLiability : undefined,
      'consumer',
      sparklLog.logData.consumer ? sparklLog.logData.consumer : undefined,
      'externalOrgId',
      sparklLog.logData.externalOrgId ? sparklLog.logData.externalOrgId : undefined,
      'vehicleStorageFee',
      sparklLog.logData.vehicleStorageFee ? sparklLog.logData.vehicleStorageFee : undefined,
      'liabilityAgreement',
      sparklLog.logData.liabilityAgreement ? sparklLog.logData.liabilityAgreement : undefined,
    );
  }

  log(logCode: any, logDetails: LogDetails = null): void {
    this.cliffLog = new CliffLogDetails(logCode.id);
    this.cliffLog.logData = logDetails ? logDetails : this.getLogDetails();
    this.cliffLog.message = logCode.text;
    this.cliffLog.logData.serviceName = logCode.svcName;
    this.sendSparklLog(this.cliffLog);
  }

}

