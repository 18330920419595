import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import anyAscii from 'any-ascii';

@Component({
  selector: 'olr-additional-comments',
  templateUrl: './additional-comments.component.html',
  styleUrls: ['./additional-comments.component.css', '../../shared/styles/question-common-styling.scss']
})
export class AdditionalCommentsComponent extends BaseComponent implements OnInit {
  @ViewChild('details', {static: false}) details: ElementRef;
  isCommentError = false;
  charCount = 0;
  answer = '';

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('additionaldetails-freeform-question');
    if (this.step.completed){
      this.answer = this.step.question.answers[0];
    }
  }

  updateCount(e: any): void {
    this.charCount = e.target.value.length;
  }

  validateStringAnswers(): boolean{
    const isCommentValid = this.regexValidate(
      anyAscii(this.details.nativeElement.value), this.step.question.allowedValuesRegex, this.step.question.minAnswers
    );
    this.isCommentError = !isCommentValid;
    return isCommentValid;
  }

  resetError(){
    this.isCommentError = false;
  }

  answerQuestion(): void {
    if (this.validateStringAnswers()) {
      const logDetails = this.logService.getLogDetails();
    logDetails.lossDescription = anyAscii(this.details.nativeElement.value);
    this.logService.log(CliffLogCodes.LOSS_DESCRIPTION, logDetails);
      this.updateAnswer(anyAscii(this.details.nativeElement.value));
    }
  }

}
