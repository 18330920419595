import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceGuard } from '../services/maintenance.guard';
import { ClaimantLandingComponent } from './claimant-landing/claimant-landing.component';


const routes: Routes = [
  { path: '', component: ClaimantLandingComponent, canActivate: [MaintenanceGuard] },
  { path: 'incident-details', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./claimant-incident-details/claimant-incident-details.module').then(m => m.ClaimantIncidentDetailsModule)
  },
  {
    path: 'additional-details', canActivate: [MaintenanceGuard],
    loadChildren:
    () => import('./claimant-additional-details/claimant-additional-details.module').then(m => m.ClaimantAdditionalDetailsModule)
  },
  { path: 'review', canActivate: [MaintenanceGuard],
    loadChildren: () => import('./claimant-review/claimant-review.module').then(m => m.ClaimantReviewModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimantRoutingModule { }
