import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ClaimantAdditionalDetailsRoutingModule } from './claimant-additional-details-routing.module';
import { LiabilityComponent } from './liability/liability.component';



@NgModule({
  declarations: [LiabilityComponent],
  imports: [
    CommonModule,
    SharedModule,
    ClaimantAdditionalDetailsRoutingModule
  ],
  exports: [
    SharedModule
  ]
})
export class ClaimantAdditionalDetailsModule { }
