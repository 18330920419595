import { Injectable } from '@angular/core';
import { ExteriorDamageCounter } from './exterior-damage-counter.model';
import { ExteriorDamageOption } from './exterior-damage-option.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExteriorDamageService {
  damageCountersUpdated = new Subject<ExteriorDamageCounter[]>();
  activeDamageCounterUpdated = new Subject<ExteriorDamageCounter>();
  optionSelectionMade = new Subject<number>();
  activeDamageCounter = null;
  selectedOptions: any[];

  private damageCounters: ExteriorDamageCounter[];
  private defaultDamageCounters = [
    { name: 'Passenger Side',
      analyticsTag: 'damage-passengerside-question',
      id: 0,
      options: [
        {text: 'Door(s)', value: 'passengerdoor'},
        {text: 'Doors jammed', value: 'passengerDoorJammed'},
        {text: 'Fender', value: 'passengerSideFront'},
        {text: 'Rear side panel', value: 'passengerSideRear'},
        {text: 'Rim(s)', value: 'passengerSideWheels'},
        {text: 'Tire(s)', value: 'passengerSideTires'},
        {text: 'Window(s)', value: 'passengerSideGlass'},
        {text: 'Other', value: 'passengerSideOther'}
      ],
      optionsSelected: [],
      unTouched: true,
      isActive: false,
      isAnimating: false,
      counter: 0
    },
    { name: 'Front',
      analyticsTag: 'damage-front-question',
      id: 1,
      options: [
        {text: 'Engine', value: 'engine'},
        {text: 'Front bumper', value: 'frontbumper'},
        {text: 'Windshield', value: 'windshield'},
        {text: 'Hood', value: 'hood'},
        {text: 'Other', value: 'frontother'}
      ],
      optionsSelected: [],
      unTouched: true,
      isActive: false,
      isAnimating: false,
      counter: 0
    },
    { name: 'Roof',
      analyticsTag: 'damage-roof-question',
      id: 2,
      options: [
        {text: 'Roof surface', value: 'roof'},
        {text: 'Glass', value: 'roofglass'},
        {text: 'Other', value: 'roofother'}
      ],
      optionsSelected: [],
      unTouched: true,
      isActive: false,
      isAnimating: false,
      counter: 0
    },
    { name: 'Rear',
      analyticsTag: 'damage-rear-question',
      id: 3,
      options: [
        {text: 'Deck Lid/Trunk', value: 'trunk'},
        {text: 'Rear bumper', value: 'rearbumper'},
        {text: 'Rear window', value: 'glass'},
        {text: 'Other', value: 'rearother'}
      ],
      optionsSelected: [],
      unTouched: true,
      isActive: false,
      isAnimating: false,
      counter: 0
    },
    { name: 'Driver Side',
      analyticsTag: 'damage-driverside-question',
      id: 4,
      options: [
        {text: 'Door(s)', value: 'driverdoor'},
        {text: 'Doors jammed', value: 'driverDoorJammed'},
        {text: 'Fender', value: 'driverSideFront'},
        {text: 'Rear side panel', value: 'driverSideRear'},
        {text: 'Rim(s)', value: 'driverSideWheels'},
        {text: 'Tire(s)', value: 'driverSideTires'},
        {text: 'Window(s)', value: 'driverSideGlass'},
        {text: 'Other', value: 'driverSideOther'}
      ],
      optionsSelected: [],
      unTouched: true,
      isActive: false,
      isAnimating: false,
      counter: 0
    }
  ];
  private motorcycleDamageCounters = [
    { name: 'Motorcycle',
      analyticsTag: 'damage-motorcycle-question',
      id: 0,
      options: [
        {text: 'Engine', value: 'engine'},
        {text: 'Front bumper', value: 'frontbumper'},
        {text: 'Windshield', value: 'windshield'},
        {text: 'Rear bumper', value: 'rearbumper'},
        {text: 'Undercarriage', value: 'undercarriage'},
        {text: 'Tire(s)', value: 'driverSideTires'},
        {text: 'Other', value: 'frontother'}
      ],
      optionsSelected: [],
      unTouched: false,
      isActive: true,
      isAnimating: false,
      counter: 0
    }
  ];

  constructor() {
    const storeDamageCounters = JSON.parse(sessionStorage.getItem('damageStore'));
    const policy = JSON.parse(sessionStorage.getItem('policyStore'));
    const categoryCode = policy ? policy.risks[0].categoryCode : "";
    this.damageCounters = storeDamageCounters ? storeDamageCounters : categoryCode == "08" ? this.motorcycleDamageCounters : this.defaultDamageCounters;
    this.selectedOptions = JSON.parse(sessionStorage.getItem('selectedOptions')) || [];
   }

  // set active damage counter once one is selected on UI
  setIsActive(id: number): void {
    for (const dc of this.damageCounters) {
      dc.isActive = false;
      dc.unTouched = !(dc.counter > 0);
    }
    this.damageCounters[id].isActive = true;
    this.damageCounters[id].unTouched = false;
    this.activeDamageCounter = this.damageCounters[id];
    this.activeDamageCounterUpdated.next(this.activeDamageCounter);
    sessionStorage.setItem('activeDamageCounter', JSON.stringify(this.activeDamageCounter));
    this.damageCountersUpdated.next(this.damageCounters);
    sessionStorage.setItem('damageStore', JSON.stringify(this.damageCounters));
  }

  // updates sub-options selected within a specific damage counter when selected on UI
  updateDamageOption(id: number, option: ExteriorDamageOption) {
    const isSelected = this.damageCounters[id].optionsSelected.find( s => s.value === option.value ) !== undefined;
    if (isSelected) {
      this.damageCounters[id].optionsSelected = this.damageCounters[id].optionsSelected.filter( s => s.value !== option.value );
      this.selectedOptions = this.selectedOptions.filter( s => s.value !== option.value);
      this.damageCounters[id].counter--;
    } else {
      this.damageCounters[id].optionsSelected.push(option);
      this.selectedOptions.push(option);
      this.damageCounters[id].counter++;
    }
    this.optionSelectionMade.next(this.selectedOptions.length);
    this.damageCountersUpdated.next(this.damageCounters);
    sessionStorage.setItem('selectedOptions', JSON.stringify(this.selectedOptions));
    sessionStorage.setItem('damageStore', JSON.stringify(this.damageCounters));
  }
  getDamageCounters(): ExteriorDamageCounter[] {

    return this.damageCounters.slice();
  }

  getTotalOptionsSelected(): number {
    return this.selectedOptions.length;
  }

  getActiveDamageCounter(): ExteriorDamageCounter {
    if (!this.activeDamageCounter){
      this.activeDamageCounter = JSON.parse(sessionStorage.getItem('activeDamageCounter'));
    }
    return this.activeDamageCounter;
  }

}
