// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  background-color: #f7f0e4;
}
.olr-nf-bottom-margin {
  margin-bottom: 30px;
}
.olr-nf-column {
  text-align: center;
  padding: 32px;
}
.olr-nf-pictogram-margins {
  margin: 0 auto 16px;
}
.olr-nf-header-margins {
  margin-top: 10px;
}
.olr-merna03-red {
  color: #D62311;
}
.olr-charcoal02, .olr-nf-message  {
  color: #403C3C
}

@media screen and (max-width: 576px) {
  .olr-nf-message {
    font-size: 20px;;
  }
}

@media (min-width: 768px) {
  .olr-nf-message {
    font-size: 23px;;
  }
}

@media (min-width: 992px) {
  .olr-nf-message {
    font-size: 25px;;
  }
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/entry-point/card-not-found/card-not-found.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".not-found {\n  background-color: #f7f0e4;\n}\n.olr-nf-bottom-margin {\n  margin-bottom: 30px;\n}\n.olr-nf-column {\n  text-align: center;\n  padding: 32px;\n}\n.olr-nf-pictogram-margins {\n  margin: 0 auto 16px;\n}\n.olr-nf-header-margins {\n  margin-top: 10px;\n}\n.olr-merna03-red {\n  color: #D62311;\n}\n.olr-charcoal02, .olr-nf-message  {\n  color: #403C3C\n}\n\n@media screen and (max-width: 576px) {\n  .olr-nf-message {\n    font-size: 20px;;\n  }\n}\n\n@media (min-width: 768px) {\n  .olr-nf-message {\n    font-size: 23px;;\n  }\n}\n\n@media (min-width: 992px) {\n  .olr-nf-message {\n    font-size: 25px;;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
