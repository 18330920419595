import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreferencesComponent } from '../opt-in/preferences/preferences.component';

const routes: Routes = [
    { path: 'preferences', component: PreferencesComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class OptInRoutingModule {}
