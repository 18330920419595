import { Component, Input } from '@angular/core';

@Component({
  selector: 'olr-coverage-panel',
  templateUrl: './coverage-panel.component.html',
  styleUrls: ['./coverage-panel.component.css']
})
export class CoveragePanelComponent {

  @Input() policyInfo: any;
  @Input() id: string;
  @Input() title: string;
  @Input() deductible: string;
  constructor() { }

}
