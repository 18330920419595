import {Name} from './name.model';
import { Contact } from './contact.model';
export class Participant {
    public clientId: string;
    public fullName: string;
    public role: string;
    public options: string[];
    public name: Name;
    public isHouseholdParticipant: string;
    public contact: Contact;
    public id: string;
    public injuryStatus: string;
    public lossOfLife: string;
    public injuries: string[];
    public medicalTreatment: string;
    public airbagDeploy: string;
}
