import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { InjuryTrackingService } from '../../services/injury-tracking.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';

@Component({
  selector: 'olr-involvement',
  templateUrl: './involvement.component.html',
  styleUrls: ['./involvement.component.css', '../../shared/styles/question-common-styling.scss']
})
export class InvolvementComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  policyVehicle: string;
  yesNoUnkOptions = ['yes', 'no', 'unknown'];
  yesNoUnkNaOptions = ['yes', 'no', 'unknown', 'na'];
  roleOptions = ['driver', 'passenger', 'pedestrian', 'other'];
  selectedRole = '';
  selectedInjuryStatus: string;
  selectedMedCare: string;
  selectedAirbagDeployed: string;
  isRoleSelected = false;
  isAnswerSelected = false;
  hasInjury = false;
  hasRoleQuestion = false;
  hasAirbagQuestion = false;
  selectedInjuries: string[] = [];
  answerRoleNumber = '1056';
  answerRoleName = 'insuredRole';
  partyInjury = 'reportingPartyInjury';
  analyticsTag = 'reporting-party-involvement';
  claimantName: string;

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private insuredParticipantTrackingService: InsuredParticipantTrackingService,
    public cdr: ChangeDetectorRef,
    private injuryTrackingService: InjuryTrackingService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    if (this.isClaimantLossReport) {
      this.roleOptions = ['owner', 'driverOwner', 'driver', 'passengerOwner', 'passenger', 'other'];
      this.answerRoleNumber = '7020IND';
      this.answerRoleName = 'claimantRole';
      this.partyInjury = 'claimantPartyInjury';
      this.analyticsTag = 'participant-details';
      this.setClaimantName();
      this.hasRoleQuestion = this.getSubQuestion('claimantRole') ? true : false;
    } else {
      const vehicle = this.policyVehicleInfoService.getSelectedPolicyVehicle();
      this.policyVehicle = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model;
      this.hasRoleQuestion = true;
    }
    this.analytics.sendData(this.analyticsTag);
    if (this.hasRoleQuestion) {
      this.selectedRole = this.getQuestionAnswer(this.answerRoleName);
    }
    this.selectedInjuryStatus = this.getQuestionAnswer('injuryStatus');
    if (this.selectedInjuryStatus) {
      this.selectedInjuries = this.getQuestionAnswer('injuries', true);
      this.selectedMedCare = this.getQuestionAnswer('medicalTreatment');
    }
    if (this.hasRoleQuestion && this.selectedInjuryStatus) {
      this.selectedAirbagDeployed = this.getQuestionAnswer('airbagDeploy');
    }
    this.hasInjury = this.selectedInjuryStatus === 'yes' ? true : false;
    this.hasAirbagQuestion = this.hasRoleQuestion && this.hasInjury;
  }

  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#insuredDropdowns'));
  }

  private getSubQuestion(questionName: string): any {
    const subQuestions = this.step?.question.subQuestions[0] ?? [];
    return subQuestions.find(question => question.name === questionName);
  }

  private getQuestionAnswer(questionName: string, hasMultipleAnswers = false): any {
    const question = this.getSubQuestion(questionName);
    if (question) {
      return hasMultipleAnswers ? question.answers : question.answers[0] || '';
    }
    return '';
  }

  ngAfterViewInit() {
    if (this.hasRoleQuestion && this.selectedRole && this.selectedInjuryStatus ||
        !this.hasRoleQuestion && this.selectedInjuryStatus) {
      this.toggleContinue();
    }
    this.cdr.detectChanges();
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement(document.querySelector('#insuredDropdowns'));
    }, 100);
  }

  private toggleContinue() {
    this.isAnswerSelected = this.hasRoleQuestion && this.selectedRole.length > 0 && this.selectedInjuryStatus.length > 0 ||
                            !this.hasRoleQuestion && this.selectedInjuryStatus.length > 0;
    this.activateFloatingLabel();
  }

  private setClaimantName() {
    const claimantNameStep = this.lossReportService.getStepByName('claimant');
    console.log('claimantNameStep: ', claimantNameStep);
    const claimantFirstName = claimantNameStep ? claimantNameStep.question.subQuestions[0][0].subQuestions[0][0].answers : [];
    console.log('claimantNameStep: ', claimantNameStep);
    const claimantLastName = claimantNameStep ? claimantNameStep.question.subQuestions[0][0].subQuestions[0][1].answers : [];
    console.log('claimantFirstName: ', claimantFirstName);
    console.log('claimantLastName: ', claimantLastName);
    this.claimantName = claimantFirstName + ' ' + claimantLastName;
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.toggleContinue();
  }

  updateInjuryStatus(answer: any) {
    this.hasInjury = answer === 'yes' ? true : false;
    this.hasAirbagQuestion = this.hasRoleQuestion && this.hasInjury;
    this.selectedInjuryStatus = answer;
    this.toggleContinue();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedCare = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  routeToEdit(route: string) {
    if (route){
      this.router.navigate([route]);
    }
  }

  answerQuestion(): void {
    if (!this.isClaimantLossReport) {
      this.insuredParticipantTrackingService.refreshSelectedDriver(this.selectedRole);
    }
    const answers = [];
    const participant = [];
    let injuryCount = 0;
    if (this.hasRoleQuestion) {
      participant.push(this.createAnsweredQuestion(this.answerRoleNumber, this.answerRoleName, this.selectedRole));
    }
    participant.push(this.createAnsweredQuestion('1051', 'injuryStatus', this.selectedInjuryStatus));
    if (this.selectedInjuryStatus === 'yes') {
      injuryCount++;
      participant.push(this.createAnsweredQuestion('1053A', 'injuries', this.selectedInjuries));
      participant.push(this.createAnsweredQuestion('1054A', 'medicalTreatment', this.selectedMedCare));
    }
    if (this.selectedInjuryStatus === 'yes' && this.hasRoleQuestion) {
      participant.push(this.createAnsweredQuestion('1055A', 'airbagDeploy', this.selectedAirbagDeployed));
    }
    answers.push(participant);
    this.injuryTrackingService.setStatus(this.partyInjury, injuryCount > 0);
    this.updateSubQuestions(answers);
  }
}
