// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-mt {
    margin-top: 35px;
}
.lr-chars {
    width: 40px;
    float: right;
}
.lr-sensitive {
    width: calc(100% - 47px);
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/review/additional-comments/additional-comments.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,wBAAwB;AAC5B","sourcesContent":[".lr-mt {\n    margin-top: 35px;\n}\n.lr-chars {\n    width: 40px;\n    float: right;\n}\n.lr-sensitive {\n    width: calc(100% - 47px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
