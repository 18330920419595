// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.olr-coverage-panel-title {
  color: #2D2929;
  font-family: 'Mecherle Sans Regular';
}

.olr-coverage-panel-deductible {
  color: #E81E1E;
  text-align: right;
}

.olr-coverage-panel-body {
  color: #2D2929;
  font-family: 'Mecherle Sans Light';
}

.olr-coverage-panel {
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 19px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/drawer-deductible-overview/coverage-panel/coverage-panel.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oCAAoC;AACtC;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".olr-coverage-panel-title {\n  color: #2D2929;\n  font-family: 'Mecherle Sans Regular';\n}\n\n.olr-coverage-panel-deductible {\n  color: #E81E1E;\n  text-align: right;\n}\n\n.olr-coverage-panel-body {\n  color: #2D2929;\n  font-family: 'Mecherle Sans Light';\n}\n\n.olr-coverage-panel {\n  padding-bottom: 24px;\n  font-size: 14px;\n  line-height: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
