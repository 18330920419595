import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'olr-button-counter',
  templateUrl: './button-counter.component.html',
  styleUrls: ['./button-counter.component.css']
})
export class ButtonCounterComponent implements OnInit {
  @Input() minVal = 0;
  @Input() maxVal = 99;
  @Input() answer: number;
  @Output() count = new EventEmitter<number>();
  counter: number;

  constructor() { }

  ngOnInit() {
    if  (this.answer){
      this.counter = this.answer;
    }
    else {
      this.counter = this.minVal;
    }
  }

  onClickAdd(): void {
    if (this.counter < this.maxVal) {
      this.counter++;
    }
    this.count.emit(this.counter);
  }

  onClickSubtract(): void {
    if (this.counter > this.minVal) {
      this.counter--;
    }
    this.count.emit(this.counter);
  }

}
