import { Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { InjuryTrackingService } from '../../../services/injury-tracking.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { CliffLogDetails } from '../../../models/cliff-log-details.model';
import { LogDetails } from '../../../models/log-details.model';

@Component({
  selector: 'olr-client-involvement',
  templateUrl: './client-involvement.component.html',
  styleUrls: ['./client-involvement.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClientInvolvementComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  yesNoOptions = ['yes', 'no'];
  yesNoUnkOptions = ['yes', 'no', 'unknown'];
  yesNoUnkNaOptions = ['yes', 'no', 'unknown', 'na'];
  roleOptions = ['owner', 'driverOwner', 'driver', 'passengerOwner', 'passenger', 'other'];
  selectedRole: string;
  selectedInjuryStatus: string;
  selectedLossOfLife: string;
  selectedMedCare: string;
  selectedAirbagDeployed: string;
  isRoleSelected = false;
  isAnswerSelected = false;
  hasInjury = false;
  hasRoleQuestion = false;
  hasAirbagQuestion = false;
  selectedInjuries: string[] = [];
  analyticsTag = 'participant-details';
  claimantName: string;
  logDetails: LogDetails;

  constructor(
    @Inject('Window') private window: any,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService,
    public cdr: ChangeDetectorRef,
    private injuryTrackingService: InjuryTrackingService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.logDetails = this.logService.getLogDetails();
    this.analytics.sendData(this.analyticsTag);
    this.setClaimantName();
    this.hasRoleQuestion = this.getSubQuestion('claimantRole') ? true : false;
    this.selectedRole = this.getQuestionAnswer('claimantRole');
    this.selectedInjuryStatus = this.getQuestionAnswer('injuryStatus');
    if (this.selectedInjuryStatus) {
      this.selectedLossOfLife = this.getQuestionAnswer('lossOfLife');
      if (this.selectedLossOfLife === 'no') {
        this.selectedInjuries = this.getQuestionAnswer('injuries', true);
        this.selectedMedCare = this.getQuestionAnswer('medicalTreatment');
      }
      if (this.hasRoleQuestion && this.selectedLossOfLife === 'no') {
        this.selectedAirbagDeployed = this.getQuestionAnswer('airbagDeploy');
      }
    }
    this.hasInjury = this.selectedInjuryStatus === 'yes' ? true : false;
    this.hasAirbagQuestion = this.hasRoleQuestion && this.hasInjury && this.selectedLossOfLife === 'no';
  }

  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#clientInvolvement'));
  }
  private getSubQuestion(questionName: string): any {
    const subQuestions = this.step?.question.subQuestions[0] ?? [];
    return subQuestions.find(question => question.name === questionName);
  }

  private getQuestionAnswer(questionName: string, hasMultipleAnswers = false): any {
    const question = this.getSubQuestion(questionName);
    if (question) {
      return hasMultipleAnswers ? question.answers : question.answers[0] || '';
    }
    return '';
  }

  ngAfterViewInit() {
    if (this.hasRoleQuestion && this.selectedRole && this.selectedInjuryStatus ||
        !this.hasRoleQuestion && this.selectedInjuryStatus) {
      this.toggleContinue();
    }
    this.cdr.detectChanges();
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement(document.querySelector('#clientInvolvement'))
    }, 100);
  }

  private toggleContinue() {
    const toggle = this.hasRoleQuestion && this.selectedRole.length > 0 && this.selectedInjuryStatus.length > 0 ||
                   !this.hasRoleQuestion && this.selectedInjuryStatus.length > 0;
    if (this.hasInjury) {
      this.isAnswerSelected = toggle && this.selectedLossOfLife !== undefined && this.selectedLossOfLife.length > 0;
    } else {
      this.isAnswerSelected = toggle;
    }
    this.activateFloatingLabel();
  }

  private setClaimantName() {
    const claimantNameStep = this.lossReportService.getStepByName('claimant');
    const claimantFirstName = claimantNameStep ? claimantNameStep.question.subQuestions[0][0].subQuestions[0][0].answers : [];
    const claimantLastName = claimantNameStep ? claimantNameStep.question.subQuestions[0][0].subQuestions[0][1].answers : [];
    this.claimantName = claimantFirstName + ' ' + claimantLastName;
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.toggleContinue();
  }

  updateInjuryStatus(answer: any) {
    this.hasInjury = answer === 'yes' ? true : false;
    this.hasAirbagQuestion = this.hasRoleQuestion && this.hasInjury;
    this.selectedInjuryStatus = answer;
    this.toggleContinue();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateLossOfLife(answer: any) {
    this.selectedLossOfLife = answer;
    this.toggleContinue();
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedCare = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  routeToEdit(route: string) {
    if (route){
      this.router.navigate([route]);
    }
  }

  answerQuestion(): void {
    const answers = [];
    const participant = [];
    let injuryCount = 0;
    let fatalityCount = 0;
    if (this.hasRoleQuestion) {
      participant.push(this.createAnsweredQuestion('7020OIC', 'claimantRole', this.selectedRole));
    }
    participant.push(this.createAnsweredQuestion('1051', 'injuryStatus', this.selectedInjuryStatus));
    if (this.selectedInjuryStatus === 'no') {
      this.logDetails.claimantInjuryStatus = 'no';
      this.logService.log(CliffLogCodes.CLAIMANT_INJURY_STATUS, this.logDetails);
    }
    if (this.selectedInjuryStatus === 'unknown') {
      this.logDetails.claimantInjuryStatus = 'unknown';
      this.logService.log(CliffLogCodes.CLAIMANT_INJURY_STATUS, this.logDetails);
    }
    if (this.selectedInjuryStatus === 'yes') {
      injuryCount++;
      this.logDetails.claimantInjuryStatus = 'yes';
      this.logService.log(CliffLogCodes.CLAIMANT_INJURY_STATUS, this.logDetails);
      participant.push(this.createAnsweredQuestion('1052', 'lossOfLife', this.selectedLossOfLife));
      if (this.selectedLossOfLife === 'yes') {
        fatalityCount++;
        this.logService.log(CliffLogCodes.FATALITY_YES, this.logDetails);
      }
    }
    if (this.selectedInjuryStatus === 'yes' && this.selectedLossOfLife === 'no') {
      participant.push(this.createAnsweredQuestion('1053', 'injuries', this.selectedInjuries));
      participant.push(this.createAnsweredQuestion('1054', 'medicalTreatment', this.selectedMedCare));
    }
    if (this.hasRoleQuestion && this.selectedInjuryStatus === 'yes' && this.selectedLossOfLife === 'no') {
      participant.push(this.createAnsweredQuestion('1055', 'airbagDeploy', this.selectedAirbagDeployed));
    }
    answers.push(participant);
    this.injuryTrackingService.setStatus('claimantPartyInjury', injuryCount > 0);
    this.injuryTrackingService.setStatus('claimantPartyFatality', fatalityCount > 0);
    this.updateSubQuestions(answers);
  }

}
