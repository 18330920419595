import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { HostnameBuilderService } from '../../../services/helpers/hostname-builder.service';
import { ClaimsEventsService } from '../../../services/claims-events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../constants/cliff-log-constants';
import { Subscription } from 'rxjs';
@Component({
  selector: 'olr-modal-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css']
})
export class SaveComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject('Window') private window: any,
    private lossReportService: LossReportService,
    private analytics: SfAnalyticsService,
    private hostnameBuilderService: HostnameBuilderService,
    private claimsEventsService: ClaimsEventsService,
    private authenticationService: AuthenticationService,
    private logService: LogService,
    ) {}

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-save'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-save'));
  }
  onCloseSaveModal() {
    this.window.oneX.Modal.hideModal();
  }

  onConfirmSaveNo() {
    this.authenticationService.stopTimer();
    this.claimsEventsService.buildEvent('Claim Not Saved');
    this.logService.log(CliffLogCodes.SFL_EXIT_SAVE_NO);
    sessionStorage.clear();
    this.lossReportService.deleteReport();
    this.window.oneX.Modal.hideModal();
    this.window.location.replace(`https://www${this.hostnameBuilderService.getHostname()}/claims`);
  }

  onConfirmSaveYes() {
    this.lossReportService.saveSession();
    this.lossReportService.sessionSaved.subscribe(response => {
      this.authenticationService.stopTimer();
      this.claimsEventsService.buildEvent('Claim Saved');
      this.logService.log(CliffLogCodes.SFL_EXIT_SAVE_YES);
      sessionStorage.clear();
      this.window.oneX.Modal.hideModal();
      this.window.location.replace(`https://${this.hostnameBuilderService.getSFLHostname()}/confirmation/save-confirmation`);
    });
    this.lossReportService.deleteReport();
  }

  private unsubscribe(subscription: Subscription): void {
      if (subscription) { subscription.unsubscribe(); }
  }

}
