import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef, Inject, AfterViewChecked } from '@angular/core';
import { GoogleMap } from "@angular/google-maps";
import { GoogleSearchService } from '../../../services/google-search.service';
import { BaseComponent } from '../../../shared/base/base.component';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { HostnameBuilderService } from '../../../services/helpers/hostname-builder.service';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { LogDetails } from '../../../models/log-details.model';
import { ValueTranslatorPipe } from '../../../shared/pipes/value-translator.pipe';
import anyAscii from 'any-ascii';

@Component({
  selector: 'olr-enter-location',
  templateUrl: './enter-location.component.html',
  styleUrls: ['./enter-location.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class EnterLocationComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  pipe: ValueTranslatorPipe;
  defaultAddress = '';
  desLocation = '';
  formattedAddress = '';
  addressType = 'enterlocation';
  googleError = false;
  disDesLoc = true;
  numCharacters = 0;
  googleResponse: any = {};
  calledOnce = false;
  addressError = false;
  stateMissing = false;
  streetNum = '';
  route = '';
  country = '';
  street1 = '';
  city = '';
  state = '';
  territoryState = '';
  postalCode = '';
  myAnimation: google.maps.Animation;
  zoom: number;
  disMarker = false;
  latlang: any = {};
  pinURL: string;
  markerURL: string;
  isLocationDescError = false;
  options = {
    disableDefaultUI: true
  };
  address = '';
  marker = {};
  text = 'Where did the incident happen?';
  center: google.maps.LatLngLiteral = { lat: 40.47, lng: -88.95 };
  markerOptions: google.maps.MarkerOptions;
  pinOptions: google.maps.MarkerOptions;
  dropAnimation = 2; // code for DROP animation
  @Output() errordata = new EventEmitter();

  @ViewChild('address', { static: false }) addressElement: ElementRef;
  @ViewChild('mobileshow') currLocation: any;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap

  private resetVariables(): void { // needed specifically for when user enters valid address then enters an invalid one (missing state)
    this.streetNum = '';
    this.route = '';
    this.country = '';
    this.street1 = '';
    this.city = '';
    this.state = '';
    this.postalCode = '';
    this.stateMissing = false;
  }

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    public lossReportingService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private googleSearchService: GoogleSearchService,
    private changeDetector: ChangeDetectorRef,
    private hostnameBuilder: HostnameBuilderService,
    public logService: LogService
  ) {
    super(lossReportingService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData(this.isClaimantVehicleStorageLocation || this.isAutoVehicleStorageLocation ? 'vehicle-storage-locaddress-question' :'loss-locaddress-question');
    this.pipe = new ValueTranslatorPipe();
    // @ts-ignore
    this.window['oneX'].addElement(document.querySelector('#incidentDetailElements'));
    const currLocButton = document.getElementById('mobileshow');
    if (window.getComputedStyle(currLocButton).display === 'none') {
      this.zoom = 2.5;
    } else { // different google map zoom level on desktop & mobile
      this.zoom = 1;
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewChecked() {
    // @ts-ignore
    this.window['oneX'].addElement(document.querySelector('#incidentDetailElements'));
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    this.window['oneX'].addElement(document.querySelector('#incidentDetailElements'));
    this.addressElement.nativeElement.focus();
    if (this.step.completed) {
      this.center = {
        lat: this.googleSearchService.latitude,
        lng: this.googleSearchService.longitude
      };
      this.zoom = 15;
      this.disMarker = true;
      // @ts-ignore
      this.window.oneX.$('#describe').val(this.step.question.subQuestions[0][6].answers[0]).change();
      this.changeDetector.detectChanges();
      this.setValuesFromSubQuestions();
      this.displayAnsweredState();
    }
    if(this.isClaimantVehicleStorageLocation || this.isAutoVehicleStorageLocation){
      this.text = 'Where is the vehicle currently located?';
    }
  }

  initializeGoogleSearch() {
    if (this.numCharacters > 4 && !this.calledOnce) {
      this.calledOnce = true;
      this.googleSearchService.bindGoogleSearch(this.addressElement.nativeElement);
      this.googleSearchService.getAutocompleteSubject().subscribe(response => {
          this.showMap();
        }, error => {
            this.googleError = true;
          });
    }
  }

  private async showMap() {
    this.addressError = false;
    this.googleSearchService.performSearch().subscribe((response: any) => {
      this.googleError = false;
      this.googleResponse = response.address_components;
      this.formattedAddress = response.formatted_address;
      this.disMarker = true;
      this.zoom = 15;
      this.center = {
        lat: response.geometry.location.lat(),
        lng: response.geometry.location.lng(),
      };
      this.marker['position'] = this.center;
      this.googleSearchService.reverseGeoCode(this.center.lat, this.center.lng).subscribe(() => {
        this.addressElement.nativeElement.value = response.formatted_address;
        this.formattedAddress = response.formatted_address;
      });
      this.changeDetector.detectChanges();
    });
  }

  private setValuesFromSubQuestions() {

    this.country = this.step.question.subQuestions[0][0].answers[0];
    this.street1 = this.step.question.subQuestions[0][1].answers[0];
    this.city = this.step.question.subQuestions[0][3].answers[0];
    this.state = this.step.question.subQuestions[0][4].answers[0];
    this.postalCode = this.step.question.subQuestions[0][5].answers[0];
    this.desLocation = this.step.question.subQuestions[0][6].answers[0];
  }

  private displayAnsweredState() {
    const add = [];
    if (this.street1 && this.street1.length > 0) { add.push(this.street1 + ', '); }
    if (this.city && this.city.length > 0) { add.push(this.city + ', '); }
    if (this.state.length > 0) { add.push(this.state + ' '); }
    if (this.postalCode && this.postalCode.length > 0) { add.push(this.postalCode + ', '); }
    if (this.country.length > 0) { add.push(this.country); }
    this.defaultAddress = add.join('');
    // @ts-ignore
    this.window.oneX.$('#address').val(this.defaultAddress).change();
    this.changeDetector.detectChanges();
  }

  validateLocationDescriptionString(): boolean{
    const isLocDescriptionValid = this.regexValidate(
      this.desLocation, this.step.question.subQuestions[0][6].allowedValuesRegex,
      this.step.question.subQuestions[0][6].minAnswers
    );
    this.isLocationDescError = !isLocDescriptionValid;
    return isLocDescriptionValid;
  }

  updateNumCharacters(value: string): void {
    this.numCharacters = value.length;
    if (this.numCharacters > 4 && !this.calledOnce) {
      const screenReaderText = document.querySelector('#sr-text');
      screenReaderText.innerHTML = 'Suggestions are available from Google Address Assist';
      this.changeDetector.detectChanges();
    }
  }

  setLoc(data: any) {
    this.desLocation = data;
  }

  setInputError(status: boolean): void {
    this.addressError = status;
  }

  setMissingState(status: boolean): void {
    this.stateMissing = status;
  }

  currentLocation() {
    this.googleSearchService.getCurrPosition().subscribe(pos => {
      this.center = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      }
      this.googleSearchService.reverseGeoCode(pos.coords.latitude, pos.coords.longitude).subscribe((response: any) => {
        this.zoom = 15;
        this.addressElement.nativeElement.value = response.formatted_address;
        this.formattedAddress = response.formatted_address;
        this.addressElement.nativeElement.focus();
        this.googleResponse = response.address_components;
      });
    }, error => {
      this.googleError = true;
      this.errordata.emit();
    });
  }

  markerDrag($event: any) {
    this.center.lat = $event.latLng.lat();
    this.center.lng = $event.latLng.lng();
    this.googleSearchService.reverseGeoCode(this.center.lat, this.center.lng).subscribe((response: any) => {
      // @ts-ignore
      this.window.oneX.$('#address').val(response.formatted_address).change();
      this.addressElement.nativeElement.value = response.formatted_address;
      this.googleResponse = response.address_components;
    });
  }

  mapReading() {
      this.markerOptions = {
        animation: this.dropAnimation,
        icon: {
          url: `https://${this.hostnameBuilder.getAWSHostname('reportloss.claims')}/assets/img/mapmarker-red.svg`,
          scaledSize: {
            width: 48,
            height: 80,
            equals: (other): boolean => {
              return other.width == 48 && other.height == 80;
            },
          }
        },
        draggable: true,
      };
      this.pinOptions = {
        animation: this.dropAnimation,
        icon: {
          url: `https://${this.hostnameBuilder.getAWSHostname('reportloss.claims')}/assets/img/pin_center.svg`,
        },
        draggable: false,
      };
  }

  answerQuestion() {
    const usTerritories = ['PR', 'VI', 'GU', 'MP', 'AS'];
    const countriesRequiringStates = ['usa', 'mexico', 'canada'];
    // if already in answered state AND the address has not changed, just route to next unanswered question
    // else process the response from google assist
    if (this.validateLocationDescriptionString()){
      if (this.step.completed && this.addressElement.nativeElement.value === this.defaultAddress) {
        //set global to boolean based on URL if boolean, new variable = 'vehicleLocationDescription', newVariable = 'incidentLocationDescription'
        this.updateSubQuestionAnswer('incidentLocationDescription', 0, null, this.desLocation || '');
        this.routingService.navigateNext();
      } else {
        if (this.googleResponse.length === undefined) {
          this.addressError = true;
          return;
        } else {
          this.addressError = false;
          this.resetVariables();
          this.logGoogleResponse();
          for (const answer of this.googleResponse) {
            if (answer.types[0] === 'street_number') {
              this.streetNum = answer.long_name;
            } else if (answer.types[0] === 'route') {
              this.route = answer.long_name;
            } else if (answer.types[0] === 'locality') {
              this.city = answer.long_name.length > 30 ? this.shortenString(answer.short_name, 30) : answer.long_name;
            } else if (answer.types[0] === 'administrative_area_level_1') {
              this.state = answer.short_name;
            } else if (answer.types[0] === 'country') {
              if (answer.short_name === 'US') {
                this.country = 'usa';
              } else if (answer.short_name === 'CA') {
                this.country = 'canada';
              } else if (answer.short_name === 'MX') {
                this.country = 'mexico';
              } else if (usTerritories.includes(answer.short_name)) {
                this.country = 'usTerritories';
                this.territoryState = answer.short_name;
              } else {
                this.country = 'other';
                this.state = '';
                break; // break since country w/ 'other' must have postal code empty, won't reach postal_code below after country set
              }
            } else if (answer.types[0] === 'postal_code') {
              this.postalCode = answer.long_name;
            }
          }
        }

        if (this.country === 'mexico') {
          this.state = this.pipe.transform(this.state, 'mexicoStates');
        }
        if (countriesRequiringStates.includes(this.country) && this.state === '') {
          this.stateMissing = true;
        }
        if (this.country === 'usTerritories') {
          this.state = this.territoryState;
        }
        if (!this.stateMissing) {
          this.street1 = (this.streetNum + ' ' + this.route).trim();
          this.googleSearchService.latitude = this.center.lat;
          this.googleSearchService.longitude = this.center.lng;
          this.logFinalAddress();
          this.updateSubQuestionAnswer('country', 0, null, this.country, false);
          if (this.street1) { this.updateSubQuestionAnswer('street1', 0, null, this.cleanText(this.street1)); }
          if (this.city) { this.updateSubQuestionAnswer('city', 0, null, this.cleanText(this.city)); }
          this.updateSubQuestionAnswer('state', 0, null, this.state);
          if (this.postalCode) { this.updateSubQuestionAnswer('postalCode', 0, null, this.postalCode); }
          this.updateSubQuestionAnswer('incidentLocationDescription', 0, null, this.cleanText(this.desLocation) || '');
          this.updateSubQuestionStep();
        }
      }
    }
  }

  private shortenString(word:string, desiredLength:number): string {
    if(word){
      return word.length <= desiredLength ? word : word.slice(0, desiredLength).trim();
    }
    return word;
  }

  private logFinalAddress() {
    const logDetails = this.logService.getLogDetails();
    logDetails.incidentEnteredLocation = this.cleanText(this.street1) + ' ' + this.cleanText(this.city) + ' '
                       + this.state + ' ' + this.postalCode + ' ' + this.country;
    this.logService.log(CliffLogCodes.FINAL_LOCATION_ADDRESS, logDetails);
  }

  private logGoogleResponse() {
    const logDetails = this.logService.getLogDetails();
    logDetails.googleLocationResponse = this.googleResponse;
    this.logService.log(CliffLogCodes.GOOGLE_API_RESPONSE, logDetails);
  }

  private cleanText(str: string): string{
    let cleanStage1 = str.normalize('NFD').replace(/[–\u0300-\u036f&\u02BB&\u201B&\u2018\u275B]/g, '');
    return anyAscii(cleanStage1);
  }
}
