import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceGuard } from '../../services/maintenance.guard';
import { ClaimantReviewClaimComponent } from './claimant-review-claim/claimant-review-claim.component';


const routes: Routes = [
  { path: 'review-claim', component: ClaimantReviewClaimComponent, canActivate: [MaintenanceGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimantReviewRoutingModule { }
