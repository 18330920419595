import { Component } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../constants/cliff-log-constants';
import { Question } from '../../models/question.model';

@Component({
  selector: 'olr-base-component',
  template: ''
})
export class BaseComponent {
  isClaimantLossReport = false;
  isFireLossReport = false;
  isClaimantVehicleStorageLocation = false;
  isAutoVehicleStorageLocation = false;


  constructor(
    public lossReportService: LossReportService,
    public router: Router,
    public routingService: RoutingService,
    public logService: LogService,
  ) {
    this.setUpLossReportUpdatedSubscription();
    this.step = this.lossReportService.getStepByRoute(this.router.url);
    if (this.router.url) {
      this.isClaimantLossReport = this.router.url.includes('/claimant/');
      this.isFireLossReport = this.router.url.includes('/homeandproperty/');
      this.isClaimantVehicleStorageLocation = this.router.url.includes('claimant/incident-details/enter-location');
      this.isAutoVehicleStorageLocation = this.router.url.includes('damage-details/enter-location')
    }
  }

  highlightButtonStandardAnswer(){
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      const element = document.querySelector(`#${answers[0]}>button`);
      element.classList.add('lr-btn-answer');
    }
  }

  updateAnswer(answer: any): void {
    this.updateAnswerWithoutRouting(answer);
    this.handleRouting();
  }


  updateAnswerWithoutRouting(answer: any): void {
    this.step.question.answers = typeof answer === 'object' ? answer : [answer];
    this.step.completed = true;
  }

  updateSubQuestionAnswer(questionName: string, answerIndex: number, subQuestionName: string,
                          answer: any, callModify: boolean = true): void {
    const subQuestions = this.step.question.subQuestions[answerIndex];
    const question = subQuestions.find((q: any) => q.name === questionName);
    let subject: any;
    if (question.subQuestions) {
      const innerSubQuestions = question.subQuestions[0];
      const innerQuestion = innerSubQuestions.find((q: any) => q.name === subQuestionName);
      subject = Object.assign({}, innerQuestion);
      this.updateQuestionAnswer(innerQuestion, answer);
    } else {
      subject = Object.assign({}, question);
      this.updateQuestionAnswer(question, answer);
    }
    if (subject.hasDependentQuestions && callModify) {
      this.lossReportService.modifyReport();
    }
  }

  updateSubQuestions(subQuestions: any[]) {
    this.step.question.subQuestions = subQuestions;
    this.updateSubQuestionStep();
  }

  updateSubQuestionStep(): void {
    this.step.completed = true;
    this.handleRouting();
  }

  private updateQuestionAnswer(question: any, answer: any) {
    question.answers = [];
    if (answer && typeof answer === 'object') {
      question.answers = answer;
    } else {
      question.answers.push(answer);
    }
  }

  private handleRouting(): void {
    if (this.step.question.hasDependentQuestions || this.lossReportService.lossReport.metaData.status === 'COMPLETE') {
      this.lossReportService.modifyReport();
    } else {
      this.routingService.navigateNext();
    }
  }

  regexValidate(answer: string, regexString: string, minAnswers: number): boolean {
    if (!answer && minAnswers === 0) {
      return true;
    }
    const regEx = new RegExp(`^${regexString}$`);
    return regEx.test(answer);
  }

  createAnsweredQuestion(questionId: string, questionName: string, simpleAnswer: any, subQuestions: any[] = null): Question {
    const question = new Question();
    question.id = questionId;
    question.name = questionName;
    if (simpleAnswer) {
      question.answers = typeof simpleAnswer === 'object' ? simpleAnswer : [simpleAnswer];
    } else if (!subQuestions && !simpleAnswer) {
      question.answers = [];
    }

    if (subQuestions) {
      question.subQuestions = [subQuestions];
    }
    return question;
  }

  private setUpLossReportUpdatedSubscription() {
    if (!this.lossReportService.lossReportUpdateSubscription$) {
      this.lossReportService.lossReportUpdateSubscription$ = this.lossReportService.lossReportUpdated.subscribe(() => {
        this.step = this.lossReportService.getStepByRoute(this.router.url);
        this.routingService.navigateNext();
      });
    }
  }

  get step() {
    return this.lossReportService.step;
  }

  set step(el: any) {
    this.lossReportService.step = el;
  }
}
