import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { LogDetails } from '../models/log-details.model';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LossReportService } from './loss-report.service';
import { PoliciesService } from './policies.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyVehicleInfoService {
  autoPolicies = [];
  private policy: any;
  private vehicleType: string;
  private logDetails: LogDetails;

  types = [
    {type: 'suv', categoryCode: '01', bodyStyle: ['SPORT WG']},
    {type: 'truck', categoryCode: '01', bodyStyle: ['PICKUP']},
    {type: 'van', categoryCode: '01', bodyStyle: ['VAN']},
    {type: 'motorcycle', categoryCode: '08'},
    {type: 'travel-trailer', categoryCode: '26'},
  ];

  constructor(private logService: LogService,
              private lossReportService: LossReportService,
              private policiesService: PoliciesService) { }

  setPolicyVehicle(policy: any): void {
    if (policy) {
      this.policy = policy;
      sessionStorage.setItem('policyStore', JSON.stringify(this.policy));
      this.setVehicleType(this.policy.risks[0]);
      this.logVehicleType(this.vehicleType);
    }
  }

  setVehicleType(risk: any){
    const categoryCode = risk.categoryCode;
    const bodyStyle = risk.vehicle.bodyStyle;
    // log categoryCode and bodyStyle for monitoring
    const vehType = this.types.find((type) => {
      const categoryMatches = type.categoryCode === categoryCode;
      if (type.bodyStyle && categoryMatches){
        return type.bodyStyle.includes(bodyStyle) && categoryMatches;
      }
      return categoryMatches;
    });

    this.vehicleType = vehType ? vehType.type : 'car';
  }

  getVehicleType() {
    return this.vehicleType;
  }

  getVehicleDescription() {
    const vehicleDiscriptor = this.getSelectedPolicyVehicle();
    return `${vehicleDiscriptor.year} ${vehicleDiscriptor.make} ${vehicleDiscriptor.model}`;
  }


  getSelectedPolicyVehicle() {
    if (!this.policy){
      this.policy = JSON.parse(sessionStorage.getItem('policyStore'));
      if (this.policy) {
        this.setVehicleType(this.policy.risks[0]);
      }
    }
    if (!this.policy) {  // if policy was not found in sessionStorgage, re-retrieve it.
      this.getPolicyFromPolicyService();
    }
    return this.policy.risks[0].vehicle;
  }

  logVehicleType(vehicleType: string) {
    this.logDetails = this.logService.getLogDetails();
    this.logDetails.vehicleType = vehicleType;
    this.logDetails.serviceName = 'policy-vehicle-info.service.ts';
    this.logService.log(CliffLogCodes.VEH_TYPE, this.logDetails);
  }

  private getPolicyFromPolicyService(): any {
    // get DOL from lossrepot
    let incidentDate = this.lossReportService.getIncidentDate();
    if (incidentDate.length === 10) {
      incidentDate = incidentDate + 'T04:00:00.000Z';
    }
    // get selected policy number from lossreport
    const policyNumberStep = this.lossReportService.getStepByName('policyNumber');
    const policyNumberFromLossReport = policyNumberStep.question.answers[0];

    // call PolicyService to get all policies
    const allowedPolicies = policyNumberStep.question.allowedValues;
    this.policiesService.getPolicies( incidentDate, 'auto').subscribe( (policiesArray: any) => {
      for (const allowedPolicy of allowedPolicies) {
        const match = policiesArray.filter( (policy: any) => policy.policyNumber === allowedPolicy);
        if ( match.length > 0 ) {
          this.autoPolicies.push(match[0]);
        }
      }
      this.setPolicyVehicle(this.autoPolicies.find((policy: any) => policy.policyNumber === policyNumberFromLossReport));
    });
  }
}
