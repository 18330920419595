// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  height: 100%;
}

.lr-address {
    padding-right: 55px !important;
    margin-bottom: 10px;
}
.lr-btn {
    margin-top: 35px;
    margin-left: 50px;
    margin-right: 50px;
}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  }

#location-error-msg {
    margin-top: -10px;
}

agm-map {
    height: 300px;
    width: 100%;
    display: block;
}

@media(min-width: 576px) and (min-height: 300px) {
    agm-map {
        height: 65vh;
        width: 40vw;
    }
}

@media(max-width: 480px) and (max-height: 850px) {
    agm-map {
        margin-top: -30px;
    }
    h3 {
        margin-top: 20px;
    }
    #mobileshow {
        z-index: 1;
        background: none;
        border: none;
        display: block !important;
        text-decoration: underline;
        margin: auto;
        margin-top: -35px;
        margin-bottom: 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/incident-location/enter-location/enter-location.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,QAAQ;EACR,SAAS;EACT,UAAU;EACV,eAAe;EACf;;AAEF;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,gBAAgB;IACpB;IACA;QACI,UAAU;QACV,gBAAgB;QAChB,YAAY;QACZ,yBAAyB;QACzB,0BAA0B;QAC1B,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":["#map {\n  height: 100%;\n}\n\n.lr-address {\n    padding-right: 55px !important;\n    margin-bottom: 10px;\n}\n.lr-btn {\n    margin-top: 35px;\n    margin-left: 50px;\n    margin-right: 50px;\n}\n\n.sr-only {\n  position:absolute;\n  left:-10000px;\n  top:auto;\n  width:1px;\n  height:1px;\n  overflow:hidden;\n  }\n\n#location-error-msg {\n    margin-top: -10px;\n}\n\nagm-map {\n    height: 300px;\n    width: 100%;\n    display: block;\n}\n\n@media(min-width: 576px) and (min-height: 300px) {\n    agm-map {\n        height: 65vh;\n        width: 40vw;\n    }\n}\n\n@media(max-width: 480px) and (max-height: 850px) {\n    agm-map {\n        margin-top: -30px;\n    }\n    h3 {\n        margin-top: 20px;\n    }\n    #mobileshow {\n        z-index: 1;\n        background: none;\n        border: none;\n        display: block !important;\n        text-decoration: underline;\n        margin: auto;\n        margin-top: -35px;\n        margin-bottom: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
