import { HagertySupportingData } from './hagerty-supporting-data.model';

export class HagertyRequest {
    agreementNumber: string;
    applicationName: string;
    intent: string;
    supportingData: HagertySupportingData;
    sourceSystemCode: number;

    constructor() {
        this.agreementNumber = '';
        this.applicationName = '';
        this.intent = '';
        this.supportingData = new HagertySupportingData();
    }

}
