import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'olr-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css']
})
export class LoadingIndicatorComponent implements OnInit {
  @ViewChild('loadingMessage', {static: true}) loadingMessage: ElementRef;

  constructor() { }

  ngOnInit() {
    setTimeout( () => {
      this.loadingMessage.nativeElement.focus();
    }, 0);
  }

}
