import { Component, Inject, Input, OnInit } from '@angular/core';
import { HostnameBuilderService } from '../../../services/helpers/hostname-builder.service';
import { FeatureFlagService } from '../../../services/feature-flag.service';

@Component({
  selector: 'olr-received-auto-ab',
  templateUrl: './received-auto-ab.component.html',
  styleUrls: ['./received-auto-ab.component.css']
})
export class ReceivedAutoABComponent{
  @Input() custExtCID: string;
  @Input() clmId: string;
  @Input() xcid: string
  @Input() repairRole: string;
  @Input() isSTP: boolean;

  constructor(
    @Inject('Window') private window: any,
    private hostnameBuilderService: HostnameBuilderService
  ) {}



  goToClaims() {
    const claimsHubURL = `https://${this.hostnameBuilderService.getAWSHostname('hub.claims')}/#/claim/${this.clmId}/client/${this.custExtCID}/role/NI/auto`;
    this.window.location.href = claimsHubURL;
  }
  goToRepair() {
    const repairAssistantUrl = this.hostnameBuilderService.getRepairAssistantUrl(this.clmId, this.repairRole, this.xcid);
    this.window.location.href = repairAssistantUrl;
  }

}
