import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LossReportingRoutingModule } from './lossreporting-routing.module';
import { MaintenanceComponent } from './maintenance-banner/maintenance.component';
import { SharedModule } from './shared/shared.module';
import { LossReportService } from './services/loss-report.service';
import { RoutingService } from './services/routing.service';
import { GoogleConfigService } from './services/helpers/google-config.service';
// import { AgmCoreModule, GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { IncidentDetailsModule } from './incident-details/incident-details.module';
import { SessionTimeOutModule } from './session-timeout/session-timeout.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { ReviewModule } from './review/review.module';
import { DamageDetailsModule } from './damage-details/damage-details.module';
import { ContactInformationModule } from './contact-information/contact-information.module';
import { OtherVehiclesModule } from './other-vehicles/other-vehicles.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { OptInModule } from './opt-in/opt-in.module';
import { ClaimantModule } from './claimant/claimant.module';
import { HomeandpropertyModule } from './homeandproperty/homeandproperty.module';
import { EntryPointComponent } from './entry-point/entry-point.component';
import { ModalPolicyViewComponent } from './entry-point/modal-policy-view/modal-policy-view.component';
import { CardLoadingComponent } from './entry-point/card-loading/card-loading.component';
import { CardNotFoundComponent } from './entry-point/card-not-found/card-not-found.component';
import { ButtonPolicyOptionComponent } from './entry-point/button-policy-option/button-policy-option.component';
import { DateTimeSelectorComponent } from './entry-point/date-time-selector/date-time-selector.component';
import { DrawerQuestionsWeMayAskComponent } from './shared/drawer-questions-we-may-ask/drawer-questions-we-may-ask.component';
import { DrawerHelpfulTipsComponent } from './shared/drawer-helpful-tips/drawer-helpful-tips.component';
import { DrawerTopQuestionsComponent } from './shared/drawer-top-questions/drawer-top-questions.component';
import { DrawerClaimProcessComponent } from './shared/drawer-claim-process/drawer-claim-process.component';
import { DrawerDeductibleOverviewComponent } from './shared/drawer-deductible-overview/drawer-deductible-overview.component';
import { RedirectAssuredComponent } from './entry-point/redirect-assured/redirect-assured.component';

// naming app.module to specific app olr-app.module
@NgModule({
  declarations: [
    MaintenanceComponent,
    EntryPointComponent,
    ModalPolicyViewComponent,
    CardLoadingComponent,
    CardNotFoundComponent,
    ButtonPolicyOptionComponent,
    DateTimeSelectorComponent,
    DrawerQuestionsWeMayAskComponent,
    DrawerDeductibleOverviewComponent,
    DrawerHelpfulTipsComponent,
    DrawerTopQuestionsComponent,
    DrawerClaimProcessComponent,

  ],
  imports: [
    BrowserModule,
    LossReportingRoutingModule,
    IncidentDetailsModule,
    SessionTimeOutModule,
    ErrorPagesModule,
    ConfirmationModule,
    ReviewModule,
    OptInModule,
    DamageDetailsModule,
    ContactInformationModule,
    OtherVehiclesModule,
    FileUploadModule,
    HttpClientModule,
    SharedModule,
    ClaimantModule,
    HomeandpropertyModule,
    GoogleMapsModule,
    RedirectAssuredComponent
  ],
  providers: [
    LossReportService,
    RoutingService,
    { provide: 'Window', useValue: window },
    { provide: 'Document', useValue: document }
  ],
  exports: [
    SharedModule,
  ]
})
export class LossReportingModule { }
