// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-separator {
    margin: 19px 0;
}
.review-answer-wrapper {
    width: 100%;
    overflow: hidden;
}
.review-answer-body-left {
    width: 90%;
    float: left;
}
.review-answer-body-right {
    text-align: right;
}
.review-answer-title {
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/review-answer-panel/review-answer-panel.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".review-separator {\n    margin: 19px 0;\n}\n.review-answer-wrapper {\n    width: 100%;\n    overflow: hidden;\n}\n.review-answer-body-left {\n    width: 90%;\n    float: left;\n}\n.review-answer-body-right {\n    text-align: right;\n}\n.review-answer-title {\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
