// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-card-container {
    border-bottom: solid 1px #e6e6e6;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
}
.lr-card-details {
    font-weight: normal;
    margin-bottom: 0;
}
.lr-card-standard-header {
    font-size: 12px !important;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/card-standard/card-standard.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".lr-card-container {\n    border-bottom: solid 1px #e6e6e6;\n    padding-bottom: 15px;\n    margin-bottom: 15px;\n    width: 100%;\n}\n.lr-card-details {\n    font-weight: normal;\n    margin-bottom: 0;\n}\n.lr-card-standard-header {\n    font-size: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
