import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { SfAnalyticsService } from 'projects/lossreporting-library/src/lib/services/sf-analytics.service';
import { HostnameBuilderService } from 'projects/lossreporting-library/src/lib/services/helpers/hostname-builder.service';

@Component({
  selector: 'olr-storage-fees-modal',
  templateUrl: './storage-fees-modal.component.html',
  styleUrls: ['./storage-fees-modal.component.css']
})
export class StorageFeesModalComponent implements AfterViewInit, OnDestroy {

  constructor(
    private analytics: SfAnalyticsService,
    private urlService: HostnameBuilderService,
    @Inject('Window') private window: any,
  ) {}

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-storage-fees-modal'));
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-storage-fees-modal'));
  }

  closeModal(): void {
    // @ts-ignore
    this.window['oneX'].Modal.hideModal();
  }

}
