import { Component, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'olr-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css']
})
export class ProgressIndicatorComponent implements AfterViewInit {

  @ViewChild('progressNav', {static: false}) progressNav: ElementRef;
  @Output() closeProgressIndicator = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit(): void {
    this.progressNav.nativeElement.focus();
  }

  onCloseProgressIndicator() {
    this.closeProgressIndicator.emit();
  }

}
