// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-btn-center{
  margin-right: auto;
  margin-left: auto;
}

.lr-button-top-position {
  margin-top: 72px;
}

.lr-btn {
margin-bottom: 32px;
margin-left: 20%;
margin-right: 20%;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/error-pages/unsupported-associate/unsupported-associate.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AACA,mBAAmB;AACnB,gBAAgB;AAChB,iBAAiB;AACjB","sourcesContent":[".lr-btn-center{\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.lr-button-top-position {\n  margin-top: 72px;\n}\n\n.lr-btn {\nmargin-bottom: 32px;\nmargin-left: 20%;\nmargin-right: 20%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
