import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  AfterContentInit} from '@angular/core'; 
import * as moment from 'moment';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-birth-date',
  templateUrl: './birth-date.component.html',
  styleUrls: ['./birth-date.component.css']
})

export class BirthDateComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() setBirthDate = new EventEmitter<string>();
  @Output() toggleOnOff = new EventEmitter<boolean>();
  @Input() error: boolean;
  @Input() futureError: boolean;
  @Input() answer: string;
  @ViewChild('date', { static: false }) date: ElementRef;

  constructor(
    @Inject('Window') private window: any,
    private cdr: ChangeDetectorRef,
    private claimsEventsService: ClaimsEventsService
  ) { }

  inputDate = '';
  inputError: boolean;
  validDate = false;
  isMobileDevice = false;

  ngOnInit() {
    this.isMobileDevice = this.detectMobile();
    this.initCalendar();
  }

  ngOnDestroy(){
    this.window.oneX.removeElement(document.querySelector('#birthDetails'));
  }

  ngAfterViewInit() {
    this.displayAnsweredState();
    this.cdr.detectChanges();
    this.initCalendar();
  }

  displayAnsweredState() {
    if (this.answer) {
      console.log("Log Test Here",this.answer);
      const hasTime = this.answer.length > 10;
      const dateAnswer = moment.utc(this.answer).toISOString();
      const dateTimeParts = dateAnswer.split('T');
      const dateParts = dateTimeParts[0].split('-');

      // @ts-ignore
      this.window.oneX.$('#birth-calendar').val(`${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`).focus();
     }
  }
  // initialize oneX javascript for calendar
  private initCalendar(): void {
    this.window.oneX.addElement(document.querySelector('#birthDetails'));
    // @ts-ignore
    const calendar = this.window.oneX.Birthdate.calendars['birth-calendar'];
    const today = calendar.startDate;
    calendar.setMax(today, true);
  }

  getInputValueDate(event: any) {
    if (event.target.value.length === 10) {
      this.inputDate = event.target.value;
      this.toggleOnOff.emit(true);
      this.setBirthDate.emit(this.inputDate);
    } else {
      this.toggleOnOff.emit(false);
    }
  }

  setInputError(status: boolean): void {
    this.error = status;
    if (status) {
      this.date.nativeElement.focus();
    }
  };

  getFullDate() {
    const date = {
      capturedDate: this.date.nativeElement.value,
    };
    return date;
  }

  private detectMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    // @ts-ignore
    return toMatch.some((toMatchItem) => {
        return this.window.navigator.userAgent.match(toMatchItem);
    });
  }
}
