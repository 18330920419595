import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from 'projects/lossreporting-library/src/lib/services/log.service';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { RoutingService } from 'projects/lossreporting-library/src/lib/services/routing.service';
import { SfAnalyticsService } from 'projects/lossreporting-library/src/lib/services/sf-analytics.service';
import { BaseComponent } from 'projects/lossreporting-library/src/lib/shared/base/base.component';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { LogDetails } from 'projects/lossreporting-library/src/lib/models/log-details.model';

@Component({
  selector: 'olr-incident-sump-pump-fail',
  templateUrl: './incident-sump-pump-fail.component.html',
  styleUrls: ['./incident-sump-pump-fail.component.css', '../../../../shared/styles/question-common-styling.scss']
})
export class IncidentSumpPumpFailComponent extends BaseComponent implements OnInit {

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit(): void {
    this.analytics.sendData('incident-type-sump-pump-fail');
    this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.incidentType = answer;
    this.logService.log(CliffLogCodes.SUMP_PUMP_CAUSE, logDetails);
    this.updateAnswer(answer);
  }
}
