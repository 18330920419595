import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'olr-drawer-claim-process',
  templateUrl: './drawer-claim-process.component.html',
  styleUrls: ['./drawer-claim-process.component.css', '../styles/drawer-common-styling.scss']
})
export class DrawerClaimProcessComponent implements OnInit, OnDestroy, AfterViewInit {

  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice = false;
  pageNumber = 0;
  forwardEnabled = true;
  previousRoute = false;
  isAuthenticated = true;
  totalNumberOfPages = 3;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
  ) {
    this.isMobileDevice = window.innerWidth <= DrawerClaimProcessComponent.oneXSmallBreakpointPx;
  }

  ngOnInit(): void {
    this.pageNumber = 1;
    this.forwardEnabled = true;
    this.previousRoute = false;

    if (this.isAuthenticated) {
      this.totalNumberOfPages = 4;
    }

  }
  ngAfterViewInit(): void {
    setTimeout(()=>{this.window['oneX'].addElement(document.querySelector('#olr-drawer-deductible-overview'));}, 1);
  }

  ngOnDestroy() {
    this.window['oneX'].removeElement(document.querySelector('#olr-drawer-deductible-overview'));
    this.pageNumber = 0;
  }
}
