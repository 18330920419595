import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { ClaimsEventsService } from '../../../services/claims-events.service';
import { BirthDateComponent } from '../../../shared/birth-date/birth-date.component';

@Component({
  selector: 'olr-claimant-dob',
  templateUrl: './claimant-dob.component.html',
  styleUrls: ['./claimant-dob.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ClaimantDOBComponent extends BaseComponent implements AfterViewInit, OnInit {
  @ViewChild(BirthDateComponent) birthdate: BirthDateComponent;

  birthDate: any = '';
  error: boolean;
  fullDateObject: any;
  futureError: boolean;
  dateFormatString = 'MM-DD-YYYY';
  answer = '';
  isDateProvided = false;

  ngAfterViewInit() {
    this.fullDateObject = this.birthdate.getFullDate();
  }

  constructor(
    private analyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analyticsService.sendData('claimantdob-question');
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      this.answer = answers[0];
      this.isDateProvided = true;
    }
  }

  // expects date in ISO 8601 format. ex: 2018-01-15T12:00:00.000Z
  answerQuestion(): void {
    const validDate = this.validateDateNotFuture();
    if (validDate) {
      this.updateAnswer(validDate);
    }
  }
  toggleContinue(event: any) {
    this.isDateProvided = event;
  }

  validateDate(event: any): boolean {
    if (this.dateIsInvalid(event)) {
      this.isDateProvided = false;
      return false;
    }
    if (this.dateIsFuture(event)) {
      this.isDateProvided = false;
      return false;
    }
    this.isDateProvided = true;
    return true;
  }

  dateIsInvalid(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isValid()) {
      this.error = false;
      return false;
    }
    this.error = true;
    this.futureError = false;
    return true;
  }

  dateIsFuture(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isBefore(moment.now())) {
      this.futureError = false;
      return false;
    }
    this.futureError = true;
    return true;
  }

  // not valid: returns false --- valid: returns Date as ISO 8601
  validateDateNotFuture(): any {
    const dateTimeObject = this.birthdate.getFullDate();
    this.birthDate = dateTimeObject.capturedDate;
    if (this.validateDate(this.birthDate)) {
      return moment.utc(this.birthDate, this.dateFormatString).toISOString();
    } else {
      return false;
    }
  }
}
