// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-livable-continue-button {
  margin-top: 50px;
  margin-bottom: 50px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/homeandproperty/homeandproperty-incident-details/homeandproperty-not-livable-details/homeandproperty-not-livable-details.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".not-livable-continue-button {\n  margin-top: 50px;\n  margin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
