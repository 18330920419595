import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  AfterContentInit} from '@angular/core';
import * as moment from 'moment';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.css']
})

export class DatetimePickerComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  @Output() setIncidentDate = new EventEmitter<string>();
  @Output() toggleOnOff = new EventEmitter<boolean>();
  @Input() error: boolean;
  @Input() futureError: boolean;
  @Input() timeError: boolean;
  @Input() answer: string;
  @ViewChild('date', { static: false }) date: ElementRef;
  @ViewChild('hour', { static: false }) hour: ElementRef;
  @ViewChild('minute', { static: false }) minute: ElementRef;
  @ViewChild('am', { static: false }) am: ElementRef;
  @ViewChild('pm', { static: false }) pm: ElementRef;
  @ViewChild('singleTime', { static: false }) singleTime: ElementRef;
  @ViewChild('hourFallback', { static: false }) hourFallback: ElementRef;
  @ViewChild('minuteFallback', { static: false }) minuteFallback: ElementRef;
  @ViewChild('meridiemFallback', { static: false }) meridiemFallback: ElementRef;
  hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
  ];

  constructor(
    @Inject('Window') private window: any,
    private cdr: ChangeDetectorRef,
    private claimsEventsService: ClaimsEventsService
  ) { }

  inputDate = '';
  inputError: boolean;
  validDate = false;
  timeOpen = false;
  supportsTimeType = true;
  isMobileDevice = false;

  ngOnInit() {
    // this.claimsEventsService.buildEvent('Claim Started - Claimant');
    this.isMobileDevice = this.detectMobile();
    this.initCalendar();
  }

  ngOnDestroy(){
    this.window.oneX.removeElement(document.querySelector('#incidentDetails'));
  }

  ngAfterContentInit() {
    this.supportsTimeType = this.getSupportsTimeType();
  }

  ngAfterViewInit() {
    this.displayAnsweredState();
    this.cdr.detectChanges();
    this.initCalendar();
  }

  displayAnsweredState() {
    if (this.answer) {
      const hasTime = this.answer.length > 10;
      const dateAnswer = moment.utc(this.answer).toISOString();
      const dateTimeParts = dateAnswer.split('T');
      const dateParts = dateTimeParts[0].split('-');
      // @ts-ignore
      this.window.oneX.$('#incident-calendar').val(`${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`).focus();

      if (dateTimeParts.length > 1 && hasTime) {
        const timeParts = dateTimeParts[1].split(':');
        let hour = parseInt(timeParts[0], 10);

        if (this.isMobileDevice && !this.supportsTimeType) {
          // @ts-ignore
          this.window.oneX.$('#meridiemFallback').val(hour >= 12 && hour !== 0 ? 'pm' : 'am').change();
          hour = hour > 12 ? hour - 12 : hour;
           // @ts-ignore
          this.window.oneX.$('#hourFallback').val(hour).change();
          // @ts-ignore
          this.window.oneX.$('#minuteFallback').val(timeParts[1]).change();
        } else if (this.isMobileDevice) {
          // @ts-ignore
          this.window.oneX.$('#singleTime').val(`${timeParts[0]}:${timeParts[1]}`).change();
        } else {
          // @ts-ignore
          this.window.oneX.$(`#${hour >= 12 && hour !== 0 ? 'pm' : 'am'}`).prop('checked', true).trigger('click');
          hour = hour > 12 ? hour - 12 : hour;
          // @ts-ignore
          this.window.oneX.$('#hour').val(hour === 0 ? 12 : hour).change();
          // @ts-ignore
          this.window.oneX.$('#minute').val(timeParts[1]).change();
        }

        this.timeOpen = true;
      }
    }
  }

  toggleTimeOpen(): void {
    this.timeOpen = !this.timeOpen;
  }

  // initialize oneX javascript for calendar
  private initCalendar(): void {
    this.window.oneX.addElement(document.querySelector('#incidentDetails'));
    // @ts-ignore
    const calendar = this.window.oneX.Datepicker.calendars['incident-calendar'];
    const today = calendar.startDate;
    calendar.setMax(today, true);
  }

  getInputValueDate(event: any) {
    if (event.target.value.length === 10) {
      this.inputDate = event.target.value;
      this.toggleOnOff.emit(true);
      this.setIncidentDate.emit(this.inputDate);
    } else {
      this.toggleOnOff.emit(false);
    }
  }

  setInputError(status: boolean): void {
    this.error = status;
    if (status) {
      this.date.nativeElement.focus();
    }
  }

  getFullDateTime() {
    const dateTime = {
      capturedDate: this.date.nativeElement.value,
      capturedHour: this.hour.nativeElement.value,
      capturedMinute: this.minute.nativeElement.value,
      ampm: ''
    };

    // if singleTime is not empty then the time is coming from moblie device
    if (this.isMobileDevice) {
      this.convertSingleTime(dateTime);
    } else {
      this.setAmPm(dateTime);
    }
    return dateTime;
  }

  private setAmPm(dateTime: { capturedDate: any; capturedHour: any; capturedMinute: any; ampm: string; }) {
    if (this.am.nativeElement.checked) {
      dateTime.ampm = 'am';
    }
    else {
      dateTime.ampm = 'pm';
    }
  }

  // get the time from the native component and convert it to the hh:mm:a format
  private convertSingleTime(dateTime: { capturedDate: any; capturedHour: any; capturedMinute: any; ampm: string; }) {
    if (this.supportsTimeType) {
      if (!this.singleTime.nativeElement.value || this.singleTime.nativeElement.value.split(':').length < 2) {
        dateTime.capturedHour = '';
        dateTime.capturedMinute = '';
        return;
      }

      const theTime = this.singleTime.nativeElement.value.split(':');

      if (theTime[0] < 12) {
        dateTime.capturedHour = theTime[0];
        dateTime.capturedMinute = theTime[1];
        dateTime.ampm = 'am';
      }
      if (theTime[0] === '12') {
        dateTime.capturedHour = theTime[0];
        dateTime.capturedMinute = theTime[1];
        dateTime.ampm = 'pm';
      }
      if (theTime[0] > 12) {
        dateTime.capturedHour = theTime[0] - 12;
        dateTime.capturedMinute = theTime[1];
        dateTime.ampm = 'pm';
      }
    } else {
      dateTime.capturedHour = this.hourFallback.nativeElement.value;
      dateTime.capturedMinute = this.minuteFallback.nativeElement.value;
      dateTime.ampm = this.meridiemFallback.nativeElement.value;
    }
  }

  private detectMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    // @ts-ignore
    return toMatch.some((toMatchItem) => {
        return this.window.navigator.userAgent.match(toMatchItem);
    });
  }

  private getSupportsTimeType() {
    let supportsTimeType: boolean;
    const input = document.createElement('input');

    try {
      input.type = 'time';
    } catch (err) {
      // just need to catch error, nothing to do here
    }

    if (input.type === 'text') {
      supportsTimeType = false;
    } else {
      supportsTimeType = true;
    }

    return supportsTimeType;
  }
}
