import { Component, OnInit, Inject } from '@angular/core';
import { BaseComponent } from '../../../shared/base/base.component';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { Router } from '@angular/router';
import { LogService } from '../../../services/log.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';

@Component({
  selector: 'olr-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class VehicleSelectionComponent extends BaseComponent implements OnInit {

  answer: string;
  risks: any[] = [];

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    @Inject('Window') private window: any
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('vehicle-list');
    this.risks = this.step?.question.displayContent;
    const answers = this.step?.question.answers;
    if (answers && answers.length > 0) {
      this.answer = answers[0];
    }
  }

  getRiskDescription(risk: any): string {
    return this.getKeyValue(risk.value, 'description');
  }

  selectRisk(risk: any): void {
    this.updateAnswer(risk.key);
  }

  isSelected(risk: any): boolean {
    return risk.key === this.answer;
  }

  showModal(): void {
    this.analytics.sendData('need-info-modal');
    this.window['oneX'].Modal.showModal(this, 'olr-modal-missing-vehicle');
  }

  private getKeyValue(attributes: any[], key: string): string {
    let found: any;
    for(const attribute of attributes) {
      if(attribute.key === key) {
        found = attribute;
        break;
      }
    }
    return found ? found.value[0] : '';
  }

}
