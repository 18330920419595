import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-reporter-type',
  templateUrl: './reporter-type.component.html',
  styleUrls: ['./reporter-type.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class ReporterTypeComponent extends BaseComponent implements OnInit {
  answer = '';

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit(): void {
    this.analytics.sendData('reporting-entity');
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      this.answer = answers[0];
    }
  }

  private updateScreenChannel(reporterType: string) {
    const screenChannel = {
      individual: 'filealoss-claimant',
      otherInsuranceCompany: 'filealoss-OIC'
    };
    this.analytics.setScreenChannel(screenChannel[reporterType]);
  }

  isSelected(reporterType: string): boolean {
    return reporterType === this.answer;
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.claimantReporterType = answer;
    this.logService.log(CliffLogCodes.CLAIMANT_REPORTER_TYPE, logDetails);
    this.updateScreenChannel(answer);
    this.updateAnswer(answer);
  }

}
