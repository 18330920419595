import { Component, OnInit, ElementRef, ViewChild, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { AddressConstants } from '../../../shared/constants/address-constants';
import { Subscription } from 'rxjs';
import { PrimaryPolicySearchService } from '../../../services/primary-policy-search.service';

@Component({
  selector: 'olr-secondary-policy-search',
  templateUrl: './secondary-policy-search.component.html',
  styleUrls: ['./secondary-policy-search.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class SecondaryPolicySearchComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('phoneNumber', {static: true}) phoneNumber: ElementRef;
  @ViewChild('lastName', {static: true}) lastName: ElementRef;
  @ViewChild('vehicleMake', {static: true}) vehicleMake: ElementRef;
  @ViewChild('street1', {static: true}) street1: ElementRef;
  @ViewChild('street2', {static: true}) street2: ElementRef;
  @ViewChild('city', {static: true}) city: ElementRef;
  @ViewChild('state', {static: true}) state: ElementRef;
  @ViewChild('postalCode', {static: true}) postalCode: ElementRef;
  countries = AddressConstants.countryStates;
  static readonly oneXSmallBreakpointPx = 768;
  isPhoneNumberError = false;
  isLastNameError = false;
  isVehicleMakeError = false;
  isStreet1Error = false;
  isStreet2Error = false;
  isCityError = false;
  isStateError = false;
  isPostalError = false;
  isAddressError: boolean;
  showPhoneNumber = false;
  showLastName = false;
  showVehicleMake = false;
  showAddress = false;
  showStreet1 = false;
  isValueProvided = false;
  makeOptions = [];
  states = [];
  insuredLastNameSubQuestion: any;
  phoneNumberSubQuestion: any;
  makeSubQuestion: any;
  addressSubQuestion: any;
  makeAnswer = '';
  stateAnswer = '';
  addressAnswer = {};
  whichField = "";
  phrase: string;
  phrase2: string;
  sfNumber = "800-732-5246";
  attemptedAddresses = {};
  attemptedPhones = {};
  attemptedLastNames = {};
  attemptedMakes = {};
  secondAttemptAddress = false;
  secondAttemptMake = false;
  secondAttemptLastName = false;
  secondAttemptPhone = false;
  unprocessableSub$: Subscription;
  isUnprocessable = false;
  lastSpaceError = false;
  isMobileDevice: boolean;

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    public searchService: PrimaryPolicySearchService,
    private analytics: SfAnalyticsService,
    @Inject('Window') private window: any,
  ) {
    super(lossReportService, router, routingService, logService);
    this.setStates();
    this.isMobileDevice = window.innerWidth <= SecondaryPolicySearchComponent.oneXSmallBreakpointPx;
   }

  ngOnInit(): void {
    this.analytics.sendData('insured-contact-info');
    this.getOptions();
    this.getSubQuestions();
    this.displayAnsweredState();


  }

  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < SecondaryPolicySearchComponent.oneXSmallBreakpointPx;
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.unprocessableSub$);
  }

  private unsubscribe(subscription: Subscription): void {
    if (subscription) { subscription.unsubscribe(); }
  }

  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#matchFields'));
  }

  private getSubQuestions() {
    this.insuredLastNameSubQuestion = this.step?.question.subQuestions[0][0];
    this.phoneNumberSubQuestion = this.step?.question.subQuestions[0][1];
    this.makeSubQuestion = this.step?.question.subQuestions[0][2];
    this.addressSubQuestion = this.step?.question.subQuestions[0][3];
  }

  private getOptions() {
    this.makeOptions = this.step.question.subQuestions[0][2].allowedValues;
  }

  private setStates(): void {
    const subject = this.countries.find((country) => {
      return country.id === 'usa';
    });
    this.states = subject.states;
  }

  private displayAnsweredState(){
    this.lastName.nativeElement.value = this.insuredLastNameSubQuestion.answers[0] || '';
    if(this.lastName.nativeElement.value){
      this.showLastName = true;
      this.window.oneX.$(`#lastname-radio`).click();
    }
    this.phoneNumber.nativeElement.value = this.phoneNumberSubQuestion.answers[0] || '';
    if(this.phoneNumber.nativeElement.value){
      this.showPhoneNumber = true;
      this.window.oneX.$(`#phonenumber-radio`).click();
    }
    this.makeAnswer = this.makeSubQuestion.answers[0] || '';
    if(this.makeAnswer){
      this.showVehicleMake = true;
      this.window.oneX.$(`#vehiclemake-radio`).click();
    }
    this.addressAnswer = this.getAddressAnswers();
    if(Object.keys(this.addressAnswer).length !== 0 && this.addressAnswer.constructor === Object){
      this.street1.nativeElement.value = this.addressAnswer['street1'];
      this.street2.nativeElement.value = this.addressAnswer['street2'];
      this.city.nativeElement.value = this.addressAnswer['city'];
      this.stateAnswer = this.addressAnswer['state'];
      this.postalCode.nativeElement.value = this.addressAnswer['postalCode'];
      this.showAddress = true;
      this.window.oneX.$(`#address-radio`).click();
    }
    this.updateContinueButtonVisibility();
  }

  private getAddressAnswers(): any {
    const street1 = this.addressSubQuestion.subQuestions[0][0].answers[0] || '';
    const street2 = this.addressSubQuestion.subQuestions[0][1].answers[0] || '';
    const city = this.addressSubQuestion.subQuestions[0][2].answers[0] || '';
    const state = this.addressSubQuestion.subQuestions[0][3].answers[0] || '';
    const postalCode = this.addressSubQuestion.subQuestions[0][4].answers[0] || '';
    if(street1 && city && state && postalCode){
      return {
        street1: street1,
        street2: street2,
        city: city,
        state: state,
        postalCode: postalCode
      };
    }

    return {};
  }

  setPhraseNField(field: string){
    this.whichField = field;
    if(this.whichField){
    this.phrase = `Unable to find a match for ${this.whichField}. Please try again.`;
    this.phrase2 = `Unable to find a match for ${this.whichField}. To report an incident with a State Farm insured, please call us at 800-SF-CLAIM `;
    }
  }

  selectRadio(selection: string) {
    this.showPhoneNumber = selection === 'phone';
    if(this.showPhoneNumber){
      this.lastName.nativeElement.value = '';
      this.vehicleMake.nativeElement.value = '';
      this.setPhraseNField("phone");
      this.resetAddressFields();
      this.secondAttemptAddress = false;
      this.secondAttemptMake = false;
      this.secondAttemptLastName = false;
      this.resetAllError();
    }

    this.showLastName = selection === 'lname';
    if(this.showLastName){
      this.phoneNumber.nativeElement.value = '';
      this.vehicleMake.nativeElement.value = '';
      this.setPhraseNField("Last name");
      this.resetAddressFields();
      this.secondAttemptAddress = false;
      this.secondAttemptMake = false;
      this.secondAttemptPhone = false;
      this.resetAllError();
    }

    this.showVehicleMake = selection === 'make';
    if(this.showVehicleMake){
      this.phoneNumber.nativeElement.value = '';
      this.lastName.nativeElement.value = '';
      this.setPhraseNField("Make");
      this.resetAddressFields();
      this.secondAttemptAddress = false;
      this.secondAttemptLastName = false;
      this.secondAttemptPhone = false;
      this.resetAllError();
    }

    this.showAddress = selection === 'address';
    if(this.showAddress){
      this.phoneNumber.nativeElement.value = '';
      this.vehicleMake.nativeElement.value = '';
      this.lastName.nativeElement.value = '';
      this.setPhraseNField("Address");
      this.secondAttemptMake = false;
      this.secondAttemptLastName = false;
      this.secondAttemptPhone = false;
      this.resetError();
    }

    this.isValueProvided = false;
  }

  resetAddressFields(){
    this.street1.nativeElement.value = '';
    this.street2.nativeElement.value = '';
    this.city.nativeElement.value = '';
    this.state.nativeElement.value = '';
    this.postalCode.nativeElement.value = '';
  }

  updateContinueButtonVisibility() {

    this.isAddressError = false;
    this.secondAttemptAddress = false;
    this.isLastNameError = false;
    this.secondAttemptLastName = false;
    this.isPhoneNumberError = false;
    this.secondAttemptPhone = false;
    this.isVehicleMakeError = false;
    this.secondAttemptMake = false;
    if(this.showPhoneNumber){
      let phoneAns = this.phoneNumber.nativeElement.value;
      let answered = phoneAns ? this.formattedPhoneNumber().length > 9 : false;
      if(answered){
        this.isValueProvided = true;
        this.phoneNumberChange();
      }else{
        this.isValueProvided = false;
      }
    } else if(this.showLastName){
      let lastNameAns = this.lastName.nativeElement.value;
      let answered = lastNameAns ? lastNameAns.length > 0 : false;
      if(answered){
        this.isValueProvided = true;
        this.lastNameChange();
      }else{
        this.isValueProvided = false;
      }
    } else if(this.showVehicleMake){
      let vehicleMakeAns = this.vehicleMake.nativeElement.value;
      let answered = vehicleMakeAns ? vehicleMakeAns.length > 0 : false;
      if(answered){
        this.isValueProvided = true;
        this.vehicleMakeChange();
      }else{
        this.isValueProvided = false;
      }
    } else if(this.showAddress){
      let street1Ans = this.street1.nativeElement.value;
      let cityAns = this.city.nativeElement.value;
      let stateAns = this.state.nativeElement.value;
      let postalCodeAns = this.postalCode.nativeElement.value;
      let answered = (street1Ans ? street1Ans.length > 0 : false) && (cityAns ? cityAns.length > 0 : false)
        && (stateAns ? stateAns.length > 0 : false) && (postalCodeAns ? postalCodeAns.length === 5 : false);

      let theAddress = `{street1: ${street1Ans},street2: ${this.street2.nativeElement.value},city: ${cityAns},state: ${stateAns},postalCode: ${postalCodeAns}}`;

      if(answered){
        this.isValueProvided = true;
        this.isAddressError = false;
        this.addressChange(theAddress);
      }else{
        this.isValueProvided = false;

      }
    }else {
      this.isValueProvided = false;
    }
  }

  private formattedPhoneNumber(): string {
    const phone: string = this.phoneNumber.nativeElement.value || '';
    return phone.replace(/[^0-9\.]+/g, '');
  }

  resetAllError(){
    this.isAddressError = false;
    this.isPhoneNumberError = false;
    this.isLastNameError = false;
    this.isVehicleMakeError = false;
    this.isStreet1Error = false;
    this.isStreet2Error = false;
    this.isCityError = false;
    this.isStateError = false;
    this.isPostalError = false;

  }
  resetError() {

    if(this.showAddress){
      this.isPhoneNumberError = false;
      this.isLastNameError = false;
      this.isVehicleMakeError = false;
      this.isPhoneNumberError = false;
      this.isVehicleMakeError = false;
      this.isAddressError = false;
    }
    if(this.showLastName){
      this.isPhoneNumberError = false;
      this.isVehicleMakeError = false;
      this.isStreet1Error = false;
      this.isStreet2Error = false;
      this.isCityError = false;
      this.isStateError = false;
      this.isPostalError = false;
      this.isAddressError = false;
    }
    if(this.showPhoneNumber){
      this.isVehicleMakeError = false;
      this.isLastNameError = false;
      this.isStreet1Error = false;
      this.isStreet2Error = false;
      this.isCityError = false;
      this.isStateError = false;
      this.isPostalError = false;
      this.isAddressError = false;
    }
    if(this.showVehicleMake){
      this.isPhoneNumberError = false;
      this.isLastNameError = false;
      this.isStreet1Error = false;
      this.isStreet2Error = false;
      this.isCityError = false;
      this.isStateError = false;
      this.isPostalError = false;
      this.isAddressError = false;
    }
  }


  checkFields(){
    if(this.showAddress){
      const answer2 = `{street1: ${this.street1.nativeElement.value},street2: ${this.street2.nativeElement.value},city: ${this.city.nativeElement.value},state: ${this.state.nativeElement.value},postalCode: ${this.postalCode.nativeElement.value}}`
      this.checkAttemptedAddress(answer2);
    }
    if(this.showLastName){
      this.checkAttemptedLastName(this.lastName.nativeElement.value);
    }
    if(this.showPhoneNumber){
      this.checkAttemptedPhones(this.phoneNumber.nativeElement.value);

    }
    if(this.showVehicleMake){
      this.checkAttemptedMakes(this.vehicleMake.nativeElement.value);
    }
  }


  checkAttemptedMakes(makeString: string){
    const hasMake = this.attemptedMakes[makeString];
    if(hasMake === undefined){
      this.attemptedMakes[makeString] = false;
      this.secondAttemptMake = false;
    }
    if(hasMake === false){
      this.attemptedMakes[makeString] = true;
      this.secondAttemptMake = true;
      this.isUnprocessable = false;
    }

  }

  checkAttemptedPhones(phoneString: string){
    const hasPhoneNumber = this.attemptedPhones[phoneString]
    if(hasPhoneNumber === undefined){
      this.attemptedPhones[phoneString] = false;
      this.secondAttemptPhone = false;
    }
    if(hasPhoneNumber === false){
      this.attemptedPhones[phoneString] = true;
      this.secondAttemptPhone = true;
      this.isUnprocessable = false;
    }

  }

  checkAttemptedAddress(address: string){
    const hasAddress = this.attemptedAddresses[address];
    if(hasAddress === undefined){
      this.attemptedAddresses[address] = false;
      this.secondAttemptAddress = false;
    }
    if(hasAddress === false){
      this.attemptedAddresses[address] = true;
      this.secondAttemptAddress = true;
      this.isUnprocessable = false;
    }

  }

  checkAttemptedLastName(name: string){
    const hasName = this.attemptedLastNames[name];
    if(hasName === undefined){
      this.attemptedLastNames[name] = false;
      this.secondAttemptLastName = false;
    }
    if(hasName === false){
      this.attemptedLastNames[name] = true;
      this.secondAttemptLastName = true;
      this.isUnprocessable = false;
    }

  }

  addressChange(someObj: string){

    if(this.showAddress && this.isValueProvided){

      const containsAddress = this.attemptedAddresses[someObj]
      if(containsAddress === undefined){
        this.secondAttemptAddress = false;
        this.isAddressError = false;
        this.isUnprocessable = false;
      }else if(containsAddress === false){
        this.isUnprocessable = true;
      }else{
        this.secondAttemptAddress = true;
        this.isUnprocessable = false;
      }
    }
  }

  vehicleMakeChange(){
    const makeValue = this.vehicleMake.nativeElement.value;
    const containsMake = this.attemptedMakes[makeValue];
    if(this.showVehicleMake && this.isValueProvided){
      if(containsMake === undefined){
        this.secondAttemptMake = false;
        this.isUnprocessable = false;
        this.isVehicleMakeError = false;
      }else if(containsMake === false){
        this.isUnprocessable = true;
      }else{
        this.secondAttemptMake = true;
        this.isUnprocessable = true;
      }
    }
  }

  lastNameChange(){
    const lastNameValue = this.lastName.nativeElement.value;
    const containsLastName = this.attemptedLastNames[lastNameValue];
    if(this.showLastName && this.isValueProvided){
      if(containsLastName === undefined){
        this.secondAttemptLastName = false;
        this.isUnprocessable = false;
        this.isLastNameError = false;
      }else if(containsLastName === false){
        this.isUnprocessable = true;
      }else{
        this.secondAttemptLastName = true;
        this.isUnprocessable = false;
      }
    }
  }

  phoneNumberChange(){
    const phoneNumbervalue = this.phoneNumber.nativeElement.value;
    const containsNumber = this.attemptedPhones[phoneNumbervalue];
    if(this.showPhoneNumber && this.isValueProvided){
      if(containsNumber === undefined){
        this.secondAttemptPhone = false;
        this.isUnprocessable = false;
        this.isPhoneNumberError = false;
      }else if(containsNumber === false){
        this.isUnprocessable = true;
      }else{
        this.secondAttemptPhone = true;
        this.isUnprocessable = false;
      }
    }
  }

  setFieldError(field:string){

    const fieldObjControl = {
      "Phone #": this.isPhoneNumberError = true,
      "Make": this.isVehicleMakeError = true,
      "Last name": this.isLastNameError = true,
      "Address": this.isAddressError = true
    }
    fieldObjControl[field]
  }
  answerQuestion() {
    const logDetails = this.logService.getLogDetails();
    const areStringsValid = this.validateStringAnswers();
    if (areStringsValid) {
      this.updateSubQuestions([this.answerPolicyMatchSubQuestion()]);
    }else{
      this.isUnprocessable = true;
    }

    this.unprocessableSub$ = this.searchService.unprocessableNotificationSubject.subscribe(response =>{
      if(response===true){
        this.setFieldError(this.whichField);
      }else{
        this.resetError()
      }
    })

  }

  private validateStringAnswers(): boolean {
    if(this.showLastName){
      const isLastNameValid = this.regexValidate(
        this.lastName.nativeElement.value,
        this.insuredLastNameSubQuestion.allowedValuesRegex,
        this.insuredLastNameSubQuestion.minAnswers
        );
      this.isLastNameError = !isLastNameValid;
      if(this.isLastNameError){
        if(this.attemptedLastNames[this.lastName.nativeElement.value] === undefined){
          this.attemptedLastNames[this.lastName.nativeElement.value] = false;
        }else if(this.attemptedLastNames[this.lastName.nativeElement.value] === false){
          this.attemptedLastNames[this.lastName.nativeElement.value] = true;
        }else{
          this.secondAttemptLastName = true;
        }
        // set policy number errorMsg for analytics
        this.analytics.sendData('insured-contact-info', null, '', 'Unable to find a match for Policy #. Please try again.');
      }
      return isLastNameValid;
    } else if(this.showPhoneNumber){
      const isPhoneNumberValid = this.regexValidate(
        this.formattedPhoneNumber(),
        this.phoneNumberSubQuestion.allowedValuesRegex,
        this.phoneNumberSubQuestion.minAnswers
        );
      this.isPhoneNumberError = !isPhoneNumberValid;
      if(this.isPhoneNumberError){
        if(this.attemptedPhones[this.phoneNumber.nativeElement.value] === undefined){
          this.attemptedPhones[this.phoneNumber.nativeElement.value] = false;
        }else if(this.attemptedPhones[this.phoneNumber.nativeElement.value] === false){
          this.attemptedPhones[this.phoneNumber.nativeElement.value] = true;
        }else{
          this.secondAttemptPhone = true;
        }
        // set vin number errorMsg for analytics
        this.analytics.sendData('insured-contact-info', null, '', 'Unable to find a match for VIN. Please try again.');
      }
      return isPhoneNumberValid;
    } else if(this.showVehicleMake){
      const makeAns = this.vehicleMake.nativeElement.value || '';
      return makeAns.length > 0;
    } else {

      const street1SubQuestion = this.addressSubQuestion.subQuestions[0][0];
      const isStreet1Valid = this.regexValidate(
        this.street1.nativeElement.value,
        street1SubQuestion.allowedValuesRegex,
        street1SubQuestion.minAnswers
        );
      this.isStreet1Error = !isStreet1Valid;
      if(this.isStreet1Error){
        // set address errorMsg for analytics
        this.analytics.sendData('insured-contact-info', null, '', 'Unable to find a match for Address. Please try again.');
      }

      const street2SubQuestion = this.addressSubQuestion.subQuestions[0][1];
      const isStreet2Valid = this.regexValidate(
        this.street2.nativeElement.value,
        street2SubQuestion.allowedValuesRegex,
        street2SubQuestion.minAnswers
      );


      const citySubQuestion = this.addressSubQuestion.subQuestions[0][2];
      const isCityValid = this.regexValidate(
        this.city.nativeElement.value,
        citySubQuestion.allowedValuesRegex,
        citySubQuestion.minAnswers
      );

      const stateSubQuestion = this.addressSubQuestion.subQuestions[0][3];
      const isStateValid = this.regexValidate(
        this.state.nativeElement.value,
        stateSubQuestion.allowedValuesRegex,
        stateSubQuestion.minAnswers
      );

      const postalCodeSubQuestion = this.addressSubQuestion.subQuestions[0][4];
      const isPostalCodeValid = this.regexValidate(
        this.postalCode.nativeElement.value,
        postalCodeSubQuestion.allowedValuesRegex,
        postalCodeSubQuestion.minAnswers
      );



      return isStreet1Valid && isStreet2Valid && isCityValid && isStateValid && isPostalCodeValid;
    }
  }

  private answerPolicyMatchSubQuestion() {
    const matchValues = [];
    matchValues.push(this.createAnsweredQuestion('1002', 'insuredLastName', this.lastName.nativeElement.value));
    matchValues.push(this.createAnsweredQuestion('1002F', 'phoneNumber', this.formattedPhoneNumber()));
    matchValues.push(this.createAnsweredQuestion('1002D', 'make', this.vehicleMake.nativeElement.value));
    matchValues.push(this.createAnsweredQuestion('1014M', 'policyMatchAddress', null, this.answerAddressSubQuestion()));
    return matchValues;
  }

  private answerAddressSubQuestion(): any{
    const addressAnswers = [];
    addressAnswers.push(this.createAnsweredQuestion('1030NCR', 'street1', this.street1.nativeElement.value || ''));
    addressAnswers.push(this.createAnsweredQuestion('1031NC', 'street2', this.street2.nativeElement.value || ''));
    addressAnswers.push(this.createAnsweredQuestion('1032NCR', 'city', this.city.nativeElement.value || ''));
    addressAnswers.push(this.createAnsweredQuestion('1033NCR', 'state', this.state.nativeElement.value || ''));
    addressAnswers.push(this.createAnsweredQuestion('1037NCR', 'postalCode', this.postalCode.nativeElement.value || ''));
    return addressAnswers;
  }

  get hasSpace(): boolean {
    return /\s/.test(this.lastName.nativeElement.value);
  }

}
