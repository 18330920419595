import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { LossReportService } from '../../services/loss-report.service';

@Component({
  selector: 'olr-modal-policy-not-found',
  templateUrl: './modal-policy-not-found.component.html',
  styleUrls: ['./modal-policy-not-found.component.css']
})
export class ModalPolicyNotFoundComponent implements OnInit, AfterViewInit, OnDestroy {
  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice = false;
  isClaimantLossReport = false;
  isFireLossReport = false;
  isCalled = false;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
    private lossReportService: LossReportService,
    private urlService: HostnameBuilderService,
    ) {
      this.isMobileDevice = window.innerWidth <= ModalPolicyNotFoundComponent.oneXSmallBreakpointPx;

      if (this.router.url) {
        this.isClaimantLossReport = this.router.url.includes('/claimant/');
        this.isFireLossReport = this.router.url.includes('/homeandproperty/');
      }
    }

  ngOnInit(): void {
    this.isMobileDevice = this.detectMobile();
    this.isCalled = true;
  }

  ngAfterViewInit(): void {
    this.window.oneX.addElement(document.querySelector('#olr-modal-policy-not-found'));
  }
  ngOnDestroy(): void {
    this.window.oneX.removeElement(document.querySelector('#olr-modal-policy-not-found'));
  }
  onResize(event:any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < ModalPolicyNotFoundComponent.oneXSmallBreakpointPx;
  }

  get editInfoText(): string {
    return this.getPage() === 'incident-date' ? 'Enter a different date' : 'Edit your information';
  }

  get modalHeaderText(): string {
    return this.getPage() === 'incident-date' ? 'We didn\'t find a policy with coverage on ' + this.getDateOfLoss() : 'We didn\'t find a policy with that information';
  }

  get helpText(): string {
    return 'or ' + (this.getPage() !== 'incident-date' ? 'to report an incident for a vehicle you don\'t own, or if you don\'t see your vehicle please ' : '') + 'call us at 800-SF-CLAIM (800-732-5246).';
  }

  // get helpTextMobile(): string {
  //   return 'or ' + (this.getPage() !== 'incident-date' ? 'to report an incident for a vehicle you don\'t own, or if you don\'t see your vehicle please ' : '') + 'call us at';
  // }

  private getPage(): string {
    if (this.router == null || this.router.url == null) return 'Edit your information';
    const urlArray = this.router.url.split("/");
    return urlArray[urlArray.length - 1];
  }

  private getDateOfLoss() {
    if (!this.lossReportService || !this.lossReportService.getStepByName('dateOfLoss')) return '';

    const step = this.lossReportService.getStepByName('dateOfLoss');
    const answer = step.question.answers && step.question.answers.length > 0 ? step.question.answers[0] : '';
    const splitAnswer = answer.split('-');

    if (splitAnswer.length > 2) {
      const day = splitAnswer[2];
      const month = splitAnswer[1];
      const year = splitAnswer[0];

      return month + '/' + day + "/" + year;
    }

    return '';
  }

  private detectMobile() {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];

      // @ts-ignore
      return toMatch.some((toMatchItem) => {
          return this.window.navigator.userAgent.match(toMatchItem);
      });
  }

  closeModal(){
    if (this.isClaimantLossReport && this.lossReportService.getStepByName('policySearchCriteria')){
      this.clearOldPolicyQuestion();
    }
    this.window.oneX.Modal.hideModal();
  }

  clearOldPolicyQuestion(): void {
    const step = this.lossReportService.getStepByName('policySearchCriteria');
    step.question.subQuestions[0][0].subQuestions[0][0].answers = [];
    step.question.subQuestions[0][0].subQuestions[0][1].answers = [];
    step.question.subQuestions[0][0].subQuestions[0][2].answers = [];
    step.question.subQuestions[0][1].subQuestions[0][0].answers = [];
    step.question.subQuestions[0][1].subQuestions[0][1].answers = [];
    step.completed = false;
  }
}
