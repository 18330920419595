import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeandpropertyRoutingModule } from './homeandproperty-routing.module';
import { HomeAndPropertyIncidentDetailsModule } from './homeandproperty-incident-details/homeandproperty-incident-details.module';
import { HomeandpropertyContactInformationModule } from './homeandproperty-contact-information/homeandproperty-contact-information.module';
import { HomeandpropertyReviewModule } from './homeandproperty-review/homeandproperty-review.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HomeandpropertyRoutingModule,
    SharedModule,
    HomeAndPropertyIncidentDetailsModule,
    HomeandpropertyContactInformationModule,
    HomeandpropertyReviewModule,
  ],
  exports: [
    SharedModule,
  ]
})
export class HomeandpropertyModule { }
