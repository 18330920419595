import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HagertyService } from '../../services/hagerty.service';
import { PoliciesService } from '../../services/policies.service';

@Component({
  selector: 'olr-modal-hagerty',
  templateUrl: './modal-hagerty.component.html',
  styleUrls: ['./modal-hagerty.component.css']
})
export class ModalHagertyComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobileDevice = false;
  isClaimantLossReport = false;
  isFireLossReport = false;
  isOICFlow: boolean = false;

  constructor(
    @Inject('Window') private window: any,
    public router: Router,
    private hagertyService: HagertyService,
    private policiesService: PoliciesService,
    ) {
      if (this.router.url) {
        this.isClaimantLossReport = this.router.url.includes('/claimant/');
        this.isFireLossReport = this.router.url.includes('/homeandproperty/');
      }
  }

  ngOnInit(): void {
    this.isMobileDevice = this.detectMobile();
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-hagerty'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-hagerty'));
  }
  private detectMobile() {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];

      // @ts-ignore
      return toMatch.some((toMatchItem) => {
          return this.window.navigator.userAgent.match(toMatchItem);
      });
  }

  redirectToHagerty(){
    this.hagertyService.execute(this.policiesService.getSelectedPolicyNumber());
  }

  closeModal(){
    // @ts-ignore
    this.window.oneX.Modal.hideModal();
  }

}
