import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';
import { CookieHandlerService } from 'projects/lossreporting-library/src/lib/services/helpers/cookie-handler.service';
import { HostnameBuilderService } from 'projects/lossreporting-library/src/lib/services/helpers/hostname-builder.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-onex-header',
  templateUrl: './onex-header.component.html',
  styleUrls: ['./onex-header.component.css']
})
export class OneXHeaderComponent implements OnInit, OnDestroy {
  showOneXHeader: boolean;
  showSubNav: boolean;
  @Input() dataType?: string = '1x-header-unauth';
  loginDestinationUrl: string;

  constructor(
    @Inject('Window') private window: any,
    private router: Router,
    private cookieService: CookieHandlerService,
    private hostNameService: HostnameBuilderService
  ) {
    this.loginDestinationUrl = encodeURIComponent(`${this.hostNameService.getReturnRedirectionURL()}`);
    this.checkCookieAuth();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)).subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            this.displayHeader(event);
            this.displaySubNav(event);
          }
      });
  }
  ngOnDestroy(){
      this.window['oneX'].removeElement(document.querySelector('#header'))
  }

  checkCookieAuth() {
    if (this.cookieService.get('sf-icp-sso') != '' || this.cookieService.get('sf-cauth1') != '' ) {
      this.dataType = '1x-header-auth';
    } else if (this.cookieService.get('sf-icp-sso-lite')) {
      this.dataType = '1x-header-unauth';
    }
  }


  // uses current route to display oneX heade or not
  private displayHeader(event: NavigationEnd) {
    const showConditions = [
      n => n === '/',
      n => n === '/start-claim',
      n => n === '/home',
      n => n === '/claimant',
      n => n === '/homeandproperty',
      n => n === '/claim-type',
      n => n === '/opt-in/preferences',
      n => n === '/homeandproperty/opt-in/preferences',
      n => n === '/session/session-expired',
      n => n === '/session/session-expired-confirmation',
      n => n === '/maintenance',
      n => n === '/redirect-assured',
      n => n === n && n.includes('?'),
      n => n === n && n.includes('/error/'),
      n => n === n && n.includes('/confirmation/'),
    ];
    const url = event.url;
    const condition = showConditions.find(fn => fn(url));
    this.showOneXHeader = condition ? condition(url) : false;
    if (this.showOneXHeader) {
      setTimeout(() => {
        this.window['oneX'].addElement(document.querySelector('#header'));
      }, 0);
    }
  }

  private displaySubNav(event: NavigationEnd) {
    const url = event.url;
    console.log("URL: ", url)
    if (url && url.includes('/start-claim')) {
      this.showSubNav = true;
    } else if (url && url.endsWith('/')) {
      this.showSubNav = true;
    } else if (url && url.includes('/homeandproperty')) {
      this.showSubNav = false;
    } else {
      this.showSubNav = false;
    }
  }

}
