import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrimaryPolicySearchService {
  unprocessable = false
  unprocessableNotificationSubject = new Subject<boolean>();
  constructor() { }


  sendUnprocessabeNotification(someValue: boolean): void {
    this.unprocessableNotificationSubject.next(someValue);
  }
}
