// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-link {
    display: block;
    text-align: center;
    margin-top: 25px;
}
.lr-eyebrow {
    color: #6e6e6e;
    text-align: center;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/other-vehicles/vehicle-known/vehicle-known.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".lr-link {\n    display: block;\n    text-align: center;\n    margin-top: 25px;\n}\n.lr-eyebrow {\n    color: #6e6e6e;\n    text-align: center;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
