// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-answer-smaller {
    font-size: 12px;
  }
  
  .lr-continue {
    margin-top: 35px;
  }
  
  #pencil{
    background-image: url('icon-edit-pencil.svg');
    margin-left: 20px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    display: -webkit-inline-box;
    }
  `, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/client-involvement/client-involvement.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,6CAAkE;IAClE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,wBAAwB;IACxB,2BAA2B;IAC3B","sourcesContent":[".lr-answer-smaller {\n    font-size: 12px;\n  }\n  \n  .lr-continue {\n    margin-top: 35px;\n  }\n  \n  #pencil{\n    background-image: url(../../../../assets/img/icon-edit-pencil.svg);\n    margin-left: 20px;\n    width: 16px;\n    height: 16px;\n    background-repeat: no-repeat;\n    background-size: contain;\n    display: -webkit-inline-box;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
