// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-margin {
    margin-right: 15px;
}

@media(min-width: 1200px) {
    .lr-margin {
        margin-right: 30px;
    }
}

.lr-ending-row-bottom-spacing {
    margin-bottom: 30px;
}
.lr-damage-option-listing {
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/damage-details/damage-confirmation/damage-confirmation.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".lr-margin {\n    margin-right: 15px;\n}\n\n@media(min-width: 1200px) {\n    .lr-margin {\n        margin-right: 30px;\n    }\n}\n\n.lr-ending-row-bottom-spacing {\n    margin-bottom: 30px;\n}\n.lr-damage-option-listing {\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
