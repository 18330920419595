import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'olr-technical-error',
  templateUrl: './technical-error.component.html',
  styleUrls: ['./technical-error.component.css', '../../shared/styles/question-common-styling.scss']
})
export class TechnicalErrorComponent implements OnInit {

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    public router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.stopTimer();
    this.analytics.sendData('technical-error');
  }

  goToClaims(){
    this.window.location.href = 'https://www.statefarm.com/claims';
  }

}
