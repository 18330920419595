// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-oneX--scrim__modal {
    background-color: rgba(0,0,0,0.75);
}
.lr-message2 {
    margin-top: 20px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/modal-error/modal-error.component.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".-oneX--scrim__modal {\n    background-color: rgba(0,0,0,0.75);\n}\n.lr-message2 {\n    margin-top: 20px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
