import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportService } from '../../services/loss-report.service';
import { ClaimDetailsService } from '../../services/claim-details.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { HostnameBuilderService } from '../../services/helpers/hostname-builder.service';
import { Customer } from '../../models/customer';
import { Subscription } from 'rxjs';
import { ClaimsEventsService } from '../../services/claims-events.service';
import { RepairEligibilityService } from '../../services/repair.eligibility.service';
import { AuthenticationService } from '../../services/authentication.service';
import {  FeatureFlagService } from '../../services/feature-flag.service';



@Component({
  selector: 'olr-received-auto-claim',
  templateUrl: './received-auto-claim.component.html',
  styleUrls: ['./received-auto-claim.component.css', '../../shared/styles/question-common-styling.scss']
})
export class ReceivedAutoClaimComponent implements OnInit {
  static readonly oneXSmallBreakpointPx = 768;
  claimNumber: string;
  claimId: string;
  customer: Customer;
  email: string;
  isAnimalIncident: boolean;
  created = false;
  loadingSubscription$: Subscription;
  isLoading = true;
  isMobileDevice: boolean;
  isOrgClaimConfirmation: boolean = false;
  displayOrgWhatsNext: boolean = false;
  orgType: string = '';
  externalClientId: string = '';
  screenChannel = 'filealoss-auto';
  isSimpleRepair: boolean;
  isModalOpen:boolean = false;
  featureOff: boolean = false;
  role:string;

  constructor(
    @Inject('Window') private window: any,
    private sfAnalyticsService: SfAnalyticsService,
    private lossReportService: LossReportService,
    private claimDetailsService: ClaimDetailsService,
    private customerProxyService: CustomerProxyService,
    private hostnameBuilderService: HostnameBuilderService,
    private claimEventsService: ClaimsEventsService,
    private repairEligibilityService: RepairEligibilityService,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService


  ) {
    this.isMobileDevice = window.innerWidth <= ReceivedAutoClaimComponent.oneXSmallBreakpointPx;
   }

  ngOnInit(): void {
    this.featureOff = this.featureFlagService.isABFeature();
    this.sfAnalyticsService.setScreenChannel(this.screenChannel);
    this.orgType = this.sfAnalyticsService.getOrgType();
    this.externalClientId = this.sfAnalyticsService.getExternalClientId();
    this.detectOrgClaim();
    this.setCustomerData();
    this.setClaimNumber();
    this.setCreated();
    this.setEmailAddress();
    this.setIsAnimal();


    this.isSimpleRepair = this.repairEligibilityService.getIsSimpleRepair();
    this.role = this.repairEligibilityService.getRole();

    sessionStorage.clear();
    this.authenticationService.stopTimer();
  }


  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width <= ReceivedAutoClaimComponent.oneXSmallBreakpointPx;
  }

  onClickAdditionalContent() {
    this.isModalOpen=true;
  }

  closePopup(){
    this.isModalOpen=false;
  }



  openOrgNextSteps(): void {
    this.displayOrgWhatsNext = true;
    this.sfAnalyticsService.setScreenChannel(this.screenChannel);
    this.sfAnalyticsService.setOrgType(this.orgType);
    this.sfAnalyticsService.setExternalClientId(this.externalClientId);
    this.sfAnalyticsService.sendData('nextsteps-info');
  }

  private detectOrgClaim(): void {
    const orgSelection = this.lossReportService.getStepByName('orgSelection');
    const orgSelectionAns = orgSelection ? orgSelection.question.answers[0] : 'personal';
    this.isOrgClaimConfirmation = orgSelectionAns !== 'personal' && orgSelectionAns !== '';
  }

  goToMyAccounts() {
    const myAccountsURL = `https://apps${this.hostnameBuilderService.getHostname()}/my-accounts/`;
    this.window.location.href = myAccountsURL;
  }

  private setClaimNumber() {
    const claim = this.claimDetailsService.getClaimDetails();
    this.claimNumber = claim.number;
    this.claimId = claim.id;
    this.sfAnalyticsService.sendData('confirmation-page');
    this.claimEventsService.buildEvent('Claim Confirmed');
  }

  private setEmailAddress() {
    const customerContact = this.lossReportService.getStepByName('customerContactInformation');
    const subQuestion = customerContact ? customerContact.question.subQuestions[0] : [];
    const enteredEmail = subQuestion.find((question) => {
      return question.name === 'namedInsuredEmail';
    });
    const emailUpdate = enteredEmail ? enteredEmail.answers[0] : '';
    if (emailUpdate || this.customer) {
      this.email = emailUpdate ? emailUpdate : this.customer.email;
    }
  }

  private setCustomerData() {
    this.customer = this.customerProxyService.customerInfo;
  }

  goToClaims() {
    const claimsHubURL = `https://${this.hostnameBuilderService.getAWSHostname('hub.claims')}/#/claim/${this.claimId}/client/${this.customer.externalClientId}/role/NI/auto`;
    this.window.location.href = claimsHubURL;
  }


  private setIsAnimal(){
    const incidentDetails = this.lossReportService.getStepByName('incidentCategory');
    const subQuestion = incidentDetails ? incidentDetails.question.answers[0] : '';
    if (subQuestion !== '') {
      this.isAnimalIncident = subQuestion === 'animal';
    }
  }

  private setCreated() {
    this.created = this.lossReportService.getCreatedIndicator();
  }

  get isRepairEligible(): boolean {
    return this.repairEligibilityService.getIsRepairEligible();
  }

  goToRepair() {
    const repairAssistantUrl = this.hostnameBuilderService.getRepairAssistantUrl(this.claimId, this.repairEligibilityService.getRole(), this.externalClientId);
    this.window.location.href = repairAssistantUrl;
  }
}
