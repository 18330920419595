import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-incident-type',
  templateUrl: './incident-type.component.html',
  styleUrls: ['./incident-type.component.css', '../../shared/styles/question-common-styling.scss']
})
export class IncidentTypeComponent extends BaseComponent implements OnInit {

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    @Inject('Window') private window: any
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.analytics.sendData('incidenttype-parent-question');
    this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.incidentType = answer;
    this.logService.log(CliffLogCodes.SELECTED_INCIDENT_TYPE, logDetails);
    this.updateAnswer(answer);
    
  }

  closeDrawer(){
    // @ts-ignore
    this.window.oneX.Drawer.hideDrawer();
    
  }
}
