import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'olr-unsupported-associate',
  templateUrl: './unsupported-associate.component.html',
  styleUrls: ['./unsupported-associate.component.css']
})
export class UnsupportedAssociateComponent implements OnInit {

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    const logDetails = this.logService.getLogDetails();
    this.analytics.sendData('unsupported-associate');
    this.logService.log(CliffLogCodes.UNSUPPORTED_ASSOCIATE, logDetails);
  }

  goToClaims() {
    this.window.location.href = 'https://www.statefarm.com/claims';
  }

}
