import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimNumberFormatter'
})
export class ClaimNumberFormatterPipe implements PipeTransform {

  transform(value: string, args: string[]): any {
    return value ? `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6)}` : value;
  }

}
