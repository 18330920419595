import { Injectable, Inject } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';
import { HostnameBuilderService } from './helpers/hostname-builder.service';

@Injectable({
  providedIn: 'root'
})
export class AssuredService {

  private _POLICY_NUMBER: string;
  private _DATE_OF_LOSS: string;
  private demandPool = ["AL", "AR", "CO", "DC", "ID", "KS", "ME", "MD", "NE", "ND", "SD", "TN", "UT", "VA"];

  constructor(
    @Inject('Window') private window: any,
    private featureFlagService: FeatureFlagService,
    private hostnameBuilderService: HostnameBuilderService
  ) { }

  getAuthCode(policyNumber: string, dol: string) {
    this.policyNumber = policyNumber
    this.dateOfLoss = dol;
    this.window.location.href = this.hostnameBuilderService.getAssuredCustomOAuthServerURL(this.generateStateId(policyNumber, dol));
  }

  isAssuredEligible(claimType: string, glassOnlyAns: string, state: string, orgRelationship: string, policySelection: any): boolean {
    return (this.featureFlagService.isAssuredEnabled() && this.isParticipatingTraffic() && claimType === "auto" && glassOnlyAns === "No" && this.demandPool.includes(state)
            && orgRelationship === "personal" && (policySelection != "" && policySelection != null && policySelection != undefined));
  }

  private isParticipatingTraffic(): boolean {
    return Math.random() < (this.featureFlagService.getAssuredTrafficPercentage() / 100);
  }

  private generateStateId(policyNumber: string = '', dol: string = ''): string {
    return `${(policyNumber.replaceAll(/[\s-]/gi, '.')).replaceAll(/::/gi, '.')},${dol.replaceAll(/[-:.]/gi, '')}`;
  }

  get policyNumber(): string {
    if (!this._POLICY_NUMBER) {
      this._POLICY_NUMBER = sessionStorage.getItem('assuredPolicyNumber');
    }
    return this._POLICY_NUMBER;
  }

  set policyNumber(policyNumber: string) {
    this._POLICY_NUMBER = policyNumber;
    sessionStorage.setItem('assuredPolicyNumber', policyNumber);
  }

  get dateOfLoss(): string {
    if (!this._DATE_OF_LOSS) {
      this._DATE_OF_LOSS = sessionStorage.getItem('assuredDateOfLoss');
    }
    return this._DATE_OF_LOSS;
  }

  set dateOfLoss(dateOfLoss: string) {
    this._DATE_OF_LOSS = dateOfLoss;
    sessionStorage.setItem('assuredDateOfLoss', dateOfLoss);
  }

}
