// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    margin-bottom: 10px;
}
select {
    margin-bottom: 10px;
}
.lr-mt {
    margin-top: 25px;
}
.-padding-left-7  {
    padding-left: 7px;
}

.-padding-right-7  {
    padding-right: 7px;
}
.lr-eyebrow {
    color: #6e6e6e;
    text-align: center;
}
.zip {
font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/contact/contact.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;AACA,eAAe;AACf","sourcesContent":["input {\n    margin-bottom: 10px;\n}\nselect {\n    margin-bottom: 10px;\n}\n.lr-mt {\n    margin-top: 25px;\n}\n.-padding-left-7  {\n    padding-left: 7px;\n}\n\n.-padding-right-7  {\n    padding-right: 7px;\n}\n.lr-eyebrow {\n    color: #6e6e6e;\n    text-align: center;\n}\n.zip {\nfont-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
