// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-disclaimer {
    margin-top: 35px;
}
.lr-submit {
    margin-top: 35px;
    margin-bottom: 50px;
    margin: 0;
}
.lr-review-answer {
    padding: 0px;
    position: relative;
}

.lr-question{
    text-align: center;
    padding-bottom: 45px;
}

.review-separator {
    margin: 38px 0 20px;
}

.review-answer-title {
    font-size: 12px;
}

.review-answer-wrapper {
    width: 100%;
    overflow: hidden;
}

.review-answer-body-left {
    width: 90%;
    float: left;
}

.review-answer-body-right {
    text-align: right;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/homeandproperty/homeandproperty-review/homeandproperty-review-claim/homeandproperty-review-claim.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".lr-disclaimer {\n    margin-top: 35px;\n}\n.lr-submit {\n    margin-top: 35px;\n    margin-bottom: 50px;\n    margin: 0;\n}\n.lr-review-answer {\n    padding: 0px;\n    position: relative;\n}\n\n.lr-question{\n    text-align: center;\n    padding-bottom: 45px;\n}\n\n.review-separator {\n    margin: 38px 0 20px;\n}\n\n.review-answer-title {\n    font-size: 12px;\n}\n\n.review-answer-wrapper {\n    width: 100%;\n    overflow: hidden;\n}\n\n.review-answer-body-left {\n    width: 90%;\n    float: left;\n}\n\n.review-answer-body-right {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
