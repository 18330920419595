import { Component, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-airbags',
  templateUrl: './airbags.component.html',
  styleUrls: ['./airbags.component.css', '../../shared/styles/question-common-styling.scss']
})
export class AirbagsComponent extends BaseComponent implements OnInit {
  answers: [];
  constructor(
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.sfAnalyticsService.setScreenChannel('filealoss-auto');
    this.analytics.sendData('airbags-question');
    if (this.step.completed){
      this.answers = this.step.question.answers[0];
    }
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.airbagDeployment = answer;
    this.logService.log(CliffLogCodes.AIRBAG_DEPLOYMENT, logDetails);
    this.updateAnswer(answer.toLowerCase());
  }
}
