import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationRoutingModule } from './confirmation-routing.module';
import { ReceivedAutoClaimComponent } from './received-auto-claim/received-auto-claim.component';
import { ReceivedClaimantClaimComponent } from './received-claimant-claim/received-claimant-claim.component';
import { ReceivedPropertyClaimComponent } from './received-property-claim/received-property-claim.component';
import { PleaseWaitComponent } from './please-wait/please-wait.component';
import { ReceivedAutoABComponent } from './received-autoAB/received-auto-ab/received-auto-ab.component';

@NgModule({
  declarations: [ReceivedAutoClaimComponent, ReceivedClaimantClaimComponent, ReceivedPropertyClaimComponent, PleaseWaitComponent, ReceivedAutoABComponent],
  imports: [
    CommonModule,
    SharedModule,
    ConfirmationRoutingModule
  ],
  exports: [
    SharedModule
  ]
})
export class ConfirmationModule { }
