import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';

@Component({
  selector: 'olr-other-involved-type',
  templateUrl: './other-involved-type.component.html',
  styleUrls: ['./other-involved-type.component.css', '../../shared/styles/question-common-styling.scss']
})
export class OtherInvolvedTypeComponent extends BaseComponent implements OnInit {
  name: string;

  constructor(
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.sfAnalyticsService.sendData('first-claimant-role-question');
    this.setClaimantName();
    this.highlightButtonStandardAnswer();
  }

  setClaimantName() {
    const claimantNameStep = this.lossReportService.getStepByName('firstClaimantName');
    const claimantNameSubQuestions = claimantNameStep ? claimantNameStep.question.subQuestions[0] : undefined;
    if (claimantNameSubQuestions && claimantNameSubQuestions[1].answers[0]) {
      this.name = `${claimantNameSubQuestions[0].answers[0] || ''} ${claimantNameSubQuestions[1].answers[0]}`.trim();
    } else {
      this.name = 'the other person';
    }
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.claimantRole = answer;
    this.logService.log(CliffLogCodes.CLAIMANT_ROLE, logDetails);
    this.updateAnswer(answer);
  }

}
