// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-pad {
    margin-top: 10%;
    padding-left: 45px;
    padding-right: 52px;
    text-align: center;
}
.lr-heading {
    margin-bottom: 26px;
    position: relative;
}
.lr-intro {
    margin-bottom: 27px;
}

.lr-btn {
    margin-bottom: 10%;
    padding-left: 20%;
}
.lr-footer { 
        width:100%; 
        padding-left: 0%;
        left:3%;
}

@media(min-width: 768px) {
    .lr-pad {
        margin-top: 10%;
    }
    .lr-intro {
        margin-bottom: 29px;
        padding-left: 20%;
        padding-right: auto;   
    }
    .lr-btn {
        margin-bottom: 10%;
        padding-left: 40%;   
    }
    .lr-list {
        margin-bottom: 56px;
    }
    .lr-footer {    
        width:100%; 
        text-align: center;
        padding-left: 0%;
        left:2%;
        
    }
}
@media(min-width: 1200px) {
    .lr-heading {
        margin-bottom: 23px;
    }
    .lr-intro {
        font-weight: normal;
        font-size: 25px;
        line-height: 38px;
        color: #000;
        margin-bottom: 32px;
    }
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/maintenance-banner/maintenance.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;QACQ,UAAU;QACV,gBAAgB;QAChB,OAAO;AACf;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,mBAAmB;QACnB,iBAAiB;QACjB,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,UAAU;QACV,kBAAkB;QAClB,gBAAgB;QAChB,OAAO;;IAEX;AACJ;AACA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,mBAAmB;QACnB,eAAe;QACf,iBAAiB;QACjB,WAAW;QACX,mBAAmB;IACvB;AACJ","sourcesContent":[".lr-pad {\n    margin-top: 10%;\n    padding-left: 45px;\n    padding-right: 52px;\n    text-align: center;\n}\n.lr-heading {\n    margin-bottom: 26px;\n    position: relative;\n}\n.lr-intro {\n    margin-bottom: 27px;\n}\n\n.lr-btn {\n    margin-bottom: 10%;\n    padding-left: 20%;\n}\n.lr-footer { \n        width:100%; \n        padding-left: 0%;\n        left:3%;\n}\n\n@media(min-width: 768px) {\n    .lr-pad {\n        margin-top: 10%;\n    }\n    .lr-intro {\n        margin-bottom: 29px;\n        padding-left: 20%;\n        padding-right: auto;   \n    }\n    .lr-btn {\n        margin-bottom: 10%;\n        padding-left: 40%;   \n    }\n    .lr-list {\n        margin-bottom: 56px;\n    }\n    .lr-footer {    \n        width:100%; \n        text-align: center;\n        padding-left: 0%;\n        left:2%;\n        \n    }\n}\n@media(min-width: 1200px) {\n    .lr-heading {\n        margin-bottom: 23px;\n    }\n    .lr-intro {\n        font-weight: normal;\n        font-size: 25px;\n        line-height: 38px;\n        color: #000;\n        margin-bottom: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
