
import { Component, OnInit, Inject } from '@angular/core';
import { SfAnalyticsService } from '../services/sf-analytics.service';
import { LogService } from '../services/log.service';
import { Router } from '@angular/router';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { LogDetails } from '../models/log-details.model';

@Component({
  selector: 'olr-maintenance-banner',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit{
  logDetails: LogDetails;

  constructor(
    @Inject('Window') private window: any,
    private analytics: SfAnalyticsService,
    private logService: LogService,
    public router: Router
  ) {}

    ngOnInit() {
      this.logDetails = this.logService.getLogDetails();
      this.analytics.setValues({
        screenChannel : 'filealoss',
        screenId : 'maintenance-error',
      });
    }

  goToClaims(): void {
    this.logDetails.routePath = '/maintenance-banner/maintenance';
    this.logDetails.serviceName = 'MaintenanceComponent';
    this.logService.log(CliffLogCodes.MAINT_ERR, this.logDetails);
    this.window.location.href = 'https://www.statefarm.com/claims';
  }

}
