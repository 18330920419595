import { Injectable } from '@angular/core';
import { Participant } from '../../models/participant.model';
import { Question } from '../../models/question.model';
import { Name } from '../../models/name.model';
import { Contact } from '../../models/contact.model';
import { InjuryTrackingService } from '../../services/injury-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class LossreportAnsweredStepBuilderService {

  constructor(
    private injuryTrackingService: InjuryTrackingService
  ) { }

  answerAdditionalInsuredParticipants(participants: Participant[]): any[] {
    const answers = [];
    let fatalityCount = 0;
    let injuryCount = 0;
    participants.forEach(participant => {
      const injuryStatus = participant.injuryStatus;
      const lossOfLife = participant.lossOfLife;
      if (injuryStatus === 'yes') { injuryCount++; }
      if (lossOfLife === 'yes') { fatalityCount++; }
      const additionalParticipants = [];
      additionalParticipants.push(
        this.createAnsweredQuestion('1010D', 'additionalPersonName', null, this.answerNameSubQuestions(participant.name)));
      additionalParticipants.push(
        this.createAnsweredQuestion('3070', 'additionalParticipantContact', null, this.answerContactSubQuestions(participant.contact)));
      additionalParticipants.push(
        this.createAnsweredQuestion('3071', 'householdIndicator', participant.isHouseholdParticipant, null));
      answers.push(this.answerInvolvementDetailsQuestions(additionalParticipants, participant, injuryStatus, lossOfLife));
    });

    this.injuryTrackingService.setStatus('additionalParticipantInjury', injuryCount > 0);
    this.injuryTrackingService.setStatus('additionalParticipantFatality', fatalityCount > 0);

    return answers;
  }

  answerPolicyInsuredParticipants(participants: Participant[]): any[]  {
    const answers = [];

    let fatalityCount = 0;
    let injuryCount = 0;
    participants.forEach(participant => {
      const injuryStatus = participant.injuryStatus;
      const lossOfLife = participant.lossOfLife;
      if (injuryStatus === 'yes') { injuryCount++; }
      if (lossOfLife === 'yes') { fatalityCount++; }
      const policyInsuredParticipant = [];
      policyInsuredParticipant.push(this.createAnsweredQuestion('3067', 'participantClientId', participant.clientId));
      answers.push(this.answerInvolvementDetailsQuestions(policyInsuredParticipant, participant, injuryStatus, lossOfLife));
    });

    this.injuryTrackingService.setStatus('insuredParticipantInjury', injuryCount > 0);
    this.injuryTrackingService.setStatus('insuredParticipantFatality', fatalityCount > 0);

    return answers;
  }

  private answerInvolvementDetailsQuestions(stepQuestions: any[], participant: Participant, injuryStatus: string, lossOfLife: string) {
    stepQuestions.push(this.createAnsweredQuestion('3068', 'participantInvolvement', participant.role));
    stepQuestions.push(this.createAnsweredQuestion('1051', 'injuryStatus', injuryStatus));
    if (injuryStatus === 'yes') {
      stepQuestions.push(this.createAnsweredQuestion('1052', 'lossOfLife', lossOfLife));
    }
    if (injuryStatus === 'yes' && lossOfLife === 'no') {
      stepQuestions.push(this.createAnsweredQuestion('1053', 'injuries', participant.injuries));
      stepQuestions.push(this.createAnsweredQuestion('1054', 'medicalTreatment', participant.medicalTreatment));
      stepQuestions.push(this.createAnsweredQuestion('1055', 'airbagDeploy', participant.airbagDeploy));
    }
    return stepQuestions;
  }

  private answerNameSubQuestions(nameAnswers: Name){
    const nameSteps = [];
    nameSteps.push(this.createAnsweredQuestion('1011NR', 'firstName', nameAnswers.unknown ? [] : nameAnswers.first));
    nameSteps.push(this.createAnsweredQuestion('1012NR', 'lastName', nameAnswers.unknown ? [] : nameAnswers.last));
    return nameSteps;
  }

  private answerContactSubQuestions(contactAnswers: Contact) {
    const contactSteps = [];
    contactSteps.push(this.createAnsweredQuestion('3037', 'country', contactAnswers ? contactAnswers.country : null || 'usa'));
    contactSteps.push(this.createAnsweredQuestion('1030NR', 'street1', contactAnswers ? contactAnswers.street1 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1031', 'street2', contactAnswers ? contactAnswers.street2 : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1032NR', 'city', contactAnswers ? contactAnswers.city : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1033NC', 'state', contactAnswers ? contactAnswers.state : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1037NC', 'postalCode', contactAnswers ? contactAnswers.zipCode : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1040NR', 'phoneType', contactAnswers ? contactAnswers.phoneType : null || [] ));
    contactSteps.push(this.createAnsweredQuestion('1041NR', 'phoneNumber', contactAnswers ? contactAnswers.phoneNumber : null || [] ));
    return contactSteps;
  }

  private createAnsweredQuestion(questionId: string, questionName: string, simpleAnswer: any, subQuestions: any[] = null): Question {
    const question = new Question();
    question.id = questionId;
    question.name = questionName;
    if (simpleAnswer) {
      question.answers = typeof simpleAnswer === 'object' ? simpleAnswer : [simpleAnswer];
    } else if (!subQuestions && !simpleAnswer) {
      question.answers = [];
    }

    if (subQuestions) {
      question.subQuestions = [subQuestions];
    }
    return question;
  }
}
