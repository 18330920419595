export class Address {
    country: string;
    streetNumber: string;
    route: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;

    constructor() {}
}
