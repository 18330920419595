// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** BEGIN: Add border around stacked checkbox selections **/
.-oneX--stacked div.-oneX-checkbox-item:first-of-type {
  border-top: 1px solid #242424;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.-oneX--stacked div.-oneX-checkbox-item {
  border-left: 1px solid #242424;
  border-right: 1px solid #242424;
  border-bottom: 1px solid #242424;
  padding: 1px;
}

.-oneX--stacked div.-oneX-checkbox-item:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/** END: Add border around stacked checkbox selections **/`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/shared/styles/checkbox-grouping.scss"],"names":[],"mappings":"AAAA,2DAAA;AACA;EACI,6BAAA;EACA,2BAAA;EACA,4BAAA;AACJ;;AAEA;EACI,8BAAA;EACA,+BAAA;EACA,gCAAA;EACH,YAAA;AACD;;AAEA;EACI,8BAAA;EACA,+BAAA;AACJ;;AACA,yDAAA","sourcesContent":["/** BEGIN: Add border around stacked checkbox selections **/\n.-oneX--stacked div.-oneX-checkbox-item:first-of-type {\n    border-top: 1px solid #242424;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n}\n\n.-oneX--stacked div.-oneX-checkbox-item {\n    border-left: 1px solid #242424;\n    border-right: 1px solid #242424;\n    border-bottom: 1px solid #242424;\n\tpadding: 1px;\n}\n\n.-oneX--stacked div.-oneX-checkbox-item:last-of-type {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n}\n/** END: Add border around stacked checkbox selections **/"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
