import { Component, OnInit, Inject} from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LogService } from '../../services/log.service';
import { PoliciesService } from '../../services/policies.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';
import { LogDetails } from '../../models/log-details.model';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-insured-participant-selection',
  templateUrl: './insured-participant-selection.component.html',
  styleUrls: ['./insured-participant-selection.component.css', '../../shared/styles/checkbox-grouping.scss', '../../shared/styles/question-common-styling.scss']
})
export class InsuredParticipantSelectionComponent extends BaseComponent implements OnInit {
  customers = [];
  vehicle: string;
  qtySelected = 0;
  private selectedOptions = [];
  selectedCustomerArray = [];
  selectedCustomerName = '';
  selectedClientId: string;
  event: Event;
  showOtherPersonOption: boolean;
  logDetails: LogDetails;

  constructor(
    private analytics: SfAnalyticsService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private policiesService: PoliciesService,
    private insuredParticipantTrackingService: InsuredParticipantTrackingService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    private lossReportAnswerFinderService: LossReportAnswerFinderService,
    @Inject('Window') private window: any,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.claimsEventsService.buildEvent('Capture Participant Details');
    this.logDetails = this.logService.getLogDetails();
    this.analytics.sendData('listed-policy-participants');
    this.displayAnsweredState();
    this.setShowOtherPerson();
    if(this.lossReportAnswerFinderService.findAnswer('feature') === 'yes') {
      this.setCustomersFromLossReport();
    } else {
      this.setCustomersFromPolicy();
    }
    this.vehicle = this.policyVehicleInfoService.getVehicleDescription();
   }

   private setCustomersFromLossReport(): void {
    const displayContent = this.step.question.displayContent;
    const pCustomers = [];
    displayContent?.forEach((content: any) => {
      const firstName = this.getKeyValue(content.value, 'firstName');
      const lastName = this.getKeyValue(content.value, 'lastName');
      const customerName = `${firstName} ${lastName}`;
      this.customers.push({clientId: content.key, name: customerName});
      pCustomers.push({clientId: content.key, name: {firstName: firstName, lastName: lastName}});
    });
    this.policiesService.setPolicyCustomers(pCustomers);
   }

   private getKeyValue(attributes: any[], key: string): string {
    let found: any;
    for(const attribute of attributes) {
      if(attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if(found) {
      return found.value[0];
    }
    return '';
  }

   private setCustomersFromPolicy(): void {
    const policyCustomers = this.policiesService.getPolicyCustomers();
    const ids = this.step ? this.step.question.allowedValues : [];
    const clientIds = ids.filter(id => id !== 'noOneElse' && id !== 'someOneElse');
    clientIds.forEach(id => {
      const nameObj = policyCustomers.find(customer => customer.clientId === id);
      const customerName = `${nameObj.name.firstName} ${nameObj.name.lastName}`;
      this.customers.push({clientId: id, name: customerName});
    });
   }

   private displayAnsweredState() {
    const answers = this.step ? this.step.question.answers : [];
    answers.forEach((answer: string) => {
      if (answer !== 'noOneElse'){
        this.qtySelected++;
        this.selectedOptions.push(answer);
      }
    });
  }

  private setShowOtherPerson() {
    const allowedValues = this.step ? this.step.question.allowedValues : [];
    this.showOtherPersonOption = allowedValues.some(value => value === 'someOneElse');
  }

   onCheckboxChange(e: Event) {
      this.event = e;
      const defaultValue = 'defaultValue';
      const inputValue = e.target[defaultValue];
      this.selectedClientId = inputValue;
      const checked = 'checked';
      const isChecked = e.target[checked];
      if (isChecked === true) {
        this.step.completed = false;
        this.qtySelected++;
        this.selectedOptions.push(inputValue);
      } else {
        if (inputValue === 'someOneElse') {
          this.selectedOptions = this.selectedOptions.filter(option => option !== inputValue);
          this.selectedCustomerName = 'all non-policy participants';
          this.confirmRemoval();
        } else {
          const selectedCustomer = this.customers.find(customer => customer.clientId === inputValue);
          this.selectedCustomerName = selectedCustomer.name;
          this.confirmRemoval();
        }
      }
  }

  private confirmRemoval() {
    this.analytics.sendData('remove-participant-modal');
    // @ts-ignore
    this.window.oneX.Modal.showModal(this, 'olr-modal-remove-participant');
  }

  answerNoOneElseInvolved(){
    this.logService.log(CliffLogCodes.INJ_NO_ONE, this.logDetails);
    this.updateAnswer(this.insuredParticipantTrackingService.removeAllSelectedParticipants('noOneElse'));
  }

  isSelected(answer: string): boolean {
    return this.selectedOptions.indexOf(answer) !== -1;
  }

  answerQuestion(): void {
    if (this.selectedOptions.includes('someOneElse')){
      this.logService.log(CliffLogCodes.SLCT_ADD_PRT, this.logDetails);
    }
    this.logDetails.numberOfInsuredParticipants = `${this.selectedOptions.length}`;
    this.logService.log(CliffLogCodes.INJ_PRT_ADD, this.logDetails);
    this.updateAnswer(this.insuredParticipantTrackingService.refreshSelectedParticipants(this.selectedOptions));
  }

  modalAnswer(answer: string) {
    if (answer === 'yes') {
      this.qtySelected--;
      if (this.selectedClientId === 'someOneElse') {
        this.insuredParticipantTrackingService.refreshParticipantsList([], 'additionalParticipants');
      }
      this.selectedOptions = this.selectedOptions.filter(option => option !== this.selectedClientId);
      this.logService.log(CliffLogCodes.INJ_PRT_RMV, this.logDetails);
      this.updateAnswerWithoutRouting(this.insuredParticipantTrackingService.refreshSelectedParticipants(this.selectedOptions));
    } else {
      this.step.completed = false;
      const id = 'id';
      const customerId = this.event.target[id];  // get the id of the last event that was fired when the checkbox was unchecked
      this.logService.log(CliffLogCodes.INJ_NO_RMV_NO, this.logDetails);
      // @ts-ignore
      this.window.oneX.$(`#${customerId}`).prop('checked', true);  // re-check the checkbox
    }
  }

}
