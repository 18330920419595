import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Inject } from '@angular/core';

@Component({
  selector: 'olr-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent   {
  @Output() setSelection = new EventEmitter<any>();
  @Input() label: string;
  @Input() options: string[];
  @Input() translationKey: string;
  @Input() id: string;
  @Input() questionText: string;
  @Input() selectedAnswer: string;
  @ViewChild('selection', { static: false }) selection: ElementRef;

  constructor(
    @Inject('Window') private window: any
  ) { }


  selectChangeHandler(): void {
    const selection = this.options.find(option => option === this.selection.nativeElement.value);
    this.setSelection.emit(selection);
  }

}
