import { WindowReference } from '../../models/window-reference.model';
import { Injectable } from '@angular/core';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class HostnameBuilderService {

  constructor(private windowRef: WindowReference) {}

  private static ENV = 'env2';

  static env() {
    return this.ENV;
  }

  public getHostname(): string {
    if (this.isTestEnvironment()) {
      return '-' + HostnameBuilderService.ENV + '.test.statefarm.com';
    } else {
      return '.statefarm.com';
    }
  }

  public getSFLHostname(): string {
    if(this.isWorkstation()) {
      return 'localhost.reportloss.claims.test.statefarm.com:4443';
    } else if (this.isTestEnvironment()) {
      return 'reportloss.claims.test.statefarm.com';
    } else {
      return 'reportloss.claims.statefarm.com';
    }
  }


  public getAWSHostname(subdomain: string): string {
    if (this.isTestEnvironment()) {
      return `${subdomain}.test.statefarm.com`;
    } else {
      return `${subdomain}.statefarm.com`;
    }
  }

  public getProofingURL():string {
    if (this.isTestEnvironment()) {
      return `https://proofing-${HostnameBuilderService.ENV}.test.statefarm.com/login-interceptor/login`;
    } else {
      return 'https://proofing.statefarm.com/login-interceptor/login';
    }
  }

  public getProofingLoginUIURL():string {
    if (this.isTestEnvironment()) {
      return `https://proofing-${HostnameBuilderService.ENV}.test.statefarm.com/login-ui/login`;
    } else {
      return 'https://proofing.statefarm.com/login-ui/login';
    }
  }

  public getPreferencesRemoteEntryUrl(): string {
    if (this.isTestEnvironment()) {
      return 'https://hub.claims.test.statefarm.com/mfe/preferences/remoteEntry.js';
    } else {
      return 'https://hub.claims.statefarm.com/mfe/preferences/remoteEntry.js';
    }
  }

  public getAuthURL(): string {
    if (this.isTestEnvironment()) {
      return 'https://auth-utl.claimsvc.test.c1.statefarm/claimutility-api-authentication/v1/details';
    } else {
      return 'https://auth-utl.claimsvc.c1.statefarm/claimutility-api-authentication/v1/details';
    }
  }

  public getAzureURL(): string {
    if (this.isTestEnvironment()) {
      return 'https://lossreporting-exp.claimsvc.test.statefarm.com/claimexperience-api-lossreporting/azure';
    } else {
      return 'https://lossreporting-exp.claimsvc.statefarm.com/claimexperience-api-lossreporting/azure';
    }
  }

  public getLRECustomerURL(): string {
    if(this.isTestEnvironment()) {
       // return 'http://127.0.0.1:8080/claimexperience-api-lossreporting/details/customer';
      return 'https://lossreporting-exp.claimsvc.test.statefarm.com/claimexperience-api-lossreporting/details/customer';
    } else {
      return 'https://lossreporting-exp.claimsvc.statefarm.com/claimexperience-api-lossreporting/details/customer';
    }
  }

  /**Depending on what the url is for claimant and oic this may need to change to getAutoFireEventUrl() etc... */
  public getEventUrl(): string {
    if(this.isTestEnvironment()){
      return 'https://media.claims.test.c1.statefarm/test/event-publisher/v2/events';
    }else{
      return  'https://media.claims.c1.statefarm/prod/event-publisher/v2/events';
    }
  }

  public getRepairEligURL(claimId: string, clientId: string): string {
      // return 'http://127.0.0.1:8080/claimexperience-api-lossreporting/claims/' + claimId +
      // '/clients/' + clientId + '/repaireligibility';

    if (this.isTestEnvironment()) {
      return 'https://lossreporting-exp.claimsvc.test.statefarm.com/claimexperience-api-lossreporting/claims/' + claimId +
      '/clients/' + clientId + '/repaireligibility';
    } else {
      return 'https://lossreporting-exp.claimsvc.statefarm.com/claimexperience-api-lossreporting/claims/' + claimId +
      '/clients/' + clientId + '/repaireligibility';
    }
  }

  public getRepairAssistantUrl(claimId: string, role: string, externalClientId: string): string {
    if (this.isTestEnvironment()) {
      return `https://repair.claims.test.statefarm.com/shopsearch?claim=${claimId}&role=${role}&client=${externalClientId}`;
    } else {
      return `https://repair.claims.statefarm.com/shopsearch?claim=${claimId}&role=${role}&client=${externalClientId}`;
    }
  }

  public getSparklEnv(): any {
    if (this.isTestEnvironment()) {
      return {
        env: 'test',
        apiKey: 'ee2AS1YCVY8okhCXKz1lI6qqJyaCIB2f932rtsjm'
      };
    } else {
      return {
        env: `prod`,
        apiKey: 'eGZvlN3NDO3zvjAtM1iqP6uNSX65zDhV9KaAZFtv'
      };
    }
  }

  public getClaimURL(){
    if (this.isTestEnvironment()) {
      return 'https://hub.claims.test.statefarm.com/#/my-claims';
    } else {
      return 'https://hub.claims.statefarm.com/#/my-claims';
    }
  }

  public isTestEnvironment() {
    const hostname: string = this.windowRef.nativeWindow.location.hostname;
    return hostname.match(/.*\.test(\.c1)?\.statefarm\./) || hostname.match(/(mpw.*|wpw.*|localhost.*)/) || hostname.startsWith('10.')
      || this.windowRef.nativeWindow.location.port.match(/.*4443/);
  }

  public isWorkstation() {
    const hostname: string = this.windowRef.nativeWindow.location.hostname;
    return hostname.match(/(mpw.*|wpw.*|localhost.*)/) && this.windowRef.nativeWindow.location.port.match(/.*4443/);
  }

  public getHagertyURL(){
    if (this.isTestEnvironment()) {
      return `https://apps-${HostnameBuilderService.ENV}.test.statefarm.com/ApplicationRouter-web/app/launchView`;
    } else {
      return 'https://apps.statefarm.com/ApplicationRouter-web/app/launchView';
    }
  }

  public getReturnRedirectionURL(): string {
    if(this.isWorkstation()) {
      return 'https://localhost.reportloss.claims.test.statefarm.com:4443/start-claim';
    } else if (this.isTestEnvironment()) {
      return 'https://reportloss.claims.test.statefarm.com/start-claim';
    } else {
      return 'https://reportloss.claims.statefarm.com/start-claim';
    }
  }

  public getAppRedirectionURL(path: string): string {
    if(this.isWorkstation()) {
      return `https://localhost.reportloss.claims.test.statefarm.com:4443${path}`;
    } else if (this.isTestEnvironment()) {
      return `https://reportloss.claims.test.statefarm.com${path}`;
    } else {
      return `https://reportloss.claims.statefarm.com${path}`;
    }
  }

  public getAssuredCustomOAuthServerURL(state: string): string {
    let domain: string;
    let authServer: string;
    let redirectURI: string;
    let clientId: string;

    const SCOPE = 'openid%20offline_access%20assured:user:profile.read';
    const RESPONSE_TYPE = 'code';
    const RESPONSE_MODE = 'query';
    const CODE_CHALLENGE_METHOD = 'S256';
    const CODE_CHALLENGE = 'Tum0tL4oEg-lMDEPYHfMRhXIoH_zFGYC9PHZPstPnHY';

    if(this.isWorkstation()) {
      domain = 'auth.proofing.test.statefarm.com/api';
      authServer = 'ausho375nkSsnrAqO1d7';
      redirectURI = 'https://localhost.reportloss.claims.test.statefarm.com:4443/redirect-assured';
      clientId = '0oag5vem25zFkyLdx1d7';
    } else if(this.isTestEnvironment()) {
      domain = 'auth.proofing.test.statefarm.com/api'
      authServer = 'ausho375nkSsnrAqO1d7';
      redirectURI = 'https://reportloss.claims.test.statefarm.com/redirect-assured';
      clientId = '0oag5vem25zFkyLdx1d7';
    } else {
      domain = 'auth.proofing.statefarm.com/api';
      authServer = 'aush40vrsdel2jlZa1d7';
      redirectURI = 'https://reportloss.claims.statefarm.com/redirect-assured';
      clientId = '0oag5vem25zFkyLdx1d7';
    }

    return  `https://${domain}/oauth2/${authServer}/v1/authorize?code_challenge_method=${CODE_CHALLENGE_METHOD}&code_challenge=${CODE_CHALLENGE}&response_mode=${RESPONSE_MODE}&response_type=${RESPONSE_TYPE}&state=${state}&client_id=${clientId}&redirect_uri=${redirectURI}&scope=${SCOPE}`;
  }

  public getAssuredExperienceURL(authCodeParameter: string, policyNumber: string, dateOfLoss: string): string {
    // TODO: swap urls for assureds test and prod when available
    let assuredBaseUrl: string;
    if (this.isTestEnvironment()) {
      assuredBaseUrl = 'www-env3.test.statefarm.com';
    } else {
      assuredBaseUrl = 'www.statefarm.com';
    }

    return `https://${assuredBaseUrl}?code=${authCodeParameter}&pn=${encodeURIComponent(policyNumber)}&dl=${encodeURIComponent(dateOfLoss)}`;
  }

}
