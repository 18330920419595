import { Component } from '@angular/core';

@Component({
  selector: 'app-drawer-accident-checklist',
  templateUrl: './drawer-accident-checklist.component.html',
  styleUrls: ['./drawer-accident-checklist.component.css']
})
export class DrawerAccidentChecklistComponent {

  static readonly oneXSmallBreakpointPx = 768;
  isMobileDevice: boolean;

  constructor() {
    this.isMobileDevice = window.innerWidth <= DrawerAccidentChecklistComponent.oneXSmallBreakpointPx ? true : false;
  }

  onResize(event:any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < DrawerAccidentChecklistComponent.oneXSmallBreakpointPx;
  }

  closeDrawer(){
    globalThis.oneX.Drawer.closeButtonClicked('drawer-accident-checklist');
  }

  handleKeyDown(event: KeyboardEvent){
    if(event.key==='Enter' || event.key===' '){
      event.preventDefault();
      this.closeDrawer();
    }
  }

}
