export class Question {
    public id: string;
    public name: string;
    public dataType: string;
    public minLength: number;
    public maxLength: number;
    public minAnswer: number;
    public maxAnswer: number;
    public allowedValuesRegex: string;
    public allowedValues: string[];
    public answers: string[];
    public subQuestions: any[][];
}
