import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject, OnDestroy} from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base/base.component';
import { LogService } from '../../../services/log.service';
import { CliffLogCodes } from '../../../shared/constants/cliff-log-constants';
import { PrimaryPolicySearchService } from '../../../services/primary-policy-search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'olr-primary-policy-search',
  templateUrl: './primary-policy-search.component.html',
  styleUrls: ['./primary-policy-search.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class PrimaryPolicySearchComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('policyNumber', {static: true}) policyNumber: ElementRef;
  @ViewChild('vinNumber', {static: true}) vinNumber: ElementRef;
  static readonly oneXSmallBreakpointPx = 768;
  policyNumberSubQuestion: any;
  vinNumberSubQuestion: any;
  isValueProvided = false;
  isPolicyNumberError = false;
  isVinNumberError = false;
  showPolicyNumber = false;
  showVin = false;
  vinSecondAttempt = false;
  unprocessableSub$: Subscription;
  isUnprocessable = false;
  policySecondAttempt = false;
  policyErrorCheck = false;
  isMobileDevice: boolean;
  policyErr2 = "Unable to find a match for Policy #. To report an incident with a State Farm insured, please call us at 800-SF-CLAIM "
  vinErr2 = "Unable to find a match for VIN. To report an incident with a State Farm insured, please call us at 800-SF-CLAIM "
  sfNumber = "800-732-5246"
  attemptedPolicies2 = {}
  attemptedVins2 = {}

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    public searchService: PrimaryPolicySearchService,
    private analytics: SfAnalyticsService,

    @Inject('Window') private window: any,
  ) {
    super(lossReportService, router, routingService, logService);
    this.isMobileDevice = window.innerWidth <= PrimaryPolicySearchComponent.oneXSmallBreakpointPx;
   }

  ngOnInit(): void {
    this.analytics.sendData('insured-policy-details');
    this.getSubQuestions();
    this.displayAnsweredState();
    this.vinSecondAttempt = false;
    this.policySecondAttempt = false;

  }

  ngAfterViewInit(): void {
      this.window.oneX.addElement(document.querySelector('#primary-search'));
  }

  ngOnDestroy(): void {
      this.unsubscribe(this.unprocessableSub$);
  }

  private getSubQuestions() {
    this.policyNumberSubQuestion = this.step?.question.subQuestions[0][0];
    this.vinNumberSubQuestion = this.step?.question.subQuestions[0][1];
  }
  private unsubscribe(subscription: Subscription): void {
    if (subscription) { subscription.unsubscribe(); }
  }
  onResize(event: any) {
    const width = event.target.innerWidth;
    this.isMobileDevice = width < PrimaryPolicySearchComponent.oneXSmallBreakpointPx;
  }
  selectRadio(selection: string) {
    this.showPolicyNumber = selection === 'policy';
    if(this.showPolicyNumber){
      this.vinNumber.nativeElement.value = '';
      this.isVinNumberError = false;
      this.vinSecondAttempt = false;
      this.isUnprocessable = false;
    }
    this.showVin = selection === 'vin';
    if(this.showVin){
      this.policyNumber.nativeElement.value = '';
      this.isPolicyNumberError = false;
      this.policySecondAttempt = false;
      this.isUnprocessable = false;
    }
    this.isValueProvided = false;
  }

  private displayAnsweredState(){
    this.policyNumber.nativeElement.value = this.policyNumberSubQuestion.answers[0] || '';
    if(this.policyNumber.nativeElement.value){
      this.showPolicyNumber = true;
      this.window.oneX.$(`#policynumber-radio`).click();
    }
    this.vinNumber.nativeElement.value = this.vinNumberSubQuestion.answers[0] || '';
    if(this.vinNumber.nativeElement.value){
      this.showVin = true;
      this.window.oneX.$(`#vin-radio`).click();
    }
    this.updateContinueButtonVisibility();
  }

  updateContinueButtonVisibility() {
    this.isVinNumberError = false;
    this.vinSecondAttempt = false;
    this.isPolicyNumberError = false;
    this.policySecondAttempt = false;
    this.isUnprocessable = false;
    if(this.showPolicyNumber){
      if(this.policyNumber.nativeElement.value.length >= 11){
        this.policySecondAttempt = false;
        this.policyChange()
      }
      let policyAns = this.policyNumber.nativeElement.value;
      if(policyAns){
        this.isValueProvided = policyAns.length >= 12;
      }
    } else if(this.showVin){
      if(this.vinNumber.nativeElement.value.length >= 9){
        this.vinSecondAttempt = false;
        this.vinChange()
      }
      let vinAns = this.vinNumber.nativeElement.value;
      if(vinAns){
        this.isValueProvided = vinAns.length >= 10;
      }
    } else {
      this.isValueProvided = false;

    }
  }

  resetError() {
    if(this.showVin){
      this.policySecondAttempt = false
      this.isPolicyNumberError = false
      this.isUnprocessable = false
    }else{
      this.vinSecondAttempt = false
      this.isVinNumberError = false
      this.isUnprocessable = false
    }


  }

  checkFields(){
    if(this.showVin){
      this.checkAttemptedVin(this.vinNumber.nativeElement.value);
    }
    if(this.showPolicyNumber){
      this.checkAttemptedPolicy(this.policyNumber.nativeElement.value);
    }
  }


  checkAttemptedVin(vin: string) {
    const hasVin = this.attemptedVins2[vin]
    if(hasVin === undefined){
      this.attemptedVins2[vin] = false
      this.vinSecondAttempt = false
    }
    if(hasVin === false){
      this.attemptedVins2[vin] = true;
      this.vinSecondAttempt = true
      this.isUnprocessable = false;
    }


  }
  checkAttemptedPolicy(policy : string) {
    const hasPolicy = this.attemptedPolicies2[policy]
    if(hasPolicy === undefined){
      this.attemptedPolicies2[policy] = false;
      this.policySecondAttempt = false;
    }
    if(hasPolicy === false){
      this.attemptedPolicies2[policy] = true;
      this.policySecondAttempt = true;
      this.isUnprocessable = false;
    }
  }


  vinChange(){
    if(this.vinNumber.nativeElement.value.length >= 10){

      if (this.attemptedVins2[this.vinNumber.nativeElement.value] === undefined){
        this.vinSecondAttempt = false
        this.isVinNumberError = false
        this.isUnprocessable = false
      }else if(this.attemptedVins2[this.vinNumber.nativeElement.value] === false){
        this.isUnprocessable = true
      }else{
        this.vinSecondAttempt = true
        this.isUnprocessable = false
      }
    }
  }
  policyChange(){
    if(this.policyNumber.nativeElement.value.length >= 12){

      if (this.attemptedPolicies2[this.policyNumber.nativeElement.value] === undefined){
        this.policySecondAttempt = false
        this.isPolicyNumberError = false
        this.isUnprocessable = false
      }else if(this.attemptedPolicies2[this.policyNumber.nativeElement.value] === false){
        this.isUnprocessable = true
      }else{
        this.policySecondAttempt = true
        this.isUnprocessable = false
      }
    }
  }

  private validateStringAnswers(): boolean {
    const isPolicyNumberValid = this.regexValidate(
      this.policyNumber.nativeElement.value,
      this.policyNumberSubQuestion.allowedValuesRegex,
      this.policyNumberSubQuestion.minAnswers
      );
    this.isPolicyNumberError = !isPolicyNumberValid;


    if(this.isPolicyNumberError){
      // set policy number errorMsg for analytics
      this.analytics.sendData('insured-policy-details', null, '', 'Unable to find a match for Policy #. Please try again.');
    }

    const isVinNumberValid = this.regexValidate(
      this.vinNumber.nativeElement.value,
      this.vinNumberSubQuestion.allowedValuesRegex,
      this.vinNumberSubQuestion.minAnswers
      );
    this.isVinNumberError = !isVinNumberValid;
    if(this.isVinNumberError){
      // set vin number errorMsg for analytics
      this.analytics.sendData('insured-policy-details', null, '', 'Unable to find a match for VIN. Please try again.');
    }
    return isPolicyNumberValid && isVinNumberValid;
  }

  answerQuestion() {
    if (this.validateStringAnswers()) {
      this.logMessage();
      this.updateSubQuestions([this.answerPolicySearchSubQuestion()]);
      this.unprocessableSub$ = this.searchService.unprocessableNotificationSubject.subscribe(response =>{
        if(response===true){
          if(this.showPolicyNumber){
            this.isPolicyNumberError = true;
          }else{
            this.isVinNumberError = true;
          }
        }
      })
    }

  }

  private logMessage(){
    const logDetails = this.logService.getLogDetails();
    const code = CliffLogCodes.CLMT_PLCY_SRCH;
    logDetails.policyNumber = this.showPolicyNumber ? this.policyNumber.nativeElement.value : this.vinNumber.nativeElement.value;
    this.logService.log(CliffLogCodes.CLMT_PLCY_SRCH, logDetails);
  }

  private answerPolicySearchSubQuestion() {
    const searchValues = [];
    searchValues.push(this.createAnsweredQuestion('1003', 'policyNumber', this.policyNumber.nativeElement.value));
    searchValues.push(this.createAnsweredQuestion('1002E', 'vinNumber', this.vinNumber.nativeElement.value));
    return searchValues;
  }

}
