import { Component, OnInit, Inject } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'olr-vehicle-storage-fee',
  templateUrl: './vehicle-storage-fee.component.html',
  styleUrls: ['./vehicle-storage-fee.component.css', '../../shared/styles/question-common-styling.scss']
})
export class VehicleStorageFeeComponent extends BaseComponent implements OnInit {
  titleCasePipe = new TitleCasePipe();
  parentAnswer = '';

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    @Inject('Window') private window: any
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit() {
    this.analytics.sendData('vehicle-storage-fee-question');
    this.highlightButtonStandardAnswer();
  }

  triggerModal(answer: string): void {
    this.parentAnswer = answer;
    this.analytics.sendData('olr-storage-fees-modal');
    setTimeout(()=> {
      // @ts-ignore
      this.window.oneX.Modal.showModal(this, 'olr-storage-fees-modal');
    }, 100);
    this.answerQuestion(answer);
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.vehicleStorageFee = answer;
    this.logService.log(CliffLogCodes.VEHICLE_STORAGE_FEE, logDetails);
    this.updateAnswer(answer);
  }
}
