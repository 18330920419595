import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaintenanceGuard } from '../services/maintenance.guard';
import { AuthGuard } from '../services/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/start-claim', pathMatch: 'full' },
  {
    path: 'opt-in', canActivate: [MaintenanceGuard],
    loadChildren: () => import('../opt-in/opt-in.module').then(m => m.OptInModule)
  },
  {
    path: 'incident-details', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren:
    () => import('./homeandproperty-incident-details/homeandproperty-incident-details.module')
    .then(m => m.HomeAndPropertyIncidentDetailsModule)
  },
  {
    path: 'contact-information', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren:
    () => import('./homeandproperty-contact-information/homeandproperty-contact-information.module')
    .then(m => m.HomeandpropertyContactInformationModule)
  },
  {
    path: 'review', canActivate: [MaintenanceGuard, AuthGuard],
    loadChildren:
    () => import('./homeandproperty-review/homeandproperty-review.module')
    .then(m => m.HomeandpropertyReviewModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeandpropertyRoutingModule { }


