import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'olr-lib-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit, AfterViewInit, OnDestroy {
  private chapters = [
     {id: 'claimant/incident-details/additional-comments', text: {current: 'Final Details', next: 'Review'}},
     {id: 'claimant/incident-details', text: {current: 'Incident Details', next: 'Final Details'}},
     {id: 'claimant/additional-details', text: {current: 'Final Details', next: 'Review'}},
     {id: 'homeandproperty/review/additional-comments', text: {current: 'Final Details', next: 'Review'}},
     {id: 'homeandproperty/incident-details', text: {current: 'Incident Details', next: 'Contact Information'}},
     {id: 'homeandproperty/contact-information', text: {current: 'Contact Information', next: 'Final Details'}},
     {id: 'homeandproperty/additional-details', text: {current: 'Final Details', next: 'Review'}},
     {id: 'homeandproperty/review/review-claim', text: {current: 'Review', next: 'Confirmation'}},
     {id: 'incident-details', text: {current: 'Incident Details', next: 'Damage Details'}},
     {id: 'damage-details', text: {current: 'Damage Details', next: 'Contact Information'}},
     {id: 'contact-information', text: {current: 'Contact Information', next: 'Other Vehicles'}},
     {id: 'other-vehicles', text: {current: 'Other Vehicles', next: 'Final Details'}},
     {id: 'review', text: {current: 'Final Details', next: 'Review'}}
  ];
  private previousRoutes = [
    {id: 'damage-confirmation', previous: '/damage-details/exterior-damage'},
    {id: 'review-claim', previous: '/review/additional-comments'}
  ];
  showProgress = false;
  progressIndicatorOpen = false;
  currentChapter: string;
  nextChapter: string;
  previousRoute: string;
  nextRoute: string;
  forwardEnabled = false;

  constructor(
    @Inject('Window') private window: any,
    private router: Router,
    private lossReportService: LossReportService,
    private routingService: RoutingService
  ) {

    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)).subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.displayHeader(event);
        }
      });
  }

  ngOnInit(){
    this.previousRoute = this.lossReportService.getStepRouteByIndex(this.router.url, -1) || this.findPreviousRoute();
    this.nextRoute = this.lossReportService.getStepRouteByIndex(this.router.url, 1);
    this.setCurrentAndNextChapterText();
    this.forwardEnabled = this.lossReportService.canMoveForward(this.router.url);
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.window['oneX'].addElement('#nav-back-arrow');
    // @ts-ignore
    this.window['oneX'].addElement('#nav-forward-arrow');
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement('#nav-back-arrow');
    this.window['oneX'].removeElement('#nav-forward-arrow');
  }
  private findPreviousRoute() {
    const currentRoute = this.router.url || '';
    if (currentRoute.includes('/claimant/review/review-claim')) {
      const previousRouteLocation = '/claimant/incident-details/additional-comments';
      return previousRouteLocation;
    }
    if (currentRoute.includes('/homeandproperty/review/review-claim')) {
      const previousRouteLocation = '/homeandproperty/review/additional-comments';
      return previousRouteLocation;
    }
    const previousRoute = this.previousRoutes.find(prev => currentRoute.indexOf(prev.id) > 0 );
    return previousRoute ? previousRoute.previous : undefined;
  }

  private setCurrentAndNextChapterText(){
    const route = this.router.url;
    const chapter = this.chapters.find((chap) => {
      return route.search(chap.id) > -1;
    });
    if (chapter) {
      this.currentChapter = chapter.text.current;
      this.nextChapter = chapter.text.next;
    } else if (route.indexOf('confirmation') > 0) {
      this.currentChapter = 'Claim Filed';
      this.nextChapter = undefined;
    }
  }

  // uses current route to display progress component
  displayHeader(event: NavigationEnd) {
    const noShowConditions = [
      n => n === '/',
      n => n === '/home',
      n => n === '/claimant',
      n => n === '/homeandproperty',
      n => n === '/claim-type',
      n => n === '/opt-in/preferences',
      n => n === '/homeandproperty/opt-in/preferences',
      n => n === '/session/session-expired',
      n => n === '/session/session-expired-confirmation',
      n => n === '/maintenance',
      n => n === '/redirect-assured',
      n => n === n && n.includes('?'),
      n => n === n && n.includes('/error/'),
      n => n === n && n.includes('/confirmation/'),
      n => n === '/incident-details/current-location',
    ];
    const url = event.url;
    this.showProgress = !noShowConditions.find(fn => fn(url));
  }

  onNavigateBack(): void {
    if (this.previousRoute) {
      this.router.navigate([this.previousRoute]);
    }
  }

  onNavigateForward(): void {
    if (this.nextRoute) {
      this.router.navigate([this.nextRoute]);
      this.forwardEnabled = this.lossReportService.canMoveForward(this.router.url);
    }
    if (!this.nextRoute && this.router.url.includes('/review/additional-comments')) {
      this.routingService.navigateNext();
    }
  }

}
