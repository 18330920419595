import { Injectable } from '@angular/core';
import { Customer } from '../../models/customer';

function getValue( jsonObject: object, lookup: any): any {
  if(!jsonObject || !lookup) {return}
  if (typeof lookup === 'string') {
    return getValue(jsonObject, lookup.split('.'));
  } else if (lookup.length === 0) {
    return jsonObject;
  } else {
    return getValue(jsonObject[lookup], lookup.slice(1));
  }
}

function getCustomerIdentifier(customerInfo) {
  const json = customerInfo
  return json ? getValue(customerInfo, 'id') : undefined;
}

function getCustomerType(customerInfo) {
  const json = customerInfo
  return json ? getValue(json, 'type') : undefined;
}

function getCustomerExternalClientId(customerInfo) {
  const json = customerInfo
  return json ? getValue(json, 'externalId') : undefined;
}

function getLanguages(customerInfo) {
  const json = customerInfo
  return json ? getValue(json, 'languagePreference') : [];
}

function getName(customerInfo) {
  const json = customerInfo
  return json ? convertName(getValue(json, 'name')) : undefined;
}

function convertName(customerName) {
  if(!customerName) {return}
  let firstName = customerName['first'];
  let lastName = customerName['last'];
  let fullName = {};
  if (firstName) {
    fullName['firstName'] = firstName;
  }
  if (lastName) {
    fullName['lastName'] = lastName;
  }
  return fullName;
}

function getEmail(customerInfo) {
  if(!customerInfo) {return}
  const json = customerInfo
  let email = json ? getValue(json, 'email') || '' : '';
  return json ? email.trim() : undefined;
}

function getResidentialAddress(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'addresses') || [];
  const residential = json.filter((value) => {
    return value.usage === "Residential"
  })
  return residential ? objectToArray(residential) : undefined;
}

function getMailingAddress(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'addresses') || [];
  const mailing = json.filter((value) => {
    return value.usage === "Mailing"
  })
  return mailing ? objectToArray(mailing) : undefined;
}

function objectToArray(customerAddress) {
  if(customerAddress && customerAddress.length == 0) {return}
  let street1 = customerAddress[0]['street1'];
  let street2 = customerAddress[0]['street2'];
  let array = [];
  if (street1) {
    array.push(street1);
  }
  if (street2) {
    array.push(street2);
  }

  customerAddress[0]['addressLines'] = array;
  return customerAddress[0];
}

function getProtectedPersonIndicator(customerInfo) {
  const json = customerInfo
  return json ? getValue(json, 'protectedPersonIndicator') === true : false;
}

function getMobilePhone(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'phones') || [];
  const mobile = json.filter((value) => {
    return value.usage === "Mobile"
  })
  return mobile && mobile.length > 0 ? mobile[0].phoneNumber : '';
}

function getWorkPhone(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'phones') || [];
  const work = json.filter((value) => {
    return value.usage === "Work"
  })
  return work && work.length > 0 ? work[0].phoneNumber : undefined;
}

function getHomePhone(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'phones') || [];
  const home = json.filter((value) => {
    return value.usage === "Home"
  })
  return home && home.length > 0 ? home[0].phoneNumber : undefined;
}

function getWorkExtension(customerInfo) {
  if(!customerInfo) {return}
  const json = getValue(customerInfo, 'phones') || [];
  const work = json.filter((value) => {
    return value.usage === "Work"
  });
  return work && work.length > 0 ? work[0].extension : undefined;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerBuilderService {
  constructor() { }

  buildCustomer(customerInfo: object): Customer {
    const included = getValue(customerInfo, 'payload');
    const customer = new Customer();
    customer.identifier = getCustomerIdentifier(included);
    customer.externalClientId = getCustomerExternalClientId(included);
    customer.customerType = getCustomerType(included);
    customer.languages = getLanguages(included);
    customer.name = getName(included);
    customer.email = getEmail(included);
    customer.residentialAddress = getResidentialAddress(included);
    customer.mailingAddress = getMailingAddress(included);
    customer.protectedPerson = getProtectedPersonIndicator(included);
    customer.mobilePhone = getMobilePhone(included);
    customer.workPhone = getWorkPhone(included);
    customer.homePhone = getHomePhone(included);
    customer.workExtension = getWorkExtension(included);
    return customer;
  }
}
