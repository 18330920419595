import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '../../../services/log.service';
import { LossReportService } from 'projects/lossreporting-library/src/lib/services/loss-report.service';
import { RoutingService } from '../../../services/routing.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { BaseComponent } from '../../../shared/base/base.component';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'olr-homeandproperty-flood-policy',
  templateUrl: './homeandproperty-flood-policy.component.html',
  styleUrls: ['./homeandproperty-flood-policy.component.css', '../../../shared/styles/question-common-styling.scss']
})
export class HomeandpropertyFloodPolicyComponent extends BaseComponent implements OnInit {
  @ViewChild('floodClaimModal') floodClaimModal!: ElementRef;

  constructor(
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    private analytics: SfAnalyticsService,
    public logService: LogService
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.analytics.sendData('property-flood-policy-question');

  }

  // since this was intercepted from the previous page, we need to handle the routing here to next page as it did prior
  // to presenting flood policy question. If flood policy component is removed, update incident-weather and incident-water-damage
  // components.
  answerQuestion(answer: string): void {
    if (answer === 'no') {
      this.routingService.navigateNext();
    }

  }

  floodClaimModalOpen(): void {
    const modal = new bootstrap.Modal(this.floodClaimModal.nativeElement);
    modal.show();

  }

}
