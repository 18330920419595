import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from '../models/auth.model';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { LogService } from './log.service';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { parseJwt, exchangeOauth2Tokens } from 'customer-oauth2-token-management-utility';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private urlService: HostnameBuilderService,
    private cookieService: CookieHandlerService,
    private logService: LogService
  ) { }

  refreshToken = exchangeOauth2Tokens;
  authParseJwt = parseJwt;
  intervalId: any;
  ssoLastChecked: number = 0;

  private async oauthRefreshTokens(): Promise<boolean> {
    return await this.refreshToken();
  }

  public startTimer() {
    let self = this;
    if (!this.router.url.includes('/claimant/')) {
      this.intervalId = setInterval(function(){
        self.isAuthenticated();
    }, 60000);
    }
  }

  public stopTimer() {
    clearInterval(this.intervalId);
  }

  private getTime(): number {
    const now = new Date();
    return now.getTime();
  }

  validateRefreshOktaToken(): Promise<boolean> {
    const parsedJWT = this.cookieService.getOauthToken();
    if (parsedJWT !== ''){
      const tokenExpiry = this.authParseJwt();
      const timeToRefresh = tokenExpiry.exp - 180;
      let currentTime = Math.ceil((this.getTime())/1000);
      if(timeToRefresh <= currentTime) {
        this.oauthRefreshTokens().then(res => {
          if (!res) {
            this.logService.log(CliffLogCodes.AUTHGUARD_OKTA_REFRESH_FAILED);
            return Promise.resolve(true);
          }
          this.logService.log(CliffLogCodes.AUTHGUARD_OKTA_REFRESH_SUCCESS);
          return Promise.resolve(true);
        });
      }
      this.logService.log(CliffLogCodes.AUTHGUARD_OKTA_STILL_ACTIVE);
      return Promise.resolve(true);
    } else {
      return Promise.resolve(true);
    }
  }

  validateRefreshSSOToken(): Promise<boolean> {
    const sso = this.cookieService.getSSOToken();
    const currentTime = Math.ceil(this.getTime()/1000);
    const timeToRefresh = Math.ceil(this.ssoLastChecked/1000) + 300;
    if(sso !== '' && timeToRefresh <= currentTime) {
      this.ssoLastChecked = this.getTime();
      const canAccess: Promise<boolean> =  this.getAuthInfo(sso).then(response => {
        if (response.loa === 4) {
          this.logService.log(CliffLogCodes.AUTHGUARD_SSO_SUCCESS);
          return true;
        } else {
          this.logService.log(CliffLogCodes.AUTHGUARD_SSO_UNAUTH_LOA);
          return true;
        }
      }).catch((error: any) => {
        if (error.status === 400) {
          this.logService.log(CliffLogCodes.AUTHGUARD_SSO_BAD_REQUEST_ERROR);
        } else {
          this.logService.log(CliffLogCodes.AUTHGUARD_SSO_REQUEST_ERROR);
        }
        return true;
      });
      return canAccess;
    } else {
      return Promise.resolve(true);
    }
  }

  public async validateRefreshAuthentication(): Promise<boolean> {
    await Promise.all([this.validateRefreshOktaToken(), this.validateRefreshSSOToken()]);
    return Promise.resolve(true);
  }

  public isAuthenticated(): Promise<boolean> {
    Promise.all([this.validateRefreshOktaToken(), this.validateRefreshSSOToken()]);
    return Promise.resolve(true);
  }

  getAuthInfo(sso : string): Promise<Auth> {
    const correlation = this.logService.getCorrelationId();
    const authurl = this.urlService.getAuthURL();
    if (sso !== '') {
      const headeroptions = {
        headers: new HttpHeaders({
          'x-sf_sso_token': sso,
          'correlationId': correlation,
          'x-api-key': 'sf-authentication-api-consumer-online-loss-reporting-ui'
        })
      };
      const response = this.http.get(authurl, headeroptions).toPromise()
        .then((response: any): Promise<any> => {
          return Promise.resolve(response);
        })
        .catch((error: any): Promise<any> => {
          return Promise.reject(error);
        });
      return response;
    }
    return Promise.reject({ status: 400 });
  }
}
