import { Component, OnInit, Output, EventEmitter, Inject, Input, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'olr-injury-selection',
  templateUrl: './injury-selection.component.html',
  styleUrls: ['./injury-selection.component.css']
})
export class InjurySelectionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() setSelection = new EventEmitter<any>();
  @Input() answeredState: string[];
  @Input() questionText: string;
  selectedInjuries = [];

  get questionTextValue(): string {
    return this.questionText || 'What injuries were sustained?';
  }

  constructor(
    @Inject('Window') private window: any
  ) { }

  ngOnInit(): void {
    this.setAnsweredState();
  }
  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#expansionPanels'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#expansionPanels'));
  }
  setAnsweredState() {
    const answers = this.answeredState || [];
    answers.forEach((answer: string) => {
      // @ts-ignore
      this.window.oneX.$(`#${answer}`).prop('checked', true);
    });
    this.selectedInjuries = answers;
  }

  updateInjuries(e: Event) {
    const defaultValue = 'defaultValue';
    const inputValue = e.target[defaultValue];
    const checked = 'checked';
    const isChecked = e.target[checked];

    if (isChecked && !this.selectedInjuries.some(injury => injury === inputValue)) {
      this.selectedInjuries.push(inputValue);
    } else  {
      this.selectedInjuries = this.selectedInjuries.filter(injury => injury !== inputValue);
    }

    this.setSelection.emit(this.selectedInjuries);
  }

}
