import { Component, OnInit ,Inject} from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';


@Component({
  selector: 'olr-purpose-of-trip-work-related',
  templateUrl: './purpose-of-trip-work-related.component.html',
  styleUrls: ['./purpose-of-trip-work-related.component.css','../../shared/styles/question-common-styling.scss']
})
export class PurposeOfTripWorkRelatedComponent extends BaseComponent implements OnInit {

  constructor(
    private analytics: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    @Inject('Window') private window: any
    ) {
      super(lossReportService, router, routingService, logService);
     }


  ngOnInit() {
    this.analytics.sendData('transported-fee-question');
    this.highlightButtonStandardAnswer();
  }

  answerQuestion(answer: string): void {
    const logDetails = this.logService.getLogDetails();
    logDetails.purposeOfTripWorkRelated = answer;
    this.logService.log(CliffLogCodes.PURPOSE_OF_TRIP_WORK_RELATED, logDetails);
    this.updateAnswer(answer);

  }

  closeDrawer(){
    // @ts-ignore
    this.window.oneX.Drawer.hideDrawer();

  }

}
