import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LogService } from '../log.service';
import { Router } from '@angular/router';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { CliffLogDetails } from '../../models/cliff-log-details.model';
import { ClaimsEventsService } from '../claims-events.service';
import { PrimaryPolicySearchService } from '../primary-policy-search.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  private handlers = [
    {service: 'claimloss-api-lossreports', errors: [
      {status: 401, page: '/error/unauthorized-access', hasErrorBody: false},
      {status: 403, code: 'E05202', message: 'Authorization Failure', page: '/error/unauthorized-access', hasErrorBody: true},
      {status: 403, code: 'E05201', message: 'Unsupported Role', page: '/error/unsupported-associate', hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A003', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A013 - Hagerty policy found', modal: { name: 'olr-modal-hagerty', tag: 'hagerty-kickout'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A004', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A006', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A012', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A994', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A995', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A996', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A997', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A999', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A013 - Policy not found', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A001 - Match Policy Error', modal: { name: 'olr-modal-policy-not-found', tag: 'policy-not-found'}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A001 - Unable to Match Policy', modal: {}, hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A666', page: '/error/duplicate-claim', hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A009', page: '/error/injury-error', hasErrorBody: true},
      {status: 422, code: 'E05111', message: 'Loss Report already submitted', page: '/error/claim-already-submitted', hasErrorBody: true},
      {status: 422, code: 'E05108', message: 'A018', modal: { name: 'olr-modal-participant-match-error', tag: 'contactinfo-rule-fail'}, hasErrorBody: true},
     ]},
    {service: 'claimpolicy-api-policies', errors: [
      {status: 401, code: 'E00600', message: 'Unauthorized Access', page: '/error/unauthorized-access', hasErrorBody: true}
    ]},
    {service: 'claimfile-api-claims', errors: []},
    {service: 'claimcustomerproxy', errors: [
      {status: 401, code: 'Unauthorized', message: 'authentication is required', page: '/error/unauthorized-access', hasErrorBody: true},
      {status: 403, code: 'Unauthorized', message: 'authentication is required', page: '/error/unauthorized-access', hasErrorBody: true}
    ]},
    {service: 'claimexperience-api-lossreporting', errors: [
      {status: 401, code: 'E00001', message: 'Access Denied', page: '/error/unauthorized-access', hasErrorBody: true}
    ]},
  ];
  private cliffLogDetails: CliffLogDetails;
  public currentPage;
  public hasSecondChance = true;
  public policyNotFoundCounter = 0;
  constructor(
    @Inject('Window') private window: any,
    private router: Router,
    private logService: LogService,
    private analytics: SfAnalyticsService,
    private claimsEventsService: ClaimsEventsService,
    private searchService: PrimaryPolicySearchService,
  ) { }

  handleError(errorResponse: HttpErrorResponse, logCode: any): void {
    //call buildevent here, and future events
    this.cliffLogDetails = new CliffLogDetails(logCode.id);
    this.cliffLogDetails.logData = this.logService.getLogDetails();

    const serviceHandler = this.getHandler(errorResponse.url);
    if (serviceHandler) {
      this.processError(errorResponse, serviceHandler.service, serviceHandler.errors, logCode);
    }else{
      this.unhandledError(errorResponse, logCode);
    }
  }

  private getHandler(url: string){
    return this.handlers.find((handler) => {
      url = url || '';
      return url.includes(handler.service);
    });
  }

  private processError(errorResponse: HttpErrorResponse, serviceName: string, errorDefs: any[], logCode: any): void {
    if (serviceName === 'claimcustomerproxy') {
      const handler = errorDefs.find((def) => {
        return def.status === errorResponse.status;
      });

      handler ? this.handle(handler, errorResponse) : this.unhandledError(errorResponse, logCode);

    } else {
      const errorMessage = errorResponse.error ? errorResponse.error.errors[0].message || '' : '';
      const handler = errorDefs.find((def) => {
        if(def.hasErrorBody) {
          return def.status === errorResponse.status && def.code === errorResponse.error.errors[0].code && errorMessage.includes(def.message);
        }
        return def.status === errorResponse.status;
      });

      handler ? this.handle(handler, errorResponse) : this.unhandledError(errorResponse, logCode);
    }
  }

  private handle(handler: any, errorResponse: any): void {
    this.cliffLogDetails.httpResponse = handler.status;
    this.cliffLogDetails.logData.serviceErrorCode = handler.code;
    this.cliffLogDetails.message = handler.message;
    this.cliffLogDetails.logData.serviceErrorMessage = JSON.stringify(errorResponse);
    this.cliffLogDetails.logData.serviceName = handler.service;
    this.logService.sendSparklLog(this.cliffLogDetails);
    if (handler.page && handler.page === '/error/duplicate-claim'){
      this.claimsEventsService.buildEvent('Duplicate Claim');
    }
    if (handler.modal) {
    const hasTag = 'tag' in handler.modal
    const hasName = 'name' in handler.modal
      if(!(this.router.url?.includes('/claimant/') && (handler.modal.tag === 'policy-not-found'))){
        hasTag ? this.analytics.sendData(handler.modal.tag): '';
      }

      // @ts-ignore
      hasName ? this.window.oneX.Modal.showModal(this, handler.modal.name): '';

      if ((hasName && handler.modal.name === 'olr-modal-policy-not-found') && (this.router.url?.includes('/claimant/'))) {
        this.policyNotFoundCounter += 1
        this.searchService.sendUnprocessabeNotification(true)

      }

      if (this.policyNotFoundCounter >= 1) {
      // handler.modal.name = 'olr-modal-policy-still-not-found';
      // handler.modal.tag = 'policy-still-not-found';
      this.searchService.sendUnprocessabeNotification(true)
      this.claimsEventsService.buildEvent('Policy Not Found');
      }

      if (handler.message === 'A001 - Unable to Match Policy' && this.hasSecondChance) {
        this.hasSecondChance = false;
        // handler.modal.name = 'olr-modal-policy-not-found';
        // handler.modal.tag = 'policy-not-found';
        this.searchService.sendUnprocessabeNotification(true)
        this.claimsEventsService.buildEvent('Policy Not Found');
      }
      if(handler.status === 422){
        this.searchService.sendUnprocessabeNotification(true)
      }
    }
    else {
      const currentPage = window.location.href;
      this.setCurrentPage(currentPage);
      this.router.navigate([handler.page]);
    }
  }

  private unhandledError(errorResponse: HttpErrorResponse, logCode: any): void {
    this.cliffLogDetails.httpResponse = errorResponse.status;
    this.cliffLogDetails.message = logCode.text;
    this.cliffLogDetails.logData.serviceErrorMessage  = JSON.stringify(errorResponse);
    this.cliffLogDetails.logData.serviceName = logCode.svcName;
    this.logService.sendSparklLog(this.cliffLogDetails);
    this.router.navigate(['/error/technical-error']);
    this.claimsEventsService.buildEvent('Technical Error');
  }

  public setCurrentPage(page: string) {
    this.currentPage = page;
  }
}
