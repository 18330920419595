// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
  min-height: 100%;
}

#main-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

#sticky-footer-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
}

#sticky-footer {
  flex-shrink: 0;
}

.olr-modal {
  display: none;
  align-content: center;
}

.hide {
  display: none;
}

:host ::ng-deep .-oneX-copyright-text {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-app/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[":host {\n  height: 100%;\n  min-height: 100%;\n}\n\n#main-container {\n  display: flex;\n  flex: 1 0 auto;\n  flex-direction: column;\n}\n\n#sticky-footer-container {\n  display: flex;\n  flex: 1 0 auto;\n  flex-direction: column;\n  height: 100%;\n}\n\n#sticky-footer {\n  flex-shrink: 0;\n}\n\n.olr-modal {\n  display: none;\n  align-content: center;\n}\n\n.hide {\n  display: none;\n}\n\n:host ::ng-deep .-oneX-copyright-text {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
