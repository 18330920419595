import { Component, Input } from '@angular/core';

@Component({
  selector: 'olr-card-standard',
  templateUrl: './card-standard.component.html',
  styleUrls: ['./card-standard.component.css']
})
export class CardStandardComponent {
  @Input() heading: string;
  @Input() showLine: boolean = true;

  constructor() { }

}
