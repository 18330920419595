import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClaimantIncidentDetailsRoutingModule } from './claimant-incident-details-routing.module';
import { ReporterTypeComponent } from './reporter-type/reporter-type.component';
import { PolicySearchComponent } from './policy-search/policy-search.component';
import { VehicleMakeSelectionComponent } from './vehicle-make-selection/vehicle-make-selection.component';
import { ClaimantIncidentTypeComponent } from './claimant-incident-type/claimant-incident-type.component';
import { IncidentDetailsModule as SharedIncidentResourceModule } from '../../incident-details/incident-details.module';
import { DamageDetailsModule as SharedDamageResourceModule } from '../../damage-details/damage-details.module';
import { ClaimantContactComponent } from './claimant-contact/claimant-contact.component';
import { ClientInvolvementComponent } from './client-involvement/client-involvement.component';
import { ClaimantVehicleComponent } from './claimant-vehicle/claimant-vehicle.component';
import { ClaimInformationComponent } from './claim-information/claim-information.component';
import { ReviewModule as SharedReviewResourceModule } from '../../review/review.module';
import { ClaimantDOBComponent } from './claimant-dob/claimant-dob.component';
import { VehicleSelectionComponent } from './vehicle-selection/vehicle-selection.component';
import { ModalMissingVehicleComponent } from './vehicle-selection/modal-missing-vehicle/modal-missing-vehicle.component';
import { ClaimantVehicleLocationComponent } from './claimant-vehicle-location/claimant-vehicle-location.component';
import { ClaimantNiDriverComponent } from './claimant-ni-driver/claimant-ni-driver.component';
import { PrimaryPolicySearchComponent } from './primary-policy-search/primary-policy-search.component';
import { SecondaryPolicySearchComponent } from './secondary-policy-search/secondary-policy-search.component';


@NgModule({
  declarations: [
    ReporterTypeComponent,
    PolicySearchComponent,
    VehicleMakeSelectionComponent,
    ClaimantIncidentTypeComponent,
    ClaimantContactComponent,
    ClientInvolvementComponent,
    ClaimantIncidentTypeComponent,
    ClaimantVehicleComponent,
    ClaimantContactComponent,
    ClaimantDOBComponent,
    ClaimInformationComponent,
    VehicleSelectionComponent,
    ModalMissingVehicleComponent,
    ClaimantVehicleLocationComponent,
    ClaimantNiDriverComponent,
    PrimaryPolicySearchComponent,
    SecondaryPolicySearchComponent
  ],
  imports: [
    CommonModule,
    ClaimantIncidentDetailsRoutingModule,
    SharedIncidentResourceModule,
    SharedDamageResourceModule,
    SharedReviewResourceModule
  ]
})
export class ClaimantIncidentDetailsModule { }
