// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-oneX-textfield--floating-input, .-oneX-dropdown {
    margin-bottom: 21px;
}
.lr-mt {
    margin-top: 35px;
}
.lr-help {
    float: right;
    margin-top: -34px;
}`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/claimant/claimant-incident-details/claimant-vehicle/claimant-vehicle.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".-oneX-textfield--floating-input, .-oneX-dropdown {\n    margin-bottom: 21px;\n}\n.lr-mt {\n    margin-top: 35px;\n}\n.lr-help {\n    float: right;\n    margin-top: -34px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
