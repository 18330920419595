import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { CookieHandlerService } from './helpers/cookie-handler.service';
import { LogService } from './log.service';
import { Subject, takeUntil } from 'rxjs';
import { CliffLogCodes } from '../shared/constants/cliff-log-constants';
import { parseJwt } from 'customer-oauth2-token-management-utility';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiKey = 'sf-authentication-api-consumer-online-loss-reporting-ui';
  private authRetrieved = new Subject<any>();
  authParseJwt = parseJwt;

  constructor(
    private http: HttpClient,
    private hostnameBuilder: HostnameBuilderService,
    private cookieService: CookieHandlerService,
    private logService: LogService,
  ) { }

  getAuthInfo(): Subject<any> {
    let parsedJwt = this.authParseJwt();
    const url = `${this.hostnameBuilder.getAuthURL()}`;
    const oauthHeader = this.cookieService.getOauthToken() ? 'Bearer ' + this.cookieService.getOauthToken() : '';

    const headers = Object.keys(parsedJwt).length > 0 ? new HttpHeaders({
      'Authorization': oauthHeader,
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId(),
      'x-api-key': this.apiKey
    }) : new HttpHeaders({
      'x-sf_sso_token': this.cookieService.getSSOToken(),
      'correlation-id': this.logService.getCorrelationId(),
      'x-api-key': this.apiKey
    })

    if (Object.keys(parsedJwt).length > 0) {
      const expiryTime = parsedJwt.exp;
      const currentTime = Math.ceil((new Date().getTime())/1000);
      const isTokenActive = expiryTime > currentTime;

      const audience = parsedJwt.aud;
      const isNotLOA2 = audience && audience != 'sf.b2c://lite-api';

      setTimeout(() => {
        if(isTokenActive && isNotLOA2) {
          this.authRetrieved.next(true);
          this.logService.log(CliffLogCodes.LR_OKTA_AUTH_PASSED);
        } else {
          this.authRetrieved.next(false);
          this.logService.log(CliffLogCodes.LR_OKTA_AUTH_FAILED);
        }
      }, 100);
    } else {
      this.http
          .get(url, { headers })
          .pipe(takeUntil(this.authRetrieved))
          .subscribe({
            next: response => {
              this.logService.log(CliffLogCodes.LR_AUTH_PASSED);
              // @ts-ignore
              this.authRetrieved.next(response.loa === 4);
            },
            error: _error => {
              this.logService.log(CliffLogCodes.LR_AUTH_FAILED);
              this.authRetrieved.next(false);
            }
          });
    }
    return this.authRetrieved;
  }
}

