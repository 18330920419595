import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeandpropertyReviewRoutingModule } from './homeandproperty-review-routing.module';
import { ReviewModule as SharedReviewModule } from '../../review/review.module';
import { HomeandpropertyReviewClaimComponent } from './homeandproperty-review-claim/homeandproperty-review-claim.component';

@NgModule({
  declarations: [HomeandpropertyReviewClaimComponent],
  imports: [
    CommonModule,
    HomeandpropertyReviewRoutingModule,
    SharedReviewModule
  ]
})
export class HomeandpropertyReviewModule { }
