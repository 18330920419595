import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdditionalCommentsComponent } from '../../review/additional-comments/additional-comments.component';
import { MaintenanceGuard } from '../../services/maintenance.guard';
import { HomeandpropertyReviewClaimComponent } from './homeandproperty-review-claim/homeandproperty-review-claim.component';
import { AuthGuard } from '../../services/guards/auth.guard';

const routes: Routes = [
  { path: 'additional-comments', component: AdditionalCommentsComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'review-claim', component: HomeandpropertyReviewClaimComponent, canActivate: [MaintenanceGuard, AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeandpropertyReviewRoutingModule { }
