import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { LossReportService } from '../../../services/loss-report.service';
import { SfAnalyticsService } from '../../../services/sf-analytics.service';
import { HostnameBuilderService } from '../../../services/helpers/hostname-builder.service';
import { ClaimsEventsService } from '../../../services/claims-events.service';
import { AuthenticationService } from '../../../services/authentication.service';
@Component({
  selector: 'olr-modal-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements AfterViewInit, OnDestroy {

  constructor(
    @Inject('Window') private window: any,
    private lossReportService: LossReportService,
    private analytics: SfAnalyticsService,
    private hostnameBuilderService: HostnameBuilderService,
    private claimsEventsService: ClaimsEventsService,
    private authenticationService: AuthenticationService,
    ) {}

  ngAfterViewInit(): void {
    this.window['oneX'].addElement(document.querySelector('#olr-modal-cancel'));
  }
  ngOnDestroy(): void {
    this.window['oneX'].removeElement(document.querySelector('#olr-modal-cancel'));
  }
  onCloseCancelModal() {
    this.window.oneX.Modal.hideModal();
  }

  onConfirmCancel() {
    this.authenticationService.stopTimer();
    this.claimsEventsService.buildEvent('Claim Cancelled');
    this.analytics.setInitialState();
    this.analytics.sendData('exit-file-a-loss', () => {
      sessionStorage.clear();
      this.lossReportService.deleteReport();
      this.window.oneX.Modal.hideModal();
      this.window.location.replace(`https://www${this.hostnameBuilderService.getHostname()}/claims`);
    });
  }

}
