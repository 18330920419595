// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incident-question{
    text-align: center;
    margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/homeandproperty/homeandproperty-incident-details/homeandproperty-incident-type/incident-septic-tank-sewer/incident-septic-tank-sewer.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".incident-question{\n    text-align: center;\n    margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
