import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClaimantReviewRoutingModule } from './claimant-review-routing.module';
import { ClaimantReviewClaimComponent } from './claimant-review-claim/claimant-review-claim.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [ClaimantReviewClaimComponent],
  imports: [
    CommonModule,
    ClaimantReviewRoutingModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ]
})
export class ClaimantReviewModule { }
