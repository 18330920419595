import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { DatetimePickerComponent } from '../../shared/datetime-picker/datetime-picker.component';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from 'projects/lossreporting-library/src/lib/shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../services/claims-events.service';

@Component({
  selector: 'olr-incident-date',
  templateUrl: './incident-date.component.html',
  styleUrls: ['./incident-date.component.css', '../../shared/styles/question-common-styling.scss']
})
export class IncidentDateComponent extends BaseComponent implements AfterViewInit, OnInit {
  @ViewChild(DatetimePickerComponent) datepicker: DatetimePickerComponent;

  incidentDate: any = '';
  error: boolean;
  futureError: boolean;
  timeError: boolean;
  sendWithTime = true;
  fullDateTimeObject: any = '';
  dateFormatString = 'MM-DD-YYYYTHH:mm:a';
  answer = '';
  isDateProvided = false;

  ngAfterViewInit() {
    this.fullDateTimeObject = this.datepicker.getFullDateTime();
  }

  constructor(
    private analyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private claimsEventsService: ClaimsEventsService,
    ) {
      super(lossReportService, router, routingService, logService);
     }

  ngOnInit() {
    this.claimsEventsService.buildEvent('Claim Started');
    this.analyticsService.sendData('dateofloss-question');
    const answers = this.step.question.answers;
    if (answers && answers.length > 0) {
      this.answer = answers[0];
      this.isDateProvided = true;
    }
  }

  // expects date in ISO 8601 format. ex: 2018-01-15T12:00:00.000Z
  answerQuestion(): void {
    const validDate = this.validateDateTimeNotFuture();
    if (validDate) {

      this.lossReportService.setIncidentDate(validDate);
      const logDetails = this.logService.getLogDetails();
      // logDetails.dateOfLoss = validDate;
      this.logService.log(CliffLogCodes.DATE_OF_LOSS, logDetails);
      this.updateAnswer(validDate);
    }
  }

  toggleContinue(event: any) {
    this.isDateProvided = event;
  }

  validateDate(event: any): boolean {
    if (this.dateIsInvalid(event)) {
      this.isDateProvided = false;
      return false;
    }
    if (this.dateIsFuture(event)) {
      return false;
    }
    this.isDateProvided = true;
    return true;
  }

  dateIsInvalid(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isValid()) {
      this.error = false;
      return false;
    }
    this.error = true;
    this.futureError = false;
    return true;
  }

  dateIsFuture(date: string): boolean {
    if (moment.utc(date, this.dateFormatString).isBefore(moment.now())) {
      this.futureError = false;
      return false;
    }
    this.futureError = true;
    return true;
  }

  timeIsValid(hour: string, min: string): boolean {
    if ((hour === '' && min !== '') || (hour !== '' && min === '')) {
      return false;
    } else {
      return true;
    }
  }

  // not valid: returns false --- valid: returns Date as ISO 8601
  validateDateTimeNotFuture(): any {
    const dateTimeObject = this.datepicker.getFullDateTime();
    let dateTimeString = '';
    // if hour AND minute are both empty OR if they are both not empty go ahead and validate
    if (this.timeIsValid(dateTimeObject.capturedHour, dateTimeObject.capturedMinute)) {
      if (dateTimeObject.capturedHour === '' && dateTimeObject.capturedMinute === '') {
        dateTimeString = dateTimeObject.capturedDate;
        this.sendWithTime = false;
      } else {
        dateTimeString = dateTimeObject.capturedDate + 'T' + dateTimeObject.capturedHour
        + ':' + dateTimeObject.capturedMinute + ':' + dateTimeObject.ampm;
      }
      this.timeError = false;
      this.incidentDate = dateTimeString;
      if (this.validateDate(this.incidentDate)) {
        const returnDate = moment.utc(this.incidentDate, this.dateFormatString).toISOString();
        return (this.sendWithTime ? returnDate : returnDate.substring(0, 10));
      } else {
        return false;
      }
    }
    this.timeError = true;
    return false;
  }
}
